const colors = {
  primary: '#3FC7E0',
  primaryLight: '#DDFAFF',
  darkGray: '#697E8D',
  mediumGray: '#979797',
  middleGray: '#F5F5F5',
  lightGray: '#F4F5F7',
  green: '#A4E7CB',
  lightGreen: '#dcf2e9',
  white: 'white',
  black: 'black',
  red: '#F44336',
  lightRed: '#f2685e',
  yellow: '#f5d17f',
};
export default colors;
