import { getFullName } from '@root/utils/functions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TrainerSession } from '../models/booking';
import collections from '../utils/enums/collections.enums';
import { db } from './firebase';

// TODO: Move this to server side
export const fetchSessionsController = async ({
  status,
  confirmation,
  from,
  to,
  client,
  trainer,
}: {
  status?: string;
  confirmation?: string;
  from: string;
  to: string;
  client: string;
  trainer: string;
}) => {
  const toEndOfDay = moment(to).endOf('day').toDate();
  let query = db
    .collection(collections.SESSIONS)
    .where('dateTimestamp', '>=', from)
    .where('dateTimestamp', '<=', toEndOfDay);
  if (status) {
    query = query.where('status', '==', status);
  }

  if (confirmation) {
    query = query.where('confirmation', '==', confirmation);
  }

  if (status && confirmation) {
    query = query
      .where('status', '==', status)
      .where('confirmation', '==', confirmation);
  }

  if (client) {
    query = query.where('userId', '==', client);
  }
  if (trainer) {
    query = query.where('trainerId', '==', trainer);
  }

  const snapshot = await query.get();

  if (snapshot.empty) {
    return [];
  }

  return Promise.all(
    snapshot.docs.map(
      async (doc) => ({
        ...doc.data(),
        clientName: await getFullName(doc.data().userId),
        trainerName: await getFullName(doc.data().trainerId),
        lateCancellation: doc.data().lateCancellation ?? false,
      } as TrainerSession),
    ),
  );
};

export const useSessionsCount = ({
  userId,
  status,
  field = 'confirmation',
  discipline,
  isTrainer = false,
  excludeDiscipline,
}: {
  userId?: string;
  status: string | string[];
  field?: string;
  discipline?: string;
  isTrainer?: boolean;
  excludeDiscipline?: string;
}) => {
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    try {
      let query;
      if (Array.isArray(status)) {
        query = db.collection(collections.SESSIONS).where(field, 'in', status);
      } else {
        query = db.collection(collections.SESSIONS).where(field, '==', status);
      }

      if (isTrainer) {
        query = query.where('trainerId', '==', userId);
      } else {
        query = query.where('userId', '==', userId);
      }
      if (discipline === 'Nutrition') {
        query = query.where('discipline', 'in', [discipline, 'Coaching']);
      }
      if (excludeDiscipline) {
        query = query.where('discipline', 'not-in', [
          excludeDiscipline,
          'Coaching',
        ]);
      }
      query.onSnapshot((snap) => {
        setCount(snap.size);
      });
    } catch (error) {
      // console.log('====error on sessions count====', error);
    }
  }, [discipline, excludeDiscipline, field, isTrainer, status, userId]);

  return { count };
};
