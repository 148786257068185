/* eslint-disable */
import StyledLabelTitle from "@root/utils/styles/StyledLabelTitle";
import {
  TrainerPerformanceHead,
} from "../../components/table/content/Head";
import {
  TrainerPerformanceRows,
} from "../../components/table/content/rows";
import Table from "../../components/table/table";
import { useMemo, useState } from "react";
import { CompensationType } from "@root/models/revenue";
import { StyledSelect } from "@root/components/select/StyledDataSelect";
import useCache from "@root/hooks/useCache";

export default function TrainerPerformance() {
  const { trainerPerformance } = useCache() as any;
  const [trainer, setTrainer] = useState<{
    label: string;
    value: string;
    compensation: {
      type: CompensationType;
      firstPackage: number;
      renewal: number;
    };
  }>();

  const filteredItems = useMemo(() => {
    if (!trainer) return trainerPerformance?.data;
    return trainerPerformance?.data.filter((item) => item.trainerId === trainer.value);
  }, [trainer, trainerPerformance?.data]);

  const trainers = useMemo(() => {
    const trainers = trainerPerformance?.data.map((item) => ({
      label: item.trainerName,
      value: item.trainerId,
    }));
    return trainers;
  }, [trainerPerformance?.data]);

  return (
    <>
      <div>
        <StyledLabelTitle title="Trainer performance" refresh={trainerPerformance.refresh}
        lastRefresh={trainerPerformance.lastSync}
        />
        <div style={{ marginTop: 10 }} />
      </div>
      <div style={{ width: "50%" }}>
        <StyledSelect
          name="Trainer"
          // validationState={error ? "error" : "default"}
          classNamePrefix="react-select"
          options={trainers}
          value={trainer}
          isSearchable
          isClearable
          // inputId={id}
          onChange={(e) => {
            setTrainer(e);
          }}
        />
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table
          isLoading={trainerPerformance.loading}
          headType={TrainerPerformanceHead}
          row={TrainerPerformanceRows(filteredItems)}
        />
      </div>
    </>
  );
}
