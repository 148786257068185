import { useState, useEffect } from 'react';
import { OptionType } from '@typeModel/types';
import { Discipline } from '@models/disciplines';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';
import { CityRegion } from '../models/city';

export const UseCityRegionsFirestore = () => {
  const [cityRegions, setCityRegions] = useState<CityRegion[]>([]);

  useEffect(() => {
    const unsubscribe = db
      .collection(collections.CITY_REGIONS)

      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        if (fetched) {
          setCityRegions(fetched as CityRegion[]);
        }
      });
    return unsubscribe;
  }, []);

  return { cityRegions };
};

export const UseCountriesFirestore = () => {
  const [countries, setCountries] = useState<OptionType[]>([]);

  useEffect(() => {
    const unsubscribe = db
      .collection(collections.COUNTRIES)

      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data() }));
        if (fetched) {
          const sortedCountries = fetched.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
          setCountries(sortedCountries as OptionType[]);
        }
      });
    return unsubscribe;
  }, []);

  return { countries };
};

export const UseLanguagesFirestore = () => {
  const [languages, setLanguages] = useState<OptionType[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.LANGUAGES)

      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data() }));
        if (fetched) {
          setLanguages(fetched as OptionType[]);
        }
      });
    return unsubscribe;
  }, []);

  return { languages };
};
//
// DISCIPLINES

export const UseDisciplinesFirestore = () => {
  const [disciplines, setDisciplines] = useState<Discipline[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.DISCIPLINES)

      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data() }));
        if (fetched) {
          setDisciplines(fetched as Discipline[]);
        }
      });
    return unsubscribe;
  }, []);

  return { disciplines };
};

// PLANS

export const usePlansFirestore = () => {
  const [plans, setPlans] = useState<OptionType[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.PLANS)
      .where('isActive', '==', true)
      .orderBy('sessions')
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data(), planId: doc.id }));
        if (fetched) {
          setPlans(fetched as unknown as OptionType[]);
        }
      });
    return unsubscribe;
  }, []);

  return { plans };
};
