import Revenue, { TrainerPayment } from '@root/models/revenue';
import FirestoreService from '@root/utils/firestoreService';
import collections from '../utils/enums/collections.enums';
import { db, generateId } from './firebase';

class MonthlyBalanceController {
  static async get(month?: number, year?: number, trainerId?: string, clientId?: string) {
    let query:any = db.collection(collections.REVENUES);
    if (year) {
      query = query.where('year', '==', year);
    }
    if (month) {
      query = query.where('month', '==', month);
    }
    if (trainerId) {
      query = query.where('trainerId', '==', trainerId);
    }
    if (clientId) {
      query = query.where('clientId', '==', clientId);
    }
    const revenues = await query.orderBy('bookingDate', 'desc').get();
    const data = revenues.docs.map((doc) => doc.data());

    return data;
  }

  static async update(id: string, data: any) {
    const doc = await db.collection(collections.REVENUES).doc(id)
      .update({
        ...data,
        updatedAt: FirestoreService.getTimestamp(),
      });
    return doc;
  }

  static async delete(id: string) {
    const doc = await db.collection(collections.REVENUES).doc(id).delete();
    return doc;
  }

  static async updatePercentage(item: Revenue, percentage: number) {
    const trainerCompensation = Number((item.pricePerSession * percentage).toFixed(2));
    const fitlovCommission = Number((item.pricePerSession - trainerCompensation).toFixed(2));
    this.update(item.id, {
      trainerCompensation,
      fitlovCommission,
      trainerPercentage: Number(percentage.toFixed(2)),
      note: `${item.note}. Admin updated percentage`,
    });
  }

  // * No implemented yet
  static async markTrainerAsPaid(info:
    {trainerId: string, amount: number, sessions: number, month: string, year: number}) {
    const trainerPayment: TrainerPayment = {
      id: generateId(collections.trainerPayments),
      trainerId: info.trainerId,
      createdAt: new Date(),
      amount: info.amount,
      sessions: info.sessions,
      month: info.month,
      year: info.year,
    };

    return db.collection(collections.trainerPayments).doc(trainerPayment.id).set(trainerPayment);
  }
}

export default MonthlyBalanceController;
