/* eslint-disable no-unused-vars */
/* eslint-disable import/export */
/* eslint-disable no-shadow */
enum Availability {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum AvailabilityTypes {
  AVAILABLE = 'available',
  UNAVAILABLE = 'not available',
  FITLOV_BOOKED = 'fitlov booked',
  BUFFER = 'buffer',
}
/*
  @enum AvailabilityLimitHours
  @description Format in 24h format
*/
export enum AvailabilityLimitHours {
  MIN_HOUR = 6,
  MAX_HOUR = 22,
}
export const MAX_HOUR_TIME = '9:30pm';

export default Availability;
