/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum routes {
  HOME = '/home',
  TRAINER = '/trainer',
  TRAINERPROFILE = '/trainerProfile',
  USERPROFILE = '/UserProfile',
  PROMOCODES = '/PromoCodes',
  GYMS = '/gyms',
  OFFERS = '/offers',
  RATINGS = '/Ratings',
  MONTHLYBALANCE = '/MonthlyBalance',
  PACKAGES = '/Packages',
  COVERAGE = '/Coverage',
  RENEWAL = '/Renewal',
  SESSIONLOGS = '/sessionLogs',
  ID = '/:id',
  LOGIN = '/login',
  TRANSACTIONS = '/transactions',
  DUPLICATEBOOKINGS = '/duplicateBookings',
  BANNERS = '/banners',
  DISCIPLINES = '/disciplines',
  CITIES = '/cities',
  TRAINERPACKAGES = '/trainerPackages',
  TRAINERCHANGES = '/trainerChanges',
  PREBOOKINGS = '/preBookings',
  TRAINERPERFORMANCE = '/trainerPerformance',
}

export default routes;
