import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';
import StyledUserProfileElementsLarge from '../../utils/styles/StyledUserProfileElementsLarge';
import StyledUserProfileElementRightLarge from '../../utils/styles/StyledUserProfileRightLarge';

export default function CoverageTop() {
  return (
    <div>
      <StyledLabelTitle title="Overview" />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 40,
          marginLeft: 20,
        }}
      >
        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementsLarge
            name="Number of PTs"
            placeholder="20"
          />
          <StyledUserProfileElementRightLarge
            name="Number of Active Users"
            placeholder="20"
          />
        </div>

        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementsLarge
            name="Overall availability"
            placeholder="90%"
          />
          <StyledUserProfileElementRightLarge
            name="Monthly sessions booked"
            placeholder="50"
          />
        </div>
        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementsLarge
            name="Peak-hour availability"
            placeholder="80%"
          />
        </div>
        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementsLarge
            name="Bookable slots"
            placeholder="70%"
          />
        </div>
        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementsLarge
            name="Bookable slots Peak Hour"
            placeholder="70%"
          />
        </div>
      </div>
    </div>
  );
}
