import { useEffect, useState } from 'react';
import './topbar.css';
import { NotificationsNone } from '@material-ui/icons';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import Button from '@atlaskit/button/standard-button';
import AuthController from '@controllers/authController';
import { useHistory } from 'react-router-dom';
import routes from '@utils/enums/routes.enums';
import { getUsers } from '@root/controllers/userFirestoreController';

export default function Topbar() {
  const history = useHistory();
  const [username, setUsername] = useState<string>('');
  useEffect(() => {
    const getUsername = async () => {
      const name = await getUsers(AuthController.getUserId());
      setUsername(name.fullname);
    };
    getUsername();
  }, []);
  const onLogout = () => {
    AuthController.signOut();
    history.replace(routes.LOGIN);
  };

  return (
    <div className="topbar">
      <div className="topLeft">
        <img src="https://i.postimg.cc/MGWLvXCq/fitlov.png" className="logo" alt="logo" />
      </div>
      <div className="topRight">
        <div className="topbarIconContainer divider-right">
          <NotificationsNone />
          <span className="topIconBadge">2</span>
        </div>
        <div className="topbarIconContainer avatar">
          <AvatarItem
            avatar={<Avatar presence="online" />}
            primaryText={username || 'No Name'}
          />
        </div>
        <div className="topbarIconContainer">
          <Button appearance="subtle" onClick={onLogout}>
            <SignOutIcon label="siginout" />
          </Button>
        </div>
      </div>
    </div>
  );
}
