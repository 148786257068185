/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "@atlaskit/button/loading-button";
import Modal, {
  ModalTransition,
  ModalBody,
  ModalFooter,
} from "@atlaskit/modal-dialog";
import TrashIcon from '@atlaskit/icon/glyph/trash';
import CustomModalHeader from "@root/components/modals/modalHeader";
import { toast } from "react-toastify";
import StorageController from "@root/controllers/storageController";
// import FirestoreService from "@root/utils/firestoreService";
import { TrainerMedia, TrainerMediaType } from "@root/models/trainerMedia";
import { db, generateId } from "@root/controllers/firebase";
import collections from "@root/utils/enums/collections.enums";
import { StyledSelect } from "@root/components/select/StyledDataSelect";
import { OptionType } from "@atlaskit/select";
import { MediaTagsTypes } from "@root/utils/common/data";

const ModalDropMedia: React.FC<{
  trainerId: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ trainerId, isOpen, onClose }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: ".jpg,.jpeg,.png,.mov,.mp4",
      multiple: true,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    } as any);

  useEffect(
    () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      () =>
        files.forEach((file: any) => URL.revokeObjectURL(file.preview)),
    []
  );

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const onRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }
  const onChangeText = (index: number,value:any) => {
    const newFiles = [...files];
    newFiles[index].tag = value;
  }
  const thumbs = files.map((file: any, index: number) => (
    <div style={thumb as any} key={file.name}>
      <div style={thumbInner}>
        {Boolean(file.type.includes("video")) ? (
          <video width="100" style={{ aspectRatio: "9 / 16" }} controls preload="metadata">
            <source src={file.url} type="video/mp4" />
          </video>
        ) : (
          <div style={{display:"flex",flexDirection:"column",width:100}}>
            <>
          <img
            alt="media"
            src={file.preview}
            style={img as any}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
            <StyledSelect
            isSearchable
            options={ MediaTagsTypes as OptionType[]}
            value={file.tag}
              onChange={(e)=>{
                onChangeText(index,e.value);
              }
            }
          /></>
        </div>
        )}
      </div>
      <div style={trashButton as any} >
        <Button appearance="link" onClick={() => onRemoveFile(index)} >
          <TrashIcon label="Delete Package" primaryColor="#DE350B" />
        </Button>
      </div>
    </div>
  ));

  const onSave = async () => {
    setLoading(true);

    if (!files.length) {
      setLoading(false);
      return toast.error("No files to upload"); 
    }

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i] as any;
        const blobUrl = URL.createObjectURL(file);
        const name = (file as any).name;
        const folder = trainerId;
        const isVideo = file.type.includes("video");

        const uri = await StorageController.uploadPdfReport({
          blobUrl,
          name,
          folder,
        });

        const trainerMedia: TrainerMedia = {
          id: generateId(collections.trainerMedia),
          createdAt: new Date(),
          order: 99,
          trainerId,
          type: isVideo ? TrainerMediaType.VIDEO : TrainerMediaType.IMAGE,
          url: uri,
          tag:files[i].tag?? null,
          enabled: false,
        };
        await db
          .collection(collections.trainerMedia)
          .doc(trainerMedia.id)
          .set(trainerMedia);
        toast.success(`${i + 1} of ${files.length} uploaded`);
      }
    } catch (error) {
      toast.error(error);
    }
    setFiles([]);

    setLoading(false);
  };

  const closeModal = () => {
    setFiles([]);
    onClose();
  };

  if (!isOpen) return <></>;

  return (
    <ModalTransition>
      <Modal onClose={closeModal}>
        <CustomModalHeader title="Add new pictures/videos" />
        <ModalBody>
          <div className="">
            <div {...getRootProps({ style } as any)}>
              <input {...getInputProps()} />
              <p>
                Drag and drop or click to select the banner here. For better
                result upload image /videos with ratio of width:height 9:16.
                <br />
                <span>1. Orientation should be portrait.</span>
                <br />
                <span>2. Video format should be mp4</span>
                <br />
                <span>3. Image format should be webp</span>
              </p>
            </div>
            <div style={thumbsContainer as any}>{thumbs}</div>
          </div>
          <ModalFooter>
            <Button onClick={onSave} isLoading={loading} isDisabled={loading}>
              Save media
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </ModalTransition>
  );
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  //   display: "block",
  width: "100%",
  aspectRatio: 9 / 16,
  objectFit: "cover",
};
const thumbsContainer = {
  display: "flex",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  aspectRatio: 9 / 16,
  padding: 4,
};

const trashButton = {
  position: 'absolute',
}

export default ModalDropMedia;
