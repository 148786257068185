/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Button, { LoadingButton } from '@atlaskit/button';
import dotenv from 'dotenv';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useCallback, useState } from 'react';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';

dotenv.config();

const API_URL = process.env.REACT_APP_FIREBASE_FUNCTION_URL;

export default function UpdateMobile({
  userId,
  existingNumber,
}: {
  userId: string;
  existingNumber: {areaCode: string; number: string}
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const {
        areaCode, number,
      } = data;
      const body = {
        userId,
        mobile: {
          areaCode,
          number,
        },
      };
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(`${API_URL}/updateUser`, options)
        .then((response) => response.json())
        .then((response) => {
          if (response.code) {
            if (response.code === 'auth/invalid-phone-number') {
              setError('The mobile number is invalid');
            } else {
              setError(response.message);
            }
          }
          if (response.uid) {
            closeModal();
          }
        })
        .catch((err) => {
          console.log('===Error updating number===', err);
          if (err.code === 'auth/invalid-phone-number') {
            setError('The mobile number is invalid');
          } else {
            setError(err.message);
          }
        })
        .finally(() => setLoading(false));
    //   closeModal();
    } catch (err) {
      console.log('Phone number update on submit error', err);
      setError('Oops! Something went wrong. Please try again.');
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        appearance="warning"
        onClick={openModal}
        style={{
          height: 38,
          alignItems: 'center',
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        Update Mobile Number
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({
                formProps,
              }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Update Mobile Number" />
                  <ModalBody>
                    <Field id="areaCode" name="areaCode" isRequired defaultValue={existingNumber?.areaCode}>
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Area Code"
                          fieldProps={fieldProps}
                          placeholder="+971"
                        />
                      )}
                    </Field>
                    <Field id="number" name="number" isRequired defaultValue={existingNumber?.number}>
                      {({ fieldProps }) => (
                        <FormFieldInput
                          type="number"
                          label="Mobile Number"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </ModalBody>
                  <ModalFooter>
                    <LoadingButton
                      type="submit"
                      isLoading={loading}
                      isDisabled={loading}
                      className="modal-button"
                    >
                      Submit
                    </LoadingButton>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
