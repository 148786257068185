/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const gray = '#313131';
function ActionIcon({ name }:{name: string}) {
  return (
    <span style={{
      fontSize: '20px',
      color: gray,
    }}
    >
      {name === 'plus' ? '+' : '-'}
    </span>
  );
}

export default function TimeSlotBtn({
  onPress,
  name,
  isLast,
  showBtn,
}: {
  onPress: () => void;
  name: string;
  isLast?: boolean;
  showBtn?: boolean;
}) {
  const color = isLast || showBtn ? gray : gray;
  return (
    <div
      onClick={onPress}
      style={{
        height: 24,
        width: 24,
        borderRadius: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: '1.5px',
        borderColor: color,
      }}
    >
      <ActionIcon name={name} />
    </div>
  );
}
