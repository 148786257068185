/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum BookingState {
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum BookingConfirmation {
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
  NO_ANSWER = 'no_answer',
  ON_HOLD = 'on_hold', // leaving it here temporarily, old logs on production still has this confirmation
}

export type BookingSlot = {
  startHour: number;
  endHour: number;
  isWeekly?: boolean;
  weekDay?: string; // monday,tuesday,wednesday,thursday,friday,saturday,sunday
  // minimunDate?: string;
  lastDate?: string;
};

export enum BookingLimits {
  HOURS_IN_ADVANCE = 0,
}
export enum BookingServiceType {
  ONE = '1 on 1',
  TWO = '2 on 1',
  THREE = '3 on1',
}
export enum BillingPaidBy {
  Admin = 'Admin',
  Mobile = 'Mobile',
}

export enum RejectedActionStatus {
  PENDING = 'PENDING',
  SOLVED = 'SOLVED',
}

export enum TrainingCancellationReasons {
  AGREED_CHANGE_WITH_CLIENT = 'I already agreed the change with the client (CANCELLATION)',
  UNAVAILABLE_AT_THAT_DATE = 'I am unavailable at that day & time (REJECTION)',
  DONT_WANT_COVER_LOCATION = "I don't want to cover this location (REJECTION)",
  DID_PRE_BOOKED_THIS_SLOT = 'I pre-booked this slot for another Fitlov client (REJECTION)',
  OTHER = 'Other - Tell us why (REJECTION)',
}
