// eslint-disable-next-line no-use-before-define
import React, { useState, useMemo, useEffect } from 'react';
import { TransactionHead } from '@root/components/table/content/Head';
import { TransactionsRows } from '@root/components/table/content/rows';
import Table from '@root/components/table/table';
import { BillingFirestore } from '@root/controllers/billingFirestoreController';
import Textfield from '@atlaskit/textfield';
import _ from 'lodash';

import Select, { OptionType } from '@atlaskit/select';
import { TransactionCreatedOptions } from '@root/utils/common/data';
import { BillingHistory } from '@root/models/billingDetails';
import { Trainer } from '@root/models/trainer';
import { fetchTrainers } from '@root/controllers/trainerFirestoreController';
import { User } from '@root/models/user';
import moment from 'moment';
import { CSVLink } from 'react-csv';

export default function Transactions() {
  const { items } = BillingFirestore();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchPromoValue, setSearchPromoValue] = useState<string>('');
  const [createdByFilter, setCreatedByFilter] = useState<string | null>('All');
  const [nearTrainers, setNearTrainers] = useState<(Trainer & User)[]>([]);
  const trainerOptions: OptionType[] = useMemo(() => {
    const options = _.map(nearTrainers || [], (trainer) => ({
      value: trainer.id!,
      label: trainer.displayName || trainer.fullname || '',
    }));

    return options;
  }, [nearTrainers]);

  const [csvData, setCsvData] = useState<any>([]);

  useEffect(() => {
    fetchTrainers().then((results) => {
      setNearTrainers(results);
    });
  }, []);

  const filterTransactionsList = useMemo(() => {
    let filteredBySearchValue: BillingHistory[] = [];

    if (searchValue.length === 0) {
      filteredBySearchValue = items;
    } else {
      filteredBySearchValue = _.filter(items, (transaction: BillingHistory) => _.includes(
        transaction?.userName?.toLocaleLowerCase(),
        searchValue.toLocaleLowerCase(),
      ));
    }

    if (searchPromoValue.length !== 0) {
      filteredBySearchValue = _.filter(
        filteredBySearchValue,
        (transaction: BillingHistory) => _.includes(
          transaction?.couponCode?.toLocaleLowerCase(),
          searchPromoValue.toLocaleLowerCase(),
        ),
      );
    }

    if (!createdByFilter || createdByFilter !== 'All') {
      filteredBySearchValue = _.filter(
        filteredBySearchValue,
        (transaction: BillingHistory) => {
          const byMadeBy = _.includes(
            transaction?.madeBy?.toLocaleLowerCase(),
            createdByFilter,
          );
          const byPaidBy = _.includes(
            transaction?.madeBy?.toLocaleLowerCase(),
            createdByFilter,
          );
          return byMadeBy || byPaidBy;
        },
      );
    }

    return filteredBySearchValue;
  }, [items, searchValue, searchPromoValue, createdByFilter]);

  useEffect(() => {
    // Generate CSV from September 2023 until today, columns: date, user, trainer,
    // number of sessions, promocode, total price, created, payment type
    const newCsv: any = [];
    const filterTransactionListByDate = _.filter(filterTransactionsList, (item) => {
      const createdAt = item?.createdAt
        ? moment(item.createdAt, 'DD MMM YYYY').format('DD MMM YYYY') : 'N/A';
      // return moment(createdAt).isAfter('Year-Month-Day');
      return moment(createdAt).isAfter('2024-02-01');
    });
    filterTransactionListByDate.forEach((transaction) => {
      newCsv.push({
        Date: transaction.createdAt,
        User: transaction.userName,
        Trainer: transaction.trainerName,
        Sessions: transaction.sessions,
        Promocode: transaction.couponCode,
        'Total Price': transaction.price,
        'Created by': transaction.madeBy,
        'Payment Type': transaction.typeOfPayment,
      });
    });
    setCsvData(newCsv);
  }, [filterTransactionsList]);

  console.log({
    csvData,
    filterTransactionsList: filterTransactionsList.length,
  });

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 150, marginLeft: 30 }}>
          <Textfield
            name="basic"
            aria-label="default text field"
            placeholder="Search by name"
            value={searchValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setSearchValue(e.currentTarget.value);
            }}
          />
        </div>
        <div style={{ width: 176, marginLeft: 30 }}>
          <Textfield
            name="basic"
            aria-label="default text field"
            placeholder="Search by promo code"
            value={searchPromoValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setSearchPromoValue(e.currentTarget.value);
            }}
          />
        </div>
        <div style={{ width: 150, marginLeft: 30 }}>
          <Select
            inputId="multi-select-example"
            className="multi-select"
            classNamePrefix="react-select"
            options={TransactionCreatedOptions}
            isSearchable={false}
            placeholder="All"
            onChange={(option) => {
              setCreatedByFilter(option?.value || null);
            }}
          />
        </div>
        <CSVLink
          data={csvData}
          filename="transactions.csv"
        >
          Export CSV
        </CSVLink>
      </div>
      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table
          row={TransactionsRows(filterTransactionsList, trainerOptions)}
          headType={TransactionHead}
        />
      </div>
    </>
  );
}
