/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Select, { OptionsType } from '@atlaskit/select';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useFilePicker } from 'use-file-picker';
import moment from 'moment';
import { Offer } from '@root/models/offer';
import CitiesController from '@root/controllers/citiesController';
import { CityTypes } from '@root/models/city';
import { OffersFirestore } from '../../controllers/offersFirestoreController';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';
import AvatarPicture from '../avatar/Avatar';
import { TextAreaInput } from '../textInput';

export default function AddOffer() {
  const [isOpen, setIsOpen] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const { addOffer } = OffersFirestore();
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const [citiesDb, setCitiesDb] = useState<CityTypes[]>([]);
  const [activeCities, setActiveCities] = useState<any>([]);

  const getCities = async () => {
    const data = await CitiesController.getAllCities();
    setCitiesDb(data);
  };
  useEffect(() => {
    getCities();
  }, []);
  // eslint-disable-next-line max-len
  const cities: OptionsType[] = citiesDb.map((item) => ({ value: item.name, label: item.name })) as any;

  const [openFileSelector, {
    filesContent, clear,
  }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const onSubmit = async (data) => {
    setFormError(null);
    if (activeCities.length < 1) {
      setFormError('Pick City');
      return;
    }
    if (filesContent[0]?.content) {
      try {
        const {
          order, title, description, promoCode, expiryDate,
        } = data;
        const couponCode: Offer = {
          order: Number(order),
          code: promoCode,
          isActive: true,
          title,
          description,
          expiryDate,
          city: activeCities.map((item) => item.value),
        };
        await addOffer(couponCode, filesContent[0].content);
        clear();
        closeModal();
        setFormError(null);
      } catch (err) {
        // console.log({ err });
      }
    } else {
      setFormError('Please select image');
    }
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        (+) Add New Offer
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New Offer" />
                  <ModalBody>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <AvatarPicture pictureUrl={filesContent[0]?.content} />
                      <Button
                        onClick={openFileSelector}
                        appearance="primary"
                        style={{
                          backgroundColor: '#3FC7E0',
                          marginLeft: 10,
                        }}
                      >
                        Upload Image
                      </Button>
                    </div>
                    {formError && <ErrorMessage>{formError}</ErrorMessage>}
                    <Field id="order" name="order" label="Order" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} type="number" value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="title" name="title" label="Title" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="promoCode" name="promoCode" label="Promo Code" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    City:
                    <Select
                      className="multi-select"
                      options={cities}
                      values={activeCities}
                      onChange={(value) => setActiveCities(value)}
                      isMulti
                      isSearchable={false}
                      styles={{
                        control: (base) => ({
                          ...base,
                        }),
                      }}
                    />
                    <Field id="description" name="description">
                      {({ fieldProps }) => (
                        <TextAreaInput
                          label="Description:"
                          {...fieldProps}
                        />
                      )}
                    </Field>
                    <Field
                      id="expiryDate"
                      name="expiryDate"
                      defaultValue={moment().format('YYYY-M-D')}
                      // eslint-disable-next-line consistent-return
                      validate={(value) => {
                        if (!value) return 'Date is required';
                      }}
                    >
                      {({ fieldProps: { onChange, ...rest }, error }) => (
                        <FormFieldInput
                          label="Expiry Date"
                          type="date"
                          error={error}
                          fieldProps={{
                            min: moment().format('YYYY-MM-DD'),
                            onChange: (
                              e: React.FormEvent<HTMLInputElement>,
                            ) => {
                              const { value } = e.currentTarget;
                              onChange(value);
                            },
                            ...rest,
                          }}
                        />
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
