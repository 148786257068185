/* eslint-disable */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import Button from '@atlaskit/button/loading-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDropzone } from 'react-dropzone';

import Checkbox from '@atlaskit/checkbox';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import CustomModalHeader from './modalHeader';
import StorageController from '@root/controllers/storageController';
import BannerController from '@root/controllers/bannerFirebaseController';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};
const thumbsContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  height: 100,
  padding: 4,
};

export default function AddBanner() {
  const [isOpen, setIsOpen] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ".jpeg,.png",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
    },
  } as any);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  const thumbs = files.map((file: any) => (
    <div style={thumb as any} key={file.name}>
      <div style={thumbInner}>
        <img
          alt="banner"
          src={file.preview}
          style={img}
          onLoad={() => { URL.revokeObjectURL(file.preview); }}
        />
      </div>
    </div>
  ));

  useEffect(
    () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      () => files.forEach((file: any) => URL.revokeObjectURL(file.preview)),
    [],
  );

  const onSubmit = async (data) => {
    const {title, subtitle, order, isActive, link} = data;
    setLoading(true);
    setFormError('');
    if (!files || !files.length) {
      setFormError('Please upload banner');
      return;
    }
    try {
      const fileUrl = await StorageController.uploadPdfReport({
        blobUrl: URL.createObjectURL(files[0]),
        name: `${(files[0] as any).name}`,
        folder: 'banners',
      });
      if(fileUrl) {
        await BannerController.addBanner({
          title,
          subtitle,
          order: Number(order),
          link,
          imageUrl: fileUrl,
          isActive: Boolean(isActive)
        });
      } else {
        setFormError('Banner image upload failed')
      }
    } catch (error) {
      console.log('Error uploading file', error);
    }
    setLoading(false);
    closeModal();
  };


  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        (+) Add New Banner
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New Banner" />
                  <ModalBody>
                    <div className="">
                      <div {...getRootProps({ style } as any)}>
                        <input {...getInputProps()} />
                        <p>Drag and drop or click to select the banner here. For better result upload image with the size 895px x 360px</p>
                      </div>
                      <div style={thumbsContainer as any}>
                        {thumbs}
                      </div>
                    </div>
                    {formError && <ErrorMessage>{formError}</ErrorMessage>}
                    <Field id="order" name="order" label="Order" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} type="number" value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="title" name="title" label="Title">
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="subtitle" name="subtitle" label="Sub Title">
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="link" name="link" label="Action" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} value={undefined} />
                          <p style={{fontSize: 12}}>Example: navigate="Screen Name"&params1="Param 1"&params2="Param 2" (navigate="Trainer List"&discipline="All Trainers"&isOfferFreeSessions="true")</p>
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="isActive" name="isActive">
                      {({ fieldProps: { onChange } }) => (
                        <Checkbox
                          // isChecked={isEnabledCustomVal}
                          label="Is Active"
                          onChange={onChange}
                          name="checkbox-default"
                          testId="cb-default"
                        />
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      isLoading={submitting || loading}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
