import { fbStorage } from './firebase';

class StorageController {
  static async getBlob(uri: string) {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  }

  static async uploadFile(
    content: string,
    folder: string,
    filename?: string,
  ): Promise<string> {
    const name = filename || new Date().getTime().toString();
    const storage = fbStorage.ref(folder).child(name);
    const file = await storage.putString(content, 'data_url');
    const fileUrl = await file.ref.getDownloadURL();
    return fileUrl;
  }

  static async uploadPdfReport({
    blobUrl,
    name,
    folder,
  }: {
    blobUrl: string;
    name: string;
    folder: string;
  }) {
    if (!blobUrl || !name) return null;

    try {
      const blob = await fetch(blobUrl).then((res) => res.blob());
      const snapshot = await fbStorage.ref(folder).child(name).put(blob);
      return await snapshot.ref.getDownloadURL();
    } catch (error) {
      // console.log('===Error uploading file====', error);
      // throw error;
      return error;
    }
  }

  static async deleteFile(uri: string): Promise<any> {
    const storage = fbStorage.refFromURL(uri);
    return storage.delete();
  }
}

export default StorageController;
