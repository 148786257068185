/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { BillingHistory, UserBillingDetails } from '@models/billingDetails';
import ServiceType from '@root/utils/enums/billing.enums';
import { getFullName } from '@root/utils/functions';
import FirestoreService from '@utils/firestoreService';
import _ from 'lodash';
import firebase from 'firebase';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import collections from '../utils/enums/collections.enums';
import CouponCodeController from './cuponCodesFirestoreController';
import { db } from './firebase';

export const useBillingFirestore = (userId: string) => {
  // eslint-disable-next-line max-len
  const [latestTrainingBilling, setLatestTrainingBilling] = useState<UserBillingDetails | null>(null);
  // eslint-disable-next-line max-len
  const [latestWellnessBilling, setLatestWellnessBilling] = useState<UserBillingDetails | null>(null);

  const fetchBillingDetails = useCallback(
    async ({ serviceType }: { serviceType?: string }) => {
      db.collection(collections.BILLING_HISTORY)
        .where('userId', '==', userId)
        .where('serviceType', '==', serviceType)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .onSnapshot(async (snap) => {
          const fetched = await Promise.all(
            snap.docs.map(async (doc) => {
              if (doc.exists) {
                return doc.data();
              }
            }),
          );
          if (serviceType === ServiceType.TRAINING) {
            setLatestTrainingBilling(fetched[0] as UserBillingDetails);
          }
          if (serviceType === ServiceType.WELLNESS) {
            setLatestWellnessBilling(fetched[0] as UserBillingDetails);
          }
        });
    },
    [userId],
  );

  useEffect(() => {
    const fetchBillings = async () => {
      await fetchBillingDetails({
        serviceType: ServiceType.TRAINING,
      });
      await fetchBillingDetails({
        serviceType: ServiceType.WELLNESS,
      });
    };
    fetchBillings();
  }, [fetchBillingDetails, userId]);

  return { latestTrainingBilling, latestWellnessBilling };
};

export const BillingFirestore = () => {
  const [items, setItems] = useState<BillingHistory[]>([]);

  useEffect(() => {
    const unsubscribe = db
      .collection(collections.BILLING_HISTORY)
      .orderBy('createdAt', 'desc')
      .onSnapshot(async (snap) => {
        console.log('New changes in billinghistory');
        const fetched = await Promise.all(
          snap.docs.map(async (doc) => {
            const username = await getFullName(doc.data().userId);
            const coupon = await CouponCodeController.getCouponCodeById(
              doc.data().couponId,
            );
            return {
              ...doc.data(),
              createdAt: moment(doc.data().createdAt.toDate()).format(
                'DD MMM YYYY',
              ),
              madeBy: doc.data().madeBy || 'client',
              userName: username,
              couponCode: coupon?.code || '',
              fitlovPoints: doc.data().fitlovPoints,
            };
          }),
        );
        console.log('Billing history updated!');
        setItems(fetched as unknown as BillingHistory[]);
      });
    return unsubscribe;
  }, []);

  return { items };
};

class BillingController {
  static async get(userId: string, limit?: number) {
    try {
      let query = await db
        .collection(collections.BILLING_HISTORY)
        .where('userId', '==', userId);
      if (limit) {
        query = query.limit(limit);
      }
      const response = await query.orderBy('createdAt', 'desc').get();

      return response.docs.map((doc) => doc.data());
    } catch (error) {
      return null;
    }
  }

  static async create(object: UserBillingDetails) {
    try {
      const response = await FirestoreService.addObjectToCollection(
        collections.BILLING_HISTORY,
        { ...object, madeBy: 'admin' },
      );
      response.update({ id: response.id });
      // console.log('response.id', response.id);
    } catch (error) {
      // console.log('create billing history error', error);
    }
  }

  static async deleteBillingHistoryWithOnBooking(bookingId: string) {
    try {
      const data = await db
        .collection(collections.BILLING_HISTORY)
        .where(bookingId, '==', bookingId)
        .get();
      await Promise.all(
        _.map(data.docs, async (doc) => doc.ref.delete()),
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  static async getTrainerPtTransactions(trainerId: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const dataSnapshot = await db
        .collection(collections.BILLING_HISTORY)
        .where('madeBy', '==', 'trainer')
        .where('trainerId', '==', trainerId)
        .get();
      const transactions = await Promise.all(
        dataSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const createdAtKey = (data.createdAt as firebase.firestore.Timestamp).toMillis();
          const createdAt = moment((data.createdAt as firebase.firestore.Timestamp).toDate()).format('DD MMM YYYY');

          // eslint-disable-next-line no-nested-ternary
          const sessionVal = typeof data?.sessions === 'string'
            ? parseInt(data?.sessions, 10)
            : typeof data?.sessions === 'number'
              ? data?.sessions
              : 0;
          const userName = await getFullName(data.userId);

          return {
            id: doc.id,
            createdAtKey,
            createdAt,
            userName,
            sessions: sessionVal.toString(),
            typeOfPayment: data.typeOfPayment,
            price: data.price,
            paidBy: data.madeBy,
            couponCode: data.couponCode,
          };
        }),
      );
      resolve(_.orderBy(transactions, ['createdAtKey'], ['desc']));
    });
  }
}

export default BillingController;
