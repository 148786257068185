/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import React, { createContext, useContext, useMemo } from 'react';
import {
  UseCityRegionsFirestore,
  UseCountriesFirestore,
  UseDisciplinesFirestore,
  UseLanguagesFirestore,
  usePlansFirestore,
} from '@controllers/globalsFirestoreController';
import { OptionType } from '@typeModel/types';
import { Discipline } from '@models/disciplines';
import { CityRegion } from '@root/models/city';

const GlobalsContext = createContext<{
  cityRegions: CityRegion[];
  languages: OptionType[];
  countries: OptionType[];
  disciplines: Discipline[];
  plans: OptionType[];
}>({
  cityRegions: [],
  languages: [],
  countries: [],
  disciplines: [],
  plans: [],
});

export function GlobalsProvider({ children }: { children: React.ReactNode }) {
  const { cityRegions } = UseCityRegionsFirestore();
  const { languages } = UseLanguagesFirestore();
  const { countries } = UseCountriesFirestore();
  const { disciplines } = UseDisciplinesFirestore();
  const { plans } = usePlansFirestore();

  const globalsContext = useMemo(() => ({
    languages,
    cityRegions,
    countries,
    disciplines,
    plans,
  }), [languages,
    cityRegions,
    countries,
    disciplines,
    plans]);

  return (
    <GlobalsContext.Provider
      value={globalsContext}
    >
      {children}
    </GlobalsContext.Provider>
  );
}
export const useGlobalsContext = () => useContext(GlobalsContext);
