/* eslint-disable */
import FirestoreService from "@root/utils/firestoreService";
import { OptionType } from "@typeModel/types";
import firebase from "firebase";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import dotenv from "dotenv";
import { User } from "../models/user";
import collections from "../utils/enums/collections.enums";
import { db, firebaseApp } from "./firebase";

import StorageController from "./storageController";
import { ClientPackage, PackagesServices } from "@root/models/clientPackage";

dotenv.config();
const API_URL = process.env.REACT_APP_FIREBASE_FUNCTION_URL;

export async function getAllUsers(
  page: number,
  fullname?: string,
  mobile?: string,
  status?: string,
  city?: string
): Promise<{
  totalUsers: number;
  totalPages: number;
  currentPage: number;
  users: User[];
}> {
  return new Promise(async function (resolve, reject) {
    console.log("Calling getAllUsers");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // CORS
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ page, fullname, mobile, status, city }),
    };
    // fetch(`${API_URL}/getAllUsers`, options)
    fetch(`${API_URL}/getAllUsers`, options)
      .then((response) => response.json())
      .then((response) => {
        // resolve(response.users);
        resolve({
          totalUsers: response.totalUsers,
          totalPages: response.totalPages,
          currentPage: response.currentPage,
          users: response.users,
        });
      })
      .catch((err) => {
        console.log(err);
        resolve({
          totalUsers: 0,
          totalPages: 0,
          currentPage: 0,
          users: [],
        });
      });
  });
}
export const useFirestore = () => {
  const [items, setItems] = useState<User[]>([]);

  const addUser = async (object) => {
    try {
      const response = await FirestoreService.addObjectToCollection(
        collections.USERS,
        object
      );
      response.update({
        id: response.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (error) {
      // console.log('create user error', error);
    }
  };

  return { items, addUser };
};

export const useProgressDetails = (UserId: string) => {
  const [progressDetails, setProgressDetails] = useState<any>([]);

  useEffect(() => {
    const unsubscribe = db
      .collection(collections.PROGRESS)
      .where("userId", "==", UserId)
      .onSnapshot((snap) => {
        const details = snap.docs.map((doc) => ({ ...doc.data() })) as any;
        setProgressDetails(details);
      });
    return unsubscribe;
  }, [UserId]);
  return { progressDetails };
};

export const getUsers = async (id) => {
  const user = await (await db.doc(`${collections.USERS}/${id}`).get()).data();

  return { ...user };
};

export const getUserWithMobileNumber = async (mobileNumber: string) => {
  const user = await db
    .collection(collections.USERS)
    .where("mobile.number", "==", mobileNumber)
    .get();
  if (user.empty) {
    return null;
  }
  return user.docs[0].data();
};
export const getDoc = (id: string) => db.collection(collections.USERS).doc(id);
export const getUsersWithIds = async (userIds) => {
  const snapshot = await db
    .collection(collections.USERS)
    .where(firebaseApp.firestore.FieldPath.documentId(), "in", userIds)
    .get();

  return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};
export const deleteUser = async ({
  userId,
  isTrainer,
}: {
  userId: string;
  isTrainer: boolean;
}): Promise<void> => {
  try {
    // 1. remove reviews
    await FirestoreService.deleteObjectsOnCollection({
      collectionName: collections.REVIEWS,
      field: isTrainer ? "trainerId" : "userId",
      value: userId,
    });
    // 2.remove chat
    const chatData = await db
      .collection(collections.CHATS)
      .where("membersId", "array-contains", userId)
      .get();
    await Promise.all(
      _.map(chatData.docs, async (doc) => {
        const messageSnapshot = await doc.ref
          .collection(`${collections.MESSAGES}`)
          .get();
        messageSnapshot.docs.map(async (msgDoc) => {
          await msgDoc.ref.delete();
        });
        await doc.ref.delete();
      })
    );
    // 3.remove Booking
    await FirestoreService.deleteObjectsOnCollection({
      collectionName: collections.BOOKINGS,
      field: isTrainer ? "trainerId" : "userId",
      value: userId,
    });

    // Update: Not necessary do this here because our server is already deleting it
    // 4.remove User
    // const userData = await await db.doc(`${collections.USERS}/${userId}`).get();
    // await userData.ref.delete();

    // 5 remove user from firebase auth
    await fetch(`${API_URL}/deleteUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: userId }),
    });
    // const responseJson = await response.json();
    // console.log('responseJson', responseJson);
  } catch (error) {
    // console.log('deleteUser error', error);
  }
};
export const updateUser = (user: User) => {
  try {
    return db.collection(collections.USERS).doc(user.id).update(user);
  } catch (error) {
    // console.log('===Error updating trainer====', error);
    return error;
  }
};

export const deleteFieldFromUser = ({
  id,
  fieldToDelete,
}: {
  id: string;
  fieldToDelete: string;
}) => {
  try {
    return db
      .collection(collections.USERS)
      .doc(id)
      .update({
        [fieldToDelete]: firebase.firestore.FieldValue.delete(),
      });
  } catch (error) {
    console.log("===Error deleting field====", error);
    return error;
  }
};

export const updateUserNutritionReport = async ({
  user,
  file,
  keyToUpdate,
  existingFile,
}: {
  user: User | null;
  file: string;
  keyToUpdate: string;
  existingFile: string | null | undefined;
}) => {
  if (user) {
    const fileUrl = await StorageController.uploadFile(file, user.id!);
    const newUser = user;
    newUser[keyToUpdate] = fileUrl;

    await updateUser(newUser);
    if (existingFile) {
      StorageController.deleteFile(existingFile);
    }
  } else {
    // console.log('===No user selected====');
  }
};

export const UseUsersFirestore = () => {
  const [userSelect, setUserSelect] = useState<OptionType[]>([]);
  useEffect(() => {
    const unsubscribe = db.collection(collections.USERS).onSnapshot((snap) => {
      const fetched = snap.docs.map((doc) => ({ ...doc.data() }));
      if (fetched) {
        const options = fetched.map(
          ({
            id: value,
            fullname: label,
            roles,
            mobile,
            isActive,
            displayName,
          }) => ({
            value,
            label:
              label || `${mobile?.areaCode || "-"} ${mobile?.number || "-"}`,
            displayName,
            roles,
            isActive,
          })
        );
        setUserSelect(options);
      }
    });
    return unsubscribe;
  }, []);

  const { trainers, users }: { trainers: OptionType[]; users: OptionType[] } =
    useMemo(() => {
      // eslint-disable-next-line no-shadow
      const trainers = userSelect.filter(
        (elm) => elm?.roles?.includes("trainer") && elm.isActive
      ) as OptionType[];
      // eslint-disable-next-line no-shadow
      const users = userSelect.filter((elm) =>
        elm?.roles?.includes("user")
      ) as OptionType[];
      return { trainers, users };
    }, [userSelect]);

  return { users, trainers, userSelect };
};

export const getPackage = async (userId: string, trainerId?: string) => {
  let snapshot = await db
    .collection(collections.CLIENT_PACKAGES)
    .where("userId", "==", userId);
  if (trainerId) {
    snapshot.where("trainerId", "==", trainerId);
  }

  return (await snapshot.get()).docs.map((doc) =>
    doc.data()
  ) as ClientPackage[];
};

export const addPackage = async (clientPackage: ClientPackage) => {
  const response = await db
    .collection(collections.CLIENT_PACKAGES)
    .add(clientPackage);

  response.update({
    id: response.id,
  });
  return response;
};

export const updatePackage = async (item: ClientPackage) => {
  const { id } = item;
  return db.collection(collections.CLIENT_PACKAGES).doc(id).update(item);
};

export const getPricePerSession = async (userId: string) => {
  try {
    const request = await fetch(`${API_URL}/getPricePerSession`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
      }),
    });
    return request.json() as Promise<{
      price: number;
      billingHistoryId: string | null;
    }>;
  } catch (error) {
    return {
      price: 0,
      billingHistoryId: null,
    };
  }
};

export const useClientPackageSessionsCount = ({
  userId,
  trainerId,
}: {
  userId: string;
  trainerId?: string | undefined;
}) => {
  const [trainingSessions, setTrainingSessions] = useState<number>(0);
  const [wellnessSessions, setWellnessSessions] = useState<number>(0);
  useEffect(() => {
    const getPackages = async () => {
      try {
        const packages = await getPackage(userId, trainerId);

        packages.forEach((pkg) => {
          if (pkg.servicesAllowed?.includes(PackagesServices.WELLNESS)) {
            setWellnessSessions(Number(pkg.sessions));
          } else {
            setTrainingSessions(Number(pkg.sessions));
          }
        });
      } catch (error) {
        console.log("===Error getting packages===", error);
      }
    };

    getPackages();
  }, []);
  // This thing was causing infinite loop and the app crash
  // }, [trainingSessions, wellnessSessions]);

  return { trainingSessions, wellnessSessions };
};
