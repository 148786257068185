/* eslint-disable react/require-default-props */
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';

export default function WarningModal({
  title,
  subtitle,
  onPrimaryAction,
  primaryButtonLabel,
  secondaryButtonLabel = 'Cancel',
  triggerButton,
}: {
  title: string;
  subtitle?: string;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
  onPrimaryAction: () => void;
  triggerButton: React.ReactElement;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const handlePrimaryAction = useCallback(() => {
    onPrimaryAction();
    closeModal();
  }, [onPrimaryAction, closeModal]);

  return (
    <>
      {React.cloneElement(triggerButton, {
        onClick: openModal,
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...triggerButton.props.style,
        },
      })}
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle appearance="danger">{`${title}`}</ModalTitle>
            </ModalHeader>
            <ModalBody>{subtitle && subtitle}</ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal} autoFocus>
                {secondaryButtonLabel}
              </Button>
              <Button appearance="danger" onClick={handlePrimaryAction}>
                {primaryButtonLabel}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
