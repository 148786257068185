import { citiesHead } from '@root/components/table/content/Head';
import { CitiesRows } from '@root/components/table/content/rows';
// import CitiesController from '@root/controllers/citiesController';
import { AddCity } from '@root/components/modals/AddNewCityModal';
import { useCitiesFirestore } from '@root/controllers/citysFirestoreController';
import Table from '../../components/table/table';

export default function Cities() {
  const { updateCity, cities } = useCitiesFirestore();

  return (
    <>
      <AddCity />
      <Table
        headType={citiesHead}
        row={CitiesRows(cities, updateCity)}
      />
    </>
  );
}
