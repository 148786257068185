import { css } from '@emotion/react';

import Textfield from '@atlaskit/textfield';

const bigFontStyles = css({
  paddingRight: 10,
  color: 'black',
  '& > [data-ds--text-field--input]': {
    fontSize: 14,
    color: 'black',
  },
});

export default function StyledLabelLarge({ label } : { label: string }) {
  return (
    <div
      className="label"
      style={{ width: 230, backgroundColor: '#F4F5F7', borderRadius: 3 }}
    >
      <Textfield
        aria-label="customized text field"
        placeholder={label}
        css={bigFontStyles}
        style={{
          minWidth: 250,
          backgroundColor: '#F4F5F7',
          color: 'red',
        }}
        color="black"
        isCompact
        isDisabled
      />
    </div>
  );
}
