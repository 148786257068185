import AddTrainerPackage from '@root/components/modals/AddTrainerPackage';
import { useTrainer } from '@root/controllers/trainerFirestoreController';
import TrainerPackageFirestore from '@root/controllers/trainerPackageFirestoreController';
import { OptionType } from '@root/types/types';
import StyledSearch from '@root/utils/styles/StyledSearch';
import { useMemo, useState } from 'react';
import { TrainerPackageHead } from '../../components/table/content/Head';
import { TrainerPackagesRows } from '../../components/table/content/rows';
import Table from '../../components/table/table';
import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';

export default function TrainerPackages() {
  const { items, filterTrainerPackage } = TrainerPackageFirestore();
  const { items: verifiedTrainers } = useTrainer();

  const [selectedTrainer, setSelectedTrainer] = useState<string | null | undefined>(null);

  const trainerOptions: OptionType[] = useMemo(
    () => {
      const trainers: OptionType[] = [];
      // eslint-disable-next-line array-callback-return
      items.map((item) => {
        const trainerData = verifiedTrainers.find((trainer) => trainer.id === item.trainerId);
        const exists = trainers.findIndex((trainer) => trainer.value === trainerData?.id) >= 0;
        if (!exists && trainerData) {
          trainers.push({
            value: trainerData.id as string,
            label: trainerData.displayName || trainerData.fullname || '',
          });
        }
      });
      return trainers;
    },
    [verifiedTrainers, items],
  );

  const selectUser = (user: OptionType, isClear?: boolean) => {
    if (isClear) {
      setSelectedTrainer(null);
      filterTrainerPackage(null);
    } else {
      setSelectedTrainer(user.value as string);
      filterTrainerPackage(user?.value as string | undefined);
    }
  };

  console.log('==Select User==', selectedTrainer);
  return (
    <>
      <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <StyledLabelTitle title="Trainer Packages" />

        <AddTrainerPackage />
        <div
          style={{
            display: 'inline-flex',
            width: 400,
            marginRight: 20,
          }}
        >
          <StyledSearch
            data={trainerOptions}
            placeholder="Select Trainer"
            selectUser={selectUser}
            isClearable
          />
        </div>
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table row={TrainerPackagesRows(items)} headType={TrainerPackageHead} />
      </div>
    </>
  );
}
