import StyledUserProfileElementDropDown from '../../utils/styles/StyledUserProfileDropDown';
import StyledLabelTitleLarge from '../../utils/styles/StyledLabelTitleLarge';
import { Discpliness, cities } from '../../utils/common/data';
import CoverageTop from './coverageTop';
import CoverageRegion from './coverageRegion';

export default function Coverage() {
  return (
    <div
      style={{
        flex: 4,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <StyledUserProfileElementDropDown options={cities} name="city" />
        <StyledUserProfileElementDropDown
          options={Discpliness}
          name="displicine "
        />
      </div>
      <div>
        <CoverageTop />
        <StyledLabelTitleLarge title="Region Breakdown" />
        <CoverageRegion number="1" />
        <CoverageRegion number="2" />
        <CoverageRegion number="3" />
        <CoverageRegion number="4" />
        <CoverageRegion number="5" />
        <CoverageRegion number="6" />
        <CoverageRegion number="7" />
        <CoverageRegion number="8" />
      </div>
    </div>
  );
}
