// eslint-disable-next-line no-use-before-define
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { TextInput } from '@root/components/textInput';
import { CuponCodes } from '@root/models/cuponCode';
import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';
import Table from '../../components/table/table';
import { PromoCodesHead } from '../../components/table/content/Head';
import { CuponCodesRows } from '../../components/table/content/rows';
import AddPromoCode from '../../components/modals/AddNewPromoCodeModal';
import { CuponCodeFirestore } from '../../controllers/cuponCodesFirestoreController';

export default function PromoCodes() {
  const { items } = CuponCodeFirestore();
  const [searchValue, setSearchValue] = useState<string>('');

  const promoCodes = useMemo(() => _.filter(
    items,
    (item) => item.code?.toLowerCase().includes(searchValue.toLowerCase()),
  ) as CuponCodes[], [items, searchValue]);

  return (
    <div>
      <div className="Top">
        <StyledLabelTitle title="Promocodes" />

        <AddPromoCode />
        <div style={{ width: 180, marginLeft: 24 }}>
          <TextInput
            name="basic"
            aria-label="default text field"
            placeholder="Search by Promo Code"
            value={searchValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setSearchValue(e.currentTarget.value);
            }}
          />
        </div>
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table headType={PromoCodesHead} row={CuponCodesRows(promoCodes)} />
      </div>
    </div>
  );
}
