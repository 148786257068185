/* eslint-disable import/prefer-default-export */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useState, useEffect } from 'react';
import { BookingState } from '@root/utils/enums/booking.enums';
import { Booking } from '@root/models/booking';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';

export const useDuplicateBookings = () => {
  const [duplicateBookings, setDuplicateBookings] = useState<Booking[][]>([]);

  useEffect(() => {
    const unsubscribe = db
      .collection(collections.BOOKINGS)
      .where('status', '==', BookingState.SCHEDULED)
      .orderBy('date', 'desc')
      .onSnapshot(async (snap) => {
        try {
          const fetchedData = await Promise.all(
            snap.docs.map((doc) => {
              const booking = doc.data() as Booking;
              return booking;
            }),
          );
          const result = Object.values(
            fetchedData.reduce((a, curr) => {
              const { date, trainerId, slot } = curr;
              const startHour = slot?.startHour;
              const key = `${date}-${trainerId}-${startHour}`;
              // eslint-disable-next-line no-param-reassign
              (a[key] = a[key] || []).push(curr);
              return a;
            }, {}),
          );
          const formattedResult = result.filter((arr: any) => arr.length > 1);
          const sortedResult = formattedResult.sort((a: any, b: any) => {
            const aTime = new Date(a[0].date).getTime();
            const bTime = new Date(b[0].date).getTime();
            // eslint-disable-next-line no-nested-ternary
            return aTime > bTime ? 1 : bTime > aTime ? -1 : 0;
          });
          setDuplicateBookings(sortedResult as Booking[][]);
        } catch (error) {
          // console.log('==Error getting duplicate bookings===', error);
        }
      });
    return unsubscribe;
  }, []);
  return { duplicateBookings };
};
