/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';
import { Gym } from '../models/gym';
import StorageController from './storageController';

export const GymsFirestore = () => {
  const [items, setItems] = useState<Gym[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.GYMS)
      .orderBy('order', 'asc')
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setItems(fetched as Gym[]);
      });
    return unsubscribe;
  }, []);
  const updateGym = (gym: Gym) => {
    try {
      return db
        .collection(collections.GYMS)
        .doc(gym.id)
        .update(gym);
    } catch (error) {
      // console.log('===Error updating trainer====', error);
    }
  };
  const deleteGym = (id: string) => {
    try {
      return db.collection(collections.GYMS).doc(id).delete();
    } catch (error) {
      // console.log('===Error deleting Gym====', error);
    }
  };

  const addGym = async (gym: Gym, image: string, images: string[]) => {
    const picture = await StorageController.uploadFile(image, 'gyms');
    const imagesUrl: string[] = await Promise.all(
      images.map(async (img) => {
        const imgUrl: string = await StorageController.uploadFile(img, 'gyms');
        return imgUrl;
      }),
    );
    const newGym = gym;
    newGym.picture = picture;
    newGym.images = imagesUrl;
    const response = await db
      .collection(collections.GYMS)
      .add(newGym);
    response.update({ id: response.id });
  };

  return {
    items, updateGym, addGym, deleteGym,
  };
};

export default class GymsController {
  static async getCouponCodeById(id: string): Promise<Gym> {
    const response = await db.collection(collections.GYMS).doc(id).get();
    return { ...response.data(), id: response.id };
  }
}
