/* eslint-disable import/no-unresolved */
import './sidebar.css';
import {
  People,
  PermIdentity,
  AssignmentTurnedIn,
  AccountBalanceWallet,
  ListAlt,
  Autorenew,
  Loyalty,
  Grade,
  TableChart,
  Settings,
  MoneyOutlined,
  SyncAlt,
} from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../utils/enums/routes.enums';

export default function Sidebar() {
  const location = useLocation();
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <Link to={routes.HOME}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.HOME && 'active'
              } `}
            >
              <People className="sidebarIcon" />
              Users
            </li>
          </Link>

          <Link to={routes.TRAINER}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.TRAINER && 'active'
              } `}
            >
              <PermIdentity className="sidebarIcon" />
              Trainers
            </li>
          </Link>

          <Link to={routes.TRAINERCHANGES}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.TRAINERCHANGES && 'active'
              } `}
            >
              <SyncAlt className="sidebarIcon" />
              Trainer Changes
            </li>
          </Link>

          <Link to={routes.TRAINERPERFORMANCE}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.TRAINERPERFORMANCE && 'active'
              } `}
            >
              <SyncAlt className="sidebarIcon" />
              Trainer Performance
            </li>
          </Link>

          <Link to={routes.TRANSACTIONS}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.TRANSACTIONS && 'active'
              } `}
            >
              <MoneyOutlined className="sidebarIcon" />
              Transactions
            </li>
          </Link>
          <Link to={routes.COVERAGE}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.COVERAGE && 'active'
              } `}
            >
              <AssignmentTurnedIn className="sidebarIcon" />
              Coverage
            </li>
          </Link>

          <Link to={routes.MONTHLYBALANCE}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.MONTHLYBALANCE && 'active'
              } `}
            >
              <AccountBalanceWallet className="sidebarIcon" />
              Monthly balance
            </li>
          </Link>
          <Link to={routes.PREBOOKINGS}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.PREBOOKINGS && 'active'
              } `}
            >
              <AccountBalanceWallet className="sidebarIcon" />
              Pre-bookings
            </li>
          </Link>

          <Link to={routes.PACKAGES}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.PACKAGES && 'active'
              } `}
            >
              <ListAlt className="sidebarIcon" />
              Package
            </li>
          </Link>
          <Link to={routes.TRAINERPACKAGES}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.TRAINERPACKAGES && 'active'
              } `}
            >
              <ListAlt className="sidebarIcon" />
              Trainer Packages
            </li>
          </Link>

          <Link to={routes.RENEWAL}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.RENEWAL && 'active'
              } `}
            >
              <Autorenew className="sidebarIcon" />
              Activity
            </li>
          </Link>
          <Link to={routes.DISCIPLINES}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.DISCIPLINES && 'active'
              } `}
            >
              <Loyalty className="sidebarIcon" />
              Disciplines
            </li>
          </Link>
          <Link to={routes.BANNERS}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.BANNERS && 'active'
              } `}
            >
              <Loyalty className="sidebarIcon" />
              Banners
            </li>
          </Link>
          <Link to={routes.OFFERS}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.OFFERS && 'active'
              } `}
            >
              <Loyalty className="sidebarIcon" />
              Offers
            </li>
          </Link>
          <Link to={routes.PROMOCODES}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.PROMOCODES && 'active'
              } `}
            >
              <Loyalty className="sidebarIcon" />
              Promo codes
            </li>
          </Link>
          <Link to={routes.GYMS}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.GYMS && 'active'
              } `}
            >
              <Grade className="sidebarIcon" />
              Gyms
            </li>
          </Link>
          <Link to={routes.RATINGS}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.RATINGS && 'active'
              } `}
            >
              <Grade className="sidebarIcon" />
              Ratings
            </li>
          </Link>
          <Link to={routes.SESSIONLOGS}>
            <li
              className={`sidebarListItem ${
                location.pathname === routes.SESSIONLOGS && 'active'
              } `}
            >
              <TableChart className="sidebarIcon" />
              Session Logs
            </li>
          </Link>
          <Link to="/duplicateBookings">
            <li
              className={`sidebarListItem ${
                location.pathname === routes.DUPLICATEBOOKINGS && 'active'
              } `}
            >
              <Settings className="sidebarIcon" />
              Duplicate Bookings
            </li>
          </Link>
          <Link to="/cities">
            <li
              className={`sidebarListItem ${
                location.pathname === routes.CITIES && 'active'
              } `}
            >
              <Settings className="sidebarIcon" />
              Cities
            </li>
          </Link>
        </div>
      </div>
    </div>
  );
}
