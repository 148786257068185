import collections from '@root/utils/enums/collections.enums';
import { db } from './firebase';

// dotenv.config();
const citiesRef = db.collection(collections.CITIES);

export default class FetchCities {
  static async getAll() {
    const cities = await citiesRef.get();
    return cities.docs.map((doc: any) => ({
      // value: doc.data().country,
      value: doc.data().name,
      label: doc.data().name,
    }));
  }
}
