/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import firebase from 'firebase';
import { TrainingAddress } from './address';
import { BillingDetails } from './billingDetails';
import Availability from '../utils/enums/availability.enum';
import AvailabilitySlot from '../types/availabilitySlot';
import { CompensationType } from './revenue';

export enum TrainerLevel {
  STANDARD = 'standard',
  PREMIUM = 'premium',
}

export interface User {
  id?: string;
  email?: string;
  fullname?: string;
  displayName?: string;
  picture?: string;
  pictureSecondary?:string;
  mobile?: {
    areaCode: string;
    number: string;
  };
  isMobileVerified?: boolean;
  roles?: string[];
  addresses?: {
    training: TrainingAddress[];
  };

  region?: string;
  preferencesDisciplines?: Array<string>;

  billing?: {
    details: BillingDetails;
  };

  sessionsAvailable?: number;
  nutritionAvailableSessions?: number;
  fcmDeviceToken?: string;
  moreAboutMe?: string;
  comment?: string;
  country?: string;
  haveCarDrivingLicense?: boolean;
  isActive?: boolean;
  details?: string;
  emiratesIdFileUri?: string;
  repsCardUri?: string;
  bio?: string;
  createdAt?: string;
  certifications?: string[] | firebase.firestore.FieldValue;
  preferencesTraining?: string[];
  otherDisciplines?: string;
  birthDate?: { month: string; day: number; year: number };
  city?: string;
  gender?: string;
  availability?: Availability;
  languages?: { label: string; value: string }[];
  fullReviews?: number;
  status?: string;
  shortReviews?: number;
  disciplines?: Array<string> | firebase.firestore.FieldValue;
  defaultDiscipline?: string;
  timeNotAvailable?: AvailabilitySlot[];
  trainingRegions?: string[];
  joinedDate?: firebase.firestore.Timestamp;
  appearanceScore?: number;
  trainerType?: string;
  isVerified?: boolean; // is trainer verified or not
  bodyAnalysisReport?: string;
  nutritionGuide?: string;
  assignedTrainerId?: string | null;
  freeSessionEligible?: boolean;
  isOfferFreeSessions?: boolean;
  statusOverride?: boolean; // this is for the admin to override the status of the user,
  // currently used for the free session banner
  trainerLevel?: TrainerLevel;
  minPrice?: number;
  maxPrice?: number;
  showPriceRange?: boolean;
  compensation?: {
    firstPackage: number; // this could be percentage or fixed amount
    renewal: number; // this could be percentage or fixed amount
    type: CompensationType
  }
}

export const Roles = {
  admin: 'admin',
  trainer: 'trainer',
  user: 'user',
  mindfullness: 'mindfullness',
  nutrition: 'nutrition',
};
