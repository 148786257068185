// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useDuplicateBookings } from '@root/controllers/duplicateBookingsFirebaseController';
import { Booking } from '@root/models/booking';
import { getTrainer } from '@controllers/trainerFirestoreController';
import { getUsers } from '@controllers/userFirestoreController';
import { convertBookingDateToDateTime } from '@root/utils/functions';

function DisplayDetail({ booking }: { booking: Booking }) {
  const [details, setDetails] = React.useState({
    trainerName: '',
    clientName: '',
    dateTime: '',
  });
  React.useEffect(() => {
    const fetchDetails = async () => {
      const trainer = await getTrainer(booking?.trainerId);
      const client = await getUsers(booking?.userId);
      const trainerName = trainer?.displayName ?? trainer?.fullname;
      const clientName = client?.id ? client.fullname : 'Deleted User';
      setDetails({
        trainerName,
        clientName,
        dateTime: convertBookingDateToDateTime({
          date: booking.date,
          startTime: booking.slot?.startHour,
          endTime: booking.slot?.endHour,
        }),
      });
    };
    fetchDetails();
  }, [
    booking.date,
    booking.slot?.endHour,
    booking.slot?.startHour,
    booking?.trainerId,
    booking?.userId,
  ]);

  return (
    <div>
      <p>{`Trainer: ${details.trainerName}`}</p>
      <p>{`Client: ${details.clientName}`}</p>
      <p>{`Date & Time: ${details.dateTime}`}</p>
    </div>
  );
}

export default function DuplicateBookings() {
  const { duplicateBookings } = useDuplicateBookings();

  return (
    <div>
      <h1>Duplicate Bookings</h1>
      {duplicateBookings && duplicateBookings.length ? (
        duplicateBookings.map((bookings) => (
          <div
            // key={`i-${i}`}
            style={{
              marginTop: 5,
              marginBottom: 20,
              backgroundColor: 'whitesmoke',
              padding: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {bookings.map((booking) => (
              <div key={booking.id} style={{ flexGrow: 1 }}>
                <DisplayDetail booking={booking} />
              </div>
            ))}
          </div>
        ))
      ) : (
        <div>No Dulicates</div>
      )}
    </div>
  );
}
