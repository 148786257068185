/* eslint-disable no-use-before-define */
import { useGlobalsContext } from '@contexts/global.context';
import { updateTrainer } from '@controllers/trainerFirestoreController';
import { Trainer } from '@models/trainer';
import { User } from '@models/user';
import StyledLabelTitle from '@root/utils/styles/StyledLabelTitle';
import { OptionType } from '@typeModel/types';
import { Genderpreference } from '@utils/common/data';
import _ from 'lodash';
import { StyledSelect } from '../../../components/select/StyledDataSelect';

export default function TrainerProfileBottomPreferences({
  trainer,
}: {
  trainer: Trainer & User;
}) {
  const { cityRegions } = useGlobalsContext();

  const RegionDataType = _.map(
    cityRegions,
    (cityRegion) => ({ value: cityRegion.id, label: cityRegion.region }),
  );
  const itemRegionValues = _.map(
    trainer.trainingRegions || [],
    (trainingRegion) => {
      const exist = _.findLast(RegionDataType, (cityRegion) => trainingRegion === cityRegion.value);
      return exist;
    },
  );

  const genderValues = _.map(trainer.preferencesTraining || [], (gender) => {
    const exist = _.findLast(
      Genderpreference,
      (item) => gender.toLowerCase() === (item.value as string).toLowerCase(),
    );
    return exist;
  });

  return (
    <div className="Bottom Content" style={{ marginTop: 20 }}>
      <StyledLabelTitle title="Trainer Preferences" />
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        <div>
          <StyledSelect
            name="Gender preference"
            options={Genderpreference}
            value={genderValues as OptionType[]}
            isMulti
            onChange={(value) => {
              const updatedGenders: string[] = _.map(
                value,
                (item) => item?.value,
              ) as string[];
              updateTrainer({
                id: trainer.id,
                preferencesTraining: updatedGenders,
              });
            }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <StyledSelect
            name="Regions covered"
            options={RegionDataType}
            value={itemRegionValues as OptionType[]}
            isMulti
            onChange={(value) => {
              const updatedRegions: string[] = _.map(
                value,
                (item) => item?.value,
              ) as string[];
              updateTrainer({
                id: trainer.id,
                trainingRegions: updatedRegions,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}
