/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
const SecureStorageKey = {
  verificationId: 'fitlov.verificationId',
  uid: 'userUID',
};

enum localStorageKeys {
  trainerPerformance = 'trainerPerformance',
  allUsers = 'allUsers',
}

export {
  localStorageKeys,
};
export default SecureStorageKey;
