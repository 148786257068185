/* eslint-disable */

export type TrainerMedia = {
    id: string;
    order: number;
    createdAt: Date;
    trainerId: string;
    type: TrainerMediaType;
    url: string;
    tag: string;
    enabled: boolean;
}

export enum TrainerMediaType {
    VIDEO = 'video',
    IMAGE = 'image'
}   