/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */

import React, { useCallback, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { Checkbox } from '@atlaskit/checkbox';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import moment from 'moment';
import _ from 'lodash';
import { useGlobalsContext } from '@root/contexts/global.context';
import { CouponCodeTypeEnum, CouponCodeType } from '@utils/common/data';
import { CuponCodeFirestore } from '../../controllers/cuponCodesFirestoreController';
import { CuponCodes } from '../../models/cuponCode';
import { StyledSelect } from '../select/StyledDataSelect';
import CustomModalHeader from './modalHeader';
import FormFieldInput from '../textInput/formFieldInput';

function AddPromoCode() {
  const [isOpen, setIsOpen] = useState(false);
  const { plans } = useGlobalsContext();
  const { addCuponCodes } = CuponCodeFirestore();
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const [isUniversal, setIsUniversal] = useState();
  const planOptions = plans.map((plan) => ({
    label: plan?.packageName,
    value: plan?.id,
    price: plan?.price,
  }));

  const onSubmit = async (data) => {
    try {
      const {
        // eslint-disable-next-line no-shadow
        amount, date, name, numTimes, packages, type, useOnce, isUniversal,
      } = data;
      const couponCode: CuponCodes = {
        code: name,
        discount: parseFloat(amount),
        numTimes: parseInt(numTimes, 10),
        expiredDate: date,
        useOnce: useOnce ?? false,
        packages: _.map(packages, (item) => item.value),
        isPercent: type.value === CouponCodeTypeEnum.percentage,
        isUniversal: isUniversal.value,
      };
      await addCuponCodes(couponCode);
      closeModal();
    } catch (error) {
      // console.log(error)
    }
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        (+) Add New Promocode
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, getState, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New Promocode" />

                  <ModalBody>
                    <Field id="name" name="name" label="Promocode" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="name" name="type" label="Type" isRequired>
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            validationState={error ? 'error' : 'default'}
                            inputId={id}
                            name="Type"
                            options={CouponCodeType}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field
                      id="amount"
                      name="amount"
                      label="Amount"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Amount is required';

                        const { type } = getState().values;
                        if (
                          type?.value === CouponCodeTypeEnum.percentage
                          && parseFloat(value) > 100
                        ) {
                          return 'Amount percent can not bigger than 100';
                        }
                      }}
                    >
                      {({ fieldProps: { id, onChange, ...rest }, error }) => (
                        <>
                          <Textfield
                            type="number"
                            step="1"
                            onChange={(
                              e: React.FormEvent<HTMLInputElement>,
                            ) => {
                              if (e.currentTarget.validity.valid) {
                                onChange(e);
                              }
                            }}
                            {...rest}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field
                      id="numTimes"
                      name="numTimes"
                      label="Number Times"
                      defaultValue={0}
                      validate={(value) => {
                        if (!value) return 'numTimes is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange, ...rest } }) => (
                        <Textfield
                            // pattern="^(\d+(\.\d{0,2})?|\.?\d{1,2})$"
                          type="number"
                          step="1"
                          onChange={(
                            e: React.FormEvent<HTMLInputElement>,
                          ) => {
                            if (e.currentTarget.validity.valid) {
                              onChange(e);
                            }
                          }}
                          {...rest}
                        />
                      )}
                    </Field>

                    <Field id="isUniversal" name="isUniversal" isRequired>
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            // isMulti
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                            isSearchable={false}
                            inputId={id}
                            name="is Universal?"
                            validationState={error ? 'error' : 'default'}
                            onChange={(value) => {
                              setIsUniversal(value?.value);
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="packages" name="packages" isRequired>
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            isDisabled={isUniversal}
                            isMulti
                            options={planOptions}
                            isSearchable={false}
                            inputId={id}
                            name="Choose package"
                            validationState={error ? 'error' : 'default'}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="date"
                      name="date"
                      defaultValue={moment().format('YYYY-M-D')}
                      validate={(value) => {
                        if (!value) return 'Date is required';
                      }}
                    >
                      {({ fieldProps: { onChange, ...rest }, error }) => (
                        <FormFieldInput
                          label="Expiry Date"
                          type="date"
                          error={error}
                          fieldProps={{
                            min: moment().format('YYYY-MM-DD'),
                            onChange: (
                              e: React.FormEvent<HTMLInputElement>,
                            ) => {
                              const { value } = e.currentTarget;
                              onChange(value);
                            },
                            ...rest,
                          }}
                        />
                      )}
                    </Field>
                    <Field id="date" name="useOnce">
                      {({ fieldProps: { onChange } }) => (
                        <Checkbox
                          // isChecked={isEnabledCustomVal}
                          label="Use only once "
                          onChange={onChange}
                          name="checkbox-default"
                          testId="cb-default"
                        />
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}

export default AddPromoCode;
