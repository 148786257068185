/* eslint-disable consistent-return */
/* eslint-disable max-len */

import { Booking } from '@models/booking';
// eslint-disable-next-line camelcase
import endpointFunctions from '@root/utils/endpoints';
import { convertTo12hTime } from '@root/utils/functions';
import { month } from '@utils/common/data';
import {
  BookingConfirmation,
  BookingState,
  TrainingCancellationReasons,
} from '@utils/enums/booking.enums';
import FirestoreService from '@utils/firestoreService';
import axios from 'axios';
import firebase from 'firebase';
import _ from 'lodash';
import moment from 'moment';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import collections from '../utils/enums/collections.enums';
import { db } from './firebase';

export const useTrainerBookings = (trainerId) => {
  const [totalBookingData, setTotalBookingData] = useState<Booking[]>([]);
  const [totalGroupedBookingData, setTotalGroupedBookingData] = useState<{
    [key: string]: Booking[];
  } | null>(null);
  const [totalBookingCount, setTotalBookingCount] = useState(0);
  const [scheduledBookingCount, setScheduledBookingCount] = useState(0);
  const [completedBookingCount, setCompletedBookingCount] = useState(0);
  const [avgBookingPerClientCount, setAvgBookingPerClientCount] = useState(0);
  const [monthFilter, setMonthFilter] = useState<string>(month[0].label);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.BOOKINGS)
      .where('trainerId', '==', trainerId)

      .onSnapshot((snap) => {
        const trainerBookings = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as Booking[];
        setTotalBookingData(trainerBookings);
        const groupedItems = _.groupBy(trainerBookings || [], (b) => moment(b.date).startOf('month').format('MMMM'));
        setTotalGroupedBookingData(
          groupedItems as { [key: string]: Booking[] },
        );
      });
    return unsubscribe;
  }, [trainerId]);

  const filteredBookingData = useMemo(() => {
    if (monthFilter === month[0].label) {
      return totalBookingData;
    }
    return totalGroupedBookingData?.[monthFilter];
  }, [monthFilter, totalBookingData, totalGroupedBookingData]);

  const updateBookingData = useCallback(async (bookingData: Booking[]) => {
    const clients = _.uniq(
      _.map(bookingData, (booking: Booking) => booking.userId),
    );
    const scheduledBookings = _.filter(
      bookingData,
      (booking: Booking) => booking.status === BookingState.SCHEDULED,
    );
    const completedBookings = _.filter(
      bookingData,
      (booking: Booking) => booking.status === BookingState.COMPLETED,
    );
    setTotalBookingCount(bookingData.length);
    setAvgBookingPerClientCount(
      Math.round((bookingData.length / clients.length) * 100) / 100 || 0,
    );
    setScheduledBookingCount(scheduledBookings.length);
    setCompletedBookingCount(completedBookings.length);
  }, []);

  const updateMonth = (monthStr: string) => {
    try {
      setMonthFilter(monthStr);
    } catch (error) {
      // console.log(error)
    }
  };
  useEffect(() => {
    updateBookingData(totalBookingData);
    return () => {};
  }, [totalBookingData, updateBookingData]);

  useEffect(() => {
    updateBookingData(filteredBookingData || []);
    return () => {};
  }, [filteredBookingData, updateBookingData]);

  return {
    totalBookingCount,
    scheduledBookingCount,
    completedBookingCount,
    avgBookingPerClientCount,
    monthFilter,
    updateMonth,
  };
};

export const useTrainerAllBookings = (trainerId) => {
  const [trainerBookings, setTrainerBookings] = useState<Booking[]>([]);

  useEffect(() => {
    const unsubscribe = db
      .collection(collections.BOOKINGS)
      .where('trainerId', '==', trainerId)
      .onSnapshot((snap) => {
        const trainerBookingsArr = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as Booking[];
        setTrainerBookings(trainerBookingsArr);
      });
    return unsubscribe;
  }, [trainerId]);
  return { trainerBookings };
};

export const getBookingByTrainer = async (
  trainerId: string,
  state: BookingState,
): Promise<Booking[]> => {
  const data = await db
    .collection(collections.BOOKINGS)
    .where('trainerId', '==', trainerId)
    .where('status', '==', state)
    .get();
  return data.docs.map((doc) => doc.data() as Booking);
};

export const createBookings = async ({
  bookings,
  moreAboutMe,
  confirmation = null,
}: {
  bookings: Booking[];
  moreAboutMe?: string;
  isExtraSession?: boolean;
  userId: string;
  confirmation?: BookingConfirmation | null
}) => {
  try {
    const response = await axios.post(endpointFunctions.createBooking, {
      booking: bookings,
      isFree: false,
      clientName: bookings[0].clientName,
      trainerName: bookings[0].trainerName,
      moreAboutMe,
      confirmation,
      isPTClient: false,
      version: 2, // if pass version 1, sessions are not decreased to user
    });
    return response.data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const updateBooking = async ({
  bookingId,
  item,
}: {
  bookingId: string;
  item: Partial<Booking>;
}) => {
  const newBooking: Partial<Booking> = {
    ...item,
    updatedAt: FirestoreService.getTimestamp(),
  };
  db.collection(collections.BOOKINGS).doc(bookingId).update(newBooking);
};

export const deleteFieldFromBooking = async ({
  bookingId,
  fieldName,
}: {
  bookingId: string;
  fieldName: string;
}) => {
  db.collection(collections.BOOKINGS)
    .doc(bookingId)
    .update({
      [fieldName]: firebase.firestore.FieldValue.delete(),
    });
};
export const deleteAllBookingOnMember = async ({
  userId,
  field,
}: {
  userId: string;
  field: string;
}): Promise<boolean> => {
  try {
    const data = await db
      .collection(collections.BOOKINGS)
      .where(field, '==', userId)

      .get();
    await Promise.all(_.map(data.docs, async (doc) => doc.ref.delete()));
    return true;
  } catch (error) {
    return false;
  }
};
export const deleteCancelledBooking = async (bookingId: string | undefined) => {
  try {
    if (bookingId) {
      const booking = await db
        .collection(collections.BOOKINGS)
        .where('id', '==', bookingId)
        .where('status', '==', BookingState.CANCELLED)
        .get();
      const { ref } = booking.docs[0];
      ref.delete();
      return true;
    }
    return false;
  } catch (error) {
    // console.log('==Error deleting cancelled booking====', error);
  }
};
export const cancelBookingWithId = async (
  bookingId,
  actorId,
): Promise<boolean> => {
  try {
    const request = await axios.post(endpointFunctions.cancel_booking, {
      bookingId,
      cancellationReason: TrainingCancellationReasons.AGREED_CHANGE_WITH_CLIENT,
      cancelledByUserId: actorId,
      madeBy: 'admin',
    });

    return !request.data.error;
  } catch (error) {
    return false;
  }
};
export const getBooking = async (id) => {
  const book = await (
    await db.doc(`${collections.BOOKINGS}/${id}`).get()
  ).data();
  return { ...book };
};

export const checkBookingExists = async ({ trainerId, date, selectedTime }) => {
  const bookings = await db
    .collection(collections.BOOKINGS)
    .where('trainerId', '==', trainerId)
    .where('date', '==', date)
    .where('status', '==', BookingState.SCHEDULED)
    .get();

  let bookingInfo;
  if (bookings.docs.length > 0) {
    bookings.docs.map(async (doc) => {
      const booking = doc.data() as Booking;
      const startHour = booking?.slot?.startHour!;
      const endHour = booking?.slot?.endHour!;
      const from = moment(`${date} ${convertTo12hTime(startHour)}`);

      const to = moment(`${date} ${convertTo12hTime(endHour)}`);

      const selectedTimeFormatted = moment(
        `${date} ${convertTo12hTime(selectedTime)}`,
      );
      const isBetween = selectedTime >= startHour
        ? selectedTimeFormatted.isBetween(from, to, undefined, '[)')
        : selectedTimeFormatted
          .add(1, 'hours')
          .isBetween(from, to, undefined, '()');
      if (isBetween) {
        bookingInfo = {
          date: moment(booking.date).format('ddd, MMM DD'),
          time: convertTo12hTime(startHour),
        };
      }
    });
  }
  return bookingInfo;
};

export const doesBookingExists = async ({
  // booking exists between the trainer and user
  userId,
  trainerId,
}: {
  userId: string;
  trainerId: string;
}) => {
  const data = await await db
    .collection(collections.BOOKINGS)
    .where('userId', '==', userId)
    .where('trainerId', '==', trainerId)
    .limit(1)
    .get();
  return data.docs.length > 0;
};

// eslint-disable-next-line no-shadow
export async function getStats(trainerId: string, month: string, year: number) {
  const request = await axios.post(endpointFunctions.get_trainer_stats, {
    trainerId,
    month,
    year,
    allowPTClients: false,
  });

  return request.data as any as {
    completedBookings: number;
    cancelledBookings: number;
    scheduledBookings: number;
    earned: number;
  };
}
