/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, {
  useCallback, useState,
} from 'react';

import Button from '@atlaskit/button/loading-button';

import Form, {
  Field,
  ErrorMessage,
} from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import dotenv from 'dotenv';

import _ from 'lodash';
import {
  useFirestore,
} from '../../controllers/userFirestoreController';
import CustomModalHeader from './modalHeader';
import FormFieldInput from '../textInput/formFieldInput';
import { TextInput } from '../textInput';

dotenv.config();

export function validateEmail(elementValue) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
}
// const Roles = {
//   trainer: 'trainer',
//   user: 'user',
//   mindfullness: 'mindfullness',
//   nutrition: 'nutrition',
// };
export function AddCityRegion({
  saving,
  isOpen,
  closeModal,
  saveRegion,
}: {
  saving
  isOpen: boolean;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  saveRegion: ({ region, areas }: { region: string, areas?: string[] }) => void
}) {
  const [formError, setFormError] = useState<string>('');
  const [areas, setAreas] = useState<string[]>([]);

  const { addUser } = useFirestore();

  const onSubmit = useCallback(
    async (data) => {
      try {
        setFormError('');
        // if (!countryValue) {
        //   setFormError('Please select country');
        //   return;
        // }
        saveRegion({ region: data.name, areas });
      } catch (error) {
        console.log('addUser error', error);
      }
    },
    [addUser, closeModal],
  );
  const onAddCert = () => {
    setAreas((prevState) => [...prevState, '']);
  };

  const onCertChangeText = (value, index) => {
    areas[index] = value;
    setAreas(areas);
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={closeModal}>
          <Form onSubmit={onSubmit}>
            {({ formProps, submitting }) => (
              <form {...formProps}>
                <CustomModalHeader title="Add New Region" />
                <ModalBody>
                  <Field
                    id="name"
                    name="name"
                    isRequired
                    validate={(value) => {
                      if (!value) return 'Name is required';
                    }}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <FormFieldInput
                          label="Name"
                          fieldProps={fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                  <div>
                    <div style={{ marginTop: '24px', marginBottom: '16px' }}>Areas</div>
                    {_.map(areas || [], (area, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 10,
                        }}
                      >
                        <TextInput
                          placeholder="Area name"
                          defaultValue={area}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            onCertChangeText(e.currentTarget.value, index);
                          }}
                        />
                        <Button
                          appearance="danger"
                          className="icon-button"
                          onClick={() => {
                            const jeff = JSON.stringify(areas);
                            const newAreas = JSON.parse(jeff);
                            newAreas.splice(index, 1);
                            setAreas(newAreas);
                          }}
                        >
                          X
                        </Button>
                      </div>
                    ))}
                    <Button
                      shouldFitContainer
                      onClick={onAddCert}
                      style={{ marginTop: 10, color: 'black' }}
                    >
                      Add Area
                    </Button>
                  </div>
                  {formError && <ErrorMessage>{formError}</ErrorMessage>}
                </ModalBody>
                <ModalFooter>
                  <Button
                    isLoading={saving}
                    type="submit"
                    isDisabled={submitting}
                    className="modal-button"
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  );
}
