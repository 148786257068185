/* eslint-disable */
enum collections {
  USERS = "users",
  TRAINERS = "trainerTest",
  SESSIONS = "bookings",
  NUTRITION = "nutritionTest",
  TRANACTIONS = "transactionsTest",
  GYMS = "gyms",
  OFFERS = "offers",
  COUPONCODES = "couponCodes",
  APPLICATION = "trainerApplication",
  COUNTRIES = "countries",
  CITIES = "cities",
  CITY_REGIONS = "cityRegions",
  PACKAGES = "package",
  PROGRESS = "progress",
  LANGUAGES = "languages",
  DISCIPLINES = "disciplines",
  BOOKINGS = "bookings",
  REVIEWS = "reviews",
  BILLING_HISTORY = "billingHistory",
  CHATS = "chats",
  MESSAGES = "MESSAGES",
  PLANS = "plans",
  STATISTICS = "statistics",
  CLIENT_PACKAGES = "clientPackages",
  BANNERS = "banners",
  USERS_SETTINGS = "userSettings",
  REVENUES = "revenues",
  TRAINER_PACKAGES = "trainerPackages",
  trainerChanges = "trainerChanges",
  trainerMedia = "trainerMedia",
  trainerPayments = "trainerPayments",
  TRAINER_REQUEST = "trainerRequests",
  FITLOV_POINTS = "fitlovPoints",
  PRE_BOOKINGS = "preBookings",
}

export enum cacheCollections {
  trainerPerformance = "cache_trainerPerformance",
  allUsers = "cache_allUsers",
}

export default collections;
