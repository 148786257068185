/* eslint-disable indent */
// eslint-disable-next-line no-shadow
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum RevenueType {
    firstPackage = 'firstPackage',
    renewal = 'renewal',
}

// eslint-disable-next-line no-shadow, no-unused-vars
enum RevenueDefaultPercentage {
    firstPackage = 0.75,
    renewal = 0.9,
}

type Revenue = {
    id: string;
    createdAt: { toDate: () => Date };
    year: number;
    month: number;
    day: number;
    sessionDate: string;
    bookingId: string;
    clientId: string;
    trainerId: string;
    type: RevenueType;
    trainerPercentage: number; // 0.75 = 75%
    pricePerSession: number; // booking price
    // eslint-disable-next-line max-len
    // trainerCompensation = pricePerSession * billingHistory.length >= 2? (trainer.compensation.renewal || 0.75) : (trainer.compensation.firstPackage || 0.90);
    trainerCompensation: number;
    // fitlovCommission = pricePerSession - TrainerCompensation;
    fitlovCommission: number;
    note?: string;
    skipped?: boolean;
    isVisible: boolean; // Visible or not for trainers wallet
    clientName: string | null;
    trainerName: string | null;
    isDuplicated?: boolean;
};

// eslint-disable-next-line no-shadow
enum CompensationType {
    fixed = 'fixed',
    percentage = 'percentage',
}

// eslint-disable-next-line no-shadow
enum RevenueDefaultFixedPayment {
    firstPackage = 140,
    renewal = 160,
}

export type TrainerPayment = {
    id: string;
    trainerId: string;
    createdAt: Date;
    amount: number;
    sessions: number;
    month: string;
    year: number;
}

export default Revenue;
export {
    RevenueType, RevenueDefaultPercentage, RevenueDefaultFixedPayment, CompensationType,
};
