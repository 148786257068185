/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export type AttributeUpdated = {
  id: string;
  trainerId: string;
  trainerName: string;
  createdAt: { toDate: () => Date };
  updatedAt: Date;
  field: string;
  oldValue: Array<string>;
  newValue: Array<string>;
  status: string;
};

export enum RequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export enum RequestFields {
  DISPLAY_NAME = 'displayName',
  CERTIFICATIONS = 'certifications',
  BIO = 'bio',
  DISCIPLINES = 'disciplines',
  PICTURE = 'picture',
}
