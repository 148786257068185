/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  useCallback, useEffect, useState,
} from 'react';

import Button from '@atlaskit/button/standard-button';
import Form, {
  Field,
  ErrorMessage,
} from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import dotenv from 'dotenv';
import UserController from '@root/controllers/userController';
import { UserGenders } from '@root/utils/common/data';
import { OptionType } from '@atlaskit/select';
import FetchCities from '@root/controllers/FetchCitiesController';
import sortingAlpha from '@root/utils/sortingAlpha';
import {
  getUserWithMobileNumber,
  useFirestore,
} from '../../controllers/userFirestoreController';
import CustomModalHeader from './modalHeader';
import FormFieldInput from '../textInput/formFieldInput';
import { StyledSelect } from '../select/StyledDataSelect';

dotenv.config();

export function validateEmail(elementValue) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
}
// const Roles = {
//   trainer: 'trainer',
//   user: 'user',
//   mindfullness: 'mindfullness',
//   nutrition: 'nutrition',
// };
export function AddUser() {
  const [isOpen, setIsOpen] = useState(false);
  const [formError, setFormError] = useState<string>('');
  const [genderValue, setGenderValue] = useState<OptionType | null>(null);
  const [cityValue, setCityValue] = useState<OptionType | null>(null);
  const [cities, setCities] = useState<{ value: string; label: string }[]>([]);
  const { addUser } = useFirestore();

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
    setFormError('');
  }, []);
  const fetchCities = async () => {
    const response = await FetchCities.getAll();
    const sortedCities = sortingAlpha(response);
    setCities(sortedCities);
  };

  useEffect(() => {
    fetchCities();
  }, []);
  const onSubmit = useCallback(
    async (data) => {
      try {
        setFormError('');
        let mobileNumber = data.number;
        mobileNumber = mobileNumber.replace(/ +/g, ''); // remove spaces between numbers
        while (mobileNumber.charAt(0) === '0') {
          mobileNumber = mobileNumber.substring(1);
        }
        const userExists = await getUserWithMobileNumber(mobileNumber);
        if (userExists?.id) {
          setFormError('User with this number already exists');
          return;
        }

        if (!cityValue) {
          setFormError('Please select city');
          return;
        }
        if (!genderValue) {
          setFormError('Please select gender');
          return;
        }
        await UserController.create({
          fullname: data.fullname,
          email: data.email,
          mobile: {
            areaCode: data.areaCode.startsWith('+')
              ? data.areaCode
              : `+${data.areaCode}`,
            number: mobileNumber,
          },
          city: cityValue.value.toString(),
          gender: genderValue.value.toString(),
          // isActive: true,
          // isMobileVerified: true,
          // roles: [Roles.user],
        });
        closeModal();
      } catch (error) {
        // console.log('addUser error');
      }
    },
    [addUser, closeModal],
  );

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
        }}
      >
        Add New User
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New User" />
                  <ModalBody>
                    <Field
                      id="fullname"
                      name="fullname"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Name is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            label="Name"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <div className="flex inputWrapper">
                      <div
                        className="label"
                        style={{ width: 310, marginTop: 8 }}
                      >
                        Phone Number
                      </div>
                      <div className="flex">
                        <div style={{ flex: 1 }}>
                          <Field
                            id="areaCode"
                            name="areaCode"
                            isRequired
                            validate={(value) => {
                              if (!value) {
                                return '';
                              }
                            }}
                          >
                            {({ fieldProps, error }) => (
                              <FormFieldInput
                                error={error}
                                fieldProps={fieldProps}
                                placeholder="+971"
                                type="tel"
                                maxLength={4}
                              />
                            )}
                          </Field>
                        </div>
                        <div style={{ flex: 2 }}>
                          <Field
                            id="number"
                            name="number"
                            isRequired
                            validate={(value) => {
                              if (!value) {
                                return '';
                              }
                            }}
                          >
                            {({ fieldProps, error }) => (
                              <FormFieldInput
                                error={error}
                                placeholder="585887044"
                                fieldProps={fieldProps}
                                type="tel"
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <Field id="email" name="email">
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            type="email"
                            label="Email"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <div style={{ marginTop: 8, marginBottom: 8 }}>
                      <StyledSelect
                        name="City"
                        value={cityValue as OptionType}
                        options={cities}
                        onChange={(option) => {
                          setCityValue(option);
                        }}
                      />
                    </div>
                    <StyledSelect
                      name="Gender"
                      value={genderValue as OptionType}
                      options={UserGenders}
                      onChange={(option) => {
                        setGenderValue(option);
                      }}
                    />
                    {formError && <ErrorMessage>{formError}</ErrorMessage>}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
