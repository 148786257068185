import { DatePicker } from '@atlaskit/datetime-picker';
import Select from '@atlaskit/select';
import { OptionType } from '../../types/types';
import StyledSearch from '../../utils/styles/StyledSearch';

export const cities: OptionType[] = [{
  key: 'All',
  label: 'All',
  value: 'All',
},
{
  key: 'CLIENTS',
  label: 'CLIENTS',
  value: 'CLIENTS',
},
{
  key: 'PT CLIENTS',
  label: 'PT CLIENTS',
  value: 'PT CLIENTS',
}];

export default function Top({
  fromDate,
  toDate,
  clients,
  trainers,
  onDateChanged,
  selectUser,
  setClientFilter,
  clientFilter,
}: {
  fromDate: string;
  toDate: string;
  clients: OptionType[];
  trainers: OptionType[];
  // eslint-disable-next-line no-unused-vars
  onDateChanged: (arg1: string, arg2: string) => void;
  // eslint-disable-next-line no-unused-vars
  selectUser: any,
  setClientFilter: any
  clientFilter: any;
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 40,
      }}
    >
      <div
        style={{
          flex: 1,
          marginRight: 20,
        }}
      >
        From
        <div style={{ width: '100%' }}>
          <DatePicker
            selectProps={{
              classNamePrefix: 'date-select',
            }}
            value={fromDate}
            dateFormat="DD MMM YYYY"
            onChange={(d) => onDateChanged(d, 'from')}
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          marginRight: 20,
        }}
      >
        To
        <div style={{ width: '100%' }}>
          <DatePicker
            selectProps={{
              classNamePrefix: 'date-select',
            }}
            value={toDate}
            dateFormat="DD MMM YYYY"
            onChange={(d) => onDateChanged(d, 'to')}
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          marginRight: 20,
        }}
      >
        Client
        <StyledSearch
          data={clients}
          placeholder="Select Client"
          selectUser={selectUser}
        />
      </div>
      <div
        style={{
          flex: 1,
          marginRight: 20,
        }}
      >
        Trainer
        <StyledSearch
          data={trainers}
          placeholder="Select Trainer"
          selectUser={selectUser}
          // isClearable TODO
        />
      </div>
      <div
        style={{
          flex: 1,
          marginRight: 20,
        }}
      >
        Client Type
        <Select
          inputId="client-filter"
          className="multi-select"
          classNamePrefix="react-select"
          options={cities}
          isSearchable={false}
          placeholder={clientFilter}
          value={clientFilter}
          onChange={(option) => {
            setClientFilter(option?.value.toString() || null);
          }}
        />
      </div>
    </div>
  );
}
