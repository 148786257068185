/* eslint-disable import/no-unresolved */
import { Route, Redirect } from 'react-router-dom';
import routes from '@utils/enums/routes.enums';
import { useAuthState } from '@contexts/auth.context';

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, ...rest }) {
  const authInfo = useAuthState();
  const { isAuthenticated, userDataPresent } = authInfo;

  if (!userDataPresent) return null;
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => (isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={`${routes.LOGIN}`} />
      ))}
    />
  );
}
export default PrivateRoute;
