import { useState, useRef } from 'react';
import Button from '@atlaskit/button/standard-button';
import Textfield from '@atlaskit/textfield';
import AuthController from '@controllers/authController';
import { firebaseApp } from '@controllers/firebase';
import 'firebase/auth';
import { useHistory } from 'react-router';

export default function Login() {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [isPending, setIsPending] = useState(false);
  const captchaRef = useRef(null);

  const OnPressLogin = async () => {
    if (phoneNumber === undefined) return;
    let recaptcha;
    try {
      setIsPending(true);
      recaptcha = new firebaseApp.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
      );
      // console.log('recaptcha', recaptcha);
      const verificationId = await AuthController.verifiedPhoneNumber(
        phoneNumber,
        recaptcha,
      );
      // console.info('Invisible Captcha', verificationId);
      history.push('confirm', { phoneNumber, verificationId });
    } catch (error: any) {
      alert(
        typeof error.message === 'string'
          ? `Error! ${error.message}. Please contact dev team.`
          : 'Error! Please contact dev team.',
      );
      // console.log('OnPressLogin error', error);
    } finally {
      setIsPending(false);
    }
  };
  const handlePhoneNumber = ({ target }) => {
    setPhoneNumber(target.value);
  };
  return (
    <div
      style={{
        background: '#3FC7E0',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ fontWeight: 300, marginTop: 240, marginLeft: -100 }}>
          <h1
            style={{
              fontWeight: 300,
            }}
          >
            Please Enter Phone Number
          </h1>
          <div id="recaptcha-container" ref={captchaRef} />
          <Textfield
            name="basic"
            aria-label="default text field"
            placeholder="+972822982"
            value={phoneNumber}
            onChange={handlePhoneNumber}
          />
          <div style={{ marginTop: 20, borderRadius: 20 }}>
            <Button
              onClick={OnPressLogin}
              isDisabled={isPending}
              appearance="primary"
              color="#3FC7E0"
              style={{
                borderRadius: 10,
                width: 500,
                height: 50,
                fontSize: 18,
                fontWeight: 300,
              }}
            >
              {isPending ? 'Loading' : 'Send Confirmation Code'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
