// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect, useMemo } from 'react';
import Select, { OptionType } from '@atlaskit/select';
import { useReviews, useTrainerRatings } from '@controllers/reviewsFirestoreController';
import _ from 'lodash';
import { ReviewRecord, TrainerRatingRecord } from '@root/models/review';
import Textfield from '@atlaskit/textfield';
import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';
import Table from '../../components/table/table';
import { RatingsRows, TrainerRatingsRows } from '../../components/table/content/rows';
import { RatingsHead, TrainerRatingsHead } from '../../components/table/content/Head';
import AddRatings from '../../components/modals/AddNewRatingModal';
import StyledLabel from '../../utils/styles/StyledLabel';
import StyledSearch from '../../utils/styles/StyledSearch';

export default function Rating() {
  const { totalReviews } = useReviews();
  const { trainerRatings } = useTrainerRatings();
  const [client, setClient] = useState<string | null>(null);
  const [clients, setClients] = useState<OptionType[]>([]);

  const [trainer, setTrainer] = useState<string | null>(null);
  const [trainers, setTrainers] = useState<OptionType[]>([]);

  const [approved, setApproved] = useState<OptionType>({ label: 'All', value: 'All' });

  const [searchValue, setSearchValue] = useState<string>('');

  const splitClientTrainerNames = () => {
    const newClients: OptionType[] = [];
    const newTrainers: OptionType[] = [];

    // eslint-disable-next-line array-callback-return
    totalReviews.map((value) => {
      const clientExists: number = newClients.findIndex((e) => e.value === value.user.name);
      const trainerExists: number = newTrainers.findIndex((e) => e.value === value.trainer.name);

      if (clientExists === -1) {
        newClients.push({
          label: value.user.name,
          value: value.user.name,
        });
      }

      if (trainerExists === -1) {
        newTrainers.push({
          label: value.trainer.name,
          value: value.trainer.name,
        });
      }
    });

    setClients(newClients);
    setTrainers(newTrainers);
  };

  useEffect(() => {
    splitClientTrainerNames();
  }, [totalReviews, trainerRatings]);

  const filteredTotalReviews = useMemo(() => {
    let filteredReviews: ReviewRecord[] = [];

    if (!trainer) {
      filteredReviews = totalReviews;
    } else {
      filteredReviews = totalReviews.filter((record) => record.trainer.name === trainer);
    }

    if (client) {
      filteredReviews = filteredReviews.filter((record) => record.user.name === client);
    }

    if (approved.value !== 'All') {
      const booleanValue = approved.value === 'true';
      filteredReviews = filteredReviews.filter((record) => record.isApproved === booleanValue);
    }

    return filteredReviews;
  }, [clients, trainers, client, trainer, approved]);

  // eslint-disable-next-line max-len
  const filteredTrainerRatings = useMemo(() => _.filter(trainerRatings, (item) => item.fullName?.toLowerCase().includes(searchValue.toLowerCase())) as TrainerRatingRecord[], [trainerRatings, searchValue]);

  return (
    <div
      style={{
        flex: 4,
      }}
    >
      <div className="Top">
        <StyledLabelTitle title="Ratings" />
        <AddRatings />
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 20,
          }}
        >
          <StyledLabel label="Client" width="120px" />
          <StyledSearch
            data={clients}
            placeholder="Select Client"
            selectUser={(val, isClear) => (!isClear ? setClient(val?.label ?? '') : setClient(null))}
            isClearable
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 20,
          }}
        >
          <StyledLabel label="Trainer" width="160px" />
          <StyledSearch
            data={trainers}
            placeholder="Select Trainer"
            selectUser={({ label }, isClear) => (!isClear ? setTrainer(label) : setTrainer(null))}
            isClearable
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 20,
          }}
        >
          <StyledLabel label="Approved" width="160px" />
          <div style={{ minWidth: '120px' }}>
            <Select
              inputId="single-select-example"
              options={
                [
                  { label: 'All', value: 'All' },
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ] as OptionType[]
              }
              value={approved}
              onChange={(data) => {
                setApproved(data ?? { label: 'All', value: 'All' });
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table
          row={RatingsRows(filteredTotalReviews)}
          headType={RatingsHead}
          defaultSortKey="Date"
          defaultSortOrder="DESC"
        />
      </div>

      <div style={{ marginTop: 64, marginLeft: 20 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '16px',
        }}
        >
          <StyledLabelTitle title="Trainer Ratings" />
          <div style={{ width: 240, marginLeft: 30 }}>
            <Textfield
              name="basic"
              aria-label="default text field"
              placeholder="Search by name"
              value={searchValue}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setSearchValue(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        <Table row={TrainerRatingsRows(filteredTrainerRatings)} headType={TrainerRatingsHead} />
      </div>
    </div>
  );
}
