import { CityTypes, CityRegion } from '@root/models/city';
import collections from '@root/utils/enums/collections.enums';
import { LatLng } from 'leaflet';
import { db } from './firebase';

// dotenv.config();
const citiesRef = db.collection(collections.CITIES);
const regionsRef = db.collection(collections.CITY_REGIONS);

export default class CitiesController {
  static async getAllCities() {
    const cities = await citiesRef.get();
    return cities.docs.map(((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))) as unknown as CityTypes[];
  }

  static async saveCity(city) {
    return citiesRef.add(city);
  }

  static async getRegionsByCity(id: string) {
    const regions = await regionsRef.where('cityId', '==', id).get();
    return regions.docs.map(((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))) as unknown as CityRegion[];
  }

  static async saveRegion(region: CityRegion) {
    return regionsRef.add(region);
  }

  static async editRegion(id: string, coordinates: LatLng[]) {
    return regionsRef.doc(id).update({ coordinates });
  }
}
