/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import React from 'react';
import Button from '@atlaskit/button';
import Toggle from '@atlaskit/toggle';
import {
  updateTrainer,
  updateTrainerProfilePicture,
} from '@controllers/trainerFirestoreController';
import { Trainer } from '@models/trainer';
import { User } from '@models/user';
import { StyledSelect } from '@root/components/select/StyledDataSelect';
import {
  DateInput,
  TextAreaInput,
  TextInput,
} from '@root/components/textInput';
import { OptionType } from '@root/types/types';
import { TrainerLevels, TrainerTypes } from '@utils/common/data';
import { findLast } from 'lodash';
import moment from 'moment';
import { useFilePicker } from 'use-file-picker';

export default function TrainerProfileTop({
  trainer,
}: {
  trainer: Trainer & User;
}) {
  const trainerType = findLast(
    TrainerTypes,
    (item) => (trainer?.trainerType || '').toLowerCase()
      === (item.value as string).toLowerCase(),
  );
  const trainerLevel = findLast(
    TrainerLevels,
    (item) => (trainer?.trainerLevel || '').toLowerCase()
      === (item.value as string).toLowerCase(),
  );
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });
  const [openFileSelector2, {
    filesContent: filesContent2, clear: clear2,
  }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });
  const onCallUpdatePhoto = async (picNum) => {
    if (picNum === 1) {
      await updateTrainerProfilePicture(trainer, filesContent[0].content, picNum);
    } else if (picNum === 2) {
      await updateTrainerProfilePicture(trainer, filesContent2[0].content, picNum);
    }
  };

  const downloadPicture = async (imgUrl: string) => {
    try {
      fetch(imgUrl)
        .then((response) => {
          response.arrayBuffer().then((buffer) => {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${trainer.displayName ? trainer.displayName : trainer.id}.png`,
            );
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(() => {
          // console.log('====Error getting file====', err);
        });
    } catch (error) {
      // console.log('===Error====', error);
    }
  };

  const chagePic = () => {
    if (filesContent.length === 0) {
      openFileSelector();
    } else {
      onCallUpdatePhoto(1);
      clear();
    }
  };
  const chagePic2 = () => {
    if (filesContent2.length === 0) {
      openFileSelector2();
    } else {
      onCallUpdatePhoto(2);
      clear2();
    }
  };
  return (
    <div className="two-columns-grid" style={{ marginTop: 40 }}>
      <div className="flex">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={filesContent[0]?.content || trainer?.picture}
            alt="trainer profile"
            style={{
              height: '133px',
              width: '107px',
              objectFit: 'cover',
              borderRadius: 8,
              overflow: 'hidden',
            }}
          />
          <Button
            onClick={
              () => chagePic()
            }
            appearance="primary"
            style={{
              backgroundColor: '#3FC7E0',
              marginTop: 10,
            }}
          >
            {filesContent.length === 0 ? 'Change Photo' : 'Update'}
          </Button>
          {trainer.picture && (
            <Button
              onClick={() => downloadPicture(trainer?.picture as string)}
              // appearance="primary"
              style={{
                // backgroundColor: '#3FC7E0',
                backgroundColor: 'white',
                marginTop: 10,
                color: 'black',
              }}
            >
              Download Photo
            </Button>
          )}
          {filesContent.length > 0 ? (
            <div style={{ marginTop: 10 }}>
              <Button
                onClick={clear}
                appearance="primary"
                style={{
                  backgroundColor: '#697E8D',
                  marginTop: 0,
                }}
              >
                Cancel
              </Button>
            </div>
          ) : null}
        </div>
        <div className="flex">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={filesContent2[0]?.content || trainer?.pictureSecondary}
              alt="trainer profile"
              style={{
                height: '133px',
                width: '107px',
                objectFit: 'cover',
                borderRadius: 8,
                overflow: 'hidden',
              }}
            />
            <Button
              onClick={
                () => chagePic2()
              }
              appearance="primary"
              style={{
                backgroundColor: '#3FC7E0',
                marginTop: 10,
              }}
            >
              {filesContent2.length === 0 ? 'Change Photo' : 'Update'}
            </Button>
            {trainer.pictureSecondary && (
              <Button
                onClick={() => downloadPicture(trainer?.pictureSecondary as string)}
                // appearance="primary"
                style={{
                  // backgroundColor: '#3FC7E0',
                  backgroundColor: 'white',
                  marginTop: 10,
                  color: 'black',
                }}
              >
                Download Photo
              </Button>
            )}
            {filesContent2.length > 0 ? (
              <div style={{ marginTop: 10 }}>
                <Button
                  onClick={clear2}
                  appearance="primary"
                  style={{
                    backgroundColor: '#697E8D',
                    marginTop: 0,
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ margin: '0 20px', width: '100%' }}>
          <TextAreaInput
            label="Bio:"
            value={trainer?.bio}
            onBlur={(e: React.FormEvent<HTMLInputElement>) => {
              updateTrainer({
                id: trainer.id,
                bio: e.currentTarget.value,
              });
            }}
          />
        </div>
      </div>
      <div className="trainerInfoInputs">
        <div className="inputWrapper">
          <div className="label">Status</div>
          <div style={{ borderRadius: 3 }}>
            <Toggle
              id="toggle-active"
              size="large"
              isChecked={trainer.isActive}
              onChange={() => {
                updateTrainer({
                  id: trainer.id,
                  isActive: !trainer.isActive,
                });
              }}
            />
          </div>
        </div>
        <div className="inputWrapper">
          <div className="label">Incognito</div>
          <div style={{ borderRadius: 3 }}>
            <Toggle
              id="toggle-active"
              size="large"
              isChecked={trainer.incognito}
              onChange={() => {
                updateTrainer({
                  id: trainer.id,
                  incognito: !trainer.incognito,
                });
              }}
            />
          </div>
        </div>
        <StyledSelect
          name="Trainer Level"
          value={trainerLevel}
          options={TrainerLevels}
          onChange={(e) => {
            updateTrainer({
              id: trainer.id,
              trainerLevel: e.value,
            });
          }}
        />
        <StyledSelect
          name="Type of Trainer"
          value={trainerType as OptionType}
          options={TrainerTypes}
          onChange={(e) => {
            updateTrainer({
              id: trainer.id,
              trainerType: e.value,
            });
          }}
        />
        <TextInput
          label="Appearance Score"
          type="number"
          defaultValue={trainer?.appearanceScore}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            const financialGoal = e.currentTarget.validity.valid
              ? e.currentTarget.value
              : trainer?.appearanceScore;
            updateTrainer({
              id: trainer.id,
              appearanceScore: Number(financialGoal),
            });
          }}
        />
        <DateInput
          label="Registration Date"
          isDisabled
          value={
            trainer?.joinedDate
              ? moment(trainer?.joinedDate.toDate()).format('YYYY-MM-DD')
              : ''
          }
          dateFormat="DD MMM YYYY"
        />
      </div>
    </div>
  );
}
