import Textfield, { TextFieldProps } from '@atlaskit/textfield';
import { css } from '@emotion/react';
import StyledLabelLarge from './StyledLabelLarge';

type Props = {
  name: string;
  placeholder: string;
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChangeValue?: (value: string) => void;
} & Partial<TextFieldProps>;
export default function StyledUserProfileElementRightLarge({
  name,
  placeholder,
  value,
  onChangeValue,
  ...props
}: Props) {
  const bigFontStyles = css({
    paddingRight: 10,
    color: 'black',
    '& > [data-ds--text-field--input]': {
      fontSize: 14,
      color: 'black',
    },
  });

  return (
    <div className="part">
      <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="Name" style={{ padding: 20 }}>
          <StyledLabelLarge label={name} />
        </div>
        <div style={{ width: 130, borderRadius: 3, marginTop: 20 }}>
          <Textfield
            aria-label="customized text field"
            placeholder={placeholder}
            css={bigFontStyles}
            style={{ borderRadius: 3 }}
            value={value}
            color="black"
            {...props}
          />
        </div>
      </div>
    </div>
  );
}
