/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';
import { Banner } from '../models/banner';

export const BannerFirestore = () => {
  const [items, setItems] = useState<Banner[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.BANNERS)
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setItems(fetched as Banner[]);
      });
    return unsubscribe;
  }, []);
  const updateBanner = (banner: Banner) => {
    try {
      return db
        .collection(collections.BANNERS)
        .doc(banner.id)
        .update(banner);
    } catch (error) {
      // console.log('===Error updating trainer====', error);
    }
  };
  const deleteBanner = (id: string) => {
    try {
      return db.collection(collections.BANNERS).doc(id).delete();
    } catch (error) {
      // console.log('===Error deleting coupon====', error);
    }
  };

  return {
    items, updateBanner, deleteBanner,
  };
};

export default class BannerController {
  static async getBannerById(id: string): Promise<Banner> {
    const response = await db.collection(collections.BANNERS).doc(id).get();
    return response.data() as Banner;
  }

  static async addBanner(banner: Banner) {
    const response = await db
      .collection(collections.BANNERS)
      .add(banner);
    response.update({ id: response.id });
  }
}
