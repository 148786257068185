/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export type Discipline = {
  id: string;
  trainerType: string;
  backgroundColor?: string;
  isSelected?: boolean;
  picture?: string;
  smallPicture?: string;
  bigPicture?: string;
};

export interface DisciplineV2 {
  id?: string;
  trainerType?: string;
  picture?: string;
  // eslint-disable-next-line camelcase
  picture_1?: string;
  order?: number;
  isLoading?: boolean;
  duration?: number;
  deliveryType?: string;
  type?: string;
  active?: boolean
}

export enum DisciplineServices {
  fitness = 'fitness',
  wellness= 'wellness'
}

export enum DisciplineDeliveryType {
  fitness = 'in-person',
  wellness= 'online'
}

export const DisciplineServicesOptions = [{
  label: DisciplineServices.fitness.toLocaleUpperCase(),
  value: DisciplineServices.fitness,
}, {
  label: DisciplineServices.wellness.toLocaleUpperCase(),
  value: DisciplineServices.wellness,
}];

export const DisciplineDeliveryTypeOptions = [{
  label: DisciplineDeliveryType.fitness.toLocaleUpperCase(),
  value: DisciplineDeliveryType.fitness,
}, {
  label: DisciplineDeliveryType.wellness.toLocaleUpperCase(),
  value: DisciplineDeliveryType.wellness,
}];
