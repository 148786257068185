/* eslint-disable consistent-return */
import { FitlovPoints } from '@root/models/fitlovPoints';
import firebase from 'firebase';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';

function getCollection() {
  return db.collection(collections.FITLOV_POINTS);
}

export const updateFitlovPoints = (fitlovPoints: FitlovPoints, newValue: number) => {
  try {
    return db.collection(collections.FITLOV_POINTS).doc(fitlovPoints.id).update({
      total: newValue,
      logs: firebase.firestore.FieldValue.arrayUnion({
        note: `${newValue - fitlovPoints.total} points added from admin-panel`,
        createdAt: new Date(),
      }),
    });
  } catch (error) {
    console.log('===Error updating Points====', error);
  }
};

export const getFitlovPointsByUserId = async (
  userId: string,
): Promise<FitlovPoints> => {
  const collection = getCollection();
  const snapshot = await collection.doc(userId).get();
  return snapshot.data() as FitlovPoints;
};

export const addFitlovPoints = async (info:
  {userId: string, total: number}) => {
  const fitlovPoints: FitlovPoints = {
    id: info.userId,
    total: info.total,
    logs: [{
      note: `${info.total} points added from admin-panel`,
      createdAt: new Date(),
    }],
  };
  return db.collection(collections.FITLOV_POINTS).doc(fitlovPoints.id).set(fitlovPoints);
};
