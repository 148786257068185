/* eslint-disable import/no-unresolved */
import { CityRegion } from '@models/city';
import FirestoreService from '@utils/firestoreService';
import collections from '../utils/enums/collections.enums';

export default class CityRegionsController {
  static async get() {
    return FirestoreService.fetchAllPairWithId(collections.CITY_REGIONS);
  }

  static async add(data: CityRegion) {
    FirestoreService.addObjectToCollection(collections.CITY_REGIONS, data);
  }
}
