// eslint-disable-next-line no-use-before-define
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@atlaskit/button';
import Select from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
// import { User } from '@models/user';
import { UserStatusOptions } from '@root/utils/common/data';
import { CSVLink } from 'react-csv';
import colors from '@root/utils/colors';
// import _ from 'lodash';
// import UserStatusEnum from '@root/utils/enums/userStatus.enums';
// import useCache from '@root/hooks/useCache';
// import StyledLabelTitle from '@root/utils/styles/StyledLabelTitle';
import { getAllUsers } from '@root/controllers/userFirestoreController';
import moment from 'moment';
import { AddUser } from '../../components/modals/AddNewUserModal';
import { userHead } from '../../components/table/content/Head';
import { UserRows } from '../../components/table/content/rows';
import Table from '../../components/table/table';
// import { getAllUsers } from '../../controllers/userFirestoreController';
import { OptionType } from '../../types/types';

import './home.css';

let timeout;

export default function Home() {
  // const { allUsers } = useCache();
  const [items, setitems] = useState<any>([]);
  const [userStatusFilter, setUserStatusFilter] = useState<string | undefined>(undefined);
  const [userNameFilter, setUserNameFilter] = useState<string | undefined>(
    undefined,
  );
  const [phoneFilter, setPhoneFilter] = useState<string | undefined>(undefined);
  const [cityFilter, setCityFilter] = useState<string | undefined>(undefined);
  const [csvUsersData, setCsvUsersData] = useState<any>([]);

  const [cities, setCities] = useState<OptionType[]>([]);

  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
  });

  const getData = async (fullname: string, mobileNumber: string, status: string, city: string) => {
    const data = await getAllUsers(pagination.page, fullname, mobileNumber, status, city);
    setLoading(false);
    setitems(data.users);
    setPagination({
      page: data.currentPage,
      totalPages: data.totalPages,
    });
  };

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setLoading(true);
      getData(userNameFilter || '', phoneFilter || '', userStatusFilter || '', cityFilter || '');
    }, 500);
    // getData(userNameFilter || '', phoneFilter || '');
  }, [userNameFilter, phoneFilter, userStatusFilter, cityFilter, pagination.page]);

  const getCities = () => {
    const citiesArr: OptionType[] = [{
      key: 'All',
      label: 'All',
      value: '',
    }];
    items?.map((user: any) => {
      const exists = citiesArr.findIndex((city) => city.label === user.city);
      if (exists < 0) {
        citiesArr.push({
          key: user.city,
          label: user.city as string,
          value: user.city as string,
        });
      }

      return null;
    });
    setCities(citiesArr);
  };
  // const filteredUserData = useMemo(() => {
  //   let filtered: User[] = [];
  //   if (!userStatusFilter || userStatusFilter === '') {
  //     filtered = items as User[];
  //   } else if (userStatusFilter === UserStatusEnum.NEW_USER) {
  //     // filtered = filtered;
  //     // filtered = _.filter(
  //     //   items,
  //     //   (item: User) => (item?.status === UserStatusEnum.NEW_USER),
  //     // );
  //   } else if (userStatusFilter === UserStatusEnum.ACTIVE_CLIENT) {
  //     filtered = _.filter(
  //       items,
  //       (item: User) => (item?.status === UserStatusEnum.ACTIVE_CLIENT),
  //     );
  //   } else if (userStatusFilter === UserStatusEnum.SIESTA_CLIENT) {
  //     filtered = _.filter(
  //       items,
  //       (item: User) => item?.status === UserStatusEnum.SIESTA_CLIENT,
  //     );
  //   } else if (userStatusFilter === UserStatusEnum.PENDING_CLIENT) {
  //     filtered = _.filter(
  //       items,
  //       (item: User) => item?.status === UserStatusEnum.PENDING_CLIENT,
  //     );
  //   } else if (userStatusFilter === UserStatusEnum.LOST_CLIENT) {
  //     filtered = _.filter(
  //       items,
  //       (item: User) => item?.status === UserStatusEnum.LOST_CLIENT,
  //     );
  //   } else if (userStatusFilter === UserStatusEnum.PT_CLIENT) {
  //     filtered = _.filter(
  //       items,
  //       (item: User) => item?.status === UserStatusEnum.PT_CLIENT,
  //     );
  //   } else if (userStatusFilter === UserStatusEnum.OVERRIDE) {
  //     filtered = _.filter(
  //       items,
  //       (item: User) => item?.status === UserStatusEnum.OVERRIDE,
  //     );
  //   } else if (userStatusFilter === UserStatusEnum.ARCHIVED_CLIENT) {
  //     filtered = _.filter(
  //       items,
  //       (item: User) => item?.status === UserStatusEnum.ARCHIVED_CLIENT,
  //     );
  //   } else {
  //     filtered = items as User[];
  //   }
  //   let filteredByUser: User[] = [];
  //   if (userNameFilter) {
  //     filteredByUser = filtered;
  //     // filteredByUser = _.filter(
  //     //   filtered,
  //     //   (item) => item.fullname?.toLowerCase().includes(userNameFilter.toLowerCase()),
  //     // ) as User[];
  //   } else if (phoneFilter) {
  //     filteredByUser = filtered;
  //     // filteredByUser = _.filter(
  //     //   filtered,
  //     //   (item) => item.mobile?.number.includes(phoneFilter),
  //     // ) as User[];
  //   } else {
  //     filteredByUser = filtered;
  //   }

  //   // if (cityFilter !== null && cityFilter !== '') {
  //   //   filteredByUser = _.filter(
  //   //     filteredByUser,
  //   //     (item) => item.city?.toLowerCase().includes(cityFilter.toLowerCase()),
  //   //   ) as User[];
  //   // }

  //   return filteredByUser;
  // }, [userStatusFilter, userNameFilter, phoneFilter, cityFilter, items]);

  const filteredUserData = useMemo(() => items, [items]);

  useEffect(() => {
    getCities();
  }, [items]);

  useEffect(() => {
    const newCsvUsersData = filteredUserData.map((item: any) => ({
      Name: `${item?.fullname}`,
      Phone: `${item?.mobile?.areaCode} ${item?.mobile?.number}`,
      Email: `${item?.email}`,
      Status: `${item?.status}`,
      // eslint-disable-next-line no-underscore-dangle
      RegistrationDate: `${item?.createdAt?._seconds}`
        // eslint-disable-next-line no-underscore-dangle
        ? `${moment(item.createdAt._seconds * 1000).format('DD MMM YYYY')}`
        : 'N/A',

    }));
    setCsvUsersData(newCsvUsersData);
  }, [filteredUserData]);

  const goToNextPage = () => {
    setPagination({
      page: pagination.page + 1,
      totalPages: pagination.totalPages,
    });
  };

  const goToPreviousPage = () => {
    setPagination({
      page: pagination.page - 1,
      totalPages: pagination.totalPages,
    });
  };

  return (
    <div className="home">
      {/* <StyledLabelTitle
        // title="All Users"
        refresh={allUsers?.refresh}
        lastRefresh={allUsers?.lastSync}
      /> */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AddUser />
        <div style={{ width: 150, marginLeft: 30 }}>
          <Select
            inputId="multi-select-example"
            className="multi-select"
            classNamePrefix="react-select"
            options={[{ label: 'All', value: '' }, ...UserStatusOptions]}
            isSearchable={false}
            placeholder="All"
            onChange={(option) => {
              setUserStatusFilter(option?.value.toLocaleString() || undefined);
            }}
          />
        </div>
        <div style={{ width: 150, marginLeft: 30 }}>
          <Textfield
            name="basic"
            aria-label="default text field"
            placeholder="Search by name"
            value={userNameFilter}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setPhoneFilter('');
              setUserNameFilter(e.currentTarget.value);
            }}
          />
        </div>
        <div style={{ marginLeft: 30 }}>
          <Textfield
            name="basic"
            aria-label="default text field"
            placeholder="Search by number"
            type="number"
            value={phoneFilter}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setUserNameFilter('');
              setPhoneFilter(e.currentTarget.value);
            }}
          />
        </div>
        <div style={{ width: 150, marginLeft: 30 }}>
          <Select
            inputId="city-filter"
            className="multi-select"
            classNamePrefix="react-select"
            options={cities}
            isSearchable={false}
            placeholder="City"
            onChange={(option) => {
              setCityFilter(option?.value.toString() || undefined);
            }}
          />
        </div>
        <div style={{ width: 150, marginLeft: 30 }}>
          <CSVLink
            data={csvUsersData}
            filename="users"
            style={{
              textDecoration: 'none',
              backgroundColor: colors.darkGray,
              color: 'white',
              padding: '8px 20px',
              borderRadius: 3,
            }}
          >
            Export CSV
          </CSVLink>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Table
          // isLoading={allUsers?.loading}
          isLoading={loading}
          headType={userHead}
          row={UserRows(filteredUserData)}
          defaultSortKey="Registration date"
          defaultSortOrder="DESC"
          rowsPerPage={null}
        />
        <div
          className="pagination-controls"
          style={{
            display: 'flex', alignItems: 'center', marginTop: 20, justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={goToPreviousPage}
            isDisabled={pagination.page === 1}
            style={{ marginRight: 10 }}
          >
            Previous
          </Button>
          <span>{`Page ${pagination.page} of ${pagination.totalPages}`}</span>
          <Button
            type="button"
            onClick={goToNextPage}
            isDisabled={pagination.page === pagination.totalPages}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
