/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import Button from '@atlaskit/button/standard-button';
import Form, { Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import ProgressController from '@root/controllers/progressFirestoreController';
import { User } from '@root/models/user';
import firebase from 'firebase';
import { useCallback, useState } from 'react';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';

const otherKeys = ['muscleMass', 'waist', 'hip', 'thigh', 'biceps'];
const API_URL = process.env.REACT_APP_FIREBASE_FUNCTION_URL;

export default function AddCheckups({ user, userId }: {user:User, userId: string }) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const onSubmit = useCallback(
    (data) => {
      const obj: any = { other: {} };
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(data)) {
        if (key === 'x') {
          obj[key] = value;
        } else if (otherKeys.includes(key)) {
          obj.other[key] = parseFloat(value as string) || 0;
        } else {
          obj[key] = parseFloat(value as string) || 0;
        }
      }
      obj.x = firebase.firestore.Timestamp.fromDate(new Date(obj.x));
      ProgressController.addOrUpdateProgress(userId, obj);
      try {
        (async () => {
          await fetch(`${API_URL}/sendPushNotification`, {
            method: 'POST',
            body: JSON.stringify({
              userIds: [user.id],
              title: 'New Body Checkup',
              message: 'Your last body checkup is available now. Check out your progress and latest acheivements!',
              deeplinkUrl: `fitlov://progress/${userId}`,
            }),
          });
        })();
      } catch (error) {
        console.log('Unable to send push notification');
      }
      setIsOpen(false);
    },
    [userId],
  );

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
        }}
      >
        (+) Add new check-up
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add new checkup" />
                  <ModalBody>
                    <Field id="date" name="x" isRequired>
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Date"
                          type="date"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>
                    <Field id="weight" name="y">
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Weight"
                          unit="KG"
                          type="number"
                          step="any"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>
                    <Field id="weightGoal" name="weightTarget">
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Weight Goal"
                          unit="KG"
                          type="number"
                          step="any"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>
                    <Field id="bmi" name="bmi">
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="BMI"
                          type="number"
                          step="any"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>

                    <Field id="bmiGoal" name="bmiTarget">
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="BMI Goal"
                          type="number"
                          step="any"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>

                    <Field id="bfp" name="bodyFat">
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="BFP"
                          unit="%"
                          type="number"
                          step="any"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>

                    <Field id="bfpGoal" name="bodyFatTarget">
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="BFP Goal"
                          unit="%"
                          type="number"
                          step="any"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>
                    <div
                      className="label"
                      style={{ margin: '10px 0', width: 'auto' }}
                    >
                      Other info
                    </div>
                    <div className="other-info-grid">
                      <Field
                        id="muscleMass"
                        name="muscleMass"
                        label="Muscle Mass"
                      >
                        {({ fieldProps }) => (
                          <FormFieldInput
                            unit="KG"
                            type="number"
                            step="any"
                            fieldProps={fieldProps}
                          />
                        )}
                      </Field>
                      <Field id="waist" name="waist" label="Waist">
                        {({ fieldProps }) => (
                          <FormFieldInput
                            unit="cm"
                            type="number"
                            step="any"
                            fieldProps={fieldProps}
                          />
                        )}
                      </Field>
                      <Field id="hip" name="hip" label="Hip">
                        {({ fieldProps }) => (
                          <FormFieldInput
                            unit="cm"
                            type="number"
                            step="any"
                            fieldProps={fieldProps}
                          />
                        )}
                      </Field>
                      <Field id="arm" name="biceps" label="Arm">
                        {({ fieldProps }) => (
                          <FormFieldInput
                            unit="cm"
                            type="number"
                            step="any"
                            fieldProps={fieldProps}
                          />
                        )}
                      </Field>
                      <Field id="thigh" name="thigh" label="Thigh">
                        {({ fieldProps }) => (
                          <FormFieldInput
                            unit="cm"
                            type="number"
                            step="any"
                            fieldProps={fieldProps}
                          />
                        )}
                      </Field>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
