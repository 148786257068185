/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import React from 'react';
import colors from '@root/utils/colors';
import { daysOfweekShift } from '@root/utils/functions';

function DaySelector({
  label,
  active,
  onClick,
}: {
  label: string;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <div
      onClick={onClick}
      style={{
        height: 40,
        width: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: active ? colors.primary : colors.middleGray,
        borderRadius: 24,
        marginRight: 5,
        cursor: 'pointer',
      }}
    >
      <span
        style={{ color: active ? colors.white : colors.black }}
      >
        {label}
      </span>
    </div>
  );
}

export default function DaysSelector({
  startOfWeekMonday,
  selectedDays,
  selectDay,
}: {
  startOfWeekMonday?: boolean;
  selectedDays: any[];
  // eslint-disable-next-line no-unused-vars
  selectDay: (index: number) => void;
}) {
  const daysOfWeekArr = [
    { index: 0, label: 'Sun' },
    { index: 1, label: 'Mon' },
    { index: 2, label: 'Tue' },
    { index: 3, label: 'Wed' },
    { index: 4, label: 'Thu' },
    { index: 5, label: 'Fri' },
    { index: 6, label: 'Sat' },
  ];

  const data = daysOfweekShift(daysOfWeekArr, startOfWeekMonday);
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10,
    }}
    >
      {data.map(({ label, index }) => (
        <DaySelector
          key={index}
          active={selectedDays.findIndex((da) => da === index) >= 0}
          onClick={() => selectDay(index)}
          label={label}
        />
      ))}
    </div>
  );
}
