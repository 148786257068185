import colors from '@root/utils/colors';
import TimeInput from './timeInput';
import TimeSlotBtn from './timeSlotBtn';

export default function TimeStartEnd({
  startTime,
  endTime,
  showDelete,
  timeSlots,
  isLast,
  onTimeChange,
  addNewSlot,
  removeSlot,
}: {
  startTime: string;
  endTime: string;
  showDelete: boolean;
  timeSlots: number;
  isLast: boolean;
  onTimeChange: (
    // eslint-disable-next-line no-unused-vars
    time: number | string,
    // eslint-disable-next-line no-unused-vars
    origin: 'start' | 'end',
    // eslint-disable-next-line no-unused-vars
    type: 'hour' | 'minute' | 'dayTime'
  ) => void;
  addNewSlot: () => void;
  removeSlot: () => void;
}) {
  return (
    <div
      style={{
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          borderRadius: 4,
          paddingTop: 5,
          paddingBottom: 5,
          paddingRight: 15,
          paddingLeft: 15,
          marginRight: 10,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          backgroundColor: colors.middleGray,
        }}
      >
        <TimeInput
          key={startTime}
          origin="start"
          onTimeChange={onTimeChange}
          time={startTime}
        />
        <span
          style={{
            marginRight: 15,
            marginLeft: 15,
          }}
        >
          to
        </span>
        <TimeInput
          key={endTime}
          origin="end"
          onTimeChange={onTimeChange}
          time={endTime}
        />
      </div>

      <TimeSlotBtn onPress={addNewSlot} isLast={isLast} name="plus" />
      {timeSlots > 1 && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {showDelete ? (
            <>
              <div style={{ width: 5 }} />
              <TimeSlotBtn onPress={removeSlot} showBtn name="minus" />
            </>
          ) : (
            <div style={{ width: 35 }} />
          )}
        </>
      )}
    </div>
  );
}
