import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
  DropdownMenuStatefulProps,
} from '@atlaskit/dropdown-menu';
import { OptionType } from '@typeModel/types';
import StyledLabel from './StyledLabel';

type Props = {
  options: any[];
  name: string;
  label?: string;
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChangeValue?: (value: string) => void;
} & Partial<DropdownMenuStatefulProps>;

export default function StyledUserProfileElementDropDown({
  options,
  name,
  label,
  value,
  onChangeValue,
  ...props
}: Props) {
  return (
    <div className="part">
      <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="Name" style={{ padding: 20 }}>
          <StyledLabel label={label || name} />
        </div>
        <div style={{ width: 225, borderRadius: 3, marginTop: 20 }}>
          <DropdownMenu trigger={name} triggerType="button" {...props}>
            <DropdownItemGroup>
              {options.map((item) => {
                if (typeof item === 'object') {
                  const optionValue = item as unknown as OptionType;
                  return (
                    <DropdownItem
                      // eslint-disable-next-line no-unused-vars
                      onMouseDown={(event: MouseEvent | KeyboardEvent) => {
                        if (onChangeValue) {
                          onChangeValue(optionValue?.value.toString());
                        }
                      }}
                    >
                      {optionValue.label}
                    </DropdownItem>
                  );
                }
                return (
                  <DropdownItem
                    // eslint-disable-next-line no-unused-vars
                    onMouseDown={(e: MouseEvent | KeyboardEvent) => {
                      if (onChangeValue) {
                        onChangeValue(item.toString());
                      }
                    }}
                  >
                    {item}
                  </DropdownItem>
                );
              })}
            </DropdownItemGroup>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}
