import './App.css';
import { GlobalsProvider } from '@contexts/global.context';
import { CityRegionProvider } from '@contexts/cityRegion.context';
import { AuthContextProvider } from '@contexts/auth.context';
import { ToastContainer } from 'react-toastify';
import Navigation from './components/navigation/navigation';
import 'react-toastify/dist/ReactToastify.css';
import { CacheProvider } from './hooks/useCache';

function App() {
  return (
    <CacheProvider>
      <AuthContextProvider>
        <GlobalsProvider>
          <CityRegionProvider>
            <Navigation />
          </CityRegionProvider>
          <ToastContainer />
        </GlobalsProvider>
      </AuthContextProvider>
    </CacheProvider>
  );
}
export default App;
