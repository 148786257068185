/* eslint-disable camelcase */
const API_URL = process.env.REACT_APP_FIREBASE_FUNCTION_URL;

const endpointFunctions = {
  buy_package: `${API_URL}/buyPackage`,
  createBooking: `${API_URL}/bookTrainer`,
  get_price_per_session: `${API_URL}/getPricePerSession`,
  cancel_booking: `${API_URL}/cancelBooking`,
  add_package: `${API_URL}/addPackage`,
  get_client_packages: `${API_URL}/getClientPackages`,
  get_trainer_packages: `${API_URL}/getTrainerPackages`,
  notify_Trainers: `${API_URL}/notifyTrainers`,
  get_trainer_stats: `${API_URL}/getStats`,
  get_expired_packages: `${API_URL}/getExpiredPackages`,
  send_notification: `${API_URL}/sendPushNotification`,
  get_trainer_performance: `${API_URL}/getTrainerPerformance`,
  trigger_cache: `${API_URL}/triggerCache`,
  getAllUserBalance: `${API_URL}/getAllUserBalance`,
};

export default endpointFunctions;
