/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import firebase from 'firebase';
import { firebaseApp } from './firebase';
import 'firebase/auth';

export default class AuthController {
  static auth(): firebase.auth.Auth {
    return firebaseApp?.auth();
  }

  static async verifiedPhoneNumber(
    phoneNumber: string,
    recaptchaVerifier: any,
  ) {
    const phoneProvider = new firebaseApp.auth.PhoneAuthProvider();
    return phoneProvider.verifyPhoneNumber(
      phoneNumber,
      // @ts-ignore
      recaptchaVerifier,
    );
  }

  static async confirmOTP(verificationId: string, verificationCode: string) {
    const credential = firebaseApp.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const authResult = await firebaseApp
      .auth()
      .signInWithCredential(credential);
    return authResult;
  }

  static async registerWithEmailAndPassword(email: string, password: string) {
    return firebaseApp?.auth().createUserWithEmailAndPassword(email, password);
  }

  static async authWithTokenFromGoogle(response: any) {
    const credential = firebaseApp.auth.GoogleAuthProvider.credential(
      response.idToken,
    );
    return firebaseApp?.auth().signInWithCredential(credential);
  }

  static async signInWithEmailAndPassword(email: string, password: string) {
    return firebaseApp?.auth().signInWithEmailAndPassword(email, password);
  }

  static async reauthenticate(password: string) {
    const user: firebase.User = firebaseApp.auth()
      .currentUser as firebase.User;
    if (user) {
      const credentials = firebaseApp.auth.EmailAuthProvider.credential(
        user.email as string,
        password,
      );
      return user.reauthenticateWithCredential(credentials);
    }
    return null;
  }

  static async updatePassword(newPassword: string) {
    const user: firebase.User = firebaseApp.auth()
      .currentUser as firebase.User;
    user
      .updatePassword(newPassword)
      .then(() => {
        firebaseApp.auth().signOut();
        return 'Contraseña Modificada correctamente';
      })
      .catch((error: any) => error);
  }

  static async sendPasswordReset(email: string) {
    return firebaseApp?.auth().sendPasswordResetEmail(email);
  }

  static async signOut() {
    return firebaseApp.auth().signOut();
  }

  static isUserLogged() {
    return firebaseApp.auth().currentUser as firebase.User;
  }

  static getUserId() {
    return firebaseApp.auth().currentUser?.uid as string;
  }
}
