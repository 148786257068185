/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import firebase from 'firebase/app';
import { fbAuth, db } from '@controllers/firebase';
import { User, Roles } from '@models/user';
import { Trainer } from '@models/trainer';
import collections from '@utils/enums/collections.enums';

export const AuthContext = createContext<{
  user:(User & Trainer) | null;
  userDataPresent: boolean;
    }>({
      user: null,
      userDataPresent: false,
    });

export function AuthContextProvider({ children }:{children: React.ReactNode }) {
  const [user, setUser] = useState<(User & Trainer) | null>(null);
  const [userDataPresent, setUserDataPresent] = useState<boolean>(false);

  const onAuthStateChanged = async (userState: firebase.User | null) => {
    if (userState) {
      db.collection(collections.USERS)
        .doc(userState.uid)
        .onSnapshot((observer) => {
          const userFirestore: User & Trainer = observer.data() as User &
            Trainer;

          setUser((prev) => ({ ...prev, ...userFirestore }));
          setUserDataPresent(true);
        });
    } else {
      setUser(null);
      setUserDataPresent(true);
    }
  };
  useEffect(() => {
    const unsubscribe = fbAuth.onAuthStateChanged(onAuthStateChanged, () => {
      setUserDataPresent(true);
    });

    return unsubscribe;
  }, []);
  const authContext = useMemo(() => ({ user, userDataPresent }), [user, userDataPresent]);
  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthState = () => {
  const { user, userDataPresent } = useContext(AuthContext);
  return {
    user,
    isAdmin: user !== null && _.includes(user?.roles || [], Roles.admin),
    isAuthenticated: user !== null,
    userDataPresent,
  };
};
