import AddBanner from '@root/components/modals/AddNewBannerModal';
import { BannersHead } from '@root/components/table/content/Head';
import { BannersRows } from '@root/components/table/content/rows';
import Table from '@root/components/table/table';
import { BannerFirestore } from '@root/controllers/bannerFirebaseController';
import StyledLabelTitle from '@root/utils/styles/StyledLabelTitle';

export default function Banners() {
  const { items } = BannerFirestore();
  return (
    <div>
      <div className="Top">
        <StyledLabelTitle title="Banners" />
        <AddBanner />
      </div>
      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table headType={BannersHead} row={BannersRows(items)} defaultSortKey="Order" defaultSortOrder="ASC" />
      </div>
    </div>
  );
}
