/* eslint-disable array-callback-return */
import Select, { OptionType } from '@atlaskit/select';
import { Booking, Session } from '@models/booking';
import {
  BookingConfirmationOptions,
  BookingStatusOptions,
  ClientTypes,
  NoOfPeopleOptions,
} from '@root/utils/common/data';
import StyledSearch from '@root/utils/styles/StyledSearch';
import { convertBookingToSessionFormat } from '@utils/functions';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { trainerProfileHead } from '../../../components/table/content/Head';
import { TrainerSessionsRows } from '../../../components/table/content/rows';
import Table from '../../../components/table/table';
import StyledLabelTitleLarge from '../../../utils/styles/StyledLabelTitleLarge';

export default function TrainerProfileBottomTable({
  trainerBookings,
}: {
  trainerBookings: Booking[];
}) {
  const [totalSessionData, setTotalSessionData] = useState<Session[]>([]);
  const [bookingStatusFilter, setBookingStatusFilter] = useState<string | null>(
    'All',
  );
  const [bookingConfirmationFilter, setBookingConfirmationFilter] = useState<
    string | null
  >('All');
  const [noOfPeopleFilter, setNoOfPeopleFilter] = useState<string | null>(
    'All',
  );

  const [clientType, setClientType] = useState<string | null>(null);

  const [monthFilter, setMonthFilter] = useState<string | number>(0);

  const [clients, setClients] = useState<OptionType[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>('');

  // eslint-disable-next-line no-unused-vars
  const [years, setYears] = useState<OptionType[]>([]);

  const [availableMonths, setAvailableMonths] = useState<OptionType[]>([]);

  const checkAvaiableTimelines = () => {
    const tempMonths: OptionType[] = [
      {
        label: 'All',
        value: 0,
      },
    ];
    const tempYears: OptionType[] = [
      {
        label: 'All',
        value: 0,
      },
    ];
    trainerBookings.map((u) => {
      const month = new Date(`${u.date}`).getMonth() + 1;
      const year = new Date(`${u.date}`).getFullYear();

      const monthStartDate = new Date(`${month}/1/${year}`).getTime();

      const existsMonths = tempMonths.findIndex(
        (y) => y.value === monthStartDate,
      );

      if (existsMonths === -1) {
        tempMonths.push({
          label: `${month.toString().padStart(2, '0')} / ${year
            .toString()
            .padStart(2, '0')}`,
          value: monthStartDate,
        });
      }
      const yearStartDate = new Date(`01/01/${year}`).getTime();

      const yearsExists = tempYears.findIndex((y) => y.value === yearStartDate);

      if (yearsExists === -1) {
        tempYears.push({ label: `${year}`, value: yearStartDate });
      }
    });
    setAvailableMonths(tempMonths);

    setYears(tempYears);
  };

  useEffect(() => {
    let filteredTotalSessionData: Session[] = totalSessionData;

    if (monthFilter !== 0) {
      filteredTotalSessionData = _.filter(
        filteredTotalSessionData,
        (item: Session) => {
          const filterDate = new Date(monthFilter).getTime();
          return moment(item?.date, 'ddd MMM DD, YYYY').isSame(
            filterDate,
            'month',
          );
        },
      );
    }

    const clientsList = filteredTotalSessionData.map((elm) => elm?.client);
    const uniqueList = _.uniqBy(clientsList, 'id');
    const clientOptions = uniqueList.map(
      (elm) => ({
        label: elm?.name,
        value: elm?.id,
      } as OptionType),
    );

    setClients(clientOptions as unknown as OptionType[]);
  }, [totalSessionData, monthFilter]);

  useEffect(() => {
    const parseBookingData = async (bookingData: Booking[]) => {
      try {
        const data: Session[] = await convertBookingToSessionFormat({
          bookingList: bookingData,
          isTrainer: true,
        });
        checkAvaiableTimelines();
        setTotalSessionData(data);
      } catch (error) {
        setTotalSessionData([]);
      }
    };
    parseBookingData(trainerBookings);
  }, [trainerBookings]);

  const filteredSessionData = useMemo(() => {
    let filtered: Session[] = [];

    if (bookingStatusFilter === 'All' && bookingConfirmationFilter === 'All') {
      filtered = totalSessionData;
    } else if (
      bookingStatusFilter !== 'All'
      && bookingConfirmationFilter !== 'All'
    ) {
      filtered = _.filter(
        totalSessionData,
        (item: Session) => item.status === bookingStatusFilter
          && item.confirmation === bookingConfirmationFilter,
      );
    } else {
      filtered = _.filter(
        totalSessionData,
        (item: Session) => item.status === bookingStatusFilter
          || item.confirmation === bookingConfirmationFilter,
      );
    }
    if (selectedClient !== '') {
      filtered = _.filter(
        filtered,
        (item: Session) => item?.client?.id === selectedClient,
      );
    }
    if (monthFilter !== 0) {
      filtered = _.filter(filtered, (item: Session) => {
        const filterDate = new Date(monthFilter).getTime();
        return moment(item?.date, 'ddd MMM DD, YYYY').isSame(filterDate, 'month');
      });
    }

    if (clientType === 'PT Clients') {
      filtered = _.filter(
        filtered,
        (item: Session) => item.isPersonalTrainerClient,
      ) as Session[];
    } else if (clientType === 'Fitlov') {
      filtered = _.filter(
        filtered,
        (item: Session) => !item.isPersonalTrainerClient,
      );
    }

    if (noOfPeopleFilter !== 'All') {
      filtered = _.filter(
        filtered,
        (item: Session) => item.type.value === Number(noOfPeopleFilter),
      );
    }

    return filtered;
  }, [
    bookingStatusFilter,
    bookingConfirmationFilter,
    selectedClient,
    monthFilter,
    noOfPeopleFilter,
    totalSessionData,
    clientType,
  ]);

  const handleClientSelect = (user: OptionType, isClear?: boolean) => {
    // eslint-disable-next-line no-unused-expressions
    isClear ? setSelectedClient('') : setSelectedClient(user.value as string);
  };

  return (
    <div className="Bottom Content" style={{ marginTop: 40, marginLeft: 20 }}>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <StyledLabelTitleLarge title="Trainer sessions" />
      </div>
      <div className="flex">
        <div style={{ width: 200 }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label style={{ color: '#121212' }}>Session Status</label>
          <div style={{ marginTop: 5 }}>
            <Select
              inputId="multi-select-example"
              className="multi-select"
              classNamePrefix="react-select"
              options={BookingStatusOptions}
              isSearchable={false}
              placeholder="All"
              onChange={(option) => {
                setBookingStatusFilter(option?.value || null);
              }}
            />
          </div>
        </div>
        <div style={{ width: 150, marginLeft: 30 }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label style={{ color: '#121212' }}>Trainer Status</label>
          <div style={{ marginTop: 5 }}>
            <Select
              inputId="multi-select-example"
              className="multi-select"
              classNamePrefix="react-select"
              options={BookingConfirmationOptions}
              isSearchable={false}
              placeholder="All"
              onChange={(option) => {
                setBookingConfirmationFilter(option?.value || null);
              }}
            />
          </div>
        </div>
        <div style={{ width: 250, marginLeft: 30 }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label style={{ color: '#121212' }}>Client</label>
          <div style={{ marginTop: 5 }}>
            <StyledSearch
              data={clients}
              placeholder="Select Client"
              selectUser={handleClientSelect}
              isClearable
            />
          </div>
        </div>
        <div style={{ width: 200, marginLeft: 30 }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label style={{ color: '#121212' }}>Months</label>
          <div style={{ marginTop: 5 }}>
            <Select
              inputId="multi-select-example"
              className="multi-select"
              classNamePrefix="react-select"
              options={availableMonths}
              isSearchable={false}
              placeholder="All"
              onChange={(option) => {
                setMonthFilter(option?.value || 0);
              }}
            />
          </div>
        </div>
        <div style={{ width: 150, marginLeft: 30 }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label style={{ color: '#121212' }}>Type</label>
          <div style={{ marginTop: 5 }}>
            <Select
              inputId="multi-select-example"
              className="multi-select"
              classNamePrefix="react-select"
              options={NoOfPeopleOptions}
              isSearchable={false}
              placeholder="All"
              onChange={(option) => {
                setNoOfPeopleFilter(option?.value || null);
              }}
            />
          </div>
        </div>
        <div style={{ width: 150, marginLeft: 30 }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label style={{ color: '#121212' }}>Client Type</label>
          <div style={{ marginTop: 5 }}>
            <Select
              inputId="multi-select-example"
              className="multi-select"
              classNamePrefix="react-select"
              options={ClientTypes}
              isSearchable={false}
              placeholder="All"
              onChange={(option) => {
                setClientType(option?.value || null);
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        {/* <Table row={TrainerSessionsRows} headType={userProfileHead} /> */}
        <Table
          row={TrainerSessionsRows(filteredSessionData)}
          headType={trainerProfileHead}
        />
      </div>
    </div>
  );
}
