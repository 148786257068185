import { ErrorMessage } from '@atlaskit/form';
import { TextInput } from './index';

export default function FormFieldInput({
  fieldProps,
  label,
  unit,
  type = 'default',
  error,
  ...rest
}: {
  unit?: string;
  label?: string;
  fieldProps: any;
  type?: string;
  error?: string;
  [x: string]: any;
}) {
  return (
    <>
      <TextInput
        label={label}
        type={type}
        elemAfterInput={unit && <p className="progress-unit">{unit}</p>}
        {...fieldProps}
        {...rest}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}
