/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useCallback, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { OptionType } from '@root/types/types';
import PackageFirestore from '../../controllers/packagesFireStoreController';
import { Package } from '../../models/package';
import { Status, UserTypes } from '../../utils/common/data';
import { StyledSelect } from '../select/StyledDataSelect';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';

export default function AddPlans() {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const { addPackage } = PackageFirestore();

  const onSubmit = (data) => {
    const shownTo = data.shownTo.map((userType) => userType.value);
    const price = parseFloat(data.price);
    const packageData: Package = {
      ...data,
      sessions: parseInt(data.sessions, 10),
      nutritionSessions: parseInt(data.nutritionSessions, 10),
      price: Number.isInteger(price) ? parseInt(`${price}`, 10) : Number(price.toFixed(2)),
      category: data.category.value,
      isActive: data.isActive.value,
      shownTo,
    };
    addPackage(packageData);
    closeModal();
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          marginLeft: 20,
          backgroundColor: '#3FC7E0',
          marginRight: 20,
        }}
      >
        (+) Add New Package
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New package" />
                  <ModalBody>
                    <Field
                      id="packageName"
                      name="packageName"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Package Name is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            label="Package Name"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="sessions"
                      name="sessions"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'PT Sessions is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            label="# of PT sessions"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="nutritionSessions"
                      name="nutritionSessions"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Nutrition Sessions is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            label="# of Nutrition Sessions"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="price"
                      name="price"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Price is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            label="Price per session (without VAT)"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="category"
                      name="category"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Category is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            options={
                              [
                                { label: 'Nutrition', value: 'nutrition' },
                                { label: 'Trainer', value: 'trainer' },
                              ] as OptionType[]
                            }
                            inputId={id}
                            name="Category"
                            validationState={error ? 'error' : 'default'}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="isActive"
                      name="isActive"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Status is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            options={Status}
                            inputId={id}
                            name="Status"
                            validationState={error ? 'error' : 'default'}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="shownTo"
                      name="shownTo"
                      isRequired
                      validate={(value) => {
                        if (!value || !value.length) return 'Shown to is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            isMulti
                            options={UserTypes}
                            inputId={id}
                            name="Shown To"
                            validationState={error ? 'error' : 'default'}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
