import { useCallback, useState } from 'react';
import colors from '@root/utils/colors';
import { useDropzone } from 'react-dropzone';
import Spinner from '@atlaskit/spinner';
import StorageController from '@root/controllers/storageController';
import { updateUser } from '@controllers/userFirestoreController';

const API_URL = process.env.REACT_APP_FIREBASE_FUNCTION_URL;

const containerStyle = {
  background: colors.primary,
  height: 20,
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  padding: 8,
  color: '#FFFFFF',
  borderRadius: 3,
  cursor: 'pointer',
};

export default function Upload({
  label,
  userId,
  existingFile,
  keyToUpdate,
  notification,
}: {
  label: string;
  userId: string;
  existingFile?: string;
  keyToUpdate: string;
  notification?: {
    expoPushToken: string;
    title: string;
    body: string;
    data: {url:string}|object;
  };
}) {
  const [loading, setLoading] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true);
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }

      try {
        const fileUrl = await StorageController.uploadPdfReport({
          blobUrl: URL.createObjectURL(file),
          name: `${file.name}_${Date.now()}`,
          folder: userId,
        });
        updateUser({ id: userId, [keyToUpdate]: fileUrl });
        if (existingFile) {
          await StorageController.deleteFile(existingFile);
        }
        if (notification) {
          const {
            title, body, data,
          } = notification;
          await fetch(`${API_URL}/sendPushNotification`, {
            method: 'POST',
            body: JSON.stringify({
              userIds: [userId],
              title,
              message: body,
              deeplinkUrl: data.url,
            }),
          });
        }
      } catch (error) {
        setLoading(false);
        // console.log('Error uploading file', error);
      }
      setLoading(false);
    },
    [existingFile, keyToUpdate, notification, userId],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div style={containerStyle} {...getRootProps()}>
      <input {...getInputProps()} />
      <p style={{ paddingRight: 5 }}>{label}</p>
      {loading ? <Spinner size="small" appearance="invert" /> : null}
    </div>
  );
}
