/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { db } from '@root/controllers/firebase';
import { User } from '@root/models/user';
import { localStorageKeys } from '@root/utils/constants/secureStorageKeys';
import endpointFunctions from '@root/utils/endpoints';
import { cacheCollections } from '@root/utils/enums/collections.enums';
import { diffHours, wait } from '@root/utils/functions';
import axios from 'axios';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type CacheOptions = {
  lastSync: Date;
  loading: boolean;
  refresh: () => void;
};

type TrainerPerformanceCacheType = {
  data: { name: string }[];
} & CacheOptions;

type AllUsersCacheType = {
  data: User[];
} & CacheOptions;

type CacheContextType = {
  trainerPerformance?: TrainerPerformanceCacheType;
  allUsers?: AllUsersCacheType;
};

// @ts-ignore
const CacheContext = createContext<CacheContextType>(undefined);

export function CacheProvider({ children }: { children: React.ReactNode }) {
  const [trainerPerformance, setTrainerPerformance] = useState<TrainerPerformanceCacheType>({
    lastSync: new Date(),
    data: [],
    loading: true,
    refresh: () => {},
  });
  const [allUsers, setAllUsers] = useState<AllUsersCacheType>({
    lastSync: new Date(),
    data: [],
    loading: true,
    refresh: () => {},
  });

  useEffect(() => {
    getTrainerPerformance();
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    const localCache = getLocalCache(localStorageKeys.allUsers);
    if (localCache) return setAllUsers(localCache);

    const data = (
      await db.collection(cacheCollections.allUsers).get()
    ).docs.map((doc) => doc.data()) as any; console.log('data: ', data);

    setLocalCache(localStorageKeys.allUsers, data);

    return setAllUsers({
      ...allUsers,
      lastSync: new Date(),
      loading: false,
      data,
    });
  };

  const getTrainerPerformance = async () => {
    const localCache = getLocalCache(localStorageKeys.trainerPerformance);
    if (localCache) return setTrainerPerformance(localCache);

    const data = (
      await db.collection(cacheCollections.trainerPerformance).get()
    ).docs.map((doc) => doc.data()) as any;

    // order by sessions
    data.sort((a, b) => b.sessionsDelivered - a.sessionsDelivered);
    setLocalCache(localStorageKeys.trainerPerformance, data);

    return setTrainerPerformance({
      ...trainerPerformance,
      lastSync: new Date(),
      loading: false,
      data,
    });
  };

  const refreshTrainerPerformance = async () => {
    setTrainerPerformance({
      ...trainerPerformance,
      loading: true,
    });
    await axios.post(endpointFunctions.trigger_cache, {
      collection: cacheCollections.trainerPerformance,
    });
    localStorage.removeItem(localStorageKeys.trainerPerformance);
    getTrainerPerformance();
  };

  const refreshAllUsers = async () => {
    setAllUsers({
      ...allUsers,
      loading: true,
    });
    await axios.post(endpointFunctions.trigger_cache, {
      collection: cacheCollections.allUsers,
    });
    await wait(1000);
    localStorage.removeItem(localStorageKeys.allUsers);
    getAllUsers();
  };

  const getLocalCache = (storageKey: localStorageKeys) => {
    const localCache = localStorage.getItem(storageKey);
    if (localCache) {
      const cacheData = JSON.parse(localCache);
      if (cacheData?.timestamp) {
        const cacheDate = new Date(cacheData.timestamp);
        if (diffHours(cacheDate) < 24) {
          return {
            refresh: () => {},
            lastSync: cacheDate,
            loading: false,
            data: cacheData.data,
          };
        }
      }
    }
    return undefined;
  };

  const setLocalCache = (storageKey: localStorageKeys, data: any) => {
    localStorage.setItem(
      storageKey,
      JSON.stringify({
        timestamp: new Date(),
        data,
      }),
    );
  };

  const contextValue = useMemo(
    () => ({
      trainerPerformance: {
        ...trainerPerformance,
        refresh: refreshTrainerPerformance,
      },
      allUsers: {
        ...allUsers,
        refresh: refreshAllUsers,
      },
    }),
    [trainerPerformance, allUsers],
  );

  return (
    <CacheContext.Provider value={contextValue}>
      {children}
    </CacheContext.Provider>
  );
}

const useCache = () => useContext(CacheContext);
export default useCache;
