/* eslint-disable eqeqeq */
/* eslint-disable no-prototype-builtins */
// eslint-disable-next-line no-use-before-define
import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import Textfield from '@atlaskit/textfield';
import { useTrainer } from '@controllers/trainerFirestoreController';
import { TrainerLevel, User } from '@models/user';
import _ from 'lodash';
import Select, {
  OptionType,
} from '@atlaskit/select';
import {
  UserGenders, FreeSessionEligible, Status, TrainerLevels, TrainerTypes,
} from '@root/utils/common/data';
import BroadcastModal from '@root/components/modals/broadcastTrainersModal';
import {
  StyledCitySelectTrainer,
  StyledTrainerProfileSelect,
} from '../../components/select/StyledDataSelect';
import {
  ApplicationHead,
  trainerHead,
} from '../../components/table/content/Head';
import {
  ApplicationRows,
  TrainerRows,
} from '../../components/table/content/rows';
import Table from '../../components/table/table';
import { useCityRegionContext } from '../../contexts/cityRegion.context';
import './trainer.css';

const allData = { label: 'All', value: 'All' };

export default function Trainer() {
  const regionSelectorRef = useRef<HTMLSelectElement>(null);
  const { items: verifiedTrainers, loading } = useTrainer();
  const { items: inVerifiedTrainers } = useTrainer(false);
  const { cityRegions } = useCityRegionContext();
  const [disciplineFilter, setDisciplineFilter] = useState<string | null>(null);
  const [applicationDisciplineFilter, setApplicationDisciplineFilter] = useState<string>('All');
  const [cityFilter, setCityFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<boolean | null>(true);
  const [trainerTypeFilter, setTrainerTypeFilter] = useState('All');
  const [regionsFiltered, setRegionsFiltered] = useState<OptionType[]>([]);
  const [regionFilter, setRegionFilter] = useState<string | null>(null);
  const [userNameFilter, setUserNameFilter] = useState<string | undefined>(
    undefined,
  );
  const [trainerLevelFilter, setTrainerLevelFilter] = useState('All');

  const [userNameFilterInverified, setUserNameFilterInverified] = useState<
    string | undefined
  >(undefined);

  const [applicationCityFilter, setApplicationCityFilter] = useState<string | null>(null);
  const [genderilter, setGenderilter] = useState<string | null>(null);
  const [freeSessionFilter, setFreeSessionFilter] = useState<string | null>(null);
  const [applicationGenderilter, setApplicationGenderilter] = useState<string | null>(null);

  const filterTrainerList = useMemo(() => {
    let filteredWithDiscipline: User[] = [];
    let filteredWithCity: User[] = [];
    let filteredWithName: User[] = [];

    if (!disciplineFilter || disciplineFilter === 'All') {
      filteredWithDiscipline = verifiedTrainers;
    } else {
      filteredWithDiscipline = _.filter(
        verifiedTrainers,
        (trainer: User) => _.includes(trainer?.disciplines as string[], disciplineFilter),
      );
    }
    if (!cityFilter || cityFilter === 'All') {
      filteredWithCity = filteredWithDiscipline;
    } else {
      filteredWithCity = _.filter(
        filteredWithDiscipline,
        (trainer: User) => (trainer?.city || '').toLocaleLowerCase()
          === cityFilter.toLocaleLowerCase(),
      );
    }
    if (regionFilter && regionFilter !== 'All') {
      filteredWithCity = _.filter(
        filteredWithDiscipline,
        (trainer: User) => {
          if (!trainer?.trainingRegions) {
            return false;
          }
          return Boolean(trainer?.trainingRegions?.find((u) => u === regionFilter));
        },
      );
    }
    if (userNameFilter) {
      filteredWithName = _.filter(
        filteredWithCity,
        // eslint-disable-next-line max-len
        (item) => item.fullname?.toLowerCase().includes(userNameFilter.toLowerCase()) || item.displayName?.toLowerCase().includes(userNameFilter.toLowerCase()),
      ) as User[];
    } else {
      filteredWithName = filteredWithCity;
    }
    if (genderilter !== null && genderilter !== 'All') {
      filteredWithName = _.filter(
        filteredWithCity,
        (trainer: User) => (trainer?.gender || '').toLocaleLowerCase()
          === genderilter?.toLocaleLowerCase(),
      );
    } if (trainerTypeFilter !== null && trainerTypeFilter !== 'All') {
      filteredWithName = _.filter(
        filteredWithCity,
        (trainer: User) => (trainer.trainerType || '').toLocaleLowerCase()
          === trainerTypeFilter?.toLocaleLowerCase(),
      );
    }
    if (freeSessionFilter !== null && freeSessionFilter !== 'All' && freeSessionFilter === 'false') {
      filteredWithName = _.filter(filteredWithCity, (trainer: User) => !trainer.hasOwnProperty('isOfferFreeSessions') || String(trainer?.isOfferFreeSessions) == freeSessionFilter);
    }
    if (freeSessionFilter === 'true') {
      filteredWithName = _.filter(
        filteredWithCity,
        (trainer: User) => String(trainer?.isOfferFreeSessions) == freeSessionFilter,
      );
    }

    if (statusFilter !== null) {
      filteredWithName = _.filter(
        filteredWithName,
        (trainer: User) => (trainer?.isActive || false) === statusFilter,
      );
    }
    if (trainerLevelFilter !== 'All') {
      filteredWithName = _.filter(
        filteredWithName,
        // eslint-disable-next-line max-len
        (trainer: User) => (trainer?.trainerLevel || TrainerLevel.STANDARD) === trainerLevelFilter,
      );
    }
    return filteredWithName;
  }, [verifiedTrainers, cityFilter, regionFilter, disciplineFilter,
    userNameFilter, genderilter, freeSessionFilter, statusFilter,
    trainerLevelFilter, trainerTypeFilter]);

  const filteredInverifiedList = useMemo(() => {
    let filteredWithName: User[] = [];
    if (!applicationDisciplineFilter || applicationDisciplineFilter === 'All') {
      filteredWithName = inVerifiedTrainers;
    } else {
      filteredWithName = _.filter(
        inVerifiedTrainers,
        // eslint-disable-next-line max-len
        (trainer: User) => _.includes(trainer?.disciplines as string[], applicationDisciplineFilter),
      );
    }

    if (userNameFilterInverified) {
      filteredWithName = _.filter(filteredWithName, (item) => item.fullname
        ?.toLowerCase()
        .includes(userNameFilterInverified.toLowerCase())) as User[];
    }

    if (applicationCityFilter !== null && applicationCityFilter !== 'All') {
      filteredWithName = _.filter(
        filteredWithName,
        (trainer: User) => (trainer?.city || '').toLocaleLowerCase()
          === applicationCityFilter?.toString().toLocaleLowerCase(),
      );
    }
    if (applicationGenderilter !== null && applicationGenderilter !== 'All') {
      filteredWithName = _.filter(
        filteredWithName,
        (trainer: User) => (trainer?.gender || '').toLocaleLowerCase()
          === applicationGenderilter?.toLocaleLowerCase(),
      );
    }

    return filteredWithName;
  }, [inVerifiedTrainers, userNameFilterInverified, applicationCityFilter,
    applicationGenderilter, applicationDisciplineFilter]);

  const filterRegions = () => {
    setRegionFilter(null);
    const regions = cityRegions.filter((region) => region.city === cityFilter);
    // @ts-ignore: Unreachable code error
    if (regionSelectorRef.current && regionSelectorRef.current.select) {
      if (cityFilter) {
        // @ts-ignore: Unreachable code error
        regionSelectorRef.current?.select.clearValue();
      }
    }
    const tempReg: OptionType[] = [];

    // eslint-disable-next-line array-callback-return
    regions.map((region) => {
      tempReg.push({
        key: region.id,
        label: region.region,
        value: region.id ?? '',
      });
    });

    const sortedTempReg = _.reverse(_.concat(tempReg, { label: 'All', value: 'All' }));
    setRegionsFiltered(sortedTempReg);
  };

  useEffect(() => {
    filterRegions();
  }, [cityFilter]);

  return (
    <div className="home">
      <div style={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', rowGap: 15,
      }}
      >
        <StyledCitySelectTrainer
          name="city"
          onChange={(option) => {
            setCityFilter(option.value);
          }}
          width={150}
        />
        <StyledCitySelectTrainer
          innerRef={regionSelectorRef}
          name="Region"
          options={regionsFiltered}
          onChange={(option) => {
            if (option) {
              setRegionFilter(option.value);
            }
          }}
          width={200}
        />
        <StyledTrainerProfileSelect
          name="Discipline"
          onChange={(option) => {
            setDisciplineFilter(option.value);
          }}
        />
        <div style={{ width: 150, marginLeft: 16 }}>
          <Select
            inputId="multi-select-example"
            className="multi-select"
            classNamePrefix="react-select"
            options={[allData, ...UserGenders]}
            isSearchable={false}
            placeholder="Gender"
            onChange={(option) => {
              setGenderilter(option?.value.toString() || null);
            }}
          />
        </div>
        <div style={{ width: 150, marginLeft: 16 }}>
          <Select
            inputId="multi-select-example"
            className="multi-select"
            classNamePrefix="react-select"
            options={[allData, ...FreeSessionEligible]}
            isSearchable={false}
            placeholder="Free Session"
            onChange={(option) => {
              setFreeSessionFilter(option?.value.toString() || null);
            }}
          />
        </div>
        <div style={{ width: 150, marginLeft: 16 }}>
          <Select
            inputId="multi-select-example"
            className="multi-select"
            classNamePrefix="react-select"
            options={[{ label: 'All', value: null }, ...Status]}
            defaultValue={{ label: 'Active', value: true }}
            isSearchable={false}
            placeholder="Status"
            onChange={(option) => {
              setStatusFilter(option?.value as boolean);
            }}
          />
        </div>
        <div style={{ width: 150, marginLeft: 16 }}>
          <Textfield
            name="basic"
            aria-label="default text field"
            placeholder="search name"
            value={userNameFilter}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setUserNameFilter(e.currentTarget.value);
            }}
          />
        </div>

        <div style={{ width: 150, marginLeft: 16 }}>
          <Select
            inputId="multi-select-example"
            className="multi-select"
            classNamePrefix="react-select"
            options={[allData, ...TrainerLevels]}
            isSearchable={false}
            placeholder="Trainer Level"
            onChange={(option) => {
              setTrainerLevelFilter(option?.value.toString() || 'All');
            }}
          />
        </div>

        <div style={{ width: 150, marginLeft: 16 }}>
          <Select
            inputId="multi-select-example"
            className="multi-select"
            classNamePrefix="react-select"
            options={[allData, ...TrainerTypes]}
            isSearchable={false}
            placeholder="Trainer Type"
            onChange={(option) => {
              setTrainerTypeFilter(option?.value.toString() || 'All');
            }}
          />
        </div>
        <div style={{ display: 'flex', marginLeft: 16 }}>
          <BroadcastModal />
        </div>
      </div>

      <div style={{ margin: 20, marginTop: 40 }}>
        <Table
          isLoading={loading}
          defaultSortKey="Apperance Score"
          headType={trainerHead}
          row={TrainerRows(filterTrainerList)}
        />

        <div style={{ marginTop: 30 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                backgroundColor: '#697E8D',
                color: '#FFF',
                borderRadius: 5,
                display: 'inline-flex',
                padding: 10,
                fontSize: 14,
                margin: '10px 0',
              }}
            >
              Trainer Applications
            </div>
            <div style={{ width: 150, marginLeft: 30 }}>
              <Textfield
                name="basic"
                aria-label="default text field"
                placeholder="search name"
                value={userNameFilterInverified}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  setUserNameFilterInverified(e.currentTarget.value);
                }}
              />
            </div>
            <StyledCitySelectTrainer
              name="city"
              onChange={(option) => {
                setApplicationCityFilter(option.value);
              }}
              width={150}
            />
            <StyledTrainerProfileSelect
              name="Discipline"
              onChange={(option) => {
                setApplicationDisciplineFilter(option.value);
              }}
            />
            <div style={{ width: 150, marginLeft: 30 }}>
              <Select
                inputId="multi-select-example"
                className="multi-select"
                classNamePrefix="react-select"
                options={[allData, ...UserGenders]}
                isSearchable={false}
                placeholder="Gender"
                onChange={(option) => {
                  setApplicationGenderilter(option?.value.toString() || null);
                }}
              />
            </div>
          </div>
          <Table
            isLoading={loading}
            headType={ApplicationHead}
            row={ApplicationRows(filteredInverifiedList)}
            defaultSortKey="Registration Date"
            defaultSortOrder="DESC"
          />
        </div>
      </div>
    </div>
  );
}
