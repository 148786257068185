import collections from '@root/utils/enums/collections.enums';
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/messaging';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
export const firebaseApp = firebase;
export const db = firebase.firestore();
export const fbStorage = firebase.storage();
export const fbAuth = firebase.auth();
export const fbMessaging = firebase.messaging();

export const generateId = (collection: collections):string => db.collection(collection).doc().id;
export const functions = firebase.functions();

/* Leaving this code block commented to reuse in case we need to run
the admin panel with firebase emulator in the future */

// eslint-disable-next-line no-restricted-globals
// if (location.hostname === 'localhost') {
//   db.useEmulator('localhost', 4000);
//   fbAuth.useEmulator('http://localhost:9099');
// }
