/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import firebase from 'firebase';
import FirestoreService from '@utils/firestoreService';
import { getUsers } from '@controllers/userFirestoreController';

import { Review, ReviewRecord, TrainerRatingRecord } from '@models/review';
import _ from 'lodash';
import moment from 'moment';
import { Trainer } from '../models/trainer';
import { User } from '../models/user';
import collections from '../utils/enums/collections.enums';
import { db } from './firebase';

export const useReviews = () => {
  const [totalReviews, setTotalReviews] = useState<ReviewRecord[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.REVIEWS)
      .orderBy('createdAt', 'desc')
      .onSnapshot(async (snap) => {
        const reviews = await Promise.all(snap.docs.map(async (doc) => {
          const data = { ...doc.data(), id: doc.id } as Review;
          const userData = await getUsers(data.userId) as User & Trainer;
          const trainerData = await getUsers(data.trainerId) as User & Trainer;
          return {
            id: doc.id,
            trainer: {
              id: trainerData.id,
              name: trainerData.displayName,
            },
            user: {
              id: userData.id,
              name: userData.fullname,
            },
            comment: data?.comment || '',
            punctualityRate: data.punctualityRate,
            friendlinessRate: data.friendlinessRate,
            isApproved: data.isApproved,
            qualityRate: data.qualityRate,
            avgRating: Math.round(((Number(data.punctualityRate)
              + Number(data.friendlinessRate) + Number(data.qualityRate)) / 3) * 100) / 100,
            date: moment((data.createdAt as firebase.firestore.Timestamp).toDate()).format('DD MMM YYYY'),
            createdAt: data.createdAt,
            reviewerName: data.reviewerName,
          } as ReviewRecord;
        })) as ReviewRecord[];
        setTotalReviews(reviews);
      });
    return unsubscribe;
  }, []);
  return { totalReviews };
};

export const useTrainerRatings = () => {
  const [trainerRatings, setTrainerRatings] = useState<TrainerRatingRecord[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.USERS)
      .where('roles', 'array-contains', 'trainer')
      .onSnapshot(async (snap) => {
        const reviews = await Promise.all(snap.docs.map(async (doc) => {
          const data = { ...doc.data(), id: doc.id } as User & Trainer;

          return {
            id: data.id,
            fullName: data.displayName,
            avgRating: data?.averageRate ?? 0,
            avgFriendlinessRate: data?.avgFriendlinessRate ?? 0,
            avgPunctualityRate: data?.avgPunctualityRate ?? 0,
            avgQualityRate: data?.avgQualityRate ?? 0,
            appearanceScore: data?.appearanceScore ? Number(data?.appearanceScore) : 100,
            isActive: data?.isActive,
            isVerified: data?.isVerified,
          } as TrainerRatingRecord;
        })) as TrainerRatingRecord[];
        const reviewFiltered = reviews.filter((review) => review.isActive && review.isVerified);
        setTrainerRatings(_.orderBy(reviewFiltered, ['appearanceScore', 'avgRating'], ['asc', 'desc']));
      });
    return unsubscribe;
  }, []);
  return { trainerRatings };
};

export const createReview = async (review: Review) => {
  try {
    const response = await FirestoreService.addObjectToCollection(
      collections.REVIEWS,
      review,
    );
    response.update({ id: response.id });
  } catch (error) {
    // console.log('createReview', error);
  }
};

export const deleteReview = async (reviewId: string | undefined) => {
  try {
    await db
      .collection(collections.REVIEWS).doc(reviewId).delete();
    return null;
  } catch (error) {
    // console.log('createReview', error);
  }
};

export const useTrainerReviews = (trainerId) => {
  const [totalTrainerReviews, setTotalTrainerReviews] = useState<Review[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.REVIEWS)
      .where('trainerUID', '==', trainerId)
      .onSnapshot((snap) => {
        const trainerReviews = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setTotalTrainerReviews(trainerReviews as Review[]);
      });
    return unsubscribe;
  }, [trainerId]);
  return { totalTrainerReviews };
};

export const updateReview = (id: string, key: string, value: string | number | boolean) => {
  try {
    return db.collection(collections.REVIEWS).doc(id).update({ [key]: value });
  } catch (error) {
    // console.log('===Error updating trainer====', error);
  }
};
