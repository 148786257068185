/* eslint-disable */
import StyledLabelTitle from "@root/utils/styles/StyledLabelTitle";
import { CSSProperties, useEffect, useState } from "react";
import Button from "@atlaskit/button";
import ModalDropMedia from "./components/modalDropMedia";
import { Trainer } from "@root/models/trainer";
import { User } from "@root/models/user";
import { TrainerMedia, TrainerMediaType } from "@root/models/trainerMedia";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import { db } from "@root/controllers/firebase";
import collections from "@root/utils/enums/collections.enums";
import StorageController from "@root/controllers/storageController";
import { Checkbox } from "@material-ui/core";
import { Reorder } from 'framer-motion/dist/framer-motion';
import _ from "lodash";
import { DragHandleRounded,InfoRounded } from "@material-ui/icons";
import Textfield from '@atlaskit/textfield';
import { StyledSelect } from "@root/components/select/StyledDataSelect";
import { OptionType } from "@atlaskit/select";
import { MediaTagsTypes } from "@root/utils/common/data";
import { updateTrainer } from "@root/controllers/trainerFirestoreController";
import Toggle from "@atlaskit/toggle";

function TrainerProfileMedia({ trainer }: { trainer: Trainer & User }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [medias, setMedias] = useState<TrainerMedia[]>([]);
  useEffect(() => {
    const sub = db
      .collection(collections.trainerMedia)
      .where("trainerId", "==", trainer.id)
      .onSnapshot((snapshot) => {
        setMedias(_.orderBy(snapshot.docs.map((doc) => doc.data() as TrainerMedia),['order'],['asc']));
      });
    return () => sub();
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      saveOrder();
    }, 1500);
    return () => clearTimeout(delayDebounce);
  }, [medias]);

  const onRemoveFile = (item: TrainerMedia) => {
    Promise.all([
      StorageController.deleteFile(item.url),
      db.collection(collections.trainerMedia).doc(item.id).delete(),
    ]);
  };
  const onchangeTag = (item: TrainerMedia,text:string) => {
    db.collection(collections.trainerMedia).doc(item.id).update({
      tag:text,
    });
  };

  const onSwitchEnableMedia = (item: TrainerMedia) => {
    db.collection(collections.trainerMedia).doc(item.id).update({
      enabled: !item.enabled,
    });
  }

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const saveOrder = async()=>{
    for (let i in medias){
      try{
      await db
          .collection(collections.trainerMedia)
          .doc(medias[i].id).update({
            order:Number(i)+1,
          });
        }catch(error){
          // document not exists, do nothing
        }
    }
  }

  const onReorder = (data: TrainerMedia[]) => {
    setMedias(data);
  }

  return (
    <div>
      <div style={styles.titleContainer}>
        <StyledLabelTitle title="Media" />
        
        
        <Button onClick={openModal}>Add media</Button>
        <div style={styles.showMediaSwitch}>Show media<Toggle
              id="toggle-active"
              size="large"
              isChecked={trainer.showMedia}
              onChange={() => {
                updateTrainer({
                  id: trainer.id,
                  showMedia: !trainer.showMedia,
                });
              }}
            /></div>
          <div style={{ borderRadius: 3 }}>
      </div>
      </div>

        {!medias.length && <p>No media found</p>}
        <Reorder.Group 
                style={{
                  overflowX: 'scroll', padding: '0px', listStyleType: 'none',...styles.mediaContainer
                }}
                axis="x"
                values={medias}
                onReorder={onReorder}
              >
        {medias.map((item) => {
          return (
            <Reorder.Item key={item.id} value={item}>
            <div style={styles.mediaItem}>
              {item.type === TrainerMediaType.IMAGE && (
                <img alt="media-file" src={item.url} style={styles.image} />
              )}
              {item.type === TrainerMediaType.VIDEO && (
                <video width="150" style={{ aspectRatio: "9 / 16" }} controls preload="metadata">
                  <source src={item.url} type="video/mp4" />
                </video>
              )}
              <div style={styles.actionsContainer}>
                <div style={styles.trashButton}>
                  <Button appearance="link" onClick={() => onRemoveFile(item)}>
                    <TrashIcon label="Delete Package" primaryColor="#DE350B" />
                  </Button>
                </div>
                <DragHandleRounded className="sidebarIcon"/>
                <div style={styles.checkButton}>
                  <Checkbox color="default" checked={item.enabled} onClick={() => onSwitchEnableMedia(item)} />
                </div>
              </div>
              <StyledSelect
            isSearchable
            options={ MediaTagsTypes as OptionType[]}
            value= { {label: item.tag||"Select Tag", value: item.tag }}
              onChange={(e)=>{
                onchangeTag(item,e.value);
              }
            }
          />
            </div>
            </Reorder.Item>
          );
        }
        )}</Reorder.Group>

      <ModalDropMedia
        trainerId={trainer.id as string}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
}

const styles: { [name: string]: CSSProperties } = {
  titleContainer: {
    display: "flex",
    gap: 10,
    marginTop: 10,
  },
  mediaContainer: {
    display: "flex",
    gap: 20,
    marginTop: 10,
  },
  mediaItem: {
    position: 'relative',
    background: '#edebeb',
    borderRadius: 10,
  },
  showMediaSwitch: {
    backgroundColor: '#f4f5f7',
    padding: "5px",
    fontSize: "14px",
    width: "130px",
    display:"flex",
    alignItems:"center",
    flexDirection:"row",
    justifyContent:"space-between",
  },
  image: {
    width: 150,
    aspectRatio: "9 / 16",
    objectFit: "cover",
    pointerEvents: "none",
  },
  actionsContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: 'space-between',
    background: 'rgba(255, 255, 255, 0.6)',
    top: 0,
    right: 0,
    left: 0
  },
  trashButton: {
    paddingLeft: 4,
    paddingTop: 4,
  },
  checkButton: {
  },
};

export default TrainerProfileMedia;
