/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import dotenv from 'dotenv';
import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { OptionType, ValueType } from '@atlaskit/select';
import { useGlobalsContext } from '@contexts/global.context';
import {
  checkBookingExists,
  createBookings,
} from '@controllers/trainerBookingController';
import {
  fetchTrainers,
  getTrainer,
} from '@controllers/trainerFirestoreController';
import AvailabilitySlot from '@models/availabilitySlot';
import { Booking } from '@models/booking';
import { Trainer } from '@models/trainer';
import { User } from '@models/user';
import {
  checkChatExist,
  createChatRoom,
  getChannelIdFromMemberPair,
} from '@root/services/chatService';
import {
  BookingConfirmation,
  BookingServiceType,
  BookingSlot,
} from '@utils/enums/booking.enums';
import functions from '@utils/functions';
import * as geolib from 'geolib';
import _ from 'lodash';
import moment from 'moment';
import { ClientPackage } from '@root/models/clientPackage';
// eslint-disable-next-line camelcase
import endpointFunctions from '@root/utils/endpoints';
import axios from 'axios';
import { StyledSelect } from '../select/StyledDataSelect';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';

dotenv.config();

const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const Nutrition = 'Nutrition';
const Coaching = 'Coaching';

const BookingStatusOptions = [
  {
    label: 'Confirmed',
    value: BookingConfirmation.CONFIRMED,
  },
  {
    label: 'Unconfirmed',
    value: BookingConfirmation.NO_ANSWER,
  },
];

const ServiceTypeOptions = [
  {
    label: BookingServiceType.ONE,
    value: 1,
  },
  {
    label: BookingServiceType.TWO,
    value: 2,
  },
  {
    label: BookingServiceType.THREE,
    value: 3,
  },
];

const serviceOptions = [
  { label: 'Training', value: 'training' },
  { label: 'Wellness', value: 'wellness' },
];

export type WeeklySlotsProps = {
  Sunday: AvailabilitySlot | null;
  Monday: AvailabilitySlot | null;
  Tuesday: AvailabilitySlot | null;
  Wednesday: AvailabilitySlot | null;
  Thursday: AvailabilitySlot | null;
  Friday: AvailabilitySlot | null;
  Saturday: AvailabilitySlot | null;
};

export function AddRecurringSession({ user }: { user: Trainer & User }) {
  //= ===Props and Context====================
  const { disciplines, cityRegions } = useGlobalsContext();

  //= ====State====================
  const [isOpen, setIsOpen] = useState(false);
  const [service, setService] = useState<string | null | undefined>(null);
  const [selectedDiscipline, setSelectedDiscipline] = useState<
    string | null | undefined
  >(null);
  const [nearTrainers, setNearTrainers] = useState<(Trainer & User)[]>([]);
  const [bookingError, setBookingError] = useState<string | null>('');
  const [continueBooking, toggleContinueBooking] = useState<boolean>(false);
  const [packages, setPackages] = useState<
    {
      [key: string]: { sessions: number; packages: ClientPackage[] };
    } & { totalSessions: number }
  >({} as any);

  const [selectedTime12h, setSelectedTime12] = useState<any>({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });
  const [weeklySlots, setWeeklySlots] = useState<WeeklySlotsProps>({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });
  const [activeTrainerId, setActiveTrainerId] = useState<
    string | null | undefined
  >(null);

  // const [activeTrainerBookingHistory, setActiveTrainerBookingHistory] = useState<Booking[]>([]);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const userSessions = useMemo(() => {
    if (user.sessionsAvailable) {
      return user.sessionsAvailable;
    }
    if (packages?.totalSessions) {
      return packages[activeTrainerId as any]?.packages[0].sessions || 0;
    }
    return 0;
  }, [user.sessionsAvailable, packages, activeTrainerId]);

  useEffect(() => {
    const updateRegionTrainer = async () => {
      try {
        const matchedRegion = _.findLast(cityRegions, (region) => {
          const matched = geolib.isPointInPolygon(
            user!.addresses!.training[0].coordinate,
            region.coordinates,
          );
          return matched;
        });
        if (matchedRegion) {
          // let data: (Trainer & User)[] = await fetchTrainersByUserLocation(
          //   matchedRegion.id
          // );
          const data: (Trainer & User)[] = await fetchTrainers();
          setNearTrainers(data);
        } else {
          setNearTrainers([]);
        }
      } catch (error) {
        setNearTrainers([]);
      }
    };
    if (user?.addresses) {
      updateRegionTrainer();
    }
    return () => { };
  }, [cityRegions, user]);

  const fetchClientPackages = useCallback(async () => {
    const request = await axios.post(endpointFunctions.get_client_packages, {
      userId: user.id,
      archived: false,
    });
    setPackages(request.data);
  }, [user]);

  const enabledBooking = useMemo(() => {
    if (user) {
      return user?.sessionsAvailable || packages.totalSessions;
    }
    return false;
  }, [user, packages]);

  useEffect(() => {
    fetchClientPackages();
  }, []);

  const disciplineOptions: OptionType[] = useMemo(() => {
    let availDisciplines: string[] = [];
    const disciplineList = _.map(
      disciplines,
      (discipli) => discipli.trainerType,
    );

    if (service === 'wellness') {
      availDisciplines = _.filter(disciplineList, (item) => item === Nutrition);
      /* adding manually for the time being. The disciplines are fetched from the
      firebase collection. If we add Coaching on the collection now,
      it will also show on the mobile app. Hence until coaching feature
      is finalized, we will leave it like this. */
      availDisciplines.push(Coaching);
    } else {
      availDisciplines = _.filter(disciplineList, (item) => item !== Nutrition);
    }

    // availDisciplines =
    //   (user?.sessionsAvailable || 0) <= 0
    //     ? _.filter(disciplineList, (item) => item === Nutrition)
    //     : (user?.nutritionAvailableSessions || 0) <= 0
    //     ? _.filter(disciplineList, (item) => item !== Nutrition)
    //     : disciplineList;
    return availDisciplines.map(
      (discipli) => ({
        label: discipli,
        value: discipli,
      } as OptionType),
    );
  }, [disciplines, service]);

  const serviceMatchedTrainers: (Trainer & User)[] = useMemo(() => {
    if (selectedDiscipline) {
      // eslint-disable-next-line arrow-body-style
      const matchedTrainers = nearTrainers.filter((trainer: Trainer & User) => {
        return _.includes(trainer.disciplines as string[], selectedDiscipline) && trainer.isActive;
      });

      return matchedTrainers || [];
    }
    return [];
  }, [selectedDiscipline, nearTrainers]);

  // eslint-disable-next-line max-len
  // const trainerOptions: OptionType[] = useMemo(
  //   () => _.map(serviceMatchedTrainers || [], (trainer) => ({
  //     value: trainer.id!,
  //     label: trainer.fullname || '',
  //     timeNotAvailable: trainer.timeNotAvailable,
  //     id: trainer.id,
  //   })),
  //   [serviceMatchedTrainers],
  // );

  const trainerOptions: OptionType[] = useMemo(() => {
    const options = _.map(serviceMatchedTrainers || [], (trainer) => ({
      value: trainer.id!,
      label: trainer.displayName || trainer.fullname || '',
    }));
    if (packages.totalSessions && !user.sessionsAvailable) {
      // If the user only have client packages, we need tof filter the trainer list
      const tempPackages = { ...packages };
      // @ts-ignore
      delete tempPackages.totalSessions;
      const keys = Object.keys(tempPackages);
      const filteredOptions: any = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < options.length; i++) {
        const currentOption = options[i];
        if (keys.find((key) => key === currentOption.value)) {
          filteredOptions.push(currentOption);
        }
      }
      return filteredOptions;
    }
    return options;
  }, [serviceMatchedTrainers, packages]);

  const generateSlots = () => {
    if (activeTrainerId) {
      const start = moment('5:30', 'hh:mm A');
      const end = moment('21:00', 'hh:mm A');

      start.minutes(Math.ceil(start.minutes() / 15) * 15);

      const result: OptionType[] = [];

      const current = moment(start);

      while (current <= end) {
        const timeLabel = current.format('hh:mm A');
        const timeValue = parseFloat(current.format('HH.mm'));
        result.push({
          label: timeLabel,
          value: timeValue,
          hour: timeValue,
        });
        current.add(15, 'minutes');
      }
      return result;
    }
    return [];
  };

  // const onSelectDate = (date: Moment) => {
  //   // setSelectedDate(date);
  // };

  const onSelectDiscipline = (value: string) => {
    setSelectedDiscipline(value);
    setActiveTrainerId(null);
  };

  const onSelectService = (value: string) => {
    setService(value);
    setActiveTrainerId(null);
    setSelectedDiscipline(null);
  };

  const onCreateCustomSlot = (day: string, hour: number) => {
    let endHour: number;
    if (selectedDiscipline === Nutrition || selectedDiscipline === Coaching) {
      const lastDecimal = (hour % 1).toFixed(2);
      if (lastDecimal === '0.45' || lastDecimal === '0.30') {
        endHour = Number((hour + 0.7).toFixed(2));
      } else {
        endHour = hour + 0.3;
      }
    } else {
      endHour = hour + 1;
    }

    const customSlot: BookingSlot = {
      startHour: hour,
      endHour,
      weekDay: day,
    };

    const newWeeklySlots = { ...weeklySlots };

    (newWeeklySlots as any)[day] = customSlot;
    setWeeklySlots(newWeeklySlots);
  };

  const checkBookingFromArray = async (bookings) => {
    for (let i = 0; i < bookings.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const doesExists = await checkBookingExists({
        trainerId: bookings[i].trainerId,
        date: bookings[i].date,
        selectedTime: bookings[i]?.slot?.startHour,
      });
      if (doesExists) {
        return doesExists;
      }
    }
    return false;
  };

  const onSubmit = async (data) => {
    const {
      confirmation,
      date,
      // people,
      discipline,
      // trainMoment,
      trainer,
      sessions,
    } = data;
    setBookingError('');
    if (sessions > userSessions) {
      alert("Client doesn't have enoguh sessions to book");
      return;
    }

    const selectedTrainer = await getTrainer(trainer.value);
    const address = user!.addresses!.training[0];
    const keys = Object.keys(weeklySlots);
    const slots: any = [];
    // let endHour;
    keys.forEach((day) => {
      if ((weeklySlots as any)[day]) {
        slots.push((weeklySlots as any)[day]);
      }
    });

    if (!slots.length) {
      alert('Please select at least one time slot');
      return;
    }
    const bookingsObjects: Booking[] = functions.generateWeeklySlots({
      user: user as User,
      trainer: trainer as Trainer,
      discipline: discipline.value,
      address,
      slots,
      selectedDate: moment(date),
      sessions,
      confirmation: confirmation.value,
    });

    const checkBooking = await checkBookingFromArray(bookingsObjects);

    if (checkBooking && !continueBooking) {
      toggleContinueBooking(true);
      setBookingError(
        `${selectedTrainer.displayName ?? selectedTrainer.fullname
        } already has session at ${checkBooking.date}, ${checkBooking.time}`,
      );
    } else {
      await createBookings({
        bookings: bookingsObjects,
        moreAboutMe: user?.moreAboutMe ? user?.moreAboutMe : '',
        userId: user!.id!,
        isExtraSession: false,
        confirmation: confirmation.value,
      });

      if (confirmation.value === BookingConfirmation.CONFIRMED) {
        const isChatExist = await checkChatExist(
          trainer.value,
          user.id as string,
        );
        if (!isChatExist) {
          const createChat = await createChatRoom(user, selectedTrainer);
          if (createChat) {
            await getChannelIdFromMemberPair(trainer.value, user.id as string);
          }
        }
      }
      toggleContinueBooking(false);
      closeModal();
    }
  };

  const formDefaultValue = useMemo(
    () => ({
      service: serviceOptions[0],
      discipline: null,
      trainer: null,
      date: moment().format('YYYY-M-DD'),

      trainMoment: null,
      confirmation: BookingStatusOptions[0],
      people: ServiceTypeOptions[0],
    }),
    [],
  );

  return (
    <>
      <Button
        isDisabled={!enabledBooking}
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
          flex: 1,
        }}
      >
        (+) Book Recurring Session
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({
                formProps, setFieldValue, submitting, reset,
              }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New Sessions" />

                  <ModalBody>
                    <Field
                      id="location"
                      name="location"
                      defaultValue={
                        user?.addresses?.training[0]?.location || ''
                      }
                      isDisabled
                    >
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Training Location"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>

                    <Field<ValueType<OptionType>>
                      id="service"
                      name="service"
                      defaultValue={formDefaultValue.service}
                      isRequired
                      validate={(value) => {
                        if (!value) return 'pls select service';
                      }}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            name="Service"
                            validationState={error ? 'error' : 'default'}
                            options={serviceOptions}
                            inputId={id}
                            value={value}
                            onChange={(e) => {
                              onSelectService(e?.value as string);
                              onChange(e);
                              setFieldValue('trainer', null);
                              setFieldValue('discipline', null);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field<ValueType<OptionType>>
                      id="discipline"
                      name="discipline"
                      defaultValue={formDefaultValue.discipline}
                      isRequired
                      validate={(value) => {
                        if (!value) return 'pls select discipline';
                      }}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            name="Discipline"
                            validationState={error ? 'error' : 'default'}
                            options={disciplineOptions}
                            inputId={id}
                            value={value}
                            onChange={(e) => {
                              onSelectDiscipline(e?.value as string);
                              onChange(e);
                              setFieldValue('trainer', null);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field
                      id="trainer"
                      name="trainer"
                      defaultValue={formDefaultValue.trainer}
                      isRequired
                      validate={(value) => {
                        if (!value) return 'pls select trainer';
                      }}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            name="Trainer"
                            validationState={error ? 'error' : 'default'}
                            classNamePrefix="react-select"
                            options={trainerOptions}
                            value={value}
                            isSearchable
                            inputId={id}
                            onChange={(e) => {
                              setActiveTrainerId(e?.value as string);
                              // setTrainerTimeNotAvailable(e.timeNotAvailable);
                              onChange(e);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field
                      id="confirmation"
                      name="confirmation"
                      defaultValue={formDefaultValue.confirmation}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            inputId={id}
                            name="Status"
                            validationState={error ? 'error' : 'default'}
                            options={BookingStatusOptions}
                            value={value}
                            onChange={(val) => {
                              onChange(val);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field
                      id="date"
                      name="date"
                      defaultValue={formDefaultValue.date}
                      // eslint-disable-next-line consistent-return
                      validate={(value) => {
                        if (!value) return 'Date is required';
                      }}
                    >
                      {({
                        fieldProps: { onChange, value, ...rest },
                        error,
                      }) => (
                        <FormFieldInput
                          label="Starting date"
                          type="date"
                          value={value}
                          error={error}
                          fieldProps={{
                            onChange: (
                              e: React.FormEvent<HTMLInputElement>,
                            ) => {
                              // onSelectDate(moment(value));
                              onChange(e.currentTarget.value);
                            },
                            ...rest,
                          }}
                        />
                      )}
                    </Field>

                    {weekdays.map((weekday) => (
                      <Field
                        id={weekday.toLowerCase()}
                        name={weekday.toLowerCase()}
                        // isRequired={false}
                        // defaultValue={weeklySlots[weekday]}
                        defaultValue={selectedTime12h[weekday]}
                      >
                        {({ fieldProps: { id, onChange, value }, error }) => (
                          <>
                            <StyledSelect
                              inputId={id}
                              name={weekday}
                              validationState={error ? 'error' : 'default'}
                              // options={possibleTimeSlotOptions}
                              options={generateSlots()}
                              value={value?.label}
                              onChange={(val) => {
                                onCreateCustomSlot(weekday, val.hour);
                                setSelectedTime12({
                                  ...selectedTime12h,
                                  [weekday]: val.label,
                                });
                                onChange(val);
                              }}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </>
                        )}
                      </Field>
                    ))}

                    <div style={{ marginTop: 16 }}>
                      {!user.sessionsAvailable ? `Balance on last package ${userSessions}`
                        : `Sessions available ${userSessions}`}
                    </div>
                    <Field
                      id="sessions"
                      name="sessions"
                      defaultValue={1}
                      isRequired
                    >
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Sessions quantity"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>
                    {bookingError && (
                      <ErrorMessage>{bookingError}</ErrorMessage>
                    )}
                  </ModalBody>

                  <ModalFooter>
                    {bookingError ? (
                      <>
                        <Button
                          type="submit"
                          isDisabled={submitting}
                          className="modal-button"
                        >
                          Continue Anyway
                        </Button>
                        <Button
                          type="button"
                          appearance="danger"
                          isDisabled={submitting}
                          className="modal-button-danger"
                          onClick={() => {
                            reset();
                            toggleContinueBooking(false);
                            setBookingError('');
                            closeModal();
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="submit"
                        isDisabled={submitting}
                        className="modal-button"
                      >
                        Create
                      </Button>
                    )}
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
