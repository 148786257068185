/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { LatLng, PolyGonType } from '@models/address';
import CityRegionsController from '@controllers/cityRegions.controller';
import _ from 'lodash';

const parseCenterCoordinate = (coordinates: LatLng[]): LatLng => {
  const x = coordinates.map((c) => c.latitude);
  const y = coordinates.map((c) => c.longitude);

  const minX = Math.min.apply(null, x);
  const maxX = Math.max.apply(null, x);

  const minY = Math.min.apply(null, y);
  const maxY = Math.max.apply(null, y);

  return {
    latitude: (minX + maxX) / 2,
    longitude: (minY + maxY) / 2,
  };
};
function useCityRegions(): PolyGonType[] {
  const [cityRegions, setCityRegions] = useState<PolyGonType[]>([]);
  useEffect(() => {
    const initCityRegions = async () => {
      try {
        const RegionIDPairs = await CityRegionsController.get();
        const polyGonList = _.reduce(
          RegionIDPairs,
          (result: PolyGonType[], item) => {
            const polyGon: PolyGonType = {
              id: item.id,
              coordinates: item?.data?.coordinates,
              region: item?.data?.region,
              city: item?.data?.city,
              center: parseCenterCoordinate(item?.data?.coordinates),
              areas: item?.data?.areas,
            };
            return _.concat(result, polyGon);
          },
          [],
        );
        setCityRegions(polyGonList as PolyGonType[]);
      } catch (error) {
        // console.log("initCityRegions error", error?.message);
      }
    };
    initCityRegions();
    return () => {};
  }, []);
  return cityRegions;
}

const CityRegionContext = createContext<{ cityRegions: PolyGonType[] }>({
  cityRegions: [],
});

export function CityRegionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const cityRegions = useCityRegions();

  const cityRegionContext = useMemo(() => ({ cityRegions }), [cityRegions]);

  return (
    <CityRegionContext.Provider value={cityRegionContext}>
      {children}
    </CityRegionContext.Provider>
  );
}
export const useCityRegionContext = () => useContext(CityRegionContext);
