/* eslint-disable import/no-unresolved */
import { updateTrainer } from '@controllers/trainerFirestoreController';
import { getUsers } from '@controllers/userFirestoreController';
import AvailabilitySlot from '@models/availabilitySlot';
import { Booking, Session } from '@models/booking';
import { Trainer } from '@models/trainer';
import { User } from '@models/user';
import { db } from '@root/controllers/firebase';
import { LatLng } from '@root/models/address';
import { ClientPackage } from '@root/models/clientPackage';
import { TimeSlotsType } from '@root/models/workingHours';
import { AvailabilityLimitHours } from '@utils/enums/availability.enum';
import {
  BookingConfirmation,
  BookingLimits,
  BookingSlot,
  BookingState,
} from '@utils/enums/booking.enums';
import firebase from 'firebase';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import collections from './enums/collections.enums';
import UserStatusEnum from './enums/userStatus.enums';

// const DATE_FORMAT = "MMM Do YYYY";
const SLOT_FORMAT = 'MM/DD/YYYY';
/*  */
type MinBookingDate = {
  date: string;
  slot: AvailabilitySlot;
};
export function validatePhoneNumber(phoneNumber: string) {
  const pattern = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
  return pattern.test(phoneNumber);
}
export function validateEmail(data: string): boolean {
  const pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  if (!pattern.test(data)) {
    return false;
  }
  return true;
}

export function isNumber(str) {
  if (str.trim() === '') {
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(str);
}
// Check if time is AM or PM
const getTypeTime = (time: number) => {
  if (time < 12) {
    return 'am';
  }
  return 'pm';
};
// Convert 24h time to 12h time
export const convertTo12hTime = (time: number, noIncludeZeros?: boolean) => {
  const sum = 12 - time;
  // eslint-disable-next-line no-nested-ternary
  let hours = time > 12 ? (sum <= 0 && Math.floor(time) === 12 ? 12 : sum) : time;
  hours = Math.abs(hours || 12);
  const amPm = getTypeTime(time);
  let minutes = '00';
  // Check if time is decimal
  if (time % 1 !== 0) {
    // Get decimals of time
    const decimals = Math.round(time * 100) % 100;
    minutes = decimals.toString();
  }
  hours = Math.round(hours);
  if (noIncludeZeros) {
    return `${hours}${amPm}`;
  }
  return `${hours}:${minutes} ${amPm}`;
};
export const parseStartTime = (startTime: number): string => {
  let noon = 'AM';
  let hour = Math.floor((startTime * 100) / 100);
  const minute = Math.floor((startTime * 100) % 100);
  if (hour >= 12) {
    noon = 'PM';
    hour -= 12;
  }
  return `${hour < 10 ? 0 : ''}${hour}:${minute === 0 ? '00' : minute} ${noon}`;
};
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const convertBookingToSessionFormat = async ({
  bookingList,
  isTrainer,
}: {
  bookingList: Booking[];
  isTrainer: boolean;
}): Promise<Session[]> => {
  try {
    const sortedList = _.orderBy(
      bookingList,
      (o) => moment(o?.date).format('YYYYMMDD'),
      ['desc'],
    );
    const data: Session[] = await Promise.all(
      _.map(sortedList, async (booking) => {
        const { id } = booking;
        const date = moment(booking.date).format('ddd MMM DD, YYYY');
        const time = convertTo12hTime(booking.slot?.startHour!);
        const service = booking.discipline || '';
        const type = {
          label: `${booking.people} on 1`,
          value: booking?.people,
        };
        const location = booking.address.location || '';

        const status = booking.status || '';
        const confirmation = booking.confirmation || '';
        const cancellationReason = booking.cancellationReason || '';
        const lateCancellation = booking.lateCancellation || false;
        const session: Session = {
          id,
          date,
          time,
          service,
          type,
          location,
          status,
          isPersonalTrainerClient: booking.isPersonalTrainerClient || false,
          confirmation,
          cancellationReason,
          madeBy: booking.madeBy,
          price: booking.price !== undefined ? `AED ${String(Number(booking.price).toFixed(2))}` : 'N/A',
          isFree: booking.isFree || false,
          lateCancellation,
        };
        const userData = (await getUsers(
          isTrainer ? booking.userId : booking.trainerId,
        )) as User & Trainer;
        if (isTrainer) {
          const client = { id: booking.userId, name: userData?.fullname || '' };
          session.client = client;
        } else {
          const trainer = {
            id: booking.trainerId,
            name: userData?.fullname || '',
          };
          session.trainer = trainer;
        }

        return session;
      }),
    );
    return data;
  } catch (error) {
    console.log('===Error convert===', error);
    return [];
  }
};

// Get the minimum startTime of data
// eslint-disable-next-line max-len
const getMinStartTime = (data: Array<any>) => data.reduce((min, item) => (item.startTime < min ? item.startTime : min), Number.MAX_SAFE_INTEGER);
// Get the maximun endTime of data
// eslint-disable-next-line max-len
const getMaxEndTime = (data: Array<any>) => data.reduce((max, item) => (item.endTime > max ? item.endTime : max), Number.MIN_SAFE_INTEGER);

export const getUnixTimestamp = (date) => moment(date).unix();

const generateAvailabilitySlots = (
  startHour: number,
  endHour: number,
  forTrainerAvailability?: boolean,
): AvailabilitySlot[] => {
  const minutes = 0.3; // This means 30 minutes
  const userArray: AvailabilitySlot[] = [];
  const trainerArray: AvailabilitySlot[] = [];
  let id = 0;

  if (forTrainerAvailability) {
    for (let i = 0; i <= 48; i += 1) {
      // if (i >= startHour && id <= endHour - 1) {
      if (i >= startHour && id <= endHour) {
        if (!id) {
          id = i;
        } else {
          id += 0.5;
        }

        const pastEndHour = trainerArray[i - 1 - startHour]?.endHour;
        const isPastIdFloat = !id ? false : id % 1 !== 0;

        trainerArray.push({
          id,
          startHour: pastEndHour ?? i,
          endHour: isPastIdFloat ? Math.ceil(id) : id + minutes,
        });
      }
    }
  } else {
    for (let i = 0; i <= 24; i += 1) {
      if (i >= startHour && i <= endHour) {
        /*
          Right now in Figma the min hour is 6 am and the max hour is 9 pm
          Doing the bucle from 0 to 24 is the guarantee that the id hour will be the same
          not matter if tomorrow the min hour is 4 am and the max hour is 6 pm
          For example, if the startHour is 7, the id is 7 not
          matter what changes cames in the future
        */
        userArray.push({
          id: i,
          startHour: i,
          endHour: i + minutes,
        });
      }
    }
  }
  return forTrainerAvailability ? trainerArray : userArray;
};
const timeSlots = generateAvailabilitySlots(
  AvailabilityLimitHours.MIN_HOUR,
  AvailabilityLimitHours.MAX_HOUR,
);
const findSlotIndex = (
  selectedTimeSlots: AvailabilitySlot[],
  timeSlot: AvailabilitySlot,
  selectedDate: string,
) => {
  let index = -1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < selectedTimeSlots.length; i++) {
    const element = selectedTimeSlots[i];
    if (element.isWeekly) {
      const weekDateDay = moment(selectedDate).format('dd');
      const itemDateDay = moment(element.date).format('dd');
      if (element.id === timeSlot.id && weekDateDay === itemDateDay) {
        if (element.exceptions?.length) {
          if (element.exceptions.includes(selectedDate)) {
            index = -1;
            break;
          }
        }
        index = i;
        break;
      }
    }
    if (element.date && element.date === selectedDate && !element.isWeekly) {
      if (element.id === timeSlot.id) {
        index = i;
        break;
      }
    }
  }
  return index;
};
export const isTrainerWorkingInThisSlot = ({
  hour,
  date,
  trainer,
}: {
  hour: number;
  date: Moment;
  trainer: User & Trainer;
}) => {
  const dateFormatted = date.format(SLOT_FORMAT);
  const day = date.format('dddd');
  let isAvailable = true;
  if (!trainer?.timeNotAvailable) {
    return isAvailable;
  }

  // Check time availability
  for (let i = 0; i < trainer.timeNotAvailable.length; i += 1) {
    const trainerSlot = trainer.timeNotAvailable[i];
    const unavailableStartHour = trainerSlot.startHour;
    const unavailableEndHour = trainerSlot.endHour;
    if (trainerSlot.isWeekly) {
      const trainerSlotDay = moment(trainerSlot.date).format('dddd');

      if (trainerSlotDay === day) {
        // If the hour is between the booking return false
        if (hour >= unavailableStartHour && hour <= unavailableEndHour) {
          isAvailable = false;
          break;
        }
        // If the next 30 minutes the trainer is not available return false
        let nextHalfHour = 0;
        const minutes = hour - parseInt(hour.toString(), 10);
        if (minutes) {
          nextHalfHour = Math.ceil(hour);
        } else {
          nextHalfHour = hour + 0.3;
        }

        if (
          nextHalfHour >= unavailableStartHour
          && nextHalfHour <= unavailableEndHour
        ) {
          return false;
        }
      }
    }

    if (trainerSlot.date === dateFormatted) {
      if (hour >= unavailableStartHour && hour <= unavailableEndHour) {
        isAvailable = false;
      }
      // If the next 30 minutes the trainer is not available return false
      let nextHalfHour = 0;
      const minutes = hour - parseInt(hour.toString(), 10);
      if (minutes) {
        nextHalfHour = Math.ceil(hour);
      } else {
        nextHalfHour = hour + 0.3;
      }

      if (
        nextHalfHour >= unavailableStartHour
        && nextHalfHour <= unavailableEndHour
      ) {
        return false;
      }
    }
  }
  return isAvailable;
};
/**
Booking utility
 */
export const getMinimunDateToBooking = ({
  date,
  trainer,
}: {
  date: Moment;
  trainer: User & Trainer;
}): MinBookingDate | null => {
  const currentDate = moment();
  let indexDate = moment();
  let hours = 0;
  let minimunSlot: AvailabilitySlot | null = null;
  let auxBucle = 0;
  while (
    hours < BookingLimits.HOURS_IN_ADVANCE
    && auxBucle < timeSlots.length
  ) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < timeSlots.length; i++) {
      const isSameDay = moment(indexDate).isSame(currentDate, 'day');
      const slot = timeSlots[i];
      if (isSameDay && slot.startHour <= currentDate.hour()) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (
        isTrainerWorkingInThisSlot({
          hour: slot.startHour,
          date,
          trainer,
        })
      ) {
        hours += 1;
      } else {
        auxBucle += 1;
      }
      if (hours >= BookingLimits.HOURS_IN_ADVANCE) {
        minimunSlot = slot;
        break;
      }
    }
    if (hours < BookingLimits.HOURS_IN_ADVANCE) {
      indexDate = indexDate.add(1, 'day');
    }
  }
  if (minimunSlot === null) {
    return null;
  }
  return {
    date: indexDate.format(SLOT_FORMAT),
    slot: minimunSlot,
  };
};

export const isTheMinimunDateToBooking = ({
  date,
  slot,
  trainer,
}: {
  slot: AvailabilitySlot;
  date: Moment;

  trainer: User & Trainer;
}) => {
  let canCreateBooking = true;
  const minimunDateToBooking = getMinimunDateToBooking({ date, trainer });
  if (minimunDateToBooking) {
    const selectedDateFormatted = date.format(SLOT_FORMAT);

    const momentMinimunDate = moment(new Date(minimunDateToBooking.date))
      .clone()
      .startOf('day');
    const momentSelectedDate = moment(date).clone().startOf('day');

    if (momentSelectedDate.isBefore(momentMinimunDate)) {
      canCreateBooking = false;
    }
    if (selectedDateFormatted === minimunDateToBooking.date) {
      const startTime = minimunDateToBooking.slot.startHour;
      const endTime = minimunDateToBooking.slot.endHour;

      if (!(startTime <= slot.startHour || endTime <= slot.endHour)) {
        canCreateBooking = false;
      }
    }
  }
  return canCreateBooking;
};

export const isHourAvailable = ({
  slot,
  hour,
  date,
  trainer,
  bookingHistory,
}: {
  slot: AvailabilitySlot;
  hour: number;
  date: Moment;
  trainer: User & Trainer;
  bookingHistory: Booking[];
}) => {
  const currentDate = moment();

  let isAvailable = true;
  const dateFormatted = date.format(SLOT_FORMAT);
  const isSameDay = moment(date).isSame(currentDate, 'day');

  // Checking out Weekly Availability
  if (!isTrainerWorkingInThisSlot({ hour, date, trainer })) {
    return false;
  }

  // Checking out if the slot is below of current hour
  if (isSameDay && slot.startHour <= currentDate.hour()) {
    return false;
  }

  // Checking out if trainer already have a booking in this slot or the slot is between the booking
  for (let i = 0; i < bookingHistory.length; i += 1) {
    const element = bookingHistory[i];

    if (element.date === dateFormatted) {
      const bookingStartHour = element.slot?.startHour as number;
      const bookingEndHour = element.slot?.endHour as number;
      // If the hour is between the booking return false
      if (hour >= bookingStartHour && hour < bookingEndHour) {
        return false;
      }

      // If the next 30 minutes the trainer have a booking return false
      let nextHalfHour = 0;
      const minutes = hour - parseInt(hour.toString(), 10);
      if (minutes) {
        nextHalfHour = Math.ceil(hour);
      } else {
        nextHalfHour = hour + 0.3;
      }

      if (nextHalfHour >= bookingStartHour && nextHalfHour <= bookingEndHour) {
        return false;
      }

      /* Update: Dec 21: Tech Pipeline issue 128: Buffer still
      applied before a scheduled session. Example: if a trainer
      has a session at 9am, admin wont allow to book him at 8am.
      Importance of this is some trainers do back to back sessions with 2 clients.
      If the next 60 minutes the trainer have a booking return false
       */
      // minutes = nextHalfHour - parseInt(nextHalfHour.toString(), 10);
      // if (minutes) {
      //   nextHalfHour = Math.ceil(nextHalfHour);
      // } else {
      //   nextHalfHour = nextHalfHour + 0.3;
      // }
      // if (nextHalfHour >= bookingStartHour && nextHalfHour <= bookingEndHour) {
      //   return false;
      // }
    }
  }

  if (!isTheMinimunDateToBooking({ date, slot, trainer })) {
    isAvailable = false;
  }
  return isAvailable;
};

export const getFullName = async (id?: string): Promise<string> => {
  try {
    const user = await getUsers(id);
    return user ? (user?.displayName || user.fullname) : '';
  } catch (error) {
    return '';
  }
};

export const convertFirebaseTimestampToDateString = (
  date,
) => {
  try {
    const dateTime = date?.seconds ? Number(date?.seconds) * 1000 : 'N/A';
    return moment(dateTime)
      .format('DD MMM YYYY \xa0\xa0\xa0  hh:mma')
      .toString();
  } catch (error) {
    return 'N/A';
  }
};
export const convertFBTimestampToDateString = (date): string => {
  try {
    const dateTime = date?.seconds ? Number(date?.seconds) * 1000 : 'N/A';
    return moment(dateTime)
      .format('DD MMM YYYY \xa0\xa0\xa0  hh:mma')
      .toString();
  } catch (error) {
    return 'N/A';
  }
};

export const convertBookingDateToDateTime = ({
  date,
  startTime,
  endTime,
}: {
  date?: string;
  startTime?: number;
  endTime?: number;
}) => {
  const d = moment(date).format('DD MMM YYYY ').toString();
  const start = convertTo12hTime(startTime as number);
  const end = convertTo12hTime(endTime as number);

  return `${d} (${start}-${end})`;
};

const makeNotAvailableTimeSlotsV2 = (
  trainerId: string,
  newTimeSlots: AvailabilitySlot[],
  // eslint-disable-next-line no-unused-vars
  onCompleted: (value: AvailabilitySlot[]) => void,
  userTimeSlots: AvailabilitySlot[],
  weekly: boolean,
) => {
  const data = newTimeSlots.map((item) => ({ ...item, isWeekly: weekly }));
  const newUserTimeSlots = [...userTimeSlots, ...data];
  onCompleted(newUserTimeSlots);
  const trainerUser = {
    id: trainerId,
    timeNotAvailable: newUserTimeSlots,
  } as User & Trainer;
  updateTrainer(trainerUser);
};

export const makeAvailableTimeSlotsV2 = (
  trainerId: string,
  newTimeSlots: AvailabilitySlot[],
  // eslint-disable-next-line no-unused-vars
  onSetUserTimeSlots: (value: AvailabilitySlot[]) => void,
  userTimeSlots: AvailabilitySlot[],
) => {
  const newUserTimeSlots = userTimeSlots.filter((item) => {
    let isDelete = false;
    newTimeSlots.forEach((newTimeSlot) => {
      if (item.id === newTimeSlot.id) {
        if (item?.isWeekly) {
          const weekDayOfBase = moment(item.date).day();
          const weekDay = moment(newTimeSlot.date).day();
          if (weekDay === weekDayOfBase) {
            isDelete = true;
          }
        } else if (item.date === newTimeSlot.date) {
          isDelete = true;
        }
      }
    });
    return !isDelete;
  });

  onSetUserTimeSlots(userTimeSlots);
  const trainerUser = {
    id: trainerId,
    timeNotAvailable: [...newUserTimeSlots],
  } as User & Trainer;
  updateTrainer(trainerUser);
};
export const makeAvailableFullTimeSlotsV2 = (
  trainerId: string,
  // eslint-disable-next-line no-unused-vars
  onSetUserTimeSlots: (value: AvailabilitySlot[]) => void,
) => {
  onSetUserTimeSlots([]);
  const trainerUser = {
    id: trainerId,
    timeNotAvailable: [],
  } as User & Trainer;
  updateTrainer(trainerUser);
};
export const generateWeeklySlots = ({
  user,
  sessions,
  selectedDate,
  slots,
  address,
  discipline,
  trainer,
  // boughtSessions,
  confirmation,
}: {
  user: User;
  sessions: number;
  trainer: Trainer;
  selectedDate: Moment;
  slots: BookingSlot[];
  discipline: string;
  address: any;
  // boughtSessions?: number;
  confirmation: BookingConfirmation;
}) => {
  const today = moment();
  const bookings: Booking[] = [];
  let day = 0;
  const newSessions = parseInt(sessions as any, 10);
  // const sessions = boughtSessions
  //   ? boughtSessions
  //   : (user.sessionsAvailable as number);
  let spentSessions = 0;

  let sumDate = selectedDate;
  while (spentSessions < newSessions || day < 365) {
    const pastBooking = moment(sumDate).isBefore(today);
    const selectedDayName = moment(sumDate).format('dddd');
    const slotIndex = slots.findIndex(
      (slot) => slot.weekDay === selectedDayName,
    );
    if (slotIndex > -1) {
      const bookingObject: Booking = {
        userId: user.id,
        address: {
          location: address?.location,
          aptHouseNumber: address?.aptHouseNumber,
        },
        status: pastBooking ? BookingState.COMPLETED : BookingState.SCHEDULED,
        confirmation: pastBooking ? BookingConfirmation.CONFIRMED : confirmation,
        people: 1,
        discipline,
        date: sumDate.format('MM/DD/YYYY'),
        slot: slots[slotIndex],
        trainerId: (trainer as any)?.value,
        madeBy: 'admin',
        clientName: user.fullname,
        trainerName: (trainer as any).label || '',
      } as Booking;
      if (confirmation === BookingConfirmation.CONFIRMED || pastBooking) {
        bookingObject.confirmedAt = firebase.firestore.FieldValue.serverTimestamp();
      }
      bookings.push(bookingObject);
      spentSessions += 1;
    }
    sumDate = selectedDate.add(1, 'day');
    if (spentSessions === newSessions) {
      break;
    }
    day += 1;
  }

  return bookings;
};
// eslint-disable-next-line no-nested-ternary
export const getActivityStatus = ({
  assignedTrainerId,
  balance,
  completedBookingCount,
  lastCompletedSessionDays,
}: {
  assignedTrainerId?: string | null;
  balance: number;
  completedBookingCount: number;
  lastCompletedSessionDays: number;
}) => {
  let userStatus = '';
  if (assignedTrainerId) {
    userStatus = UserStatusEnum.PT_CLIENT;
  } else if (balance === 0 && completedBookingCount === 0) {
    // New User (never completed a session or balance = 0)
    userStatus = UserStatusEnum.NEW_USER;
  } else if (lastCompletedSessionDays >= 30 && balance > 0) {
    // Siesta Client (clients with balance > 0 ,
    // NO scheduled sessions and more than 1 of last completed session)
    userStatus = UserStatusEnum.SIESTA_CLIENT;
  } else if (balance > 0) {
    // Active Client (completed a session and/or balance > 0)
    userStatus = UserStatusEnum.ACTIVE_CLIENT;
  } else if (lastCompletedSessionDays <= 60 && balance === 0) {
    // Pending Client (client with balance = 0 and less than 2 months of last completed session)
    userStatus = UserStatusEnum.PENDING_CLIENT;
  } else if (lastCompletedSessionDays > 60 && balance === 0) {
    // Lost Client (client with balance = 0 and more than 2 months of last completed session)
    userStatus = UserStatusEnum.LOST_CLIENT;
  }

  return userStatus;
};

export async function getUserStatus(userId: string) {
  const dataSnap = await db
    .collection(collections.USERS)
    .doc(userId)
    .get();

  const data = await dataSnap.data() as User;

  if (data.assignedTrainerId) {
    return UserStatusEnum.PT_CLIENT;
  }
  const isArchived = (
    await db.collection(collections.CLIENT_PACKAGES)
      .where('userId', '==', userId)
      .where('archived', '==', true)
      .where('sessions', '>', 0)
      .get()
  ).docs.length;

  if (isArchived > 0) {
    return UserStatusEnum.ARCHIVED_CLIENT;
  }
  const scheduledBookingSnapShot = await db
    .collection(collections.BOOKINGS)
    .where('userId', '==', userId)
    .where('status', '==', BookingState.SCHEDULED)
    .where('discipline', '!=', 'Nutrition')
    .get();
  const completededBookingSnapShot = await db
    .collection(collections.BOOKINGS)
    .where('userId', '==', userId)
    .where('status', '==', BookingState.COMPLETED)
    .where('discipline', '!=', 'Nutrition')
    .get();

  const completedBookingCount = completededBookingSnapShot.docs.length;

  const scheduledBookingCount = scheduledBookingSnapShot.docs.length;
  let sessionsAvailable = data?.sessionsAvailable ?? 0;

  if (sessionsAvailable <= 0) {
    const snapshot = await db
      .collection(collections.CLIENT_PACKAGES)
      .where('userId', '==', userId)
      .where('sessions', '>', 0)
      .get();
    const sessionData = snapshot.docs.map((doc: any) => doc.data()) as ClientPackage[];

    sessionsAvailable = sessionData.reduce((acc, item) => acc + item.sessions, 0);
  }
  const balance = scheduledBookingCount + sessionsAvailable;

  // fetch last completed session
  const completedBookingSnapShot = await db
    .collection(collections.BOOKINGS)
    .where('userId', '==', userId)
    .where('status', '==', BookingState.COMPLETED)
    .orderBy('completedAt', 'desc')
    .get();

  const lastCompleteSession = completedBookingSnapShot.docs.length > 0
    ? completedBookingSnapShot.docs[0]?.data()
    : null;

  // days after last completed session
  const lastCompletedSessionDays = lastCompleteSession
    ? moment().diff(moment(lastCompleteSession?.completedAt.toDate()), 'days')
    : 0;
  const userStatus = await getActivityStatus({
    assignedTrainerId: data?.assignedTrainerId,
    balance,
    completedBookingCount,
    lastCompletedSessionDays,
  });

  return userStatus;
}

export const indexToTableNum = (
  { index, numToRender = 10 }:
    { index: number, numToRender?: number },
) => {
  const value = (index + 1) % numToRender;
  return value === 0 ? numToRender : value;
};

export const parseCenterCoordinate = (coordinates: LatLng[]): LatLng => {
  const x = coordinates.map((c) => c.latitude);
  const y = coordinates.map((c) => c.longitude);

  const minX = Math.min.apply(null, x);
  const maxX = Math.max.apply(null, x);

  const minY = Math.min.apply(null, y);
  const maxY = Math.max.apply(null, y);

  return {
    latitude: (minX + maxX) / 2,
    longitude: (minY + maxY) / 2,
  };
};

function arraySortedOrNot(a, n) {
  // Base case
  if (n === 1 || n === 0) {
    return true;
  }

  // Check if present index and index
  // previous to it are in correct order
  // and rest of the array is also sorted
  // if true then return true else return
  // false
  return a[n - 1] >= a[n - 2] && arraySortedOrNot(a, n - 1);
}

// eslint-disable-next-line no-shadow
export const timeSlotsNotOverlapping = (timeSlots: TimeSlotsType[]) => {
  const arr: number[] = [];

  timeSlots.map((slot) => {
    arr.push(Number(moment(slot.startTime, ['h:mm:A']).format('HH.mm')));
    arr.push(Number(moment(slot.endTime, ['h:mm:A']).format('HH.mm')));
    return null;
  });

  const n = arr.length;

  if (arraySortedOrNot(arr, n)) {
    return true;
  }
  return false;
};

export const makeCopy = (data: any) => {
  const newData = JSON.stringify(data);
  return JSON.parse(newData);
};

export const daysOfweekShift = (data: any[], startOfWeekMonday?: boolean) => {
  const sundayIndex = data.findIndex((d) => d.index === 0);
  const sunday = data[sundayIndex];
  const newData = data.filter((d) => d.index !== 0);

  if (startOfWeekMonday) {
    return newData.concat(sunday);
  }
  return [sunday].concat(newData);
};

export const generateYears = (from: number, to: number) => Array(to - from).fill(0)
  .map((item, index) => from + (index + 1));

export const diffHours = (date1: string | Date, date2?: string | Date) => {
  const now = date2 || new Date();
  const diff = moment(date1).diff(now, 'hours');
  return Math.abs(diff);
};

export const wait = async (ms: number) => {
  const value = await new Promise((resolve) => {
    setTimeout(() => resolve(true), ms);
  });
  return value;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  validateEmail,
  parseStartTime,
  capitalizeFirstLetter,
  convertBookingToSessionFormat,
  getTypeTime,
  getMinStartTime,
  getMaxEndTime,
  convertTo12hTime,
  generateAvailabilitySlots,
  findSlotIndex,
  getFullName,
  makeAvailableTimeSlotsV2,
  makeNotAvailableTimeSlotsV2,
  generateWeeklySlots,
  makeAvailableFullTimeSlotsV2,
};
