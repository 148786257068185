/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import { useState, useEffect } from 'react';
import { db } from '@controllers/firebase';
import { User } from '@models/user';
import { Trainer } from '@models/trainer';
import { Booking } from '@models/booking';
import collections from '../utils/enums/collections.enums';

const useUserService = (userId: string) => {
  const [user, setUser] = useState<(Trainer & User) | null>(null);
  const [userBookings, setUserBookings] = useState<Booking[]>([]);

  useEffect(() => {
    const unsubscribeUserBooking = db
      .collection(collections.BOOKINGS)
      .where('userId', '==', userId)
      .onSnapshot((snap) => {
        const allBookings = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          lateCancellation: doc.data().lateCancellation ?? false,
        })) as Booking[];
        setUserBookings(allBookings);
      });
    const unsubscribeUser = db
      .collection(collections.USERS)
      .doc(userId)
      .onSnapshot((observer) => {
        const userObj: User & Trainer = observer.data() as User & Trainer;
        setUser(userObj);
      });
    return () => {
      unsubscribeUserBooking();
      unsubscribeUser();
    };
  }, [userId]);

  return { userBookings, user };
};
export const updateUser = (trainer: Partial<Trainer & User>) => {
  try {
    return db.collection(collections.USERS).doc(trainer.id).update(trainer);
  } catch (error) {
    // console.log('===Error updating trainer====', error);
  }
};
export default useUserService;
