/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import _ from 'lodash';
import firebase from 'firebase';
import { WorkingDaysType } from '@root/models/workingHours';
import defaultSlots from '@root/data/defaultSlots';
import { StorageBuckets } from '@root/models/storageBuckets';
import { AttributeUpdated } from '@root/models/attributeUpdated';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';
import { User } from '../models/user';
import { Trainer, TrainerTypesEnum } from '../models/trainer';
import StorageController from './storageController';

type TrainerType = Trainer & User;

function getCollection() {
  return db.collection(collections.USERS);
}

export const useTrainer = (isVerified = true) => {
  const [items, setItems] = useState<(User & Trainer)[]>([]);
  const [loading, toggleLoading] = useState<boolean>(false);
  useEffect(() => {
    toggleLoading(true);
    const unsubscribe = db
      .collection(collections.USERS)
      .where('roles', 'array-contains', 'trainer')
      .where('isVerified', '==', isVerified)
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({
          ...doc.data(),
          fullname: doc.data()?.fullname,
          trainerType: doc.data()?.trainerType ?? TrainerTypesEnum.bronze,
          id: doc.id,
        }));
        setItems(_.orderBy(fetched, ['appearanceScore', 'averageRate'], ['asc', 'desc']));
        toggleLoading(false);
      });
    return unsubscribe;
  }, [isVerified]);

  const updateTrainer = (trainer: Trainer & User) => {
    try {
      return db.collection(collections.USERS).doc(trainer.id).update(trainer);
    } catch (error) {
      // console.log('===Error updating trainer====', error);
    }
  };

  return { items, loading, updateTrainer };
};

export const getTrainer = async (id) => {
  const user = await (await db.doc(`${collections.USERS}/${id}`).get()).data();

  return { ...user };
};
export const updateTrainer = (trainer: Trainer & User) => {
  try {
    return db.collection(collections.USERS).doc(trainer.id).update({
      ...trainer,
      updatedBy: 'admin',
    });
  } catch (error) {
    // console.log('===Error updating trainer====', error);
  }
};
export const updateTrainerProfilePicture = async (
  user: Trainer & User,
  content: string,
  picNum:Number,
  oldPictureUri?: string,
) => {
  if (picNum === 1) {
    const userObj = user;
    const downloadedPicture = await StorageController.uploadFile(
      content,
      StorageBuckets.profilePhotos,
      user.id!,
    );

    userObj.picture = downloadedPicture;
    await updateTrainer(userObj);
  } else {
    const userObj = user;
    const downloadedPicture = await StorageController.uploadFile(
      content,
      StorageBuckets.secondaryProfilePhotos,
      user.id!,
    );

    userObj.pictureSecondary = downloadedPicture;
    await updateTrainer(userObj);
  }

  if (oldPictureUri) {
    StorageController.deleteFile(oldPictureUri);
  }
};
export const fetchTrainersByUserLocation = async (
  region: string,
): Promise<TrainerType[]> => {
  const collection = getCollection();
  const snapshot = await collection
    .where('trainingRegions', 'array-contains', region)
    .where('isVerified', '==', true)
    .get();

  const data = _.filter(
    // eslint-disable-next-line no-unsafe-optional-chaining
    (snapshot?.docs).map((doc) => doc.data()),
    (item: Trainer & User) => (item?.roles || []).includes('trainer' as any),
  ) as TrainerType[];
  return data;
};

// TODO: Move this to server side
export const fetchTrainers = async (): Promise<TrainerType[]> => {
  const collection = getCollection();
  const snapshot = await collection
    .where('roles', 'array-contains', 'trainer')
    .where('isActive', '==', true)
    .get();

  const data = _.filter(
    snapshot.docs.map((doc) => doc.data()),
    (item: Trainer) => item,
  ) as TrainerType[];
  return data;
};

export const getTrainerAvailability = async (trainerId: string): Promise<WorkingDaysType[]> => {
  const trainerSettingsRef = db
    .collection(collections.USERS_SETTINGS)
    .doc(trainerId);
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    trainerSettingsRef.get().then(async (docSnapshot) => {
      if (docSnapshot.exists) {
        const workingDays = docSnapshot.data()?.workingDays
          ? docSnapshot.data()?.workingDays
          : defaultSlots;
        resolve(workingDays);
      } else {
        resolve(defaultSlots);
      }
    });
  });
};

export const saveTrainerAvailability = async (
  trainerId: string,
  workingDays: WorkingDaysType[],
): Promise<any> => {
  const trainerSettingsRef = db
    .collection(collections.USERS_SETTINGS)
    .doc(trainerId);
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    trainerSettingsRef.get().then(async (docSnapshot) => {
      if (docSnapshot.exists) {
        await trainerSettingsRef.update({
          workingDays,
        });
        resolve(true);
      } else {
        await trainerSettingsRef.set({
          workingDays,
          id: trainerSettingsRef.id,
        });
        resolve(true);
      }
    });
  });
};

export const updateTrainerAwayMode = async (
  trainerId: string,
  awayMode: boolean,
  awayModeStart?: string | null,
  awayModeEnd?: string | null,
): Promise<void> => {
  try {
    return db
      .collection(collections.USERS)
      .doc(trainerId)
      .update({
        awayMode,
        awayModeStart: awayMode
          ? awayModeStart
          : firebase.firestore.FieldValue.delete(),
        awayModeEnd: awayMode
          ? awayModeEnd
          : firebase.firestore.FieldValue.delete(),
      });
  } catch (error) {
    console.log('updateUser error', error);
  }
};

export const useTrainerChanges = () => {
  const [items, setItems] = useState<AttributeUpdated[]>([]);
  const [loading, toggleLoading] = useState<boolean>(false);
  useEffect(() => {
    toggleLoading(true);
    const unsubscribe = db.collection(collections.TRAINER_REQUEST).orderBy('createdAt', 'desc')
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))as AttributeUpdated[];
        setItems(fetched);
        toggleLoading(false);
      });
    return unsubscribe;
  }, []);

  // const updateTrainer = (trainer: Trainer & User) => {
  //   try {
  //     return db.collection(collections.USERS).doc(trainer.id).update(trainer);
  //   } catch (error) {
  //     // console.log('===Error updating trainer====', error);
  //   }
  // };

  return { items, loading, updateTrainer };
};

export const deleteTrainerRequest = async (requestId: string | undefined) => {
  try {
    if (requestId) {
      await db.collection(collections.TRAINER_REQUEST).doc(requestId).delete();
      return true;
    }
    return false;
  } catch (error) {
    console.log('==Error deleting TRAINER REQUEST====', error);
  }
};
