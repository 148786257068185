/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
export interface Trainer {
  id?: string;
  incognito?:boolean;
  showMedia?:boolean;
  GenderPreference?: string;
  appearanceScore?: number;
  averageRate?: number;
  avgFriendlinessRate?: number;
  avgPunctualityRate?: number;
  avgQualityRate?: number;
  PeakHourAvailability?: string;
  preferencesTraining?: string[];
  instagram?: string;
  Phone?: string;
  Regions?: string;
  Status?: string;
  TrainerGender?: string;
  TypeofTrainer?: string;
  archived?: boolean;
  isActive?: boolean;
  note?: string;
  isOfferFreeSessions?: boolean;
  awayMode?: boolean;
  awayModeStart?: string;
  awayModeEnd?: string;
  updatedBy?: 'admin' | 'trainer';
  updateMonthlyBalance?: true
}

export enum TrainerTypesEnum {
  gold='Gold',
  silver='Silver',
  bronze ='Bronze',
}
