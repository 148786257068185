/* eslint-disable consistent-return */
import { useState, useEffect, useCallback } from 'react';
import FirestoreService from '@root/utils/firestoreService';
import { TrainerPackage } from '@root/models/trainerPackage';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';

const TrainerPackageFirestore = () => {
  const [items, setItems] = useState<TrainerPackage[]>([]);

  const getAllTrainerPackages = useCallback(async () => {
    db.collection(collections.TRAINER_PACKAGES).orderBy('trainerName', 'asc').onSnapshot((snap) => {
      const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setItems(fetched as TrainerPackage[]);
    });
  }, []);

  useEffect(() => {
    getAllTrainerPackages();
  }, []);

  const filterTrainerPackage = async (trainerId: string | null | undefined) => {
    if (trainerId) {
      try {
        const filteredPackages = db.collection(collections.TRAINER_PACKAGES).where('trainerId', '==', trainerId).onSnapshot((snap) => {
          const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          setItems(fetched as TrainerPackage[]);
        });
        return filteredPackages;
      } catch (error) {
        console.log('===Error getting filtered trainer packages====', error);
      }
    } else {
      getAllTrainerPackages();
    }
  };
  const updateTrainerPackage = (packages: TrainerPackage) => {
    try {
      return db.collection(collections.TRAINER_PACKAGES).doc(packages.id).update(packages);
    } catch (error) {
      // console.log('===Error updating trainer====', error);
    }
  };

  const addTrainerPackage = async (object: TrainerPackage) => {
    try {
      const response = await FirestoreService.addObjectToCollection(
        collections.TRAINER_PACKAGES,
        object,
      );
      response.update({ id: response.id });
    } catch (error) {
      // console.log('create package error', error);
    }
  };

  const deleteTrainerPackage = (id: string) => {
    try {
      return db.collection(collections.TRAINER_PACKAGES).doc(id).delete();
    } catch (error) {
      // console.log('===Error deleting package====', error);
    }
  };

  return {
    items, updateTrainerPackage, addTrainerPackage, deleteTrainerPackage, filterTrainerPackage,
  };
};

export default TrainerPackageFirestore;
