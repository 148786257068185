/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable */
import { TextInput } from "@root/components/textInput";
import { useSessionsCount } from "@root/controllers/sessionsFirestoreController";
import { Trainer } from "@root/models/trainer";
import { User } from "@root/models/user";
import { BookingState } from "@root/utils/enums/booking.enums";
import { useBillingFirestore } from "@controllers/billingFirestoreController";
import moment from "moment";
import InlineEdit from "@atlaskit/inline-edit";
import { updateUser } from "@root/services/useUserService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ClientPackage, PackagesServices } from "@root/models/clientPackage";
import FirestoreService from "@root/utils/firestoreService";
import {
  getPackage,
  useClientPackageSessionsCount,
} from "@root/controllers/userFirestoreController";
import UserController from "@root/controllers/userController";

export default function UserProfileTopBottom({
  user,
}: {
  user: Trainer & User;
}) {
  const [clientPackage, setPackage] = useState<ClientPackage>();
  const { trainingSessions, wellnessSessions } = useClientPackageSessionsCount({
    userId: user.id as string,
    trainerId: user?.assignedTrainerId as string | undefined,
  });

  const { count: completedCount } = useSessionsCount({
    userId: user.id,
    status: BookingState.COMPLETED,
    field: "status",
    excludeDiscipline: "Nutrition",
  });
  const { count: completedWellnessCount } = useSessionsCount({
    userId: user.id,
    status: BookingState.COMPLETED,
    field: "status",
    discipline: "Nutrition",
  });
  const { count: scheduledCount } = useSessionsCount({
    userId: user.id,
    status: BookingState.SCHEDULED,
    field: "status",
    excludeDiscipline: "Nutrition",
  });
  const { count: scheduledWellnessCount } = useSessionsCount({
    userId: user.id,
    status: BookingState.SCHEDULED,
    field: "status",
    discipline: "Nutrition",
  });

  const { latestTrainingBilling, latestWellnessBilling } = useBillingFirestore(
    user.id!
  );

  useEffect(() => {
    if (user.assignedTrainerId) {
      getClientPackages();
    }
  }, [user.id, scheduledCount]);

  const getClientPackages = useCallback(async () => {
    const existingPackage = await getPackage(
      user.id as string,
      user.assignedTrainerId as string
    );
    const trainingPackage = existingPackage.filter(
      (pkg) => !pkg.servicesAllowed?.includes(PackagesServices.WELLNESS)
    );
    setPackage(trainingPackage[0]);
  }, []);

  const handleUnscheduleSessions = async (value: string) => {
    if (user?.assignedTrainerId) {
      UserController.updatePackage({
        ...clientPackage,
        sessions: parseInt(value, 10),
      } as ClientPackage);
      getClientPackages();
    } else {
      await updateUser({
        id: user?.id,
        sessionsAvailable: parseInt(value, 10),
      });
    }
  };
  const handleWellnessRemainingSessions = async (value: string) => {
    await updateUser({
      id: user?.id,
      nutritionAvailableSessions: parseInt(value, 10),
    });
  };

  const userSessions = clientPackage?.sessions || user.sessionsAvailable || 0;

  return (
    // <div className="two-columns-grid non-edit-input">
    //! What css library is this?
    <div className="two-columns-grid non-edit-input">
      <h3>Training</h3>
      {/* <h3>Wellness</h3> */}
      {/* <TextInput
        label="Latest package acquired"
        value={
          latestTrainingBilling
            ? `${latestTrainingBilling.sessions} sessions`
            : "No active package"
        }
        isDisabled
        appearance="none"
      />
      <TextInput
        label="Latest package acquired"
        value={
          latestWellnessBilling
            ? `${latestWellnessBilling.sessions} sessions`
            : "No active package"
        }
        isDisabled
        appearance="none"
      />
      <TextInput
        label="Last package purchased"
        value={
          latestTrainingBilling
            ? moment(latestTrainingBilling.createdAt.toDate()).format(
                "MMM DD YYYY"
              )
            : "No active package"
        }
        isDisabled
        appearance="none"
      />
      <TextInput
        label="Last package purchased"
        value={
          latestWellnessBilling
            ? moment(latestWellnessBilling.createdAt.toDate()).format(
                "MMM DD YYYY"
              )
            : "No active package"
        }
        isDisabled
        appearance="none"
      />

      <TextInput
        label="Last type of training acquired"
        value={
          // eslint-disable-next-line no-nested-ternary
          latestTrainingBilling
            ? latestTrainingBilling.people
              ? `${latestTrainingBilling.people} on 1`
              : "1 on 1"
            : "No sessions booked"
        }
        isDisabled
        appearance="none"
      />
      <TextInput
        label="Last type of training acquired"
        value={
          // eslint-disable-next-line no-nested-ternary
          latestWellnessBilling
            ? latestWellnessBilling.people
              ? `${latestWellnessBilling.people} on 1`
              : "1 on 1"
            : "No sessions booked"
        }
        isDisabled
        appearance="none"
      /> */}
      <div/>
      <TextInput
        label="Scheduled PT sessions"
        value={scheduledCount}
        isDisabled
        appearance="none"
      />
      <TextInput
        label="Completed PT sessions"
        value={completedCount}
        isDisabled
        appearance="none"
      />
      {/* <TextInput
        label="Completed Wellness sessions"
        value={completedWellnessCount}
        isDisabled
        appearance="none"
      /> */}
      {/* <TextInput
        label="Scheduled PT sessions"
        value={scheduledCount}
        isDisabled
        appearance="none"
      /> */}
      {/* <TextInput
        label="Scheduled Wellness sessions"
        value={scheduledWellnessCount}
        isDisabled
        appearance="none"
      /> */}
      
      <div className="inlineEdit">
        <InlineEdit
          // label="Unscheduled PT sessions"
          label="Universal PT sessions"
          defaultValue={
            user.assignedTrainerId ? trainingSessions : userSessions
          }
          isEditing
          editView={({ errorMessage, ...fieldProps }) => (
            <TextInput type="number" min={0} {...fieldProps} />
          )}
          readView={() => (
            <div>
              {user.assignedTrainerId ? trainingSessions : userSessions}
            </div>
          )}
          onConfirm={handleUnscheduleSessions}
          keepEditViewOpenOnBlur
        />
        <label>This sessions can be use with any trainer</label>
      </div>
      {/* <div className="inlineEdit">
        <InlineEdit
          label="Unscheduled Wellness sessions"
          defaultValue={user.nutritionAvailableSessions || 0}
          isEditing
          editView={({ errorMessage, ...fieldProps }) => (
            <TextInput type="number" min={0} {...fieldProps} />
          )}
          readView={() => <div>{user.nutritionAvailableSessions || 0}</div>}
          onConfirm={handleWellnessRemainingSessions}
          keepEditViewOpenOnBlur
        />
      </div> */}
    </div>
  );
}
