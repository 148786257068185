/* eslint-disable import/no-unresolved */
import { db } from '@controllers/firebase';
import { User } from '@root/models/user';
import defaultValues from '@root/utils/constants/defaultValues';
import collections from '@root/utils/enums/collections.enums';
import { isEqual, sortBy, map } from 'lodash';

export async function checkChatExist(ownerId: string, partnerId: string) {
  const roomName = ownerId < partnerId ? `${ownerId}_${partnerId}` : `${partnerId}_${ownerId}`;
  const chatsRef = db.collection(collections.CHATS);
  const docRef = chatsRef.doc(roomName);
  const data = await docRef.get();
  return data.exists;
}

export async function getChannelIdFromMemberPair(
  userId: string,
  partnerId: string,
): Promise<string | null> {
  try {
    let channelId: string | null = null;

    const snapshot = await db.collection(collections.CHATS).get();
    snapshot.forEach((doc) => {
      const data = doc.data();
      if (isEqual(sortBy(data.membersId), sortBy([userId, partnerId]))) {
        channelId = doc.id;
      }
    });
    return channelId;
  } catch (error) {
    // console.log('===Error getting chat thread info====', error);
    return null;
  }
}

export async function chatRoom(roomName: string, membersInfo: any) {
  const chatsRef = db.collection(collections.CHATS);
  const docRef = chatsRef.doc(roomName);
  await docRef
    .get()
    .then((doc: any) => {
      if (!doc.exists) {
        chatsRef
          .doc(roomName)
          .set({
            latestMessage: {
              //  text: ``,
              createdAt: new Date().getTime(),
            },
            membersId: map(membersInfo, (info) => info.uid),
            membersInfo,
          })
          .then(() => {})
          .catch((error: any) => {
            throw error;
          });
      }
    })
    .catch(() => {
      throw new Error('some internal error occured');
    });
}

export async function createChatRoom(userProfile: User, trainerProfile: User) {
  const {
    id, fullname, email, picture,
  } = trainerProfile;

  const userId = userProfile.id as string;
  const userEmail = userProfile.email;
  const userFullName = userProfile.fullname;
  const userPicture = userProfile.picture
    ? userProfile.picture
    : defaultValues.uriAvatarImage;
  if (userId !== '' && id) {
    const roomName = userId < id ? `${userId}_${id}` : `${id}_${userId}`;
    const memberInfo = [
      {
        uid: userId,
        avatar: userPicture,
        name: userFullName,
        email: userEmail,
        fcmToken: userProfile.fcmDeviceToken || '',
      },
      {
        uid: id,
        avatar: picture,
        name: fullname,
        email,
        fcmToken: trainerProfile.fcmDeviceToken || '',
      },
    ];
    try {
      await chatRoom(roomName, memberInfo);
      return true;
    } catch (error) {
      // console.log('createChatRoom error:', error);
      return false;
    }
  } else {
    return true;
  }
}

export async function sendMessage(messages: any, channelId: string) {
  const { text } = messages[0];
  try {
    await db
      .collection(collections.CHATS)
      .doc(channelId)
      .collection(collections.MESSAGES)
      .add({ ...messages[0], createdAt: new Date().getTime() });
    await db
      .collection(collections.CHATS)
      .doc(channelId)
      .set(
        {
          latestMessage: {
            text,
            createdAt: new Date().getTime(),
          },
        },
        { merge: true },
      );
  } catch (error) {
    console.log('===Error sending message===', error);
  }
}
