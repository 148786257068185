import {
  KeyboardEvent, MutableRefObject, useState, useRef,
} from 'react';

// const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

// const minutes = [0, 30];

const dayTime = ['AM', 'PM'];

const inputStyles = {
  outline: 'none',
  borderWidth: 0,
  backgroundColor: 'transparent',
};

export default function TimeInput({
  origin,
  time,
  onTimeChange,
}: {
  origin: 'start' | 'end';
  time: string;
  onTimeChange: (
    // eslint-disable-next-line no-shadow, no-unused-vars
    time: number | string,
    // eslint-disable-next-line no-shadow, no-unused-vars
    origin: 'start' | 'end',
    // eslint-disable-next-line no-shadow, no-unused-vars
    type: 'hour' | 'minute' | 'dayTime'
  ) => void;
}) {
  const ref = useRef() as MutableRefObject<HTMLInputElement>;
  const times = time.split(':');
  const hour = Number(times[0]);
  const minute = Number(times[1]);
  const dayTimeStr = times[2];
  const inputWidth = '40px';
  const [dayTimeStrLocal, setDayTimeStrLocal] = useState(dayTimeStr);
  const [timeStr, setTimeStr] = useState(time.substring(0, 5));

  const changeFormat = () => {
    const h = timeStr.replace(':', '').match(/.{1,2}/g);
    if (h !== null) {
      const newHour = Number(h[0]);
      const newMinute = Number(h[1]);
      if (newHour >= 12 || newMinute > 59) {
        alert('Invalid time format. please use format hh:mm. Where hh are hours in 12 hour format and mm are minutes 0 to 59');
        setTimeStr(`${hour.toString().padStart(2, '0')}:${minute.toString().padEnd(2, '0')}`);
        return;
      }
      if (typeof newHour === 'number') {
        onTimeChange(
          Number(newHour),
          origin,
          'hour',
        );
      }
      if (typeof newMinute === 'number') {
        onTimeChange(
          Number(newMinute),
          origin,
          'minute',
        );
      }
      setTimeStr(`${newHour.toString().padStart(2, '0')}:${newMinute.toString().padEnd(2, '0')}`);
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      ref.current?.blur();
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}
    >
      <div style={{ width: inputWidth }}>
        <input
          ref={ref}
          value={timeStr}
          onBlur={changeFormat}
          onKeyUp={onKeyUp}
          // type="number"
          // min="0"
          // max="12"
          maxLength={5}
          style={{
            width: inputWidth,
            fontSize: '16px',
            ...inputStyles,
          }}
          onChange={(e) => setTimeStr(e.target.value)}
        />
      </div>
      <div style={{ marginLeft: '5px', width: inputWidth }}>
        <select
          name={dayTimeStr}
          value={dayTimeStrLocal}
          style={{
            ...inputStyles,
          }}
          onChange={(e) => {
            setDayTimeStrLocal(e.target.value);
            onTimeChange(
              e.target.value,
              origin,
              'dayTime',
            );
          }}
        >
          {dayTime.map((e) => (
            <option
              key={e}
              value={e}
            >
              {e}
            </option>
          ))}

        </select>
      </div>
    </div>
  );
}
