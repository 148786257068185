/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Button, { LoadingButton } from '@atlaskit/button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { Chip } from '@material-ui/core';
import { generateId } from '@root/controllers/firebase';
import { UseUsersFirestore } from '@root/controllers/userFirestoreController';
import PreBooking from '@root/models/preBooking';
// eslint-disable-next-line camelcase
import endpointFunctions from '@root/utils/endpoints';
import collections from '@root/utils/enums/collections.enums';
import { getCollection } from '@root/utils/firestoreService';
import functions from '@root/utils/functions';
import axios from 'axios';
// eslint-disable-next-line camelcase
import { OptionType } from '@typeModel/types';
import _ from 'lodash';
import moment from 'moment';
// eslint-disable-next-line no-use-before-define
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import { StyledSelect } from '../select/StyledDataSelect';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';
import { TextInput } from '../textInput';

const SLOT_FORMAT = 'MM/DD/YYYY';
export default function AddPreBooking() {
  const { users, trainers } = UseUsersFirestore();
  const [isOpen, setIsOpen] = useState(false);
  // const [nearTrainers, setNearTrainers] = useState<(Trainer & User)[]>([]);
  const [selectedTrainers, setSelectedTrainers] = useState<OptionType[]>([]);
  const [timeSlots, setTimeSlots] = useState<OptionType[] | null>(null);
  const [address, setAddress] = useState('');

  useEffect(() => {
    const start = moment('5:30', 'hh:mm A');
    const end = moment('21:00', 'hh:mm A');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    const result: OptionType[] = [];

    const current = moment(start);

    while (current <= end) {
      const timeLabel = current.format('hh:mm A');
      const timeValue = parseFloat(current.format('HH.mm'));
      result.push({
        label: timeLabel,
        value: timeValue,
      });
      current.add(15, 'minutes');
    }
    setTimeSlots(result);
  }, []);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
    setSelectedTrainers([]);
  }, []);

  const deleteSelectedTrainer = (index: number) => {
    const options = [...selectedTrainers];
    options.splice(index, 1);
    setSelectedTrainers(options);
  };

  const onSubmit = async (data) => {
    try {
      const { date, client, trainMoment } = data;
      console.log(data);
      const startHour = trainMoment.value;
      const endHour = trainMoment.value + 1;
      const pendingBy = selectedTrainers.map((trainer) => ({
        trainerId: trainer.value as string,
        trainerName: trainer.label as string,
        // trainerName: trainer.displayName || trainer.label,
      }));
      const preBooking: PreBooking = {
        id: generateId(collections.PRE_BOOKINGS),
        userId: client.value,
        clientName: client.label,
        confirmedBy: [],
        pendingBy,
        rejectedBy: [],
        selected: null,
        date: moment(date).format(SLOT_FORMAT),
        slot: {
          startHour,
          endHour,
        },
        address,
        createdAt: new Date(),
      };
      console.log({
        userIds: selectedTrainers.map((item) => item.value),
      });
      await Promise.all([
        getCollection(collections.PRE_BOOKINGS)
          .doc(preBooking.id)
          .set(preBooking),
        axios.post(endpointFunctions.send_notification, {
          userIds: selectedTrainers.map((item) => item.value),
          title: 'New booking',
          message: 'A user has booked a session with you. Check your Fitlov app for further details and confirm the session.',
        }),
      ]);
      toast.success('Pre-booking created successfully');
      closeModal();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
          height: 42,
          alignItems: 'center',
        }}
      >
        (+) Create Pre-booking
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({
                formProps, getState, setFieldValue, submitting,
              }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Creating pre-booking" />
                  <ModalBody>
                    <Field
                      id="date"
                      name="date"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'select date';
                        return '';
                      }}
                    >
                      {({ fieldProps: { onChange, ...rest }, error }) => (
                        <FormFieldInput
                          label="Date"
                          type="date"
                          error={error}
                          fieldProps={{
                            onChange: (
                              e: React.FormEvent<HTMLInputElement>,
                            ) => {
                              const { value } = e.currentTarget;
                              onChange(value as any);
                            },
                            ...rest,
                          }}
                        />
                      )}
                    </Field>
                    <Field
                      id="trainer"
                      name="trainer"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'select trainer';
                        return '';
                      }}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            name="Trainer"
                            validationState={error ? 'error' : 'default'}
                            classNamePrefix="react-select"
                            options={trainers}
                            value={null}
                            isSearchable
                            inputId={id}
                            onChange={(e) => {
                              const existingTrainer = selectedTrainers.find(
                                (selected) => selected.value === e.value,
                              );
                              if (!existingTrainer) {
                                setSelectedTrainers([...selectedTrainers, e]);
                              }
                              onChange(e);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="client"
                      name="client"
                      // defaultValue={formDefaultValue.trainer}
                      isRequired
                      validate={(value) => {
                        if (!value) return 'select user';
                        return '';
                      }}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            name="Client"
                            validationState={error ? 'error' : 'default'}
                            classNamePrefix="react-select"
                            options={users}
                            value={value as any}
                            isSearchable
                            inputId={id}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="trainMoment"
                      name="trainMoment"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'select time';
                        return '';
                      }}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            inputId={id}
                            name="Time"
                            validationState={error ? 'error' : 'default'}
                            options={timeSlots as OptionType[]}
                            value={value as any}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <div style={{ marginTop: 10 }} />
                    <TextInput
                      label="Address"
                      onChange={(e) => {
                        setAddress(e.currentTarget.value);
                      }}
                    />

                    <br />
                    {selectedTrainers.map((trainerOption, index) => (
                      <Chip
                        style={{ marginLeft: 2, marginBottom: 2 }}
                        label={trainerOption.displayName || trainerOption.label}
                        onDelete={() => deleteSelectedTrainer(index)}
                      />
                    ))}
                  </ModalBody>
                  <ModalFooter>
                    <LoadingButton
                      type="submit"
                      isLoading={submitting}
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </LoadingButton>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
