/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import routes from '@utils/enums/routes.enums';
import { useAuthState } from '@contexts/auth.context';
import AuthController from '@controllers/authController';

// eslint-disable-next-line react/prop-types
function PublicRoute({ component: Component, restricted, ...rest }) {
  const authInfo = useAuthState();
  const history = useHistory();

  const { isAuthenticated, userDataPresent, isAdmin } = authInfo;
  useEffect(() => {
    if (isAuthenticated === true && !isAdmin) {
      alert('You are not Admin!!!');
      AuthController.signOut();

      history.replace(routes.LOGIN);
    }
    return () => {};
  }, [isAuthenticated, isAdmin]);
  if (!userDataPresent) return null;
  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated && isAdmin && restricted ? (
        <Redirect to={`${routes.HOME}`} />
      ) : (
        <Component {...props} />
      ))}
    />
  );
}
export default PublicRoute;
