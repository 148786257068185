/* eslint-disable import/no-unresolved */
import 'firebase/firestore';
import _ from 'lodash';
import { db as firebaseDB, firebaseApp } from '@controllers/firebase';

export function getCollection(collectionName: string) {
  return firebaseDB.collection(collectionName);
}

export default class FirestoreService {
  static async fetchAll(collectionName: string) {
    const snapshot = await getCollection(collectionName).get();
    return snapshot.docs.map((doc) => doc.data());
  }

  static async fetchAllPairWithId(collectionName: string) {
    const snapshot = await getCollection(collectionName).get();
    return snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
  }

  static async fetchLimit(collectionName: string, maxDocs: number) {
    const snapshot = await getCollection(collectionName).limit(maxDocs).get();
    return snapshot.docs.map((doc) => doc.data());
  }

  static async fetchByWhereOrderBy({
    collectionName,
    orderBy,
    maxDocs,
    field,
    where,
    value,
    sort,
  }: {
    collectionName: string;
    orderBy: string;
    maxDocs: number;
    field: string;
    where:
    | '<'
    | '<='
    | '=='
    | '!='
    | '>'
    | '>='
    | 'in'
    | 'not in'
    | 'array-contains'
    | 'array-contains-any';
    value: number | string;
    sort: 'asc' | 'desc';
  }) {
    const collection = getCollection(collectionName);
    const snapshot = await collection
      .where(field, where as any, value)
      .orderBy(orderBy, sort)
      .limit(maxDocs)
      .get();
    return snapshot.docs.map((doc) => doc.data());
  }

  static async fetchDoc(collectionName: string, docId: string) {
    const collection = getCollection(collectionName).doc(docId);
    return collection.get();
  }

  static fetchDocRef(collectionName: string, docId: string) {
    return getCollection(collectionName).doc(docId);
  }

  static async fetchByField(
    collectionName: string,
    field: string,
    id: number | string,
  ) {
    const collection = getCollection(collectionName);
    const snapshot = await collection.where(field, '==', id).get();

    return snapshot.docs.map((doc) => doc.data());
  }

  static async fetchOneFiledById(
    collectionName: string,
    field: string,
    docId: string,
  ) {
    const collection = getCollection(collectionName).doc(docId);
    const data = await (await collection.get()).data();
    return data ? data[field] : null;
  }

  static async fetchByWhere(
    collectionName: string,
    field: string,
    where:
      | '<'
      | '<='
      | '=='
      | '!='
      | '>'
      | '>='
      | 'in'
      | 'not in'
      | 'array-contains'
      | 'array-contains-any',
    value: number | string,
  ) {
    const collection = getCollection(collectionName);
    const snapshot = await collection.where(field, where as any, value).get();

    return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  }

  static async fetchIDPairDataByWhere(
    collectionName: string,
    field: string,
    where:
      | '<'
      | '<='
      | '=='
      | '!='
      | '>'
      | '>='
      | 'in'
      | 'not in'
      | 'array-contains'
      | 'array-contains-any',
    value: number | string,
  ) {
    const collection = getCollection(collectionName);
    const snapshot = await collection.where(field, where as any, value).get();
    return snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
  }

  static async addObjectToCollection(collectionName: string, objectToAdd: any) {
    const collection = getCollection(collectionName);
    return collection.add(objectToAdd);
  }

  static async deleteObjectsOnCollection({ collectionName, value, field }: {
    collectionName: string;
    value: string;
    field: string;
  }) {
    const collection = getCollection(collectionName);
    try {
      const data = await collection
        .where(field, '==', value)
        .get();
      await Promise.all(_.map(data.docs, async (doc) => doc.ref.delete()));
      return true;
    } catch (error) {
      return false;
    }
  }

  static async updateObjet(collectionName: string, objectToUpdate: any) {
    const collection = getCollection(collectionName);
    collection.doc(objectToUpdate.id).update(objectToUpdate);
  }

  static getTimestamp(): Object {
    return firebaseApp.firestore.FieldValue.serverTimestamp();
  }

  static generateTimestamp(date): Object {
    return firebaseApp.firestore.Timestamp.fromDate(date);
  }
}
