import {
  Switch,
} from 'react-router-dom';
import Transactions from '@root/pages/transactions';
import Offers from '@root/pages/offers/offers';
import DuplicateBookings from '@root/pages/duplicateBookings';
import Gyms from '@root/pages/gyms/gyms';
import Banners from '@root/pages/banners';
import Disciplines from '@root/pages/disciplines';
import TrainerPackages from '@root/pages/trainerPackages';
import Home from '@root/pages/home/Home';
import TrainerProfile from '@root/pages/trainer/trainerProfile/trainerProfile';
import Trainer from '@root/pages/trainer/Trainer';
import UserInformation from '@root/pages/user/userInformation';
import PromoCodes from '@root/pages/promoCodes/promoCodes';
import Rating from '@root/pages/rating/rating';
import MonthlyBalance from '@root/pages/monthlyBalance/monthlyBalance';
import Packages from '@root/pages/packages/packages';
import Coverage from '@root/pages/coverage/coverage';
import Renewals from '@root/pages/renewals/renewals';
import Cities from '@root/pages/cities';
import Regions from '@root/pages/cities/regions';
import SessionLogs from '@root/pages/sessionLogs/sessionLogs';
import TraineChanges from '@root/pages/trainerChanges/trainerChanges';
import PreBookings from '@root/pages/preBookings/preBookings';
import TrainerPerformance from '@root/pages/trainerPerformance/trainerPerformance';
import routes from '../../utils/enums/routes.enums';
import Login from '../../pages/main/login';
import LoginOtp from '../../pages/main/loginOtp';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export default function Switchs() {
  return (
    <Switch>
      <PublicRoute
        exact
        restricted
        path="/"
        component={Login}
      />
      <PublicRoute
        exact
        restricted
        path={routes.LOGIN}
        component={Login}
      />
      <PublicRoute
        restricted
        path="/confirm"
        component={LoginOtp}
      />

      <PrivateRoute path={routes.HOME} component={Home} />
      <PrivateRoute path={routes.TRAINER} component={Trainer} />
      <PrivateRoute
        path={`${routes.TRAINERPROFILE}/:id`}
        component={TrainerProfile}
      />
      <PrivateRoute
        path={`${routes.USERPROFILE}/:id`}
        component={UserInformation}
      />
      {/* <Route path={routes.USERPROFILE} component={UserInformation}></Route> */}
      <PrivateRoute
        path={routes.DISCIPLINES}
        component={Disciplines}
      />
      <PrivateRoute
        path={routes.BANNERS}
        component={Banners}
      />
      <PrivateRoute
        path={routes.OFFERS}
        component={Offers}
      />
      <PrivateRoute
        path={routes.GYMS}
        component={Gyms}
      />
      <PrivateRoute
        path={routes.PROMOCODES}
        component={PromoCodes}
      />
      <PrivateRoute path={routes.RATINGS} component={Rating} />
      <PrivateRoute
        path={routes.MONTHLYBALANCE}
        component={MonthlyBalance}
      />
      <PrivateRoute
        path={routes.TRAINERCHANGES}
        component={TraineChanges}
      />
      <PrivateRoute
        path={routes.TRAINERPERFORMANCE}
        component={TrainerPerformance}
      />
      <PrivateRoute path={routes.PACKAGES} component={Packages} />
      <PrivateRoute path={routes.COVERAGE} component={Coverage} />
      <PrivateRoute path={routes.RENEWAL} component={Renewals} />
      <PrivateRoute
        path={routes.SESSIONLOGS}
        component={SessionLogs}
      />
      <PrivateRoute
        path={routes.TRANSACTIONS}
        component={Transactions}
      />
      <PrivateRoute
        path={routes.DUPLICATEBOOKINGS}
        component={DuplicateBookings}
      />
      <PrivateRoute
        path={routes.CITIES}
        component={Cities}
        exact
      />
      <PrivateRoute
        path={`${routes.CITIES}/:id`}
        component={Regions}
        exact
      />
      <PrivateRoute
        path={routes.TRAINERPACKAGES}
        component={TrainerPackages}
      />
      <PrivateRoute
        path={routes.PREBOOKINGS}
        component={PreBookings}
      />
    </Switch>
  );
}
