/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useCallback, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { OptionType } from '@atlaskit/select';
import { createReview } from '@controllers/reviewsFirestoreController';
import { UseUsersFirestore } from '@controllers/userFirestoreController';
import { Review } from '@models/review';
import StyledLabel from '@root/utils/styles/StyledLabel';
import StyledSearch from '@root/utils/styles/StyledSearch';
import FirestoreService from '@utils/firestoreService';
import { TextAreaInput } from '../textInput';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';

export default function AddRatings() {
  const [isOpen, setIsOpen] = useState(false);
  const [isActualClient, setIsActualClient] = useState<OptionType | null>(null);
  const { users, trainers } = UseUsersFirestore();
  const [clientErrorMsg, setClientErrorMsg] = useState<string | null>(null);
  const [trainerErrorMsg, setTrainerErrorMsg] = useState<string | null>(null);

  const [selectedClient, setSelectedClient] = useState<OptionType | null>(null);

  const [selectedTrainer, setSelectedTrainer] = useState<OptionType | null>(null);
  const [reviewCharCount, setReviewCharCount] = useState<number>(0);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const selectUser = (user: OptionType, isClear?: boolean) => {
    if (user.roles.includes('user')) {
      if (isClear) {
        setSelectedClient(null);
      } else {
        setSelectedClient(user);
      }
    } else if (isClear) {
      setSelectedTrainer(null);
    } else {
      setSelectedTrainer(user);
    }
  };

  const onSubmit = (data) => {
    const {
      friendliness, puncuality, quality, review, name,
    } = data;
    setClientErrorMsg(null);
    setTrainerErrorMsg(null);

    if (isActualClient?.value) {
      if (!selectedClient) {
        setClientErrorMsg('Client is required');
        return;
      }
    } else if (name === '') {
      setClientErrorMsg('Name is required');
      return;
    }

    if (!selectedTrainer) {
      setTrainerErrorMsg('Trainer is required');
      return;
    }
    const newReview: Review = {
      trainerId: selectedTrainer?.value as string,
      userId: isActualClient?.value ? selectedClient?.value as string
        : selectedTrainer?.value as string,
      /*
        I'm sending the client fullname because if we have a lot of comments,
        is a bad idea to send the client id, because it will be a lot of
        requests to the database.
      */
      reviewerName: isActualClient?.value ? selectedClient?.label : name,
      reviewedBy: 'Admin',
      createdAt: data.date ? FirestoreService.generateTimestamp(new Date(data.date))
        : FirestoreService.getTimestamp(),
      friendlinessRate: parseFloat(friendliness),
      punctualityRate: parseFloat(puncuality),
      qualityRate: parseFloat(quality),
      comment: review || null,
      isApproved: false,
    };
    createReview(newReview);
    closeModal();
  };

  const maxReviewLength = 400;
  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          marginLeft: 16,
          backgroundColor: '#3FC7E0',
          marginRight: 20,
        }}
      >
        (+) Add New Rating
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form
              onSubmit={onSubmit}
            >
              {({ formProps }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New Rating" />
                  <ModalBody>
                    <Field id="date" name="date" isRequired>
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Date"
                          type="date"
                          fieldProps={fieldProps}
                        />
                      )}
                    </Field>
                    <Field
                      id="isActualClient"
                      name="isActualClient"
                    >
                      {() => (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <StyledLabel label="Actual Client" width="90%" />
                          <StyledSearch
                            data={[{ label: 'Yes', value: true },
                              { label: 'No', value: false }]}
                            placeholder="Select Client"
                            selectUser={setIsActualClient}
                            value={isActualClient?.value
                              ? { label: 'Yes', value: true }
                              : { label: 'No', value: false }}
                          />
                        </div>
                      )}
                    </Field>
                    <Field
                      id="client"
                      name="client"
                    >
                      {() => (
                        <>
                          <div
                            style={{
                              display: isActualClient?.value ? 'flex' : 'inline',
                              flex: isActualClient?.value ? 1 : 0,
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            {isActualClient?.value ? (
                              <>
                                <StyledLabel label="Client" width="90%" />
                                <StyledSearch
                                  data={users}
                                  placeholder="Select Client"
                                  selectUser={selectUser}
                                  isClearable
                                />
                              </>
                            ) : (
                              <Field id="name" name="name" isRequired>
                                {({ fieldProps }) => (
                                  <FormFieldInput
                                    label="Name"
                                    type="text"
                                    fieldProps={fieldProps}
                                  />
                                )}
                              </Field>
                            )}
                          </div>
                          {clientErrorMsg && <ErrorMessage>{clientErrorMsg}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="trainer"
                      name="trainer"
                    >
                      {() => (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <StyledLabel label="Trainer" width="90%" />
                            <StyledSearch
                              data={trainers}
                              placeholder="Select Trainer"
                              selectUser={selectUser}
                              isClearable
                            // {...fieldProps}
                            />
                          </div>
                          {trainerErrorMsg && <ErrorMessage>{trainerErrorMsg}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field id="quality" name="quality" isRequired>
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Quality"
                          type="number"
                          fieldProps={fieldProps}
                          min={0}
                          max={5}
                          step={0.01}
                        />
                      )}
                    </Field>
                    <Field id="puncuality" name="puncuality" isRequired>
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Punctuality"
                          type="number"
                          fieldProps={fieldProps}
                          min={0}
                          max={5}
                          step={0.1}
                        />
                      )}
                    </Field>
                    <Field id="friendliness" name="friendliness" isRequired>
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Friendliness"
                          type="number"
                          fieldProps={fieldProps}
                          min={0}
                          max={5}
                          step={0.1}
                        />
                      )}
                    </Field>
                    <Field id="review" name="review">
                      {({ fieldProps }) => (
                        <TextAreaInput
                          onKeyUp={(value) => {
                            setReviewCharCount(value.target.value.length);
                          }}
                          label="Review:"
                          {...fieldProps}
                          maxLength={maxReviewLength}
                        />
                      )}
                    </Field>
                    <div>{`remaining ${reviewCharCount} / ${maxReviewLength}`}</div>
                  </ModalBody>
                  <ModalFooter>
                    <Button appearance="subtle" onClick={closeModal}>
                      Close
                    </Button>
                    <Button appearance="primary" type="submit">
                      Create
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
