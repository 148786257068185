/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useState } from 'react';
import { StyledSelect } from '@components/select/StyledDataSelect';
import { useGlobalsContext } from '@contexts/global.context';
import { LatLng, TrainingAddress } from '@models/address';
import { Trainer } from '@models/trainer';
import { User } from '@models/user';
import StyledLabelTitle from '@root/utils/styles/StyledLabelTitle';
import StyledSearch from '@root/utils/styles/StyledSearch';
import PlacesAutoComplete from '@root/components/placesAutocomplete';
import { TextInput, TextAreaInput } from '@root/components/textInput';

import { OptionType } from '@typeModel/types';
import { getTrainer } from '@controllers/trainerFirestoreController';
import {
  Genderpreference,
  UserStatusOptions,
} from '@utils/common/data';
import { validateEmail, getUserStatus } from '@utils/functions';
import dotenv from 'dotenv';
import * as geolib from 'geolib';
import _ from 'lodash';
import { useTrainer } from '@root/controllers/trainerFirestoreController';
import FirestoreService from '@root/utils/firestoreService';
import { ClientPackage, PackagesCodes, PackagesServices } from '@root/models/clientPackage';
import Toggle from '@atlaskit/toggle';
import moment from 'moment';
import UserStatusEnum from '@root/utils/enums/userStatus.enums';
import { FitlovPoints } from '@root/models/fitlovPoints';
import { addFitlovPoints, updateFitlovPoints } from '@root/controllers/fitlovPointsController';

import { db } from '@root/controllers/firebase';
import collections from '@root/utils/enums/collections.enums';
import {
  getPackage,
  updatePackage,
  updateUser,
  addPackage,
  deleteFieldFromUser,
} from '../../controllers/userFirestoreController';

dotenv.config();
export default function UserProfileTop({ user }: { user: Trainer & User }) {
  const [userStatus, setUserStatus] = useState<OptionType | undefined>(undefined);
  const { disciplines, cityRegions } = useGlobalsContext();
  const { items: verifiedTrainers } = useTrainer();
  const [trainerAssigned, setTrainerAssigned] = useState<{
    label: string;
    value: string;
  }>();
  const [userFitlovPoint, setUserFitlovPoint] = useState<FitlovPoints>();

  useEffect(() => {
    if (user?.id) {
      db.collection(collections.FITLOV_POINTS).doc(user?.id).onSnapshot((snapshot) => {
        if (snapshot.exists) {
          setUserFitlovPoint(snapshot.data() as FitlovPoints);
        }
      });
    }
  }, [user?.id]);

  useEffect(() => {
    if (!user.region && user.addresses?.training.length) {
      const location = user.addresses?.training[0].coordinate;
      const address = user.addresses?.training[0].location as string;
      if (location && address) {
        onUpdateLocation({
          coordinate: {
            latitude: location?.latitude as number,
            longitude: location?.longitude as number,
          },
          address,
        });
      }
    }
    statusValue();
  }, [user]);

  const disciplineOptions = disciplines.map((discipline) => ({
    label: discipline?.trainerType,
    value: discipline?.trainerType,
  }));
  const trainerOptions = verifiedTrainers.map((trainer) => ({
    label: trainer.displayName || trainer.fullname,
    value: trainer.id,
  })) as { label: string; value: string }[];

  const disciplineValue: OptionType[] = useMemo(
    () => (user?.preferencesDisciplines || []).map((discipline) => ({
      label: discipline,
      value: discipline,
    })),
    [user?.preferencesDisciplines],
  );
  useEffect(() => {
    if (user.assignedTrainerId) {
      getTrainer(user.assignedTrainerId).then((trainer) => {
        setTrainerAssigned({
          label: trainer.displayName || trainer.fullname,
          value: trainer.id,
        });
      });
    }
  }, [user.assignedTrainerId]);

  const onUpdateLocation = ({
    coordinate,
    address,
  }: {
    coordinate: LatLng;
    address: string;
  }) => {
    try {
      const matchedRegion = _.findLast(cityRegions, (region) => {
        const matched = geolib.isPointInPolygon(coordinate, region.coordinates);
        return matched;
      });
      if (matchedRegion) {
        let { addresses } = user;
        if (addresses?.training && addresses?.training.length > 0) {
        addresses!.training[0].location = address;
        addresses!.training[0].coordinate = coordinate;
        } else {
          addresses = {
            training: [
              {
                id: new Date().getTime().toString(),
                location: address,
                coordinate,
                aptHouseNumber: '',
                title: '',
                default: true,
              },
            ],
          };
        }

        updateUser({
          id: user.id,
          addresses,
          city: matchedRegion.city,
          region: matchedRegion.region,
        });
      }
    } catch (error) { console.log(error); }
  };
  const genderValue = _.findLast(
    Genderpreference,
    (item) => (user?.gender || '').toLowerCase()
      === (item.value as string).toLowerCase(),
  );

  const statusValue = async () => {
    const status = user.assignedTrainerId
      ? UserStatusEnum.PT_CLIENT
      : await getUserStatus(user.id as string);
    setUserStatus(UserStatusOptions.find((e) => e.value === (status || '')) as OptionType);
  };

  const onAssignTrainer = async (item: any) => {
    if (item?.value) {
      setTrainerAssigned(item);
      if (!user.assignedTrainerId) {
        const updatedUser: any = {
          id: user.id,
          assignedTrainerId: item?.value,
        };
        const clientPackage: any = {
          userId: user.id as string,
          trainerId: item.value as string,
          code: PackagesCodes.PT_CLIENT,
          createdAt: FirestoreService.getTimestamp() as any,
        };
        if (user?.sessionsAvailable as number > 0) {
          clientPackage.sessions = Number(user.sessionsAvailable);
          clientPackage.servicesAllowed = [PackagesServices.TRAINING];
          const packageAdded = await addPackage(clientPackage);
          if (packageAdded.id) {
            deleteFieldFromUser({ id: user.id as string, fieldToDelete: 'sessionsAvailable' });
          }
        }
        if (user?.nutritionAvailableSessions as number > 0) {
          clientPackage.sessions = Number(user.nutritionAvailableSessions);
          clientPackage.servicesAllowed = [PackagesServices.WELLNESS];
          const packageAdded = await addPackage(clientPackage);
          if (packageAdded.id) {
            deleteFieldFromUser({ id: user.id as string, fieldToDelete: 'nutritionAvailableSessions' });
          }
        }
        updateUser(updatedUser);
        alert('Converted to PT Client. Please reload the page to see the balance');

        return;
      }
      const previousPackage = await getPackage(
        user.id as string,
        user.assignedTrainerId as string,
      );

      previousPackage.forEach((clientPackage: ClientPackage) => {
        clientPackage.updatedAt = FirestoreService.getTimestamp() as any;
        clientPackage.trainerId = item.value;
        updatePackage(clientPackage);
      });
      await updateUser({
        id: user.id,
        assignedTrainerId: item?.value,
      });
    } else {
      setTrainerAssigned({ label: '', value: '' });
      updateUser({
        id: user.id,
        assignedTrainerId: '',
      });
      alert('Trainer UnAssigined. Please reload the page for updates to take effect ;)');
    }
  };

  const onChangeStatus = async (item: any) => {
    if (item?.value === UserStatusEnum.active) {
      const { assignedTrainerId } = user;
      if (assignedTrainerId) {
        const previousPackage = await getPackage(
          user.id as string,
          assignedTrainerId as string,
        );
        let sumSessions = 0;
        let sumWellnessSessions = 0;
        previousPackage.forEach((clientPackage: ClientPackage) => {
          if (clientPackage?.servicesAllowed && clientPackage.servicesAllowed.length && clientPackage.servicesAllowed.includes(PackagesServices.WELLNESS)) {
            sumWellnessSessions += clientPackage.sessions;
          } else {
            sumSessions += Number(clientPackage.sessions);
            clientPackage.sessions = 0;
          }
          updatePackage(clientPackage);
        });
        updateUser({
          id: user.id,
          status: item.value,
          sessionsAvailable: (user.sessionsAvailable ?? 0) + sumSessions,
          nutritionAvailableSessions: (user.nutritionAvailableSessions ?? 0) + sumWellnessSessions,
          assignedTrainerId: null,
        });
      }
    } else {
      updateUser({
        id: user.id,
        status: item.value,
      });
    }
    statusValue();
  };

  const handleFitlovPoints = async (value: number) => {
    if (userFitlovPoint) {
      if (value !== userFitlovPoint.total) {
        return updateFitlovPoints(userFitlovPoint, value);
      }
      return null;
    }
    return addFitlovPoints({ userId: user?.id as string, total: Number(value) });
  };

  const createdAt = user.createdAt as any;
  const registrationDateWithTime = user?.createdAt ? moment(createdAt.toDate()).format('DD MMM YYYY  hh:mm A') : 'N/A';

  return (
    user && (
      <>
        <div className="two-columns-grid">
          <TextInput
            label="Name"
            defaultValue={user.fullname}
            onBlur={(e: React.FormEvent<HTMLInputElement>) => {
              updateUser({
                id: user.id,
                fullname: e.currentTarget.value,
              });
            }}
          />
          <StyledSelect
            name="Gender"
            value={genderValue as OptionType}
            options={Genderpreference}
            onChange={(option) => {
              updateUser({
                id: user.id,
                gender: option.value.toLowerCase(),
              });
            }}
          />
          <TextInput
            isDisabled
            label="Area Code"
            value={user.mobile?.areaCode}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const { value } = e.currentTarget;
              if (value) {
                updateUser({
                  id: user.id,
                  mobile: {
                    ...user.mobile!,
                    areaCode: value,
                  },
                });
              }
            }}
          />
          <TextInput
            isDisabled
            label="Phone Number"
            value={user.mobile?.number}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const { value } = e.currentTarget;
              if (value) {
                updateUser({
                  id: user.id,
                  mobile: {
                    ...user.mobile!,
                    number: value,
                  },
                });
              }
            }}
          />
          <TextInput
            label="Email"
            placeholder={user?.email || 'Email'}
            defaultValue={user?.email}
            onBlur={(e: React.FormEvent<HTMLInputElement>) => {
              const { value } = e.currentTarget;
              if (validateEmail(value) || value === '') {
                updateUser({
                  id: user.id,
                  email: value,
                });
              }
            }}
          />
          <TextInput
            label="City"
            placeholder={user.city}
            value={user.city}
            isDisabled
          />

          <TextInput
            label="Region (Address 1)"
            placeholder="Region"
            value={user?.region}
            isDisabled
          />
          <StyledSelect
            name="Status"
            value={userStatus}
            options={UserStatusOptions}
            onChange={onChangeStatus}
          />
          <div className="inputWrapper">
            <div className="label">Eligible for Free Session?</div>
            <div style={{ borderRadius: 3 }}>
              <Toggle
                id="toggle-active"
                size="large"
                isChecked={user.freeSessionEligible}
                onChange={() => {
                  updateUser({
                    id: user.id,
                    freeSessionEligible: !user.freeSessionEligible,
                  });
                }}
              />
            </div>
          </div>
          <div className="inputWrapper">
            <div className="label">Override user status</div>
            <div style={{ borderRadius: 3 }}>
              <Toggle
                id="toggle-active"
                size="large"
                isChecked={user.statusOverride}
                onChange={() => {
                  updateUser({
                    id: user.id,
                    statusOverride: !user.statusOverride,
                  });
                }}
              />
            </div>
          </div>
          <TextInput
            isDisabled
            label="Registration Date"
            value={registrationDateWithTime}
          />
          <TextInput
            label="Fitlov Points"
            placeholder="0"
            defaultValue={userFitlovPoint?.total}
            // eslint-disable-next-line consistent-return
            onBlur={(e: React.FormEvent<HTMLInputElement>) => {
              const value = Number(e.currentTarget.value);
              if (value > 0) {
                handleFitlovPoints(value);
              }
            }}
          />
        </div>
        <div
          className="full-width-grid"
          style={{ marginTop: '1.2em', marginBottom: '1.2em' }}
        >
          <StyledSelect
            isMulti
            name="Preferred Disciplines"
            value={disciplineValue}
            options={disciplineOptions}
            onChange={(value) => {
              const updatedDisciplines: string[] = value.map(
                (item) => item?.value,
              ) as string[];
              updateUser({
                id: user.id,
                preferencesDisciplines: updatedDisciplines,
              });
            }}
          />
        </div>
        <div
          className="full-width-grid"
          style={{ marginTop: '1.2em', marginBottom: '1.2em' }}
        >
          <div className="inputWrapper">
            <div className="label" style={{}}>
              PT Client
            </div>
            <div className="input" />
            <StyledSearch
              value={trainerAssigned}
              data={trainerOptions}
              placeholder="PT Client"
              selectUser={(val, isClear) => (isClear ? onAssignTrainer(null) : onAssignTrainer(val ?? ''))}
              isClearable
            />
          </div>
        </div>

        <TextAreaInput
          label="Comment"
          defaultValue={user.comment}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            updateUser({
              id: user.id,
              comment: e.currentTarget.value,
            });
          }}
          maxLength="400"
        />

        <StyledLabelTitle
          title="User address"
          style={{ marginTop: '1.2em', marginBottom: '1.2em' }}
        />
        <div className="two-columns-grid">
          <PlacesAutoComplete
            latitude={user.addresses?.training[0]?.coordinate.latitude ?? 0}
            longitude={user.addresses?.training[0]?.coordinate.longitude ?? 0}
            label="Pin Location"
            defaultValue={user?.addresses?.training[0]?.location || ''}
            onPlaceSelected={(place) => {
              const {
                geometry: { location },
                // eslint-disable-next-line camelcase
                formatted_address,
              } = place;
              onUpdateLocation({
                coordinate: {
                  latitude: location.lat(),
                  longitude: location.lng(),
                },
                address: formatted_address,
              });
            }}
          />
          <TextInput
            label="Building / street name"
            placeholder={user.addresses?.training[0]?.customAddress ?? ''}
            defaultValue={user.addresses?.training[0]?.customAddress ?? ''}
            onBlur={(e: React.FormEvent<HTMLInputElement>) => {
              const { value } = e.currentTarget;
              const newAddress = user.addresses ?? [{
                customAddress: '', aptHouseNumber: '', coordinate: { latitude: 0, longitude: 0 },
              }] as TrainingAddress[];
              newAddress.training[0].customAddress = value;
              updateUser({
                id: user.id,
                addresses: newAddress,
              });
            }}
          />
          <TextInput
            label="Apt/House Number"
            placeholder={user.addresses?.training[0]?.aptHouseNumber ?? ''}
            defaultValue={user.addresses?.training[0]?.aptHouseNumber ?? ''}
            onBlur={(e: React.FormEvent<HTMLInputElement>) => {
              const { value } = e.currentTarget;
              const newApt = user.addresses ?? [{
                customAddress: '', aptHouseNumber: '', coordinate: { latitude: 0, longitude: 0 },
              }] as TrainingAddress[];
              newApt.training[0].aptHouseNumber = value;
              updateUser({
                id: user.id,
                addresses: newApt,
              });
            }}
          />
        </div>
      </>
    )
  );
}
