// eslint-disable-next-line no-use-before-define
import React from 'react';
import Toggle from '@atlaskit/toggle';
import { useGlobalsContext } from '@contexts/global.context';
import { updateTrainer } from '@controllers/trainerFirestoreController';
import { Trainer } from '@models/trainer';
import { User } from '@models/user';
import { DateInput, TextInput } from '@root/components/textInput';
import { OptionsType, OptionType } from '@typeModel/types';
import { validateEmail } from '@utils/functions';
import _, { findLast } from 'lodash';
import moment from 'moment';
import { StyledSelect } from '../../../components/select/StyledDataSelect';

const YesNo: OptionsType = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
export default function TrainerProfileBottom({
  trainer,
}: {
  trainer: Trainer & User;
}) {
  const { birthDate } = trainer;
  const { languages } = useGlobalsContext();
  const birdDayDate = birthDate
    ? moment(
      `${birthDate?.year}-${parseInt(birthDate?.month, 10) || 1}-${birthDate?.day
      }`,
    ).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  // the trainer profile was crashing when the month was 0, so if month is 0 converting it to Jan.

  const languageValues = (_.map(trainer?.languages || [], (item: OptionType) => {
    const exist = _.findLast(languages, (language) => (
      (item.value as string).toLowerCase()
      === (language.value as string).toLowerCase()
    ));
    return exist;
  }) as OptionType[]) || [];

  const driveLicence = findLast(YesNo, (item) => (trainer?.haveCarDrivingLicense || '') === (item.value as string));
  return (
    <>
      <div className="two-columns-grid" style={{ marginTop: 40 }}>
        <TextInput
          label="Display Name"
          defaultValue={trainer?.displayName}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            updateTrainer({
              id: trainer.id,
              displayName: e.currentTarget.value,
            });
          }}
        />
        <TextInput
          label="Instagram"
          defaultValue={trainer.instagram}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            updateTrainer({
              id: trainer.id,
              instagram: e.currentTarget.value,
            });
          }}
        />
        <TextInput
          label="Phone Number"
          value={
            trainer?.mobile
              ? `${trainer?.mobile?.areaCode}${trainer?.mobile?.number}`
              : ''
          }
          isDisabled
        />
        <TextInput
          label="Full Name"
          defaultValue={trainer?.fullname}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            updateTrainer({
              id: trainer.id,
              fullname: e.currentTarget.value,
            });
          }}
        />
        <TextInput
          label="Email"
          defaultValue={trainer?.email}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            if (validateEmail(e.currentTarget.value) || e.currentTarget.value === '') {
              updateTrainer({
                id: trainer.id,
                email: e.currentTarget.value,
              });
            }
          }}
        />
        <DateInput
          label="Birth Date"
          value={birdDayDate}
          dateFormat="DD MMM YYYY"
          onChange={(e: string) => {
            if (e.length > 0) {
              updateTrainer({
                id: trainer.id,
                birthDate: {
                  day: parseInt(moment(e).format('DD'), 10),
                  month: moment(e).format('MM'),
                  year: parseInt(moment(e).format('YYYY'), 10),
                },
              });
            }
          }}
        />
        <StyledSelect
          name="Driving Licence"
          value={driveLicence as OptionType}
          options={YesNo}
        />
        <div className="inputWrapper">
          <div className="label">Eligible for Free Session?</div>
          <div style={{ borderRadius: 3 }}>
            <Toggle
              id="toggle-active"
              size="large"
              isChecked={trainer.isOfferFreeSessions}
              onChange={() => {
                updateTrainer({
                  id: trainer.id,
                  isOfferFreeSessions: !trainer.isOfferFreeSessions,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="full-width-grid" style={{ marginTop: '1.2em' }}>
        <StyledSelect
          name="Languages"
          isMulti
          isSearchable
          value={languageValues}
          options={languages}
          onChange={(value) => {
            updateTrainer({
              id: trainer.id,
              languages: value as { label: string; value: string }[],
            });
          }}
        />
      </div>
    </>
  );
}
