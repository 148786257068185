import Textfield, { TextFieldProps } from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import { DatePicker } from '@atlaskit/datetime-picker';
import { CSSProperties } from 'react';

type TextInputProps = {
  label?: string;
  labelColor?: string;
  [x: string]: any;
  labelWidth?: number;
} & Partial<TextFieldProps>;

export function TextInput({
  label, labelColor, labelWidth, ...rest
}: TextInputProps) {
  return (
    <div className="inputWrapper">
      {label && (
        <div
          className="label"
          style={{ ...labelColor ? { backgroundColor: labelColor, color: 'white' } : {}, width: labelWidth }}
        >
          {label}
        </div>
      )}
      <div className="input">
        <Textfield {...rest} />
      </div>
    </div>
  );
}
export function TextAreaInput({
  label,
  value,
  ...rest
}: {
  label?: string;
  value?: string | undefined;
  [x: string]: any;
}) {
  return (
    <div className="textAreaWrapper">
      {label && <div className="">{label}</div>}
      <div className="input">
        <TextArea
          resize="auto"
          minimumRows={5}
          maxHeight="10vh"
          defaultValue={value}
          {...rest}
        />
      </div>
    </div>
  );
}
export function DateInput({
  label,
  value,
  divStyle,
  ...rest
}: {
  label?: string;
  value: string | undefined;
  divStyle?:CSSProperties;
  [x: string]: any;
}) {
  return (
    <div className="date-input-wrapper" style={divStyle}>
      {label && <div className="label">{label}</div>}
      <div>
        <DatePicker value={value} {...rest} />
      </div>
    </div>
  );
}
