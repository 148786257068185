import { useEffect, useState, useCallback } from 'react';
import dotenv from 'dotenv';
import StyledLabelTitle from '@root/utils/styles/StyledLabelTitle';
import Table from '@root/components/table/table';
import { MyClientsHead } from '@root/components/table/content/Head';
import { MyClientsRows } from '@root/components/table/content/rows';

dotenv.config();
const API_URL = process.env.REACT_APP_FIREBASE_FUNCTION_URL;

export default function MyClients({ trainerId }: {trainerId: string;}) {
  const [myClients, setMyClients] = useState([]);

  const fetchMyClients = useCallback(async () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      trainerId,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    const response = await fetch(`${API_URL}/getMyClients`, requestOptions as any);
    const data = await response.json();
    setMyClients(data.allClients);
  }, [trainerId]);

  useEffect(() => {
    fetchMyClients();
  }, []);
  return (
    <div className="Bottom Content" style={{ marginTop: 40, marginLeft: 20 }}>
      <StyledLabelTitle title="My Clients" />
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        <Table
          row={MyClientsRows(myClients, trainerId, fetchMyClients)}
          headType={MyClientsHead}
        />
      </div>
    </div>
  );
}
