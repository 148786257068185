/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
/* eslint-disable no-use-before-define */

import React, {
  useCallback, useEffect, useMemo, useState, createRef,
} from 'react';

import Button from '@atlaskit/button';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { OptionType } from '@typeModel/types';
import { useGlobalsContext } from '@contexts/global.context';
import { updateTrainer } from '@controllers/trainerFirestoreController';
import { Trainer } from '@models/trainer';
import { TrainerLevel, User } from '@models/user';
import firebase from 'firebase';
import _ from 'lodash';
import { TextInput } from '@root/components/textInput';
import {
  CompensationType,
  RevenueDefaultFixedPayment,
  RevenueDefaultPercentage,
} from '@root/models/revenue';
import { toast } from 'react-toastify';
import { Reorder } from 'framer-motion/dist/framer-motion';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { DragHandleRounded } from '@material-ui/icons';
import Edit from '@atlaskit/icon/glyph/edit';
import CheckMark from '@atlaskit/icon/glyph/check';
import FetchCities from '@root/controllers/FetchCitiesController';
import sortingAlpha from '@root/utils/sortingAlpha';
import { StyledSelect } from '../../../components/select/StyledDataSelect';

function PhotoModal({
  photoUrl,
  alt,
  label,
}: {
  photoUrl?: string;
  alt: string;
  label?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {label && <div className="label">{label}</div>}
        {photoUrl && (
          <div style={{ fontSize: 14, padding: '0 10px' }}>Uploaded</div>
        )}
        <Button
          appearance="primary"
          onClick={openModal}
          style={{
            backgroundColor: '#3FC7E0',
          }}
        >
          View
        </Button>
      </div>
      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle />
            </ModalHeader>
            <ModalBody>
              <img src={photoUrl} alt={alt} width="300" height="300" />
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  );
}

export default function TrainerProfileBottomTwo({
  trainer,
}: {
  trainer: Trainer & User;
}) {
  const { countries, disciplines } = useGlobalsContext();
  // eslint-disable-next-line no-unused-vars
  const [newCertification, setNewCertification] = useState(false);
  const [certs, setCerts] = useState<string[]>(trainer?.certifications ?? []);
  const [editCertNumber, setEditCertNumber] = useState(0);
  const [certValue, setCertValue] = useState<string>('');
  const [trainerCompensation, setTrainerCompensation] = useState(
    trainer.compensation,
  );
  const [cities, setCities] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (!trainer.compensation) {
      if (trainer.trainerLevel === TrainerLevel.PREMIUM) {
        setTrainerCompensation({
          type: CompensationType.percentage,
          firstPackage: RevenueDefaultPercentage.firstPackage,
          renewal: RevenueDefaultPercentage.renewal,
        });
      } else {
        setTrainerCompensation({
          type: CompensationType.fixed,
          firstPackage: RevenueDefaultFixedPayment.firstPackage,
          renewal: RevenueDefaultFixedPayment.renewal,
        });
      }
    }
  }, [trainer]);

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    const response = await FetchCities.getAll();
    const sortedCities = sortingAlpha(response);
    setCities(sortedCities);
  };

  const countryValue = _.findLast(
    countries,
    (country) => country.value === trainer?.country,
  );
  const cityValue = _.findLast(cities, (city) => city.value === trainer?.city);
  const disciplineValue: OptionType[] = _.map(
    trainer?.disciplines,
    (discipline) => {
      const findItem = _.findLast(
        disciplines,
        (item) => item?.trainerType === discipline,
      );
      return findItem
        ? { label: findItem.trainerType, value: findItem.trainerType }
        : { label: '', value: '' };
    },
  );
  const disciplineOptions = _.map(disciplines, (discipline) => ({
    label: discipline?.trainerType,
    value: discipline?.trainerType,
  }));

  const saveCompensation = async () => {
    const values = { ...trainerCompensation };
    values.firstPackage = Number(values.firstPackage);
    values.renewal = Number(values.renewal);

    if (values.type === CompensationType.percentage) {
      if (values.firstPackage >= 1) {
        values.firstPackage /= 100;
      }
      if (values.renewal >= 1) {
        values.renewal /= 100;
      }
    }
    if (trainerCompensation) {
      await updateTrainer({
        id: trainer.id,
        compensation: { ...values as any },
        updateMonthlyBalance: true,
      });
      toast.success('Saved successfully');
    }
  };

  const revenuesTypes = [
    { label: 'Fixed', value: CompensationType.fixed },
    { label: 'Percentage', value: CompensationType.percentage },
  ];

  const certificatesRefs: any = [];
  function pushRef(array) {
    array.push(createRef());
  }
  const saveCert = () => {
    if (certValue !== '' && !certs.includes(certValue)) {
      setCerts((prevState) => [...prevState, certValue]);
      setCertValue('');
    }
  }; const deleteCert = (delString: string) => {
    setCerts((prevState) => prevState.filter((cer) => cer !== delString));
  };
  const editCert = (search: string, replacement:string) => {
    if (replacement !== '' && !certs.includes(replacement)) {
      setCerts((prevState) => prevState.map((cer) => (cer === search ? replacement : cer)));
    }
  };

  const onReorder = (data: string[]) => {
    setCerts(data);
  };

  useEffect(() => {
    try {
      updateTrainer({
        id: trainer.id,
        certifications:
        certs,
      });
    } catch (error) {
      // console.log(error);
    }
  }, [certs]);

  return (
    <>
      <div className="two-columns-grid" style={{ marginTop: 40 }}>
        <div className="trainer-details">
          {trainer.emiratesIdFileUri && (
            <PhotoModal
              label="Emirates ID"
              photoUrl={trainer.emiratesIdFileUri}
              alt="Emirates ID"
            />
          )}
          {trainer.repsCardUri && (
            <PhotoModal
              label="REPs card"
              photoUrl={trainer.repsCardUri}
              alt="REPs card"
            />
          )}
          <StyledSelect
            name="Nationality"
            isSearchable
            options={countries}
            value={countryValue}
            onChange={(value) => {
              updateTrainer({
                id: trainer.id,
                country: value.value,
              });
            }}
          />
          <StyledSelect
            name="City"
            isSearchable
            options={cities}
            value={cityValue}
            onChange={(value) => {
              updateTrainer({
                id: trainer.id,
                city: value.value,
              });
            }}
          />
        </div>
        <div>
          <div className="inputWrapper" style={{ alignItems: 'flex-start' }}>
            <div className="label" style={{ width: 100 }}>
              Certification
            </div>
            <div style={{ width: 400 }}>
              <Reorder.Group
                style={{
                  overflowY: 'scroll', padding: '0px', marginTop: '0px', listStyleType: 'none',
                }}
                axis="y"
                values={certs}
                onReorder={onReorder}
              >
                {_.map(certs || [], (certification, index) => (
                  <Reorder.Item key={certification} value={certification}>
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 10,
                        width: '100%',
                      }}
                    >
                      {pushRef(certificatesRefs)}
                      <DragHandleRounded className="sidebarIcon" />
                      <div className="label" ref={certificatesRefs[index]} style={{ width: '100%' }}>
                        {editCertNumber === (index + 1) ? (
                          <TextInput
                            defaultValue={certification}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                // this chain of childern: to accsess the divs inside TextInput
                                editCert(
                                  certs[index],
                                  certificatesRefs[index]
                                    .current.children[0].children[0].children[0].children[0].value,
                                );
                                setEditCertNumber(0);
                              }
                            }}
                          />
                        )
                          : <div>{ certification }</div>}
                      </div>
                      {
                        editCertNumber !== index + 1
                          ? (
                            <Button
                              appearance="primary"
                              style={{ backgroundColor: 'gray' }}
                              onClick={() => {
                                setEditCertNumber(index + 1);
                              }}
                            >
                              <Edit label="edit" primaryColor="#FFF" />
                            </Button>
                          ) : (
                            <Button
                              appearance="primary"
                              style={{ backgroundColor: 'green' }}
                              onClick={() => {
                                editCert(
                                  certs[index],
                                  certificatesRefs[index]
                                    .current.children[0].children[0].children[0].children[0].value,
                                );
                                setEditCertNumber(0);
                              }}
                            >
                              <CheckMark label="check-bold" primaryColor="#FFF" />
                            </Button>
                          )

}
                      <Button
                        appearance="primary"
                        style={{ backgroundColor: 'red', marginLeft: '10px' }}
                        onClick={() => {
                          deleteCert(certificatesRefs[index].current.children[0].innerHTML);
                        }}
                      >
                        <TrashIcon label="Delete Trainer Package" primaryColor="#FFF" />
                      </Button>

                    </div>
                  </Reorder.Item>
                ))}
              </Reorder.Group>
              <div className="certificateInput">
                <TextInput
                  value={certValue}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setCertValue(e.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      saveCert();
                    }
                  }}
                />
                <Button
                  className="save-button"
                  onClick={saveCert}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-width-grid">
        <StyledSelect
          name="Disciplines"
          isMulti
          isSearchable
          options={disciplineOptions}
          value={disciplineValue}
          onChange={(value, actionMeta) => {
            if (actionMeta.action === 'select-option') {
              const newDisciplines = firebase.firestore.FieldValue.arrayUnion(
                actionMeta.option.label,
              );
              updateTrainer({
                id: trainer.id,
                disciplines: newDisciplines,
              });
            }
            if (actionMeta.action === 'clear') {
              const items = actionMeta.removedValues.map((v) => v.label);
              updateTrainer({
                id: trainer.id,
                disciplines: firebase.firestore.FieldValue.arrayRemove(
                  ...items,
                ),
              });
            }
            if (actionMeta.action === 'remove-value') {
              updateTrainer({
                id: trainer.id,
                disciplines: firebase.firestore.FieldValue.arrayRemove(
                  actionMeta.removedValue.label,
                ),
              });
            }
          }}
        />
        <TextInput
          label="Other disciplines"
          defaultValue={trainer?.otherDisciplines}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            updateTrainer({
              id: trainer.id,
              otherDisciplines: e.currentTarget.value,
            });
          }}
        />
        <div
          // className="two-columns-grid"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div style={{ width: '27%' }}>
            <StyledSelect
              name="Revenues type"
              isSearchable={false}
              options={revenuesTypes}
              value={{
                value: trainerCompensation?.type as any,
                label: trainerCompensation?.type as any,
              }}
              onChange={(type) => {
                setTrainerCompensation({
                  ...(trainerCompensation as any),
                  type: type.value as CompensationType,
                });
              }}
            />
          </div>
          <div style={{ width: '25%', marginLeft: 10 }}>
            <TextInput
              label="First package"
              labelWidth={100}
              value={trainerCompensation?.firstPackage}
              onChange={(e) => {
                setTrainerCompensation({
                  ...trainerCompensation as any,
                  firstPackage: e.currentTarget.value,
                });
              }}
            />
          </div>
          <div style={{ width: '25%', marginLeft: 10 }}>
            <TextInput
              label="Renewal"
              labelWidth={100}
              value={trainerCompensation?.renewal}
              onChange={(e) => {
                setTrainerCompensation({
                  ...trainerCompensation as any,
                  renewal: e.currentTarget.value,
                });
              }}
            />
          </div>
          <Button
            appearance="primary"
            style={{
              backgroundColor: '#3FC7E0',
              marginLeft: 10,
            }}
            onClick={saveCompensation}
          >
            Save
          </Button>
        </div>
        <TextInput
          label="Note"
          labelColor="#697e8d"
          defaultValue={trainer?.note}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            updateTrainer({
              id: trainer.id,
              note: e.currentTarget.value,
            });
          }}
        />
      </div>
    </>
  );
}
