import Autocomplete from 'react-google-autocomplete';
import dotenv from 'dotenv';

dotenv.config();

export default function PlacesAutoComplete(
  {
    latitude,
    longitude,
    label, defaultValue, onPlaceSelected, ...rest
  }: {
    latitude?: number,
    longitude?: number,
    label: string,
    defaultValue: string,
    // eslint-disable-next-line no-unused-vars
    onPlaceSelected: (place: any) => void
  },
) {
  return (
    <div className="inputWrapper">
      <div className="label">
        <span>{`${label}  `}</span>
        <a
          href={`https://maps.google.com?q=${latitude},${longitude}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: '#27AAE1' }}
        >
          VIEW MAP
        </a>
      </div>

      <div className="input">
        <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          defaultValue={defaultValue}
          onPlaceSelected={onPlaceSelected}
          options={{
            types: ['geocode', 'establishment'],
            fields: 'formatted_address,address_component,name,geometry',
          }}
          className="places-autocomplete"
          {...rest}
        />
      </div>
    </div>
  );
}
