import colors from '../colors';

export default function StyledLabelTitleLarge({ title }: { title: string }) {
  return (
    <div
      style={{
        display: 'inline-flex',
        height: 32,
        backgroundColor: colors.darkGray,
        borderRadius: 3,
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 10px',
      }}
    >
      <p
        style={{
          fontSize: 14,
          color: 'white',
        }}
      >
        {title}
      </p>
    </div>
  );
}
