import { OptionType } from '@atlaskit/select';
import collections from '@root/utils/enums/collections.enums';
import { db } from './firebase';

// dotenv.config();
const countriesRef = db.collection(collections.COUNTRIES);
export default class CountryController {
  static async getAll(): Promise<OptionType[]> {
    const countries = await countriesRef.get();
    return countries.docs.map(((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))) as unknown as OptionType[];
  }
}
