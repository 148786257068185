/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Button, { LoadingButton } from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useGlobalsContext } from '@root/contexts/global.context';
import { fetchTrainers } from '@root/controllers/trainerFirestoreController';
import { Trainer } from '@root/models/trainer';
import { User } from '@root/models/user';
// eslint-disable-next-line camelcase
import endpointFunctions from '@root/utils/endpoints';
import { OptionType } from '@typeModel/types';
import { BillingPaidBy } from '@utils/enums/booking.enums';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
// eslint-disable-next-line no-use-before-define
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { StyledSelect } from '../select/StyledDataSelect';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';

const trainingType = [
  { label: '1 on 1', value: 1 },
  { label: '2 on 1', value: 2 },
  { label: '3 on 1', value: 3 },
];
const paymentTypes = [
  { label: 'Cash', value: 'cash' },
  { label: 'Link Gateway', value: 'linkGateway' },
  { label: 'Free', value: 'free' },
];

export const isRenewalTypes = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const disciplineOptions = [
  { label: 'Training', value: 'training', category: 'training' },
  { label: 'Wellness', value: 'wellness', category: 'wellness' },
];

export default function AddPackage({
  userId,
  user,
}: {
  userId: string;
  user: Trainer & User;
}) {
  const [plans, setPlans] = useState<any[]>([]);
  const [isEnabledCustomVal, setIsEnabledCustomVal] = useState(false);
  // const [useClientPackageLogic, setUseClientPackageLogic] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [date, setDate] = useState<Date | null>(null);
  const [nearTrainers, setNearTrainers] = useState<(Trainer & User)[]>([]);
  const [activeTrainerId, setActiveTrainerId] = useState<
    string | null | undefined
  >(null);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsEnabledCustomVal(false);
    setIsOpen(false);
  }, []);

  const filteredPlans = _.filter(plans, (plan) => (plan?.shownTo || []).includes('Repeating Users')) as OptionType[];

  const planOptions = filteredPlans.map((plan) => ({
    // label: plan?.packageName,
    label: `${plan?.sessions} Sessions`,
    value: plan?.sessions,
    price: plan?.price,
    nutritionSessions: plan?.nutritionSessions,
    category: plan?.category,
  }));

  const trainerOptions: OptionType[] = useMemo(
    () => _.map(nearTrainers || [], (trainer) => ({
      value: trainer.id!,
      label: trainer.displayName || trainer.fullname || '',
    })),
    [nearTrainers],
  );

  const fetchTrainerPackages = async () => {
    try {
      const request = await axios.post(endpointFunctions.get_trainer_packages, {
        trainerId: activeTrainerId,
        category: 'training',
        userId,
      });
      setPlans(request.data.packages);
    } catch (error) {
      console.log(error);
      alert('Error fetching trainer packages');
    }
  };

  useEffect(() => {
    if (activeTrainerId) {
      fetchTrainerPackages();
    }
  }, [activeTrainerId]);

  useEffect(() => {
    fetchTrainers().then((results) => {
      setNearTrainers(results);
    });
  }, []);

  const onSubmit = async (data) => {
    try {
      const {
        sessions, paymentType, Service, people, amountPaid, date, isRenewal, expirationDate,
      } = data;
      const sessionValue = typeof sessions === 'string' ? parseInt(sessions, 10) : sessions.value;

      await axios.post(endpointFunctions.add_package, {
        userId,
        trainerId: activeTrainerId,
        sessions: sessionValue,
        price: parseFloat(amountPaid || '0'),
        madeBy: BillingPaidBy.Admin,
        paidBy: BillingPaidBy.Admin,
        serviceType: Service.label,
        people: parseInt(people.value, 10),
        typeOfPayment: paymentType.label,
        date: date || null,
        renewal: isRenewal.value,
        expirationDate: expirationDate || null,
        // version: useClientPackageLogic ? 2 : 1,
        version: 2,
      });
      closeModal();
    } catch (error) {
      // console.log('onSubmit error', error);
    }
  };

  const getCalculatePrice = ({ sessions, people, paymentType }) => {
    if (paymentType && paymentType?.value === 'free') {
      return 0;
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    const totalWithOutSave = sessions && sessions?.price ? sessions?.price * sessions.value : 0;
    const addOnAmount = people
      ? (totalWithOutSave * 100 * (Number(people?.value) - 1) * 0.25) / 100
      : 0;
    const amount = totalWithOutSave + addOnAmount;
    const vatAmount = Math.round(amount * 100 * 0.05) / 100;

    const price = amount + vatAmount;
    return price;
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
          height: 42,
          alignItems: 'center',
        }}
      >
        (+) Add New Package
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({
                formProps, getState, setFieldValue, submitting,
              }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New package" />
                  <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Checkbox
                        isChecked={isEnabledCustomVal}
                        label="Apply Custom Session "
                        onChange={() => {
                          setIsEnabledCustomVal((current) => !current);
                          setFieldValue('amountPaid', 0);
                        }}
                        name="checkbox-default"
                        testId="cb-default"
                      />
                      {/* <Checkbox
                        isChecked={useClientPackageLogic}
                        label="Use client package logic"
                        onChange={() => {
                          setUseClientPackageLogic((current) => !current);
                        }}
                        name="checkbox-default"
                        testId="cb-default"
                      /> */}
                    </div>
                    <Field
                      id="date"
                      name="date"
                      defaultValue={null}
                    >
                      {({ fieldProps: { onChange, ...rest }, error }) => (
                        <FormFieldInput
                          label="Date"
                          type="date"
                          error={error}
                          fieldProps={{
                            // min: moment().format('YYYY-MM-DD'),
                            onChange: (
                              e: React.FormEvent<HTMLInputElement>,
                            ) => {
                              const { value } = e.currentTarget;
                              onChange(value as any);
                              // setDate(moment(value).toDate());
                            },
                            ...rest,
                          }}
                        />
                      )}
                    </Field>
                    <Field
                      id="date"
                      name="expirationDate"
                      // isRequired
                      // validate={(value) => {
                      //   if (!value) return 'please select expiration date';
                      //   return '';
                      // }}
                    >
                      {({ fieldProps: { onChange, ...rest }, error }) => (
                        <FormFieldInput
                          label="Expiration Date"
                          type="date"
                          error={error}
                          fieldProps={{
                            // min: moment().format('YYYY-MM-DD'),
                            onChange: (
                              e: React.FormEvent<HTMLInputElement>,
                            ) => {
                              const { value } = e.currentTarget;
                              onChange(value as any);
                              // setDate(moment(value).toDate());
                            },
                            ...rest,
                          }}
                        />
                      )}
                    </Field>
                    <Field
                      id="trainer"
                      name="trainer"
                      // defaultValue={formDefaultValue.trainer}
                      isRequired
                      validate={(value) => {
                        if (!value) return 'pls select trainer';
                        return '';
                      }}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            isDisabled={!!user.assignedTrainerId}
                            name="Trainer"
                            validationState={error ? 'error' : 'default'}
                            classNamePrefix="react-select"
                            options={trainerOptions}
                            value={value as any}
                            isSearchable
                            inputId={id}
                            onChange={(e) => {
                              setActiveTrainerId(e?.value as string);
                              onChange(e);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="services"
                      name="Service"
                      defaultValue={null}
                      isRequired
                      validate={(value) => !value && 'Service is required'}
                    >
                      {({ fieldProps: { id, onChange, ...rest }, error }) => (
                        <>
                          <StyledSelect
                            options={disciplineOptions}
                            validationState={error ? 'error' : 'default'}
                            inputId={id}
                            onChange={(value) => {
                              onChange(value);
                              if (value.value === 'wellness') {
                                // Right now there is no package for wellness,
                                // so admin will add custom sessions and amount
                                setIsEnabledCustomVal(true);
                                setFieldValue('amountPaid', 0);
                                setFieldValue('people', trainingType[0]);
                              }

                              if (!isEnabledCustomVal) {
                                setFieldValue(
                                  'amountPaid',
                                  getCalculatePrice(getState().values),
                                );
                              }
                            }}
                            {...rest}
                            label="Service"
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="sessions"
                      name="sessions"
                      isRequired
                      // eslint-disable-next-line consistent-return
                      validate={(value) => {
                        if (!value) return 'Sessions is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange, ...rest }, error }) => (
                        <>
                          {isEnabledCustomVal ? (
                            <FormFieldInput
                              label="Sessions"
                              type="number"
                              fieldProps={{ id, onChange, ...rest }}
                            />
                          ) : (
                            <StyledSelect
                              name="# of Sessions"
                              options={planOptions}
                              validationState={error ? 'error' : 'default'}
                              inputId={id}
                              onChange={(option) => {
                                onChange(option);
                                setFieldValue(
                                  'amountPaid',
                                  getCalculatePrice(getState().values),
                                );
                                const selectedDiscipline = disciplineOptions.filter(
                                  (discipline) => discipline.category === option.category,
                                );
                                setFieldValue('Service', selectedDiscipline[0]);
                              }}
                            />
                          )}

                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="people"
                      name="people"
                      defaultValue={null}
                      // eslint-disable-next-line consistent-return
                      validate={(value) => {
                        if (!value) return 'Training Type is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            options={trainingType}
                            validationState={error ? 'error' : 'default'}
                            inputId={id}
                            name="Type of training"
                            onChange={(value) => {
                              onChange(value);
                              if (!isEnabledCustomVal) {
                                setFieldValue(
                                  'amountPaid',
                                  getCalculatePrice(getState().values),
                                );
                              }
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>

                    <Field id="amountPaid" name="amountPaid">
                      {({ fieldProps }) => (
                        <FormFieldInput
                          label="Amount Paid"
                          type="number"
                          fieldProps={fieldProps}
                          min={0}
                          step="any"
                        />
                      )}
                    </Field>
                    <Field
                      id="paymentType"
                      name="paymentType"
                      // eslint-disable-next-line consistent-return
                      validate={(value) => {
                        if (!value) return 'Payment Type is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            options={paymentTypes}
                            validationState={error ? 'error' : 'default'}
                            inputId={id}
                            name="Payment Type"
                            onChange={(value) => {
                              onChange(value);
                              if (!isEnabledCustomVal) {
                                setFieldValue(
                                  'amountPaid',
                                  getCalculatePrice(getState().values),
                                );
                              }
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="isRenewal"
                      name="isRenewal"
                      // eslint-disable-next-line consistent-return
                      validate={(value) => {
                        if (!value) return 'Is Renewal is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            options={isRenewalTypes}
                            validationState={error ? 'error' : 'default'}
                            inputId={id}
                            name="Is Renewal"
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <LoadingButton
                      type="submit"
                      isLoading={submitting}
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </LoadingButton>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
