import { TextInput } from '@root/components/textInput';
import { TransactionInfo } from '@models/transactions';

export default function UserProfileBottomInfo({
  transactionInfo,
  completedSession,
}: {
  transactionInfo: TransactionInfo;
  completedSession: number;
}) {
  const { totalSessions, totalAmount, totalTransactions } = transactionInfo;
  return (
    <div className="two-columns-grid non-edit-input">
      <TextInput
        label="Total sessions purchased"
        value={totalSessions.toString()}
        isDisabled
        appearance="none"
      />
      <TextInput
        label="Total amount spent"
        value={`${totalAmount.toString()} AED`}
        isDisabled
        appearance="none"
      />
      <TextInput
        label="Total sessions completed"
        value={completedSession.toString()}
        isDisabled
        appearance="none"
      />
      <TextInput
        label="Total transactions done"
        value={totalTransactions.toString()}
        isDisabled
        appearance="none"
      />
    </div>
  );
}
