/*
DEFUALT SLOTS USED WHEN TRAINER HASN'T SET THEIRS
They start 6AM to 9PM

index are 0 to 6
0 = Sunday
6 = Sat

*/

const startTime = '06:00:AM';
const endTime = '10:00:PM';

export default [
  {
    index: 0,
    timeSlots: [
      {
        startTime,
        endTime,
      },
    ],
  },
  {
    index: 1,
    timeSlots: [
      {
        startTime,
        endTime,
      },
    ],
  },
  {
    index: 2,
    timeSlots: [
      {
        startTime,
        endTime,
      },
    ],
  },
  {
    index: 3,
    timeSlots: [
      {
        startTime,
        endTime,
      },
    ],
  },
  {
    index: 4,
    timeSlots: [
      {
        startTime,
        endTime,
      },
    ],
  },
  {
    index: 5,
    timeSlots: [
      {
        startTime,
        endTime,
      },
    ],
  },
  {
    index: 6,
    timeSlots: [
      {
        startTime,
        endTime,
      },
    ],
  },
];

export const displayDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
