export const createHeadUser = () => ({
  cells: [
    {
      key: 'Name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'Phone',
      content: 'Phone',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Email',
      content: 'Email',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Status',
      content: 'Status',
      isSortable: true,
      shouldTruncate: true,
    },

    {
      key: 'Registration date',
      content: 'Registration date',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'City',
      content: 'City',
      shouldTruncate: true,
    },

    {
      key: 'Region',
      content: 'Region',
      shouldTruncate: true,
    },
  ],
});

export const createHeadTrainer = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Display Name',
      content: 'Display Name',
      isSortable: true,
      width: withWidth ? 5 : undefined,
    },
    {
      key: 'Trainer type',
      content: 'Trainer type',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 4 : undefined,
    },
    {
      key: 'Trainer Gender',
      content: 'Gender',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 3 : undefined,
    },

    {
      key: 'Apperance Score',
      content: 'Apperance Score',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 3 : undefined,
    },
    {
      key: 'Trainer Level',
      content: 'Trainer Level',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Regions',
      content: 'Regions',
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'freeSesssion',
      content: 'Offering Free Session',
      width: withWidth ? 5 : undefined,
      isSortable: true,
    },
    {
      key: 'Status',
      content: 'Status',
      width: withWidth ? 5 : undefined,
      isSortable: true,
    },
  ],
});

export const createHeadTrainerProfile = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Value',
      content: '',
    },
    {
      key: 'Made By',
      content: '',
    },
    {
      key: 'Date',
      content: 'Date',
      isSortable: true,
    },
    {
      key: 'Time',
      content: 'Time',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Service',
      content: 'Service',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Type',
      content: 'Type',
      shouldTruncate: true,
    },

    {
      key: 'Location',
      content: 'Location',
      shouldTruncate: true,
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'Client',
      content: 'Client',
      shouldTruncate: true,
    },
    {
      key: 'Status',
      content: 'Status',
      shouldTruncate: true,
    },
    {
      key: 'Price',
      content: 'Price',
      shouldTruncate: true,
    },
    {
      key: 'Action',
      content: 'Action',
      shouldTruncate: true,
    },
  ],
});

export const createHeadUserProfile = (withWidth: boolean) => ({
  cells: [
    {
      key: 'RejectedIcon',
      content: '',
    },
    {
      key: 'Made By',
      content: '',
    },
    {
      key: 'Date',
      content: 'Date',
      isSortable: true,
    },
    {
      key: 'Time',
      content: 'Time',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Service',
      content: 'Service',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Type',
      content: 'Type',
      shouldTruncate: true,
    },

    {
      key: 'Location',
      content: 'Location',
      shouldTruncate: true,
      width: withWidth ? 15 : undefined,
    },
    {
      key: 'Trainer',
      content: 'Trainer',
      shouldTruncate: true,
    },
    {
      key: 'Status',
      content: 'Status',
      shouldTruncate: true,
      isSortable: true,
    },
    // {
    //   key: 'Price',
    //   content: 'Price',
    //   shouldTruncate: true,
    //   isSortable: true,
    // },
  ],
});

export const createHeadUserProfileNutrition = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Date',
      content: 'Date',
      isSortable: true,
      width: withWidth ? 9 : undefined,
    },
    {
      key: 'Weight',
      content: 'Weight',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'Weight goal',
      content: 'Weight goal',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'BMI',
      content: 'BMI',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },

    {
      key: 'BMI goal',
      content: 'BMI goal',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'BFP',
      content: 'BFP',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'BFP goal',
      content: 'BFP goal',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'Muscle Mass',
      content: 'Muscle Mass',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'Waist',
      content: 'Waist',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'Hip',
      content: 'Hip',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'Arm',
      content: 'Arm',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },
    {
      key: 'Thigh',
      content: 'Thigh',
      shouldTruncate: true,
      width: withWidth ? 6 : undefined,
    },
  ],
});

export const createHeadTrainerApplication = () => ({
  cells: [
    {
      key: 'Name',
      content: 'Name',
      isSortable: true,
      width: 8,
    },
    {
      key: 'Phone',
      content: 'Phone',
      shouldTruncate: true,
      isSortable: true,
      width: 5,
    },
    {
      key: 'Gender',
      content: 'Gender',
      shouldTruncate: true,
      isSortable: true,
      width: 5,
    },
    {
      key: 'Registration Date',
      content: 'Registration Date',
      shouldTruncate: true,
      isSortable: true,
      width: 5,
    },
    {
      key: 'Nationality',
      content: 'Nationality',
      shouldTruncate: true,
      width: 3,
    },

    {
      key: 'City',
      content: 'City',
      shouldTruncate: true,
      width: 5,
    },
    {
      key: 'Regions',
      content: 'Regions',
      shouldTruncate: true,
      width: 25,
    },

    {
      key: 'Permission',
      content: '',
      shouldTruncate: true,
      width: 5,
    },
  ],
});

export const createHeadUserTransactions = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Date',
      content: 'Date',
      isSortable: true,
      // width: withWidth ? 25 : undefined,
    },
    {
      key: 'Trainer',
      content: 'Trainer',
      isSortable: true,
      width: 12,
    },
    {
      key: 'Package',
      content: 'Package',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Amount Paid',
      content: 'Amount Paid',
      shouldTruncate: true,
    },
    {
      key: 'Fitlov Points',
      content: 'Fitlov Points',
      shouldTruncate: true,
    },
    {
      key: 'Coupon Used',
      content: 'Coupon Used',
      shouldTruncate: true,
    },

    {
      key: 'Type of Payment',
      content: 'Type of Payment',
      shouldTruncate: true,
    },
    {
      key: 'Paid By',
      content: 'Paid By',
      shouldTruncate: true,
    },
    {
      key: 'Action',
      content: 'Action',
      shouldTruncate: true,
    },
  ],
});

export const createTransactions = () => ({
  cells: [
    {
      key: 'Date',
      content: 'Date',
      isSortable: true,
    },
    {
      key: 'User',
      content: 'User',
      isSortable: true,
    },
    {
      key: 'Trainer',
      content: 'Trainer',
      isSortable: true,
    },
    {
      key: 'Sessions',
      content: '# Sessions',
      isSortable: true,
    },
    {
      key: 'Fitlov Points',
      content: 'Fitlov Points',
      isSortable: true,
    },
    {
      key: 'Promocode',
      content: 'Promocode',
      isSortable: true,
    },
    {
      key: 'TotalPrice',
      content: 'Total Price',
      isSortable: true,
    },
    {
      key: 'Created',
      content: 'Created',
      isSortable: true,
    },
    {
      key: 'PaymentType',
      content: 'Payment Type',
      isSortable: true,
    },
  ],
});
export const createHeadOffers = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Order',
      content: 'Order',
      isSortable: true,
      width: withWidth ? 5 : undefined,
    },
    {
      key: 'Picture',
      content: 'Picture',
    },
    {
      key: 'PromCode',
      content: 'Code',
      isSortable: true,
    },
    {
      key: 'Title',
      content: 'Title',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Description',
      content: 'Description',
      shouldTruncate: true,
      isSortable: true,
    },

    {
      key: 'ExpiryDate',
      content: 'Expiry Date',
      shouldTruncate: true,
    },
    {
      key: 'Cities',
      content: 'Cities',
      shouldTruncate: true,
    },
    {
      key: 'ShowTo',
      content: 'Show To',
      shouldTruncate: true,
    },
    {
      key: 'Orientation',
      content: 'Orientation',
      shouldTruncate: true,
    },
    {
      key: 'active',
      content: 'Active',
      shouldTruncate: true,
      width: withWidth ? 5 : undefined,
    },
    {
      key: 'Action',
      content: 'Action',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
  ],
});
export const createHeadBanners = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Order',
      content: 'Order',
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Banner Image',
      content: 'Banner Image',
    },
    {
      key: 'Show To',
      content: 'Show To',
      shouldTruncate: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'IsActive',
      content: 'Is Active',
    },
    {
      key: 'Link',
      content: 'Link',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Action',
      content: 'Action',
    },
  ],
});

export const createHeadGyms = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Order',
      content: 'Order',
      isSortable: true,
      width: withWidth ? 5 : undefined,
    },
    {
      key: 'Picture',
      content: 'Picture',
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Name',
      content: 'Name',
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Cities',
      content: 'Cities',
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'description',
      content: 'Description',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'membershipOffers',
      content: 'Membership Offers',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'howToAccess',
      content: 'How to access',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'images',
      content: 'Images',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'active',
      content: 'Active',
      shouldTruncate: true,
      width: withWidth ? 5 : undefined,
    },
    {
      key: 'delete',
      content: 'Delete',
      shouldTruncate: true,
      width: withWidth ? 5 : undefined,
    },
  ],
});

export const createHeadDisciplines = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Order',
      content: 'Order',
      isSortable: true,
      width: withWidth ? 5 : undefined,
    },
    {
      key: 'name',
      content: 'Name',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Image',
      content: 'Image',
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Session Duration',
      content: 'Session Duration',
      isSortable: true,
      width: 5,
    },
    {
      key: 'Delivery Type',
      content: 'Delivery Type',
      isSortable: true,
      width: 5,
    },
    {
      key: 'Type',
      content: 'Type',
      isSortable: true,
      width: 5,
    },
    {
      key: 'IsActive',
      content: 'Is Active',
      width: 5,
    },
  ],
});

export const createHeadPromoCodes = (withWidth: boolean) => ({
  cells: [
    {
      key: 'PromCode',
      content: 'PromCode',
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Type',
      content: 'Type',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Amount',
      content: 'Amount',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },

    {
      key: 'Max Times',
      content: 'Max Times',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Use Once',
      content: 'Use Once',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Universal',
      content: 'Universal',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Applicable package',
      content: 'Applicable package',
      shouldTruncate: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'Actions',
      content: 'Actions',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
  ],
});

export const createHeadRatings = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Date',
      content: 'Date',
      isSortable: true,
    },
    {
      key: 'Client Name',
      content: 'Client Name',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Trainer Name',
      content: 'Trainer Name',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Avg Rating',
      content: 'Avg Rating',
      isSortable: true,
      shouldTruncate: true,
    },

    {
      key: ' Quality',
      content: ' Quality',
      shouldTruncate: true,
    },
    {
      key: 'Punctuality',
      content: 'Punctuality',
      shouldTruncate: true,
    },
    {
      key: 'Friendliness',
      content: 'Friendliness',
      shouldTruncate: true,
    },
    {
      key: 'Review',
      content: 'Review',
      shouldTruncate: true,
      width: withWidth ? 30 : undefined,
    },

    {
      key: 'Approved',
      content: 'Approved',
      shouldTruncate: true,
    },
  ],
});

export const createHeadTrainerRatings = () => ({
  cells: [
    {
      key: 'Full Name',
      content: 'Full Name',
      isSortable: true,
    },
    {
      key: 'Avg Rating',
      content: 'Avg Rating',
      isSortable: true,
    },
    {
      key: 'Avg Quality Rating',
      content: 'Avg Quality Rating',
      isSortable: true,
    },
    {
      key: 'Avg Punctuality Rating',
      content: 'Avg Punctuality Rating',
      isSortable: true,
      shouldTruncate: true,
    },

    {
      key: 'Avg Friendliness Rating',
      content: ' Avg Friendliness Rating',
      isSortable: true,
      shouldTruncate: true,
    }, {
      key: 'Appearance Score',
      content: 'Appearance Score',
      isSortable: true,

    },
  ],
});

export const createHeadTrainerChanges = () => ({
  cells: [
    {
      key: 'createdAt',
      content: 'Date',
      isSortable: true,
    },
    {
      key: 'Trainer',
      content: 'trainer',
      isSortable: true,
    },
    {
      key: 'Attribute',
      content: 'Field',
      isSortable: true,
    },
    {
      key: 'Old',
      content: 'from',
      isSortable: true,
    },
    {
      key: 'New',
      content: 'to',
      isSortable: true,
    },
    {
      key: 'status',
      content: 'status',
      isSortable: true,
    },
  ],
});

export const createHeadBalance = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Num',
      content: ' ',
      isSortable: false,
      width: 1,
    },
    {
      key: 'Session Date',
      content: 'Session Date',
      isSortable: false,
      width: 9,
    },
    {
      key: 'Client',
      content: 'Client',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Trainer',
      content: 'Trainer',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Package type',
      content: 'Package type',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Price per session',
      content: 'Price per session',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    // {
    //   key: '%',
    //   content: 'Percentage used',
    //   shouldTruncate: true,
    //   width: 2,
    // },
    {
      key: 'Trainer compensation',
      content: 'Trainer compensation',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Fitlov commission',
      content: 'Fitlov commission',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Notes',
      content: 'Notes',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Action',
      content: 'Action',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
  ],
});

export const createClientPackagesHead = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Date',
      content: 'Date',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Trainer',
      content: 'Trainer',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Renewal',
      content: 'Renewal',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 7 : undefined,
    },
    {
      key: 'Price',
      content: 'Price',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 7 : undefined,
    },
    {
      key: 'Price p/s',
      content: 'Price p/s',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 7 : undefined,
    },
    {
      key: '# Sessions',
      content: '# Sessions',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 7 : undefined,
    },
    // {
    //   key: 'Scheduled Sessions',
    //   content: 'Scheduled Sessions',
    //   shouldTruncate: true,
    //   isSortable: true,
    //   width: withWidth ? 10 : undefined,
    // },
    {
      key: 'Pending',
      content: 'Pending',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 7 : undefined,
    },
    {
      key: 'Scheduled',
      content: 'Scheduled',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 7 : undefined,
    },
    {
      key: 'Completed',
      content: 'Completed',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 7 : undefined,
    },
    // {
    //   key: 'Paid',
    //   content: 'Paid',
    //   shouldTruncate: true,
    //   width: 10,
    // },
    {
      key: 'Archived',
      content: 'Archived',
      shouldTruncate: true,
      width: withWidth ? 7 : undefined,
    },
    {
      key: 'Expiry',
      content: 'Expiry',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Action',
      content: 'Action',
      shouldTruncate: true,
      width: withWidth ? 7 : undefined,
    },
  ],
});

export const createHeadBalanceSummary = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Client',
      content: 'Client',
      isSortable: true,
      width: 7,
    },
    {
      key: '# of sessions',
      content: '# of sessions',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Total price paid',
      content: 'Total price paid',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Trainer compensation',
      content: 'Trainer compensation',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Fitlov commission',
      content: 'Fitlov commission',
      shouldTruncate: true,
      width: 2,
    },
  ],
});

export const createHeadPackage = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Package Name',
      content: 'Package Name',
      isSortable: true,
    },
    {
      key: '# of PT Sessions',
      content: '# of PT Sessions',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: '# of Nutrition Sessions ',
      content: '# of Nutrition Sessions',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Price',
      content: 'Price per session',
      shouldTruncate: true,
    },
    {
      key: 'Category',
      content: 'Category',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Starter Pack',
      content: 'Starter Pack',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Most Popular',
      content: 'Most Popular',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Status',
      content: 'Status',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },

    {
      key: 'Shown to',
      content: 'Shown to',
      shouldTruncate: true,
    },
    {
      key: 'Actions',
      content: 'Actions',
      shouldTruncate: true,
    },
  ],
});
export const createHeadTrainerPackage = (withWidth: boolean) => ({
  cells: [
    {
      key: 'Trainer',
      content: 'Trainer',
      isSortable: true,
    },
    {
      key: 'Package Name',
      content: 'Package Name',
      isSortable: true,
    },
    {
      key: '# of PT Sessions',
      content: '# of PT Sessions',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Price',
      content: 'Price per session',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Starter Pack',
      content: 'Starter Pack',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Status',
      content: 'Status',
      shouldTruncate: true,
      width: withWidth ? 10 : undefined,
    },

    {
      key: 'Shown to',
      content: 'Shown to',
      shouldTruncate: true,
    },
    {
      key: 'Actions',
      content: 'Actions',
      shouldTruncate: true,
    },
  ],
});

export const createHeadRenewal = (hideLastBilling: boolean) => ({
  cells: [
    {
      key: 'Name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'Phone',
      content: 'Phone',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Balance',
      content: 'Balance',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Last Completed Session',
      content: 'Last Completed Session',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Last Trainer',
      content: 'Last Trainer',
      shouldTruncate: true,
    },
    {
      key: 'Last Package',
      content: 'Last Package Date',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Comment',
      content: 'Comment',
    },
  ].filter(((e) => {
    if (hideLastBilling) {
      return e.key !== 'Last Package';
    }
    return true;
  })),
});
export const createPendingRenewal = () => ({
  cells: [
    {
      key: 'Name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'Phone',
      content: 'Phone',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Completed Sessions',
      content: 'Completed Sessions',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Last Completed Session',
      content: 'Last Completed Session',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Last Trainer',
      content: 'Last Trainer',
      shouldTruncate: true,
    },
    {
      key: 'Comment',
      content: 'Comment',
    },
    {
      key: 'Status',
      content: 'Status',
    },
  ],
});

export const createHeadUnconfirmed = () => ({
  cells: [
    {
      key: 'Made By',
      content: '',
    },
    {
      key: 'Session Date & Time',
      content: 'Session Date & Time',
      isSortable: true,
    },
    {
      key: 'Booking Date & Time',
      content: 'Booking Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Client Name ',
      content: 'Client Name',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Trainer Name',
      content: 'Trainer Name',
      shouldTruncate: true,
    },
    {
      key: 'Discipline',
      content: 'Discipline',
      shouldTruncate: true,
    },
    {
      key: 'Type of Service',
      content: 'Type of Service',
      shouldTruncate: true,
    },
  ],
});

export const createHeadCancelled = () => ({
  cells: [
    {
      key: 'Made By',
      content: '',
    },
    {
      key: 'Session Date & Time',
      content: 'Session Date & Time',
      isSortable: true,
    },
    {
      key: 'Booking Date & Time',
      content: 'Booking Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },

    {
      key: 'Cancellation Date & Time',
      content: 'Cancellation Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'With more than 6 Hours',
      content: 'With more than 6 Hours',
      shouldTruncate: true,
      isSortable: true,
      width: 8,
    },

    {
      key: 'Cancelled by',
      content: 'Cancelled by',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Client Name',
      content: 'Client Name',
      shouldTruncate: true,
    },
    {
      key: 'Trainer Name',
      content: 'Trainer Name',
      shouldTruncate: true,
    },
    {
      key: 'action',
      content: 'Actions',
      shouldTruncate: true,
      width: 3,
    },
  ],
});
export const createHeadRejected = () => ({
  cells: [
    {
      key: 'Made By',
      content: '',
    },
    {
      key: 'Session Date & Time',
      content: 'Session Date & Time',
      isSortable: true,
    },
    {
      key: 'Booking Date & Time',
      content: 'Booking Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },

    {
      key: 'Rejected Date & Time',
      content: 'Rejected Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Reject Reason',
      content: 'Reject Reason',
      isSortable: true,
    },
    {
      key: 'Client Name',
      content: 'Client Name',
      shouldTruncate: true,
    },
    {
      key: 'Trainer Name',
      content: 'Trainer Name',
      shouldTruncate: true,
    },
    {
      key: 'Cancelled By',
      content: 'Cancelled By',
    },
    {
      key: 'Action',
      content: 'Action',
      shouldTruncate: true,
    },
  ],
});

export const createHeadConfirmed = () => ({
  cells: [
    {
      key: 'Made By',
      content: '',
    },
    {
      key: 'Session Date & Time',
      content: 'Session Date & Time',
      isSortable: true,
    },
    {
      key: 'Booking Date & Time',
      content: 'Booking Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },

    {
      key: 'Confirmation Date & Time',
      content: 'Confirmation Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },
    {
      key: 'Client Name',
      content: 'Client Name',
      shouldTruncate: true,
    },
    {
      key: 'Trainer Name',
      content: 'Trainer Name',
      shouldTruncate: true,
    },
  ],
});

export const createHeadComplete = () => ({
  cells: [
    {
      key: 'Made By',
      content: '',
    },
    {
      key: 'Session Date & Time',
      content: 'Session Date & Time',
      isSortable: true,
    },
    {
      key: 'Booking Date & Time',
      content: 'Booking Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },

    {
      key: 'Completed Date & Time',
      content: 'Completed Date & Time',
      shouldTruncate: true,
      isSortable: true,
    },

    {
      key: 'Client Name',
      content: 'Client Name',
      shouldTruncate: true,
    },
    {
      key: 'Trainer Name',
      content: 'Trainer Name',
      shouldTruncate: true,
    },
  ],
});

export const createHeadMyClients = () => ({
  cells: [
    {
      key: 'Client Name',
      content: 'Client Name',
      isSortable: true,
    },
    {
      key: 'Sessions Balance',
      content: 'Sessions Balance',
    },
    {
      key: 'Action',
      content: 'Action',
      shouldTruncate: true,
    },
  ],
});

export const createHeadCities = () => ({
  cells: [
    {
      key: 'Name',
      content: 'Name',
      isSortable: true,
    },
    {
      key: 'Country',
      content: 'Country',
    },
    {
      key: 'Regions',
      content: 'Regions',
      shouldTruncate: true,
    },
    {
      key: 'Active',
      content: 'Active',
      shouldTruncate: true,
    },
  ],
});

export const createHeadExpiration = () => ({
  cells: [
    {
      key: 'Client',
      content: 'Client',
      isSortable: true,
    },
    {
      key: 'Trainer',
      content: 'Trainer',
      isSortable: true,
    },
    // {
    //   key: 'Sessions',
    //   content: 'Sessions',
    // },
    {
      key: 'Sessions left',
      content: 'Sessions left',
    },
    {
      key: 'Price per session',
      content: 'Price per session',
    },
    {
      key: 'Total expired',
      content: 'Total expired',
    },
    {
      key: 'expirationDate',
      content: 'Expiry Date',
      // shouldTruncate: true,
      // isSortable: true,
      width: 10,
    }, {
      key: 'Archived',
      content: 'Archived',
      shouldTruncate: true,
      isSortable: true,
    },
    // {
    //   key: 'Comment',
    //   content: 'Comment',
    //   shouldTruncate: true,
    // },
  ],
});

export const createHeadPrebookings = () => ({
  cells: [
    {
      key: 'Created at',
      content: 'Created at',
      width: 5,
    },
    {
      key: 'Client',
      content: 'Client',
      isSortable: true,
      width: 10,
    },
    {
      key: 'Date time',
      content: 'Date time',
      width: 10,
    },
    {
      key: 'Address',
      content: 'Address',
      width: 10,
    },
    {
      key: 'Pending',
      content: 'Pending',
      width: 20,
    },
    {
      key: 'Rejected',
      content: 'Rejected',
      width: 10,
    },
    {
      key: 'Confirmed',
      content: 'Confirmed',
      width: 10,
    },
    {
      key: 'Selected',
      content: 'Selected',
      width: 10,
    },
    {
      key: 'Action',
      content: 'Action',
      width: 12,
    },
  ],
});
export const createTrainerPerformanceHead = () => ({
  cells: [
    {
      key: 'Trainer',
      content: 'Trainer',
      // isSortable: true,
      width: 10,
    },
    {
      key: 'Clients',
      content: 'Clients',
      isSortable: true,
      width: 10,
    },
    {
      key: 'Sessions delivered',
      content: 'Sessions delivered',
      isSortable: true,
      width: 10,
    },
    {
      key: 'Avg. Sessions per Client',
      content: 'Avg. Sessions per Client',
      isSortable: true,
      width: 10,
    },
    {
      key: 'Lost Clients',
      content: 'Lost Clients',
      isSortable: true,
      width: 20,
    },
    {
      key: 'Positive Converted',
      content: 'Positive Converted',
      isSortable: true,
      width: 5,
    },
    {
      key: 'Negative Converted',
      content: 'Negative Converted',
      isSortable: true,
      width: 5,
    },
    {
      key: 'Convertion Rate',
      content: 'Convertion Rate',
      isSortable: true,
      width: 10,
    },
    {
      key: 'Negative Retention',
      content: 'Negative Retention',
      isSortable: true,
      width: 5,
    },
    {
      key: 'Positive Retention',
      content: 'Positive Retention',
      isSortable: true,
      width: 5,
    },
    {
      key: 'Retention Rate',
      content: 'Retention Rate',
      isSortable: true,
      width: 10,
    },
    {
      key: 'Clients Completed 10 sessions',
      content: 'Clients Completed 10 sessions',
      isSortable: true,
      width: 10,
    },
    {
      key: 'Clients Completed 20 sessions',
      content: 'Clients Completed 20 sessions',
      isSortable: true,
      width: 10,
    },
    {
      key: 'Clients Completed 30 sessions',
      content: 'Clients Completed 30 sessions',
      isSortable: true,
      width: 10,
    },
  ],
});

export const ApplicationHead = createHeadTrainerApplication();
export const CompleteHead = createHeadComplete();
export const ConfirmHead = createHeadConfirmed();
export const CancelHead = createHeadCancelled();
export const RejectedHead = createHeadRejected();
export const unconfirmedHead = createHeadUnconfirmed();
export const RenewalHead = createHeadRenewal;
export const ExpirationHead = createHeadExpiration();
export const PendingRenewalHead = createPendingRenewal();
export const PackageHead = createHeadPackage(true);
export const TrainerPackageHead = createHeadTrainerPackage(true);
export const TrainerChangesHead = createHeadTrainerChanges();
export const TrainerPerformanceHead = createTrainerPerformanceHead();
export const BalanceHead = createHeadBalance(true);
export const ClientPackagesHead = createClientPackagesHead(true);
export const BalanceSummaryHead = createHeadBalanceSummary(true);
export const trainerProfileHead = createHeadTrainerProfile(true);
export const RatingsHead = createHeadRatings(true);
export const OffersHead = createHeadOffers(true);
export const GymsHead = createHeadGyms(true);
export const Disciplinesheader = createHeadDisciplines(true);
export const BannersHead = createHeadBanners(true);
export const TrainerRatingsHead = createHeadTrainerRatings();
export const PromoCodesHead = createHeadPromoCodes(true);

export const userProfileNutritionHead = createHeadUserProfileNutrition(true);
export const userProfileHead = createHeadUserProfile(true);
export const userHead = createHeadUser();
export const trainerHead = createHeadTrainer(true);
export const userTransactionHead = createHeadUserTransactions(true);
export const TransactionHead = createTransactions();
export const MyClientsHead = createHeadMyClients();

export const citiesHead = createHeadCities();
export const preBookingsHead = createHeadPrebookings();
