import Button, { LoadingButton } from '@atlaskit/button';
import { db } from '@controllers/firebase';
import { deleteUser } from '@controllers/userFirestoreController';
import { Trainer } from '@models/trainer';
import { User } from '@models/user';
import collections from '@utils/enums/collections.enums';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTrainerAllBookings } from '@controllers/trainerBookingController';
import WarningModal from '@root/components/modals/WarningModal';
import UpdateMobile from '@root/components/modals/UpdateMobileNumberModal';
import StyledLabelTitle from '../../../utils/styles/StyledLabelTitle';
import TrainerProfileBottom from './trainerProfileBottom';
import TrainerProfileBottomPreferences from './trainerProfileBottomPreferences';
import TrainerProfileBottomStatistics from './trainerProfileBottomStatistics';
import TrainerProfileBottomTable from './trainerProfileBottomTable';
import TrainerProfileBottomTwo from './trainerProfileBottomTwo';
import TrainerProfileTop from './trainerProfileTop';
import TrainerAvailabilityView from './trainerAvailabilityView';
import TrainerPTTransactionsTable from './trainerPTTransactionsTable';
import MyClients from './myClients';
import TrainerPackages from './trainerPackages';
import TrainerProfileMedia from './trainerProfileMedia';

export default function TrainerProfile() {
  const params = useParams() as { id: string };
  const { trainerBookings } = useTrainerAllBookings(params.id);
  const [trainer, setTrainer] = useState<(Trainer & User) | null>(null);
  const [removingUser, setRemovingUser] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const unsubscribe = db
      .collection(collections.USERS)
      .doc(params.id)
      .onSnapshot((observer) => {
        const userFirestore: User & Trainer = observer.data() as User & Trainer;
        setTrainer(userFirestore);
      });
    return unsubscribe;
  }, []);

  const removeUser = () => {
    setRemovingUser(true);
    deleteUser({ userId: params.id, isTrainer: true });
    setRemovingUser(false);
    history.goBack();
  };
  if (!trainer) {
    return <div />;
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 20,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1 }}>
          <StyledLabelTitle title="Trainer infomation" />
          <Button
            appearance="primary"
            style={{
              backgroundColor: '#3FC7E0',
              margin: '0 20px',
            }}
          >
            Trainer Availability
          </Button>
        </div>
        <div
          className="flex"
          style={{
            flex: 2,
            justifyContent: 'flex-end',
          }}
        >
          <UpdateMobile userId={params.id} existingNumber={trainer.mobile as any} />
          <WarningModal
            title="Are you sure you want to delete this trainer?"
            onPrimaryAction={() => removeUser()}
            primaryButtonLabel="Delete Trainer"
            triggerButton={(
              <LoadingButton
                isLoading={removingUser}
                appearance="primary"
                style={{
                  backgroundColor: '#FA5D7C',
                  marginTop: 0,
                  marginRight: 20,
                }}
              >
                Delete Trainer
              </LoadingButton>
              )}
          />
        </div>
      </div>
      {trainer ? (
        <div>
          <TrainerProfileTop trainer={trainer} />
          <TrainerProfileBottom trainer={trainer} />
          <TrainerProfileBottomTwo trainer={trainer} />
          <TrainerProfileMedia trainer={trainer} />
          <TrainerProfileBottomStatistics trainerId={params.id} />
          <TrainerProfileBottomPreferences trainer={trainer} />
          <TrainerPackages trainerId={trainer.id as string} />
          <MyClients trainerId={trainer.id as string} />
          <TrainerPTTransactionsTable trainerId={params.id} />
          <TrainerProfileBottomTable trainerBookings={trainerBookings} />
          <TrainerAvailabilityView
            trainer={trainer}
            trainerBookings={trainerBookings}
          />
        </div>
      ) : (
        <div>Loading...</div>
      )}

      <div />
    </div>
  );
}
