/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Select from '@atlaskit/select';
import RenewalController from '@controllers/renewalFirestoreController';
import { Renewal } from '@models/renewal';
import { ExpiredPackage } from '@root/models/expiredPackages';
import { UserStatusOptions, formatCurrency } from '@root/utils/common/data';
import UserStatusEnum from '@root/utils/enums/userStatus.enums';
import StyledLabelTitle from '@utils/styles/StyledLabelTitle';
import _ from 'lodash';
import moment from 'moment';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { CSVLink } from 'react-csv';
import colors from '@root/utils/colors';
import {
  createHeadExpiration,
  PendingRenewalHead,
  RenewalHead,
} from '../../components/table/content/Head';
import {
  ClientFinsihingRows,
  ClientPendingRenewalRows,
  ClientPendingRows,
  ExpirationRows,
} from '../../components/table/content/rows';
import Table from '../../components/table/table';

export default function Renewals() {
  const [loading, setLoading] = useState(true);
  const [renewalData, setRenewalData] = useState<Renewal[]>([]);
  const [pendingData, setPendingData] = useState<Renewal[]>([]);
  const [noBalanceData, setNoBalanceData] = useState<Renewal[]>([]);
  const [allRenewalData, setAllRenewalData] = useState<Renewal[]>([]);
  const [allPendingData, setAllPendingData] = useState<Renewal[]>([]);
  const [allNoBalanceData, setAllNoBalanceData] = useState<Renewal[]>([]);
  const [allExpiredPackages, setAllExpiredPackages] = useState<ExpiredPackage[]>([]);
  const [trainers, setTrainers] = useState<{label: string, value: string}[]>([]);
  const [users, setUsers] = useState<{label: string, value: string}[]>([]);
  const [expirationMonths, setExpirationMonths] = useState<{label: string, value: string}[]>([]);
  const [userExpFilter, setUserExpFilter] = useState<string>('');
  const [monthExpFilter, setMonthExpFilter] = useState<string>('');
  const [archivedExpFilter, setArchivedExpFilter] = useState<string>('');
  const [loadingExpiredPackages, setLoadingExpiredPackages] = useState<boolean>(true);
  const [getAllUserBalance, setGetAllUserBalance] = useState<any[]>([]);
  const [userStatusFilter, setUserStatusFilter] = useState<string | null>(null);

  useEffect(() => {
    RenewalController.getAllUserBalance().then((result) => {
      setGetAllUserBalance(result);
    });
  }, []);

  useEffect(() => {
    const fetchInit = async () => {
      try {
        const { zeroBalance, pending, renewal } = await RenewalController.getDataOnUser();
        // const pending = await RenewalController.getDataOnUser({
        //   type: 'pending',
        // });
        // const noBalance = await RenewalController.getDataOnUser({
        //   type: 'noBalance',
        // });
        setRenewalData(renewal as Renewal[]);
        setAllRenewalData(renewal as Renewal[]);
        setPendingData(pending as Renewal[]);
        setAllPendingData(pending as Renewal[]);
        setNoBalanceData(zeroBalance as Renewal[]);
        setAllNoBalanceData(zeroBalance as Renewal[]);
        setLoading(false);
        const trainersData = _.compact(_.map(_.unionBy(zeroBalance, pending, renewal, 'lastCompletedTrainerName'), 'lastCompletedTrainerName'));
        const trainersObj = _.orderBy(_.map(trainersData, (trainer) => ({ label: trainer, value: trainer })), ['label'], ['asc']);
        setTrainers(trainersObj);
      } catch (error) {
        // console.log(error)
      }
    };
    fetchInit();
    return () => { };
  }, []);
  // fetching clientPackages for expiration
  useEffect(() => {
    RenewalController.getExpiredPackages()
      .then((result) => {
        // default is No
        // const filtered = _.filter(result, (item) => Boolean(item.archived) === false);
        setAllExpiredPackages(result);
        setLoadingExpiredPackages(false);
        const selectUsers = result.map((item) => ({ label: item.clientName, value: item.userId }));
        setUsers(_.uniqBy(selectUsers, 'value'));
        const selectMonth = result.map((item) => ({ label: moment(item.expirationDate).format('MMM YYYY'), value: item.expirationDate?.toString() }));
        setExpirationMonths(_.uniqBy(selectMonth, 'label'));
      });

    // const clientPackagesSnap = db.collection(collections.CLIENT_PACKAGES)
    //   .where('notifications', 'array-contains-any', ['expiration-notice']).get();
    // (async () => {
    //   const aboutToExpire = ((await clientPackagesSnap).docs.map((doc) => doc.data())) as ClientPackage[];
    //   // console.log(aboutToExpire);
    //   setAboutToExpirePackages(aboutToExpire);
    // })();
  }, []);
  const filteredData = useCallback((data, type) => {
    if (type === 'renewal') {
      if (data === 'All') {
        setRenewalData(allRenewalData);
      } else {
        const filtered = _.filter(allRenewalData, (item) => item.lastCompletedTrainerName === data);
        setRenewalData(filtered);
      }
    }
    if (type === 'pending') {
      if (data === 'All') {
        setPendingData(allPendingData);
      } else {
        const filtered = _.filter(allPendingData, (item) => item.lastCompletedTrainerName === data);
        setPendingData(filtered);
      }
    }
    if (type === 'noBalance') {
      if (data === 'All') {
        setNoBalanceData(allNoBalanceData);
      } else {
        const filtered = _.filter(allNoBalanceData, (item) => item.lastCompletedTrainerName === data);
        setNoBalanceData(filtered);
      }
    }
  }, [renewalData, pendingData, noBalanceData, allRenewalData, allPendingData, allNoBalanceData]);

  const filteredExpirationTable = useMemo(() => {
    let filtered: ExpiredPackage[] = allExpiredPackages;

    if (userExpFilter !== '' && userExpFilter !== 'All') {
      filtered = _.filter(filtered, (item) => item.userId === userExpFilter);
    }
    if (archivedExpFilter !== '' && archivedExpFilter !== 'All') {
      filtered = _.filter(filtered, (item) => Boolean(item.archived) === (archivedExpFilter === 'Yes'));
    }
    if (monthExpFilter !== '' && monthExpFilter !== 'All') {
      filtered = _.filter(filtered, (item) => item.expirationDate.toString().slice(0, -17) === monthExpFilter.toString().slice(0, -17));
    }
    return filtered;
  }, [userExpFilter, archivedExpFilter, monthExpFilter, allExpiredPackages]);

  const filteredNoBalanceData = useMemo(() => {
    let filtered: Renewal[] = [];
    if (!userStatusFilter || userStatusFilter === 'All') {
      filtered = noBalanceData;
    } else if (userStatusFilter === 'ACTIVE') {
      filtered = _.filter(
        noBalanceData,
        (item: Renewal) => (item?.status) === 'ACTIVE',
      );
    } else if (userStatusFilter === 'PENDING') {
      filtered = _.filter(
        noBalanceData,
        (item: Renewal) => (item?.status) === 'PENDING',
      );
    } else if (userStatusFilter === 'LOST') {
      filtered = _.filter(
        noBalanceData,
        (item: Renewal) => (item?.status) === 'LOST',
      );
    } else if (userStatusFilter === 'ARCHIVED') {
      filtered = _.filter(
        noBalanceData,
        (item: Renewal) => (item?.status) === 'ARCHIVED',
      );
    } else {
      filtered = noBalanceData;
    }
    return filtered;
  }, [userStatusFilter, noBalanceData]);

  const updateRenewalArr = (id, label, value, type) => {
    if (type === 'TRAINING') {
      const index = renewalData.findIndex((element) => element.id === id);
      if (index !== -1) {
        renewalData[index][label] = value;
        setRenewalData(renewalData);
      }
    } else if (type === 'PENDING') {
      const index = noBalanceData.findIndex((element) => element.id === id);
      if (index !== -1) {
        noBalanceData[index][label] = value;
        setRenewalData(noBalanceData);
      }
    } else if (type === 'NOT_TRAINING') {
      const index = pendingData.findIndex((element) => element.id === id);
      if (index !== -1) {
        pendingData[index][label] = value;
        setRenewalData(pendingData);
      }
    }
    // else if (type === 'Expiration') {
    //   const index = expiredPackages.data.findIndex((element) => element.billingHistoryId === id);
    //   if (index !== -1) {
    //     expiredPackages.data[index][label] = value;
    //     setRenewalData(pendingData);
    //   }
    // } else if (type === 'ExpirationDeleteIndex') {
    //   const index = expiredPackages.data.findIndex((element) => element.billingHistoryId === id);
    //   if (index !== -1) {
    //     expiredPackages.data.splice(index, 1);
    //     setRenewalData(pendingData);
    //   }
    // }
  };

  const trainersSelection = (type) => (
    <div style={{ width: 150, marginLeft: 30 }}>
      <label htmlFor="user-status-select">Trainers</label>
      <Select
        inputId="user-status-select"
        className="multi-select"
        classNamePrefix="react-select"
        options={[
          { label: 'All', value: 'All' },
          ...trainers,
        ]}
        isSearchable
        placeholder="All"
        onChange={(option) => filteredData(option?.value, type)}
      />
    </div>
  );
  const archivedFilter = () => (
    <div style={{ width: 150, marginLeft: 30 }}>
      <label htmlFor="user-status-select">Archived</label>
      <Select
        inputId="user-status-select"
        className="multi-select"
        classNamePrefix="react-select"
        options={[
          { label: 'All', value: 'All' },
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' },
        ]}
        defaultValue={{ label: 'All', value: 'All' }}
        isDisabled={loadingExpiredPackages}
        isSearchable
        onChange={(option) => setArchivedExpFilter(option?.value as string)}
      />
    </div>
  );
  const monthFilter = () => (
    <div style={{ width: 150, marginLeft: 30 }}>
      <label htmlFor="user-status-select">Month</label>
      <Select
        inputId="user-status-select"
        className="multi-select"
        classNamePrefix="react-select"
        options={[
          { label: 'All', value: 'All' },
          ...expirationMonths,
        ]}
        defaultValue={{ label: 'All', value: 'All' }}
        isDisabled={loadingExpiredPackages}
        isSearchable
        onChange={(option) => setMonthExpFilter(option?.value as string)}
      />
    </div>
  );

  const userFilter = () => (
    <div style={{ width: 150, marginLeft: 30 }}>
      <label htmlFor="user-status-select">Users</label>
      <Select
        inputId="user-status-select"
        className="multi-select"
        classNamePrefix="react-select"
        options={[
          { label: 'All', value: 'All' },
          ...users,
        ]}
        defaultValue={{ label: 'All', value: 'All' }}
        isDisabled={loadingExpiredPackages}
        isSearchable
        onChange={(option) => { setUserExpFilter(option?.value as string); }}
      />
    </div>
  );

  const totalSessions = useMemo(() => {
    let total = 0;
    if (filteredExpirationTable.length > 0) {
      filteredExpirationTable.forEach((item) => {
        total += item.sessionsLeft;
      });
    }
    return total;
  }, [filteredExpirationTable]);

  const totalExpired = useMemo(() => {
    let total = 0;
    if (filteredExpirationTable.length > 0) {
      filteredExpirationTable.forEach((item) => {
        total += ((item.sessionsLeft * item.pricePerSession) || 0);
      });
    }
    return Number(total.toFixed(2));
  }, [filteredExpirationTable]);

  return (
    <>
      <div>
        {getAllUserBalance.length ? (
          <div>
            <CSVLink
              data={getAllUserBalance}
              filename={`user-balance-${moment().format('DD-MM-YYYY HH:mm')}`}
              style={{
                textDecoration: 'none',
                backgroundColor: colors.darkGray,
                color: 'white',
                padding: '8px 20px',
                borderRadius: 3,
              }}
            >
              Export user balance
            </CSVLink>
          </div>
        ) : <div>Fetching user balance csv...</div>}
        <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 32 }}>
          <StyledLabelTitle title="Clients about to finish their package" />
          {trainersSelection('renewal')}
        </div>
      </div>
      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table
          isLoading={loading}
          headType={RenewalHead(true)}
          row={ClientFinsihingRows(renewalData, updateRenewalArr)}
          defaultSortKey="Last Completed Session"
          defaultSortOrder="DESC"
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 32 }}>
        <StyledLabelTitle title="Clients pending renewal" />
        <div style={{ width: 150, marginLeft: 30 }}>
          <label htmlFor="user-status-select">User Status</label>
          <Select
            inputId="user-status-select"
            className="multi-select"
            classNamePrefix="react-select"
            options={[
              { label: 'All', value: 'All' },
              ...UserStatusOptions.filter((u) => u.label !== UserStatusEnum.PT_CLIENT),
            ]}
            isSearchable={false}
            placeholder="All"
            onChange={(option) => {
              setUserStatusFilter(option?.value.toString() || null);
            }}
          />
        </div>
        {trainersSelection('noBalance')}
      </div>
      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table
          isLoading={loading}
          headType={PendingRenewalHead}
          row={ClientPendingRenewalRows(filteredNoBalanceData, updateRenewalArr)}
          defaultSortKey="Last Completed Session"
          defaultSortOrder="DESC"
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 32 }}>
        <StyledLabelTitle title="Clients not training" />
        {trainersSelection('pending')}
      </div>
      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table
          isLoading={loading}
          headType={RenewalHead(false)}
          row={ClientPendingRows(pendingData, updateRenewalArr)}
          defaultSortKey="Last Completed Session"
          defaultSortOrder="DESC"
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 32 }}>
        <StyledLabelTitle title="Expired packages" />
        {archivedFilter()}
        <div>
          {userFilter()}
        </div>
        <div>
          {monthFilter()}
        </div>

      </div>
      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <span>
          Total sessions:
          {' '}
          {totalSessions}
        </span>
        <span>
          {' | '}
        </span>
        <span>
          Total expired: AED
          {' '}
          {formatCurrency(totalExpired)}
        </span>
        <Table
          isLoading={loadingExpiredPackages}
          headType={createHeadExpiration()}
          row={ExpirationRows(filteredExpirationTable)}
          defaultSortOrder="DESC"
        />
      </div>
    </>
  );
}
