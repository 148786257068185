/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { RequestStatus } from '@root/models/attributeUpdated';
import { TrainerLevel } from '@root/models/user';
import { OptionsType } from '@typeModel/types';
import { BookingConfirmation, BookingState } from '../enums/booking.enums';
import UserStatusEnum from '../enums/userStatus.enums';
import { capitalizeFirstLetter } from '../functions';

export enum CouponCodeTypeEnum {
  fixed = 'fixed',
  percentage = 'percentage',
}

export const CouponCodeType = [
  { label: 'Fixed', value: CouponCodeTypeEnum.fixed },
  { label: 'Percentage', value: CouponCodeTypeEnum.percentage },
];

export const UserTypesShown: OptionsType = [
  { label: 'New Users', value: 'New Users', extra: 'extra' },
  { label: 'Reapting Users', value: 'Reapting Users' },
];

export const languages: OptionsType = [
  { label: 'English', value: 'English', extra: 'extra' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'French', value: 'French' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'German', value: 'German' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Italian', value: 'Italian' },
];

export const UserGenders: OptionsType = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];
export const FreeSessionEligible: OptionsType = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const Genderpreference: OptionsType = [
  { label: 'Male', value: 'Male', extra: 'extra' },
  { label: 'Female', value: 'Female' },
  { label: 'Kids', value: 'Kids' },
];

export const TrainerTypes: OptionsType = [
  // { label: 'In-house Trainer', value: 'In-house Trainer' },
  { label: '$$$', value: 'Gold' },
  { label: '$$', value: 'Silver' },
  { label: '$', value: 'Bronze' },
];
export const MediaTagsTypes: OptionsType = [
  { label: 'Trainer Video', value: 'Trainer Video' },
  { label: 'Trainer Image', value: 'Trainer Image' },
  { label: 'Routine', value: 'Routine' },
  { label: 'Introduction', value: 'Introduction' },
  { label: 'Before & After', value: 'Before & After' },
  { label: 'Testimonial', value: 'Testimonial' },
];

export const TrainerLevels: OptionsType = [
  { label: TrainerLevel.STANDARD.toUpperCase(), value: TrainerLevel.STANDARD },
  { label: TrainerLevel.PREMIUM.toUpperCase(), value: TrainerLevel.PREMIUM },
];

export const RequestStatusOptions: OptionsType = [
  {
    label: RequestStatus.PENDING.toLocaleUpperCase(),
    value: RequestStatus.PENDING,
  },
  {
    label: RequestStatus.DENIED.toLocaleUpperCase(),
    value: RequestStatus.DENIED,
  },
  {
    label: RequestStatus.APPROVED.toLocaleUpperCase(),
    value: RequestStatus.APPROVED,
  },
];

export const PromoCodeTypes: OptionsType = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Percentage', value: 'percentage' },
];

export const RegionsCovered: OptionsType = [
  { label: 'Region 1', value: 'Region 1', extra: 'extra' },
  { label: 'Region 2', value: 'Region 2' },
  { label: 'Region 3', value: 'Region 3' },
  { label: 'Region 4', value: 'Region 4' },
];

export const Disciplines: OptionsType = [
  { label: 'Boxing', value: 'Boxing', extra: 'extra' },
  { label: 'yoga', value: 'yoga' },
  { label: 'Personal Training', value: 'Personal Training' },
  { label: 'Dancing', value: 'Dancing' },
  { label: 'Post-injury Training', value: 'Post-injury Training' },
  { label: 'Kids Training', value: 'Kids Training' },
  { label: 'Pilates', value: 'Pilates' },
  { label: 'Kickboxing', value: 'Kickboxing' },
  { label: 'Swimming', value: 'Swimming' },
  { label: 'Kickboxing', value: 'Kickboxing' },
  { label: 'Meditation', value: 'Meditation' },
  { label: 'MMA', value: 'MMA' },
  { label: 'Endurance & Training', value: 'Endurance & Training' },
];

export const UserStatusOptions: OptionsType = [
  { label: 'New Client', value: UserStatusEnum.NEW_USER },
  { label: 'Active Client', value: UserStatusEnum.ACTIVE_CLIENT },
  { label: 'Siesta Client', value: UserStatusEnum.SIESTA_CLIENT },
  { label: 'Pending Client', value: UserStatusEnum.PENDING_CLIENT },
  { label: 'Lost Client', value: UserStatusEnum.LOST_CLIENT },
  { label: 'PT Client', value: UserStatusEnum.PT_CLIENT },
  { label: 'Override', value: UserStatusEnum.OVERRIDE },
  { label: 'Archived Client', value: UserStatusEnum.ARCHIVED_CLIENT },
];

export const TrainerStatusOptions: OptionsType = [
  { label: 'New Trainer', value: UserStatusEnum.NEW_TRAINER },
  { label: 'Registered Trainer', value: UserStatusEnum.REGISTERED_TRAINER },
  { label: 'Standard Trainer', value: UserStatusEnum.STANDARD_TRAINER },
  { label: 'Pro Trainer', value: UserStatusEnum.PRO_TRAINER },
];

export const AllUserStatusOptions: OptionsType = [...UserStatusOptions, ...TrainerStatusOptions];

export const OrientationOptions: OptionsType = [
  { label: 'square', value: 'square' },
  { label: 'landscape', value: 'landscape' },
];

export const Status: OptionsType = [
  { label: 'Active', value: true },
  { label: 'InActive', value: false },
];

export const Discpliness = [
  'Boxing',
  'Meditation',
  'MMA',
  'Yoga',
  'Endurance & Training',
  'Dancing',
  'Personal Training',
  'Post-injury Training',
  'Kids Training',
  'Pilates',
  'Kickboxing',
  'Swimming',
  '',
];
// This one will be hardcoded for now because Fitlov only works in Dubai, same as mobile app
export const cities = [
  { label: 'Dubai', value: 'Dubai' },
  { label: 'Sharjah', value: 'Sharjah' },
  { label: 'Abu Dhabi', value: 'Abu Dhabi' },
];
export const nation = [
  'Mexioc',
  'Germany',
  'UAE',
  'USA',
  'Qatar',
  'Italy',
  'Spain',
];
export const month = [
  { label: 'January', value: 'january' },
  { label: 'February', value: 'february' },
  { label: 'March', value: 'march' },
  { label: 'April', value: 'april' },
  { label: 'May', value: 'may' },
  { label: 'June', value: 'june' },
  { label: 'July', value: 'july' },
  { label: 'August', value: 'august' },
  { label: 'September', value: 'september' },
  { label: 'October', value: 'october' },
  { label: 'November', value: 'november' },
  { label: 'December', value: 'december' },
];

// Later we gonna do this dynamically
export const years = [
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
];

export const Packages: OptionsType = [
  { label: 'Trail', value: 'Trail', extra: 'extra' },
  { label: '1 session', value: '1 session' },
  { label: '10 session', value: '10 session' },
  { label: '20 session', value: '20 session' },
  { label: '30 session', value: '30 session' },
];

export const UserTypes: OptionsType = [
  { label: 'New Users', value: 'New Users', extra: 'extra' },
  { label: 'Repeating Users', value: 'Repeating Users' },
];

export const Cities: OptionsType = [
  { label: 'Dubai', value: 'Dubai', extra: 'extra' },
  { label: 'Cairo', value: 'Cairo' },
  { label: 'Tokyo', value: 'Tokyo', extra: 'extra' },
  { label: 'Paris', value: 'Paris' },
];

export const BookingStatusOptions = [
  { label: 'All', value: 'All' },
  {
    label: capitalizeFirstLetter(BookingState.SCHEDULED),
    value: BookingState.SCHEDULED as string,
  },
  {
    label: capitalizeFirstLetter(BookingState.COMPLETED),
    value: BookingState.COMPLETED as string,
  },
  {
    label: capitalizeFirstLetter(BookingState.CANCELLED),
    value: BookingState.CANCELLED as string,
  },
];

export const BookingConfirmationOptions = [
  { label: 'All', value: 'All' },
  {
    label: 'Unconfirmed',
    value: BookingConfirmation.NO_ANSWER as string,
  },
  {
    label: capitalizeFirstLetter(BookingConfirmation.CONFIRMED),
    value: BookingConfirmation.CONFIRMED as string,
  },
  {
    label: capitalizeFirstLetter(BookingConfirmation.REJECTED),
    value: BookingConfirmation.REJECTED as string,
  },
];
export const BookingLateCancellationOptions = [
  { label: 'All', value: null },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const ActiveStatusOptions = [
  { label: 'All', value: 'All' },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'InActive',
    value: 'InActive',
  },
];
export const NoOfPeopleOptions = [
  { label: 'All', value: 'All' },
  {
    label: '1 on 1',
    value: '1',
  },
  {
    label: '2 on 1',
    value: '2',
  },
  {
    label: '3 on 1',
    value: '3',
  },
];

export const formatCurrency = (value: number) => {
  const formattedValue = value
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    .split('.');
  const amount = formattedValue[1] === '00'
    ? formattedValue[0]
    : `${formattedValue[0]}.${formattedValue[1]}`;
  return amount;
};

export const TransactionCreatedOptions = [
  { label: 'All', value: 'All' },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Client',
    value: 'client',
  },
  {
    label: 'Trainer',
    value: 'trainer',
  },
];

export const ClientNames = ['Ahmed', 'Jafar', 'Elio', 'Renys', 'Li'];

export const ClientTypes = [
  { label: 'All', value: 'All' },
  {
    label: 'Clients',
    value: 'Fitlov',
  },
  {
    label: 'PT Clients',
    value: 'PT Clients',
  },
];
