import TimeStartEnd from './timeStartEnd';

export default function DayAvailability({
  name,
  timeSlots,
  slotError,
  isSelected,
  onTimeChange,
  addNewSlot,
  removeSlot,
}: {
  name: string;
  timeSlots: any[];
  slotError?: string;
  isSelected: boolean;
  onTimeChange: (
    // eslint-disable-next-line no-unused-vars
    slotIndex: number,
    // eslint-disable-next-line no-unused-vars
    time: number | string,
    // eslint-disable-next-line no-unused-vars
    origin: 'start' | 'end',
    // eslint-disable-next-line no-unused-vars
    type: 'hour' | 'minute' | 'dayTime'
  ) => void;
  addNewSlot: () => void;
  // eslint-disable-next-line no-unused-vars
  removeSlot: (slotIndex: number) => void;
}) {
  const timeSlotsLength = timeSlots.length;
  if (isSelected) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      >
        <span
          style={{
            width: 44,
            paddingRight: 5,
          }}
        >
          {name}
        </span>
        <div style={{ flex: 1 }}>
          {timeSlots.map(({ startTime, endTime }, index) => (
            <TimeStartEnd
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-${startTime}`}
              startTime={startTime}
              endTime={endTime}
              timeSlots={timeSlotsLength}
              showDelete={index !== 0}
              isLast={index + 1 === timeSlotsLength}
              onTimeChange={(time, origin, type) => onTimeChange(index, time, origin, type)}
              addNewSlot={addNewSlot}
              removeSlot={() => removeSlot(index)}
            />
          ))}
          {slotError ? (
            <span style={{ marginBottom: 5, color: 'red', fontSize: '16px' }}>
              {slotError}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
  return null;
}
