import AddPlans from '../../components/modals/AddPlansModal';
import { PackageHead } from '../../components/table/content/Head';
import { PackagesRows } from '../../components/table/content/rows';
import Table from '../../components/table/table';
import PackageFirestore from '../../controllers/packagesFireStoreController';
import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';

export default function Packages() {
  const { items } = PackageFirestore();
  return (
    <>
      <div style={{ flexDirection: 'row', alignItems: 'center' }}>
        <StyledLabelTitle title="Packages" />

        <AddPlans />
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table row={PackagesRows(items)} headType={PackageHead} />
      </div>
    </>
  );
}
