/* eslint-disable */
// eslint-disable-next-line no-use-before-define
/* eslint-disable */
import { CSVLink } from "react-csv";
import { StyledSelect } from "@root/components/select/StyledDataSelect";
import { TextInput } from "@root/components/textInput";
import MonthlyBalanceController from "@root/controllers/monthlyBalanceController";
import Revenue, { CompensationType } from "@root/models/revenue";
import { generateYears } from "@root/utils/functions";
import StyledLabelTitle from "@root/utils/styles/StyledLabelTitle";
import StyledSearch from "@root/utils/styles/StyledSearch";
import { uniqBy } from "lodash";
import Button from "@atlaskit/button/loading-button";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
  BalanceHead,
  BalanceSummaryHead,
} from "../../components/table/content/Head";
import {
  MonthlyBalancerows,
  MonthlyBalanceSummaryrows,
} from "../../components/table/content/rows";
import Table from "../../components/table/table";
import { formatCurrency, month as months } from "../../utils/common/data";
import colors from "@root/utils/colors";
import { RevenueType } from "@models/revenue";
import { getStats } from "@controllers/trainerBookingController";

const currentDate = moment();
const monthsList = months.map((item, index) => ({
  label: item.label,
  value: index + 1,
}));
const yearsList = generateYears(2020, currentDate.year()).map((item) => ({
  label: item,
  value: item,
}));

export default function MonthlyBalance() {
  const [data, setData] = useState<Revenue[]>([]);
  const [summary, setSummary] = useState([]);
  const [year, setYear] = useState({
    label: currentDate.year(),
    value: currentDate.year(),
  });
  const [month, setMonth] = useState({
    label: currentDate.format("MMMM"),
    value: Number(currentDate.get("month") + 1) as number,
  });
  const [allTrainersWithSessions, setAllTrainersWithSessions] = useState<
    { label: string; value: string }[]
  >([]);
  const [trainer, setTrainer] = useState<{
    label: string;
    value: string;
    compensation: {
      type: CompensationType;
      firstPackage: number;
      renewal: number;
    };
  }>();

  const [client, setClient] = useState<{
    label: string;
    value: string;
    compensation: {
      type: CompensationType;
      firstPackage: number;
      renewal: number;
    };
  }>();

  const [compensationValue, setCompensationValue] = useState<number>();
  const [completedBookings, setCompletedBookings] = useState<number>();

  const totalOfSummarySessions = useMemo(
    () => summary.reduce((acc: any, curr: any) => acc + curr.totalSessions, 0),
    [summary]
  );

  const totalOfSummaryPricePaid = useMemo(
    () => summary.reduce((acc: any, curr: any) => acc + curr.totalPaid, 0),
    [summary]
  );

  const totalOfSummaryTrainerCompensation = useMemo(
    () =>
      summary.reduce(
        (acc: any, curr: any) => acc + curr.totalTrainerCompensation,
        0
      ),
    [summary]
  );

  const totalOfSummaryFitlovComission = useMemo(
    () =>
      summary.reduce(
        (acc: any, curr: any) => acc + curr.totalFitlovCommission,
        0
      ),
    [summary]
  );

  const clientOptions = useMemo(() => {
    if (!clientOptions) {
      const options = data.map((revenue) => ({
        label: revenue.clientName,
        value: revenue.clientId,
      })) as { label: string; value: string }[];

      return uniqBy(options, (item) => item.value);
    }
  }, [data]);

  useEffect(() => {
    getData();
    getTrainerStats();
  }, [year, month, trainer, client]);

  useEffect(() => {
    const summaryData = {};
    data.forEach((item: Revenue) => {
      const clientName = item.clientName || "Unknown";
      if (!summaryData[clientName]) {
        summaryData[clientName] = {
          totalSessions: 0,
          totalPaid: 0,
          totalTrainerCompensation: 0,
          totalFitlovCommission: 0,
        };
      }
      summaryData[clientName].totalSessions += 1;
      summaryData[clientName].totalPaid += Math.round(
        item.pricePerSession || 0
      );
      summaryData[clientName].totalTrainerCompensation += Math.round(
        item.trainerCompensation || 0
      );
      summaryData[clientName].totalFitlovCommission += Math.round(
        item.fitlovCommission || 0
      );
      // summaryData[clientName].totalPaid = summaryData[clientName].totalPaid.toFixed(2);
    });

    const summaryDataArray = Object.keys(summaryData).map((key) => {
      const item = summaryData[key];
      return {
        clientName: key,
        totalSessions: item.totalSessions,
        totalPaid: item.totalPaid,
        totalTrainerCompensation: item.totalTrainerCompensation,
        totalFitlovCommission: item.totalFitlovCommission,
      };
    });

    setSummary(summaryDataArray as any);
  }, [data]);

  const getTrainerStats = () => {
    if (trainer?.value) {
      setCompletedBookings(0);
      getStats(trainer.value, month.label, year.value).then((result) => {
        console.log("result: ", result);
        setCompletedBookings(result.completedBookings);
      });
    }
  };

  const getData = async () => {
    const data = await MonthlyBalanceController.get(
      month.value,
      year.value,
      trainer?.value,
      client?.value
    );
    let parsedData = [...data] as Revenue[];
    const trainers: { label: string; value: string }[] = [];
    for (let i = 0; i < parsedData.length; i++) {
      const item = parsedData[i];
      if (!trainers.find((trainer) => trainer.value === item.trainerId)) {
        trainers.push({
          label: item.trainerName as string,
          value: item.trainerId,
        });
      }

      // Check if item is duplicated in parsedData[i]
      const duplicatedItem = parsedData.find(
        (current) =>
          current.id !== item.id &&
          current.sessionDate === item.sessionDate &&
          current.clientId === item.clientId &&
          current.trainerId === item.trainerId
      );

      if (duplicatedItem) {
        parsedData[i].isDuplicated = true;
      }
    }
    setAllTrainersWithSessions(trainers);
    setData(parsedData);
  };

  const csvData = useMemo(() => {
    const arrays: Array<Array<string | number>> = [];
    arrays.push(["Trainer", "Month", "Total sessions", "Total compensation"]);

    arrays.push([
      trainer?.label as string,
      month.label,
      totalOfSummarySessions,
      formatCurrency(totalOfSummaryTrainerCompensation) + " AED",
    ]);
    arrays.push([]);

    arrays.push([
      "Session date",
      "Client name",
      "Package type",
      "Trainer commission",
    ]);

    data.forEach((item) => {
      arrays.push([
        item.sessionDate,
        item.clientName as string,
        item.type === RevenueType.firstPackage ? "First" : "Renewal",
        item.trainerCompensation + " AED",
      ]);
    });
    return arrays;
  }, [data, totalOfSummarySessions, totalOfSummaryTrainerCompensation]);

  const updateCompensationRows = async () => {
    const newData: Revenue[] = [];
    await Promise.all(
      data.map((item) => {
        if (
          item.trainerId == trainer?.value &&
          item.clientId == client?.value
        ) {
          newData.push({
            ...item,
            trainerCompensation: Number(compensationValue || 0),
            note: "Admin updates trainer compensation",
            fitlovCommission: Number(
              (item.pricePerSession - Number(compensationValue)).toFixed(2)
            ),
          });
          return MonthlyBalanceController.update(item.id, {
            trainerCompensation: Number(compensationValue || 0),
            note: "Admin updates trainer compensation",
            fitlovCommission: Number(
              (item.pricePerSession - Number(compensationValue)).toFixed(2)
            ),
          });
        }
      })
    );
    setData(newData);
  };

  return (
    <>
      <div>
        <StyledLabelTitle title="Monthly balance" />
        <div
          style={{
            marginTop: 20,
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridGap: 20,
          }}
        >
          <StyledSelect
            labelWidth="20%"
            name="Year"
            value={year as any}
            options={yearsList as any}
            onChange={(val) => {
              setYear(val);
            }}
          />
          <StyledSelect
            labelWidth="20%"
            name="Month"
            value={month}
            options={monthsList}
            onChange={(val) => {
              setMonth(val);
            }}
          />
          <div
            className="full-width-grid"
            style={{ marginTop: "1.2em", marginBottom: "1.2em" }}
          >
            <div className="inputWrapper">
              <div className="label" style={{}}>
                Assigned trainer
              </div>
              <div className="input" />
              <StyledSearch
                value={trainer}
                data={allTrainersWithSessions}
                placeholder="Assign trainer"
                selectUser={(item, isClear) => {
                  if (!isClear) {
                    setTrainer(item);
                  } else {
                    setTrainer(undefined);
                  }
                }}
                isClearable
              />
            </div>
          </div>
          <div className="inputWrapper">
            <div className="label" style={{}}>
              Client
            </div>
            <div className="input" />
            <StyledSearch
              value={client}
              data={clientOptions || []}
              placeholder="Select client"
              selectUser={(item, isClear) => {
                if (!isClear) {
                  setClient(item);
                } else {
                  setClient(undefined);
                }
              }}
              isClearable
            />
          </div>
        </div>
        {Boolean(trainer?.value && client?.value && data.length > 0) && (
          <div
            style={{
              marginTop: "1.2em",
              marginBottom: "1.2em",
              width: "max-content",
              display: "flex",
            }}
          >
            <TextInput
              label="Change all compensation rows"
              defaultValue={""}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                const value: number = Number(e.currentTarget.value);
                setCompensationValue(value);
              }}
            />

            <Button
              style={{ marginLeft: "1em" }}
              onClick={updateCompensationRows}
            >
              Update
            </Button>
          </div>
        )}
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table headType={BalanceHead} row={MonthlyBalancerows(data, getData)} />
      </div>
      {trainer && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <CSVLink
            filename={`${trainer.label} [${month.label} ${year.value} FITLOV]`}
            style={styles.downloadCSV}
            data={csvData}
          >
            Download CSV
          </CSVLink>
        </div>
      )}

      <div style={{ marginTop: 20 }}>
        <StyledLabelTitle title="Summary" />
        <Table
          headType={BalanceSummaryHead}
          row={MonthlyBalanceSummaryrows(summary)}
        />
        <p>
          <b>Total sessions:</b> {totalOfSummarySessions}
          {Boolean(trainer?.value) && Boolean(completedBookings) && (
            <>
              {" "}
              | <b>Completed bookings:</b> {completedBookings}
            </>
          )}
        </p>
        <p>
          <b>Total paid:</b>{" "}
          {totalOfSummaryPricePaid.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}{" "}
          AED
        </p>
        <p>
          <b>Total trainer compensation:</b>{" "}
          {totalOfSummaryTrainerCompensation.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}{" "}
          AED
        </p>
        <p>
          <b>Total fitlov commission:</b>{" "}
          {totalOfSummaryFitlovComission.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}{" "}
          AED
        </p>
      </div>
    </>
  );
}

const styles = {
  downloadCSV: {
    textDecoration: "none",
    backgroundColor: colors.darkGray,
    color: "white",
    padding: 10,
    borderRadius: 3,
  },
};
