/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useFilePicker } from 'use-file-picker';
import { DisciplineServices, DisciplineServicesOptions, DisciplineV2 } from '@root/models/disciplines';
import disciplinesFirestore from '@root/controllers/disciplinesFirebaseController';
import CustomModalHeader from './modalHeader';
import AvatarPicture from '../avatar/Avatar';
import { StyledSelect } from '../select/StyledDataSelect';

export default function AddDiscipline() {
  const [isOpen, setIsOpen] = useState(false);
  const { addDiscipline } = disciplinesFirestore();
  const [formError, setFormError] = useState<string | null>(null);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const [openFileSelector, {
    filesContent, clear,
  }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const onSubmit = async (data) => {
    if (filesContent[0]?.content) {
      try {
        const {
          order, name, type, active,
        } = data;
        const dsicipline: DisciplineV2 = {
          order: Number(order),
          trainerType: name,
          type: type?.value ?? DisciplineServices.fitness,
          active: active?.value ?? false,
        };
        console.log({ dsicipline });
        await addDiscipline(dsicipline, filesContent[0].content);
        clear();
        closeModal();
        setFormError(null);
      } catch (err) {
        // console.log({ err });
      }
    } else {
      setFormError('Please select image');
    }
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        (+) Add New Discipline
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New Discipline" />
                  <ModalBody>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <AvatarPicture pictureUrl={filesContent[0]?.content} />
                      <Button
                        onClick={openFileSelector}
                        appearance="primary"
                        style={{
                          backgroundColor: '#3FC7E0',
                          marginLeft: 10,
                        }}
                      >
                        Upload Image
                      </Button>
                    </div>
                    {formError && <ErrorMessage>{formError}</ErrorMessage>}
                    <Field id="order" name="order" label="Order" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} type="number" value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="name" name="name" label="Name" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="type" name="type" label="Type" isRequired>
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            validationState={error ? 'error' : 'default'}
                            inputId={id}
                            name="Type"
                            options={DisciplineServicesOptions}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="name" name="active" label="active" isRequired>
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            validationState={error ? 'error' : 'default'}
                            inputId={id}
                            name="Active"
                            options={
                              [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                              ]
                            }
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
