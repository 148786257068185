/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

/*
  Explanation for PackagesServices:
  The client can have multiple packages.
  A package could have multiple services, none or just one.
  If for example a package only have NUTRITION as a servicesAllowed, the client can only use that package to get nutrition sessions.
  If package only have trainerId, doesn't matter (at least for now) if have servicesAllowed or not, maybe later this can change.
  If is a FULL package, can use all services (at least for now)
*/
export enum PackagesServices {
  FULL = 'full',
  TRAINING = 'training',
  WELLNESS = 'wellness',
}

export enum PackagesCodes {
  FREE_SESSION = 'free_session',
  PT_CLIENT = 'pt_client',
}

export type ClientPackage = {
  id: string;
  code: string; // pt_client, free_session, promo2022, etc
  userId: string;
  trainerId?: string;
  trainerName?: string;
  clientName?: string;
  sessions: number;
  servicesAllowed?: string[];
  createdAt: { toDate: () => Date };
  updatedAt: any;
  billingHistoryId?: string;
  price: number; // Full package price
  // New for open market things
  pricePerSession: number;
  package?: any;
  expirationDate?: { toDate: () => Date };
  archived?: boolean;
  renewal?: boolean;
  canRemove?: boolean;
  completedBookings?: number;
  scheduledBookings?: number;
  trainerPaid?: boolean;
  comment?:string
};
