import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';
import Table from '../../components/table/table';
import { OffersHead } from '../../components/table/content/Head';
import { OffersRows } from '../../components/table/content/rows';
import AddOffer from '../../components/modals/AddNewOfferModal';
import { OffersFirestore } from '../../controllers/offersFirestoreController';

export default function Offers() {
  const { items } = OffersFirestore();
  return (
    <div>
      <div className="Top">
        <StyledLabelTitle title="Offers" />
        <AddOffer />
        {/* change to add offer */}
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table headType={OffersHead} row={OffersRows(items)} defaultSortKey="Order" defaultSortOrder="ASC" />
      </div>
    </div>
  );
}
