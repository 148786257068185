/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
// esling-disable file
import AddPreBooking from '@root/components/modals/AddNewPreBookingModal';
import { preBookingsHead } from '@root/components/table/content/Head';
import { PreBookingsRows } from '@root/components/table/content/rows';
import Table from '@root/components/table/table';
import { fetchTrainers } from '@root/controllers/trainerFirestoreController';
import { UseUsersFirestore } from '@root/controllers/userFirestoreController';
import PreBooking from '@root/models/preBooking';
import { Trainer } from '@root/models/trainer';
import collections from '@root/utils/enums/collections.enums';
import { getCollection } from '@root/utils/firestoreService';
import StyledLabelTitle from '@root/utils/styles/StyledLabelTitle';
import { useEffect, useState } from 'react';
import dummyData from './preBookings.json';

function PreBookings() {
  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const { trainers: trainerList } = UseUsersFirestore();
  const [data, setData] = useState<PreBooking[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTrainers().then((result) => {
      setTrainers(result);
    });

    getCollection(collections.PRE_BOOKINGS)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        setLoading(false);
        setData(snapshot.docs.map((doc) => doc.data() as PreBooking));
      });
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <StyledLabelTitle title="Pre-bookings" />
        <AddPreBooking />
      </div>
      <br />
      <br />
      <Table
        isLoading={loading}
        headType={preBookingsHead}
        row={PreBookingsRows(data, trainerList)}
      />
    </>
  );
}

export default PreBookings;
