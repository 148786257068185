/* eslint-disable no-unused-vars */
/* eslint-disable import/export */
/* eslint-disable no-shadow */

enum UserStatusEnum {
  // User status'
  NEW_USER = 'NEW_USER', // never completed a session or balance = 0
  ACTIVE_CLIENT = 'ACTIVE_CLIENT', // completed a session and/or balance > 0
  SIESTA_CLIENT = 'SIESTA_CLIENT', // clients with balance > 0 , NO scheduled sessions and more than 1 of last completed session
  PENDING_CLIENT = 'PENDING_CLIENT', // client with balance = 0 and less than 2 months of last completed session
  LOST_CLIENT = 'LOST_CLIENT', // client with balance = 0 and more than 2 months of last completed session
  PT_CLIENT = 'PT_CLIENT',
  OVERRIDE = 'OVERRIDE',
  ARCHIVED_CLIENT = 'ARCHIVED_CLIENT',

  // trainer status
  NEW_TRAINER = 'NEW_TRAINER',
  REGISTERED_TRAINER = 'REGISTERED_TRAINER',
  PRO_TRAINER = 'PRO_TRAINER',
  STANDARD_TRAINER = 'STANDARD_TRAINER',
}

export default UserStatusEnum;
