/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  useCallback, useEffect, useState,
} from 'react';

import Button from '@atlaskit/button/standard-button';
import Form, {
  Field,
  ErrorMessage,
} from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import dotenv from 'dotenv';
import CitiesController from '@root/controllers/citiesController';
import { OptionType } from '@atlaskit/select';
import CountryController from '@root/controllers/countries.controller';
import {
  useFirestore,
} from '../../controllers/userFirestoreController';
import CustomModalHeader from './modalHeader';
import FormFieldInput from '../textInput/formFieldInput';
import { StyledSelect } from '../select/StyledDataSelect';

dotenv.config();

export function validateEmail(elementValue) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
}
// const Roles = {
//   trainer: 'trainer',
//   user: 'user',
//   mindfullness: 'mindfullness',
//   nutrition: 'nutrition',
// };

export function AddCity() {
  const [isOpen, setIsOpen] = useState(false);
  const [countries, setCountries] = useState<OptionType[]>([]);
  const [formError, setFormError] = useState<string>('');
  const [countryValue, setCountryValue] = useState<OptionType | null>(null);
  const { addUser } = useFirestore();

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
    setFormError('');
  }, []);

  const getCountries = async () => {
    const data = await CountryController.getAll();
    setCountries(data);
  };
  useEffect(() => {
    getCountries();
  }, []);
  const onSubmit = useCallback(
    async (data) => {
      try {
        setFormError('');
        if (!countryValue) {
          setFormError('Please select country');
          return;
        }
        await CitiesController.saveCity({
          name: data.name,
          country: countryValue.value.toString(),
          isActive: false,
        });
        closeModal();
      } catch (error) {
        console.log('addUser error', error);
      }
    },
    [addUser, closeModal],
  );

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
        }}
      >
        Add New City
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add new city" />
                  <ModalBody>
                    <Field
                      id="name"
                      name="name"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Name is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            label="Name"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <div style={{ marginTop: 8, marginBottom: 8 }}>
                      <StyledSelect
                        name="Country"
                        value={countryValue as OptionType}
                        options={countries}
                        isSearchable
                        onChange={(option) => {
                          setCountryValue(option);
                        }}
                      />
                    </div>
                    {formError && <ErrorMessage>{formError}</ErrorMessage>}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
