/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import {
  FormEvent,
  useCallback, useState,
} from 'react';

import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import dotenv from 'dotenv';
// import UserStatusEnum from '@root/utils/enums/userStatus.enums';
import axios from 'axios';
import endpointFunctions from '@root/utils/endpoints';
import Select, { OptionType } from '@atlaskit/select';
import { TrainerStatusOptions } from '@root/utils/common/data';
import TextArea from '@atlaskit/textarea';
import { toast } from 'react-toastify';
import CustomModalHeader from './modalHeader';
import FormFieldInput from '../textInput/formFieldInput';

dotenv.config();

export default function BroadcastModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [userGroups, setUserGroups] = useState<OptionType[]>([]);
  const [multiSelectionError, SetMultiSelectionError] = useState<string | null>(null);
  const [messageCounter, setMessageCounter] = useState<number>(0);
  const maximumNumberOfletters = 250;

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSubmit = async (data) => {
    const { message, title } = data;
    try {
      const finalUserGroups = userGroups.map((e) => e.value);
      // handle userGroups errors:
      if (finalUserGroups.length === 0) {
        SetMultiSelectionError('Please select at least one of the user groups');
        return;
      }

      await axios.post(endpointFunctions.notify_Trainers, {
        title,
        message,
        userGroups: finalUserGroups,
      }).then((response) => {
        toast.success(response.data);
      }).catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log('server responded');
          toast.error('Broadcast error');
        } else if (error.request) {
          console.log('network error');
        } else {
          console.log(error);
        }
      });

      closeModal();
      setMessageCounter(0);
    } catch (error) {
      // console.log('broadcast error', error);
      toast.error('Broadcast error');
    }
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
        }}
      >
        broadcast
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={() => { closeModal(); setMessageCounter(0); }}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Broadcast" />
                  <ModalBody>
                    <Field id="title" name="title" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            type="text"
                            label="Title"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}

                        </>
                      )}
                    </Field>
                    <Field id="message" name="message" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <TextArea
                            isRequired
                            placeholder="Enter your message"
                            maxLength={maximumNumberOfletters}
                            {...fieldProps}
                            style={{ marginTop: '10px' }}
                            onChange={(
                              e: FormEvent<HTMLInputElement>,
                            ) => {
                              const curValue = e.currentTarget;
                              setMessageCounter(curValue.value.length);
                            }}
                          />
                          <HelperMessage>
                            {messageCounter}
                            /
                            {maximumNumberOfletters}
                            {' '}
                            Characters
                          </HelperMessage>
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>

                      )}
                    </Field>

                  </ModalBody>
                  <div style={{ marginLeft: '25px', marginRight: '25px', marginTop: '10px' }}>
                    <Select
                      inputId="multi-select-region"
                      className="multi-select"
                      classNamePrefix="react-select"
                      placeholder="Select user group..."
                      options={TrainerStatusOptions}
                      value={userGroups || []}
                      onChange={(value) => {
                        setUserGroups(value as any);
                        SetMultiSelectionError(null);
                      }}
                      isMulti
                      isSearchable={false}
                    />
                    {multiSelectionError && <ErrorMessage>{multiSelectionError}</ErrorMessage>}
                  </div>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
