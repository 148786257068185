/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import FirestoreService from '@root/utils/firestoreService';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';
import { Package } from '../models/package';

const PackageFirestore = () => {
  const [items, setItems] = useState<Package[]>([]);

  useEffect(() => {
    const unsubscribe = db.collection(collections.PLANS).onSnapshot((snap) => {
      const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setItems(fetched as Package[]);
    });
    return unsubscribe;
  }, []);

  const updatePackageFirestore = (packages: Package) => {
    try {
      return db.collection(collections.PLANS).doc(packages.id).update(packages);
    } catch (error) {
      // console.log('===Error updating trainer====', error);
    }
  };

  const addPackage = async (object: Package) => {
    try {
      const response = await FirestoreService.addObjectToCollection(
        collections.PLANS,
        object,
      );
      response.update({ id: response.id });
    } catch (error) {
      // console.log('create package error', error);
    }
  };

  const deletePackage = (id: string) => {
    try {
      return db.collection(collections.PLANS).doc(id).delete();
    } catch (error) {
      // console.log('===Error deleting package====', error);
    }
  };

  return {
    items, updatePackageFirestore, addPackage, deletePackage,
  };
};

export default PackageFirestore;
