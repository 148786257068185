import { CityTypes } from '@root/models/city';
/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';

export const useCitiesFirestore = () => {
  const [cities, setCities] = useState<CityTypes[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.CITIES)
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setCities(fetched as CityTypes[]);
      });
    return unsubscribe;
  }, []);
  const updateCity = (cityId: string, city:CityTypes) => {
    try {
      return db
        .collection(collections.CITIES)
        .doc(cityId)
        .update(city);
    } catch (error) {
      // console.log('===Error updating Cities====', error);
    }
  };
  const deleteCity = (id: string) => {
    try {
      return db.collection(collections.CITIES).doc(id).delete();
    } catch (error) {
      // console.log('===Error deleting Gym====', error);
    }
  };

  return {
    cities, setCities, updateCity, deleteCity,
  };
};

export default class CitiesFirestoneController {
  static async getCitybyId(id: string): Promise<CityTypes> {
    const response = await db.collection(collections.CITIES).doc(id).get();
    return { ...response.data(), id: response.id };
  }
}
