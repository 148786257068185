import Avatar from '@atlaskit/avatar';

function AvatarPicture({
  pictureUrl,
}: {
  pictureUrl: string | undefined;
}) {
  return (
    <Avatar
      appearance="circle"
      src={pictureUrl}
      size="xxlarge"
      name="John Doe"
    />
  );
}

export default AvatarPicture;
