import Select from '@atlaskit/select';
import { OptionType } from '../../types/types';

export default function StyledSearch({
  value,
  data,
  placeholder,
  selectUser,
  isClearable, ...rest
}
 :{
   data: OptionType[],
   placeholder: string,
   isClearable?: boolean,
   value?: OptionType,
    // eslint-disable-next-line no-unused-vars
    selectUser: any// (arg: OptionType | null, isClear?: boolean) => void
  }) {
  return (
    <div style={{ width: '100%' }}>
      <Select
        options={data}
        placeholder={placeholder}
        isClearable={isClearable}
        value={value}
        onChange={(d, actionMeta) => {
          if (actionMeta.action === 'select-option') {
            selectUser(d);
          }
          if (actionMeta.action === 'clear') {
            selectUser(actionMeta.removedValues[0], true);
          }
        }}
        {...rest}
      />
    </div>
  );
}
