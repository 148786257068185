/* eslint-disable array-callback-return */
// import _ from 'lodash';
import { useEffect, useState } from 'react';
import BillingController from '@root/controllers/billingFirestoreController';
import { TransactionHead } from '../../../components/table/content/Head';
import { TransactionsRows } from '../../../components/table/content/rows';
import Table from '../../../components/table/table';
import StyledLabelTitleLarge from '../../../utils/styles/StyledLabelTitleLarge';

export default function TrainerPTTransactionsTable({
  trainerId,
}: {
  trainerId: string;
}) {
  // const [loading, setLoading] = useState<boolean>(true);
  const [transactions, setTransactions] = useState<any[]>([]);

  const getTransactions = async () => {
    const data = await BillingController.getTrainerPtTransactions(trainerId);
    setTransactions(data as any);
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <div className="Bottom Content" style={{ marginTop: 40, marginLeft: 20 }}>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <StyledLabelTitleLarge title="PT transactions" />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        <Table
          row={TransactionsRows(transactions)}
          headType={TransactionHead}
          defaultSortKey="createdAtKey"
        />
      </div>
    </div>
  );
}
