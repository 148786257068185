import { useMemo } from 'react';
import { Review } from '@models/review';
import { useTrainerBookings } from '@controllers/trainerBookingController';
import { useTrainerReviews } from '@controllers/reviewsFirestoreController';
import _ from 'lodash';
import StyledLabelTitle from '@root/utils/styles/StyledLabelTitle';
import StyledUserProfileElementRightLarge from '../../../utils/styles/StyledUserProfileRightLarge';
import StyledUserProfileElementsLarge from '../../../utils/styles/StyledUserProfileElementsLarge';
import StyledUserProfileElementDropDown from '../../../utils/styles/StyledUserProfileDropDown';
import { month } from '../../../utils/common/data';

export default function TrainerProfileBottomStatistics({
  trainerId,
}: {
  trainerId: string;
}) {
  const {
    totalBookingCount,
    scheduledBookingCount,
    avgBookingPerClientCount,
    completedBookingCount,
    monthFilter,
    updateMonth,
  } = useTrainerBookings(trainerId);
  const { totalTrainerReviews } = useTrainerReviews(trainerId);
  const avgRate = useMemo(() => {
    if (totalTrainerReviews.length === 0) {
      return '0.0';
    }
    const sumScore = _.reduce(
      totalTrainerReviews,
      (result: number, review: Review) => (
        result
          + Number(Number(review?.qualityRate)
            + Number(review?.friendlinessRate)
            + Number(review?.punctualityRate))
            / 3
      ),
      0,
    );
    return (
      Math.round((sumScore / totalTrainerReviews.length) * 100) / 100
    ).toString();
  }, [totalTrainerReviews]);

  return (
    <div className="Bottom Content" style={{ marginTop: 20 }}>
      <StyledLabelTitle title="Trainer statistics" />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementDropDown
            label="month"
            name={monthFilter || 'Month'}
            value={monthFilter}
            options={month}
            onChangeValue={updateMonth}
          />
          <StyledUserProfileElementRightLarge
            name="Scheduled sessions"
            placeholder={scheduledBookingCount.toString()}
          />

          <StyledUserProfileElementRightLarge
            name="Completed sessions"
            placeholder={completedBookingCount.toString()}
          />
        </div>

        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementsLarge
            name="Total Sessions with Fitlov"
            placeholder={totalBookingCount.toString()}
          />
          <StyledUserProfileElementRightLarge
            name="Avg. Sessions per client"
            placeholder={avgBookingPerClientCount.toString()}
          />

          <StyledUserProfileElementRightLarge
            name="Renewal Rate"
            placeholder="80%"
          />
        </div>

        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementsLarge
            name="Overall availability to Fitlov next 7 days"
            placeholder="90%"
          />
          <StyledUserProfileElementRightLarge
            name="Peak hour availability next 7 days"
            placeholder="90%"
          />
        </div>
        <div
          className=" Top-content"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <StyledUserProfileElementsLarge
            name="Overall rating"
            placeholder={avgRate}
          />
          <StyledUserProfileElementRightLarge
            name="Client reviews"
            placeholder={totalTrainerReviews.length.toString()}
          />
        </div>
      </div>
    </div>
  );
}
