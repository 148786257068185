/* eslint-disable max-len */
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Toggle from '@atlaskit/toggle';
import { Booking } from '@models/booking';
import { Trainer } from '@models/trainer';
import { User } from '@models/user';
import { LoadingButton } from '@atlaskit/button';
import { BookingState } from '@root/utils/enums/booking.enums';
import functions, {
  convertTo12hTime, daysOfweekShift, makeCopy, timeSlotsNotOverlapping,
} from '@utils/functions';
import StyledLabelTitleLarge from '@utils/styles/StyledLabelTitleLarge';
import _ from 'lodash';
import moment from 'moment';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import AvailabilitySlot from '@root/types/availabilitySlot';
import { WorkingDaysType } from '@root/models/workingHours';
import defaultSlots, { displayDays } from '@root/data/defaultSlots';
import { getTrainerAvailability, saveTrainerAvailability, updateTrainerAwayMode } from '@root/controllers/trainerFirestoreController';
import { DateInput } from '@root/components/textInput';
import DaysSelector from './components/daysSelector';
import DayAvailability from './components/dayAvailability';

const dateFormat = 'YYYY-MM-DDTHH:mm:00.000Z';
const timeFormat = 'HH:mm:00.000Z';

const startOfWeekMonday = true;
const moment12HourFormat = 'hh:mm:A';
const awayDateFormat = 'DD/MM/YYYY';

export default function TrainerAvailabilityView({
  trainer,
  trainerBookings,
}: {
  trainer: Trainer & User;
  trainerBookings: Booking[];
}) {
  // const [lastRefreshTimestamp, setLastRefreshTimestamp] = useState(
  //   new Date().getTime(),
  // );
  const [bookedSlots, setBookedSlots] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAvailability, setLoadingAvailability] = useState<boolean>(false);

  const [selectedDays, setSelectedDays] = useState([1, 2, 3, 4, 5]);
  const [daysOfWeek, setDaysOfWeek] = useState<WorkingDaysType[]>(defaultSlots);
  const [awayMode, setAwayMode] = useState(false);

  const [awayModeStart, setAwayModeStart] = useState<string | undefined>(undefined);
  const [awayModeEnd, setAwayModeEnd] = useState<string | undefined>(undefined);

  const [slotsError, setSlotsError] = useState<string[]>([]);

  const [saving, setSaving] = useState(false);

  const parseBookedSlot = useCallback(async () => {
    setLoading(true);
    const naArray: any = [];
    try {
      const slots = await Promise.all(
        _.map(trainerBookings, async (booking) => {
          const startTime = convertTo12hTime(booking?.slot?.startHour as number);
          const endTime = convertTo12hTime(booking?.slot?.endHour as number);
          const startAt = moment(`${booking?.date as string},${startTime}`).format(dateFormat);
          const endAt = moment(`${booking?.date as string},${endTime}`).format(dateFormat);
          let color = 'blue';
          switch (booking?.status) {
            case BookingState.CANCELLED:
              color = '#d32f2f';
              break;
            case BookingState.COMPLETED:
              color = '#2e7d32';
              break;
            default:
              color = '#1976d2';
              break;
          }
          return {
            id: booking.id,
            start: startAt,
            end: endAt,
            title: await functions.getFullName(booking.userId),
            color,
          };
        }),
      );
      await Promise.all(
        _.map(trainer.timeNotAvailable, (na) => {
          if (na.isWeekly) {
            const arr = {
              id: Number(na.id),
              groupId: 'Weekly',
              startTime: moment(`${na.date},${convertTo12hTime(na?.startHour as number)}`).format(timeFormat),
              endTime: moment(`${na.date},${convertTo12hTime(na?.endHour as number)}`).format(timeFormat),
              color: 'grey',
              daysOfWeek: [moment(na.date).day()],
              title: '',
            };
            naArray.push(arr);
          }
          const arr = {
            id: na.date + String(na.id),
            start: moment(`${na?.date as string},${convertTo12hTime(na?.startHour as number)}`).format(dateFormat),
            end: moment(`${na?.date as string},${convertTo12hTime(na?.endHour as number)}`).format(dateFormat),
            color: 'grey',
            title: '',
          };
          naArray.push(arr);
        }),
      );
      const allSlots = [...slots, ...naArray];
      setBookedSlots(allSlots as any);
      setLoading(false);
    } catch (error) {
      // console.log(error)
    }
  }, [trainerBookings, trainer]);

  const savedWorkingDays = async () => {
    // fetch saved working days if trainer hasnt set in database
    const data = await getTrainerAvailability(trainer?.id as string);
    const dbSavedSelectedDays: number[] = [];

    // check if it exists in database and set the saved copy to state
    const newData = data.map((workingDay) => {
      if (workingDay.timeSlots.length < 1) {
        // eslint-disable-next-line no-param-reassign
        workingDay.timeSlots = defaultSlots[0].timeSlots;
      } else {
        dbSavedSelectedDays.push(workingDay.index);
      }
      return workingDay;
    });

    if (trainer?.awayMode) {
      setAwayMode(trainer?.awayMode);
      setAwayModeStart(trainer?.awayModeStart ? moment(trainer?.awayModeStart, awayDateFormat, true).format('YYYY-MM-DD') : undefined);
      setAwayModeEnd(trainer?.awayModeEnd ? moment(trainer?.awayModeEnd, awayDateFormat, true).format('YYYY-MM-DD') : undefined);
    }
    setSelectedDays(dbSavedSelectedDays);
    setDaysOfWeek(newData);
    setLoadingAvailability(false);
    // setLastRefreshTimestamp(new Date().getTime());
  };

  useEffect(() => {
    savedWorkingDays();
    parseBookedSlot();
    return () => {};
  }, []);

  const handleSelectedEvents = async (event) => {
    const tempArray = [...selectedEvents];
    const eventFormatted = { id: Number(event.id), date: moment(event.startStr).format('MM/DD/yyyy') };
    const isExists = _.some(selectedEvents, { id: eventFormatted.id, date: eventFormatted.date });
    if (!isExists) {
      setSelectedEvents([...selectedEvents, eventFormatted]);
    } else {
      _.remove(tempArray, { id: eventFormatted.id, date: eventFormatted.date });
      setSelectedEvents([...tempArray]);
    }
  };

  const makeAvailable = async () => {
    functions.makeAvailableTimeSlotsV2(
      trainer.id as string,
      selectedEvents,
      () => parseBookedSlot(),
      trainer.timeNotAvailable as AvailabilitySlot[],
    );
  };

  const selectDay = (index: number) => {
    const selectedDaysCopy = selectedDays;
    const exists = selectedDays.findIndex((da) => da === index);
    if (exists >= 0) {
      setSelectedDays(
        _.sortBy(selectedDaysCopy.filter((de) => de !== index)),
      );
    } else {
      selectedDaysCopy.push(index);
      setSelectedDays(_.sortBy(selectedDaysCopy));
    }
  };
  const onTimeChange = (
    objIndex: number,
    slotIndex: number,
    time: number | string,
    origin: 'start' | 'end',
    type: 'hour' | 'minute' | 'dayTime',
  ) => {
    // const daysOfWeekLocal = makeCopy(daysOfWeek) as WorkingDaysType[];

    // search for index of the selected day
    const index = daysOfWeek.findIndex(
      (dayOfWeek) => dayOfWeek.index === objIndex,
    );

    // slot thats going to be edited reference
    const slotRef = daysOfWeek[index].timeSlots[slotIndex];

    // hour start time inputs
    const currStartTime = slotRef.startTime.split(':');
    let startHour = currStartTime[0];
    let startMinutes = currStartTime[1];
    let startDayTime = currStartTime[2];

    // hour end time inputs
    const currEndTime = slotRef.endTime.split(':');
    let endHour = currEndTime[0];
    let endMinutes = currEndTime[1];
    let endDayTime = currEndTime[2];

    if (origin === 'start') {
      if (type === 'hour') {
        startHour = time.toString().padStart(2, '0');
      } else if (type === 'minute') {
        startMinutes = time.toString().padStart(2, '0');
      } else {
        startDayTime = time.toString();
      }

      slotRef.startTime = `${startHour}:${startMinutes}:${startDayTime}`;
    } else {
      if (type === 'hour') {
        endHour = time.toString().padStart(2, '0');
      } else if (type === 'minute') {
        endMinutes = time.toString().padStart(2, '0');
      } else {
        endDayTime = time.toString();
      }
      slotRef.endTime = `${endHour}:${endMinutes}:${endDayTime}`;
    }
    setDaysOfWeek(daysOfWeek);
    // setLastRefreshTimestamp(new Date().getTime());
  };

  const addNewSlot = (objIndex: number) => {
    // add new slot to day of week

    const daysOfWeekLocal = makeCopy(daysOfWeek) as WorkingDaysType[];
    const index = daysOfWeekLocal.findIndex(
      (dayOfWeek) => dayOfWeek.index === objIndex,
    );

    // get time for lst slot and add 1 hour to it to set new slot start time
    const lastSlotIndex = daysOfWeekLocal[index].timeSlots.length - 1;
    const lastTime = daysOfWeekLocal[index].timeSlots[lastSlotIndex].endTime;

    const startTime = moment(lastTime, ['hh:mm:A'])
      .add(1, 'hour')
      .format('hh:mm:A');
    const endTime = moment(lastTime, ['hh:mm:A'])
      .add(2, 'hour')
      .format('hh:mm:A');

    daysOfWeekLocal[index].timeSlots.push({
      startTime,
      endTime,
    });

    setDaysOfWeek(daysOfWeekLocal);
    // setLastRefreshTimestamp(new Date().getTime());
  };

  const removeSlot = (objIndex: number, slotIndex: number) => {
    // remove  slot from day of week
    const daysOfWeekLocal = makeCopy(daysOfWeek) as WorkingDaysType[];

    const index = daysOfWeekLocal.findIndex(
      (dayOfWeek) => dayOfWeek.index === objIndex,
    );
    daysOfWeekLocal[index].timeSlots.splice(slotIndex, 1);

    setDaysOfWeek(daysOfWeekLocal);
    // setLastRefreshTimestamp(new Date().getTime());
  };
  // eslint-disable-next-line no-unused-vars

  // eslint-disable-next-line consistent-return
  const save = async () => {
    setSlotsError([]);
    setSaving(true);

    const slotsErrorsLocal: string[] = [];

    daysOfWeek.map((day) => {
      // check for errors in the timeslots
      if (!timeSlotsNotOverlapping(day.timeSlots)) {
        slotsErrorsLocal[day.index] = 'slots over lapping';
      }

      // check if all timeslots are greater than or equal to 1 hour
      // we dont support slots less than 1 hour
      day.timeSlots.map((timeSlot) => {
        const startHour = Number(
          moment(timeSlot.endTime, moment12HourFormat).format('H.mm'),
        );
        const endHour = Number(
          moment(timeSlot.startTime, moment12HourFormat).format('H.mm'),
        );
        const timeDiff = startHour - endHour;
        if (timeDiff < 1) {
          slotsErrorsLocal[day.index] = 'minimun allowed slot duration is 1 hour';
        }
        return null;
      });
      return null;
    });

    setSlotsError(slotsErrorsLocal);

    if (slotsErrorsLocal.length > 0) {
      setSaving(false);
      return null;
    }

    const newDays = makeCopy(daysOfWeek) as WorkingDaysType[];

    newDays.map((day) => {
      const exists = selectedDays.findIndex((da) => da === day.index);
      if (exists < 0) {
        // eslint-disable-next-line no-param-reassign
        day.timeSlots = [];
      }
      return day;
    });

    // save to userSettings
    await saveTrainerAvailability(trainer?.id as string, newDays);
    // save to trainer profile
    await updateTrainerAwayMode(
      trainer?.id as string,
      awayMode,
      typeof awayModeStart === 'string' ? moment(awayModeStart).format(awayDateFormat) : awayModeStart,
      typeof awayModeEnd === 'string' ? moment(awayModeEnd).format(awayDateFormat) : awayModeEnd,
    );
    setSaving(false);
  };
  const data = useMemo(() => daysOfweekShift(daysOfWeek, startOfWeekMonday) as WorkingDaysType[], [daysOfWeek]);

  return (
    <div className="Bottom Content" style={{ marginTop: 40, marginLeft: 20 }}>
      <StyledLabelTitleLarge title="Availability" />
      <div style={{ fontSize: '18px', margin: '24px 0 16px' }}>
        Select your working days
      </div>
      <DaysSelector
        startOfWeekMonday={startOfWeekMonday}
        selectedDays={selectedDays}
        selectDay={selectDay}
      />
      <div style={{ fontSize: '18px', margin: '24px 0 16px' }}>
        Working hours
      </div>
      {!loadingAvailability && (
      <>
        {
        data.map((dayOfWeek) => {
          const { timeSlots, index } = dayOfWeek;
          const isSelected = selectedDays.findIndex((day) => day === index) >= 0;
          return (
            <DayAvailability
              key={`${index}`}
              isSelected={isSelected}
              name={displayDays[index]}
              timeSlots={timeSlots}
              slotError={slotsError[index]}
              onTimeChange={(slotIndex, time, origin, type) => onTimeChange(index, slotIndex, time, origin, type)}
              addNewSlot={() => addNewSlot(index)}
              removeSlot={(slotIndex) => removeSlot(index, slotIndex)}
            />
          );
        })
      }
      </>
      )}
      <br />
      <div className="inputWrapper">
        <div className="label">Away Mode</div>
        <div style={{ borderRadius: 3 }}>
          <Toggle
            id="toggle-active"
            size="large"
            isChecked={awayMode}
            onChange={() => {
              setAwayMode(!awayMode);
            }}
          />
        </div>
      </div>
      <br />
      {awayMode && (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      >
        <DateInput
          label="Away Start Date"
          value={awayModeStart}
          dateFormat="DD MMM YYYY"
          onChange={(e: string) => {
            if (e.length > 0) {
              setAwayModeStart(moment(e).toISOString());
            }
          }}
        />
        <div style={{ marginLeft: '48px' }} />
        <DateInput
          label="Away End Date"
          value={awayModeEnd}
          dateFormat="DD MMM YYYY"
          onChange={(e: string) => {
            if (e.length > 0) {
              setAwayModeEnd((moment(e).toISOString()));
            }
          }}
        />
      </div>
      )}

      <LoadingButton
        isLoading={saving}
        onClick={save}
        appearance="primary"
        style={{
          backgroundColor: '#3FC7E0',
          margin: '1em 0 2.5em',
        }}
      >
        Save Changes
      </LoadingButton>
      <br />
      <StyledLabelTitleLarge title="Schedule" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 20,
          width: '100%',
          height: '100vh',
        }}
      >
        {bookedSlots && !loading && (
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          events={bookedSlots}
          allDaySlot={false}
          slotMinTime="06:00"
          slotMaxTime="22:00"
          dayHeaderFormat={{ month: 'short', day: 'numeric', weekday: 'short' }}
          selectable
          selectOverlap
          eventClick={(info) => {
            // eslint-disable-next-line no-param-reassign
            info.el.classList.toggle('selectedEvent');
            handleSelectedEvents(info.event);
          }}
          customButtons={{
            makeAvailableButton: {
              text: 'Make Available',
              click: () => {
                if (!selectedEvents.length) {
                  alert('Please select a time slot');
                }
                setLoading(true);
                makeAvailable();
              },
            },
          }}
          headerToolbar={{
            center: 'makeAvailableButton',
          }}
        />
        )}
      </div>
    </div>
  );
}
