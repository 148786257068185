import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import { ProgressData } from '../models/progress';
import collections from '../utils/enums/collections.enums';
import { db } from './firebase';

class ProgressController {
  // bit different updating the nested object, there could be a better logic. Need to research more
  static async addOrUpdateProgress(
    userId: string,
    progressObject?: ProgressData,
    oldProgressDate?: ProgressData,
  ) {
    try {
      const query = db
        .collection(collections.PROGRESS)
        .where('userId', '==', userId);
      const isEmpty = (await query.get()).empty;
      if (isEmpty) {
        db.collection(collections.PROGRESS).add({
          userId,
          progress: firebase.firestore.FieldValue.arrayUnion({
            ...progressObject,
            id: uuidv4(),
          }),
        });
      } else {
        query.get().then((querySnapshot) => {
          querySnapshot.forEach((document) => {
            if (progressObject?.id) {
              document.ref.update({
                progress: firebase.firestore.FieldValue.arrayRemove({
                  ...oldProgressDate,
                }),
              });
            }
            document.ref.update({
              progress: firebase.firestore.FieldValue.arrayUnion({
                ...progressObject,
                id: uuidv4(),
              }),
            });
          });
        });
      }
    } catch (error) {
      // console.log({error})
    }
  }

  // static async updateProgress() {}
}

export default ProgressController;
