/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useFilePicker } from 'use-file-picker';
import { Gym, GymLocation } from '@root/models/gym';
import { CityTypes } from '@root/models/city';
import CitiesController from '@root/controllers/citiesController';
import Select, { OptionsType } from '@atlaskit/select';
import { GymsFirestore } from '../../controllers/gymsFirestoreController';
import CustomModalHeader from './modalHeader';
import AvatarPicture from '../avatar/Avatar';
import { TextAreaInput } from '../textInput';
import PlacesAutoComplete from '../placesAutocomplete';

export default function AddGym() {
  const [isOpen, setIsOpen] = useState(false);
  const [gymLocation, setGymLocation] = useState<GymLocation | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [activeCities, setActiveCities] = useState<any>([]);
  const { addGym } = GymsFirestore();
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const [citiesDb, setCitiesDb] = useState<CityTypes[]>([]);

  const getCities = async () => {
    const data = await CitiesController.getAllCities();
    setCitiesDb(data);
  };
  useEffect(() => {
    getCities();
  }, []);
  // eslint-disable-next-line max-len
  const cities: OptionsType[] = citiesDb.map((item) => ({ value: item.name, label: item.name })) as any;
  const [openFileSelector, {
    filesContent, clear,
  }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const [openMultiFileSelector, {
    filesContent: filesContents, clear: clearMultiFiles,
  }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { min: 0, max: 3 },
  });
  const onSubmit = async (data) => {
    setFormError(null);
    if (filesContent.length < 1) {
      setFormError('Please select image');
      return;
    }
    if (filesContent.length < 1) {
      setFormError('Please carousel images');
      return;
    }
    if (gymLocation === null) {
      setFormError('Please gym location');
      return;
    }
    if (activeCities.length < 1) {
      setFormError('Pick City');
      return;
    }
    try {
      const {
        order, name, membershipOffers, description, howToAccess,
      } = data;
      const gym: Gym = {
        name,
        description,
        howToAccess,
        isActive: true,
        order: Number(order),
        city: activeCities.map((item) => item.value),
        membershipOffers: membershipOffers.length === 0 ? undefined : membershipOffers,
        location: {
          latitude: gymLocation?.latitude ?? 0,
          longitude: gymLocation?.longitude ?? 0,
          placeId: gymLocation?.placeId ?? '',
          address: gymLocation?.address ?? '',

        },
      };
      await addGym(gym, filesContent[0].content, filesContents.map((file) => file?.content));
      clear();
      clearMultiFiles();
      closeModal();
      setFormError(null);
    } catch (err) {
      // console.log({ err });
    }
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          backgroundColor: '#3FC7E0',
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        (+) Add New Gym
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New Gym" />
                  <ModalBody>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <AvatarPicture pictureUrl={filesContent[0]?.content} />
                      <Button
                        onClick={openFileSelector}
                        appearance="primary"
                        style={{
                          backgroundColor: '#3FC7E0',
                          marginLeft: 10,
                        }}
                      >
                        Upload thumbnail
                      </Button>

                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {filesContents.map((file) => <AvatarPicture pictureUrl={file?.content} />)}
                      <Button
                        onClick={openMultiFileSelector}
                        appearance="primary"
                        style={{
                          backgroundColor: '#3FC7E0',
                          marginLeft: 10,
                        }}
                      >
                        Upload gym images
                      </Button>
                    </div>
                    {formError && <ErrorMessage>{formError}</ErrorMessage>}
                    <Field id="order" name="order" label="Order" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} type="number" value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field id="name" name="name" label="Name" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <Textfield {...fieldProps} value={undefined} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    City:
                    <Select
                      className="multi-select"
                      options={cities}
                      values={activeCities}
                      onChange={(value) => setActiveCities(value)}
                      isMulti
                      isSearchable={false}
                      styles={{
                        control: (base) => ({
                          ...base,
                        }),
                      }}
                    />
                    <Field id="description" name="description" isRequired>
                      {({ fieldProps }) => (
                        <TextAreaInput
                          label="Description:"
                          {...fieldProps}
                        />
                      )}
                    </Field>
                    <Field id="membershipOffers" name="membershipOffers">
                      {({ fieldProps }) => (
                        <TextAreaInput
                          label="Membership Offers:"
                          {...fieldProps}
                        />
                      )}
                    </Field>
                    <Field id="howToAccess" name="howToAccess" isRequired>
                      {({ fieldProps, error }) => (
                        <>
                          <TextAreaInput label="How to access" {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <PlacesAutoComplete
                      label="Pin Location"
                      defaultValue={gymLocation?.address || ''}
                      onPlaceSelected={(place) => {
                        const {
                          geometry: { location },
                          // eslint-disable-next-line camelcase
                          place_id,
                          // eslint-disable-next-line camelcase
                          formatted_address,
                        } = place;
                        setGymLocation({
                          latitude: location.lat(),
                          longitude: location.lng(),
                          placeId: place_id,
                          address: formatted_address,
                        });
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
