import { ClientPackage } from '@root/models/clientPackage';
import collections from '@root/utils/enums/collections.enums';
import dotenv from 'dotenv';
import { db, firebaseApp } from './firebase';

dotenv.config();
const API_URL = process.env.REACT_APP_FIREBASE_FUNCTION_URL;

class UserController {
  static async create({
    fullname,
    email,
    mobile,
    city,
    gender,
  }: // roles,
  {
    fullname: string;
    email?: string;
    mobile: {
      areaCode: string;
      number: string;
    };
    city?: string;
    gender?: string;
  }) {
    fetch(`${API_URL}/createUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fullname,
        email,
        mobile,
        isActive: true,
        isMobileVerified: true,
        city,
        gender,
        freeSessionEligible: false,
      }),
    })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  static async getPackage(userId: string, trainerId?: string) {
    const snapshot = await db
      .collection(collections.CLIENT_PACKAGES)
      .where('userId', '==', userId);
    if (trainerId) {
      snapshot.where('trainerId', '==', trainerId);
    }

    return (await snapshot.get()).docs.map((doc) => doc.data()) as ClientPackage[];
  }

  static async updatePackage(clientPackage: ClientPackage) {
    await db
      .collection(collections.CLIENT_PACKAGES)
      .doc(clientPackage.id)
      .update(clientPackage);
  }

  static async getPricePerSession(userId: string) {
    try {
      const request = await fetch(`${API_URL}/getPricePerSession`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
        }),
      });
      return request.json() as Promise<{
        price: number;
        billingHistoryId: string | null;
      }>;
    } catch (error) {
      return {
        price: 0,
        billingHistoryId: null,
      };
    }
  }

  static async removeClient(
    clientId: string,
    trainerId: string,
    type: string,
  ): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (type === 'PT_CLIENT') {
        await db.collection(collections.USERS).doc(clientId).update({
          assignedTrainerId: firebaseApp.firestore.FieldValue.delete(),
        });
        resolve(true);
      } else {
        const trainerSettingsRef = db
          .collection(collections.USERS_SETTINGS)
          .doc(trainerId);

        const data = await trainerSettingsRef.get();

        const removedClients: string[] = data?.data()?.removedClients ?? [];

        const clientExists = removedClients.findIndex(
          (userId) => userId === clientId,
        );

        if (clientExists < 0) {
          removedClients.push(clientId);
        }

        trainerSettingsRef.get().then(async (docSnapshot) => {
          if (docSnapshot.exists) {
            await trainerSettingsRef.update({
              removedClients:
                removedClients.length > 0 ? removedClients : undefined,
            });
            resolve(true);
          } else {
            await trainerSettingsRef.set({
              removedClients,
            });
            resolve(true);
          }
        });
      }
    });
  }
}

export default UserController;
