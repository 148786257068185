/* eslint-disable */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import Button from '@atlaskit/button/standard-button';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalTransition
} from '@atlaskit/modal-dialog';
import { useTrainer } from '@root/controllers/trainerFirestoreController';
import TrainerPackageFirestore from '@root/controllers/trainerPackageFirestoreController';
import { TrainerPackage } from '@root/models/trainerPackage';
import { OptionType } from '@root/types/types';
import { map } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Status, UserTypes } from '../../utils/common/data';
import { StyledSelect } from '../select/StyledDataSelect';
import FormFieldInput from '../textInput/formFieldInput';
import CustomModalHeader from './modalHeader';

export default function AddTrainerPackage() {
  const [isOpen, setIsOpen] = useState(false);
  const { items: verifiedTrainers } = useTrainer();
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const [activeTrainerId, setActiveTrainerId] = useState<
    OptionType | null | undefined
  >(null);
  const { addTrainerPackage } = TrainerPackageFirestore();

  const trainerOptions: OptionType[] = useMemo(
    () => map(verifiedTrainers || [], (trainer) => ({
      value: trainer.id!,
      label: trainer.displayName || '',
    })),
    [verifiedTrainers],
  );

  const onSubmit = (data) => {
    const shownTo = data.shownTo.map((userType) => userType.value);
    const price = parseFloat(data.price);

    const packageData: TrainerPackage = {
      trainerId: activeTrainerId?.value as string,
      trainerName: activeTrainerId?.label,
      packageName: data.packageName,
      sessions: parseInt(data.sessions, 10),
      price:  (Number.isInteger(price) ? parseInt(`${price}`) : Number(price.toFixed(2))) as number ,
      isActive: data.isActive.value,
      category: "training", // hardcoded for now because we have only one inhouse nutritionist
      shownTo,
    };
    addTrainerPackage(packageData);
    closeModal();
  };

  return (
    <>
      <Button
        appearance="primary"
        onClick={openModal}
        style={{
          marginLeft: 20,
          backgroundColor: '#3FC7E0',
          marginRight: 20,
        }}
      >
        (+) Add New Trainer Package
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <Form onSubmit={onSubmit}>
              {({ formProps, submitting }) => (
                <form {...formProps}>
                  <CustomModalHeader title="Add New Trainer package" />
                  <ModalBody>
                    <Field
                      id="trainer"
                      name="trainer"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Please select trainer';
                      }}
                    >
                      {({ fieldProps: { id, onChange, value }, error }) => (
                        <>
                          <StyledSelect
                            name="Trainer"
                            validationState={error ? 'error' : 'default'}
                            classNamePrefix="react-select"
                            options={trainerOptions}
                            value={value as any}
                            isSearchable
                            inputId={id}
                            onChange={(e) => {
                              setActiveTrainerId(e as OptionType);
                              onChange(e);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="packageName"
                      name="packageName"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Package Name is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                            label="Package Name"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="sessions"
                      name="sessions"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'PT Sessions is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                          type='number'
                          min="0"
                          step="1"
                            label="# of PT sessions"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="price"
                      name="price"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Price is required';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <FormFieldInput
                          type='number'
                            label="Price per session (without VAT)"
                            fieldProps={fieldProps}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="isActive"
                      name="isActive"
                      isRequired
                      validate={(value) => {
                        if (!value) return 'Status is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            options={Status}
                            inputId={id}
                            name="Status"
                            validationState={error ? 'error' : 'default'}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                    <Field
                      id="shownTo"
                      name="shownTo"
                      isRequired
                      validate={(value) => {
                        if (!value || !value.length) return 'Shown to is required';
                      }}
                    >
                      {({ fieldProps: { id, onChange }, error }) => (
                        <>
                          <StyledSelect
                            isMulti
                            options={UserTypes}
                            inputId={id}
                            name="Shown To"
                            validationState={error ? 'error' : 'default'}
                            onChange={(value) => {
                              onChange(value);
                            }}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </>
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="submit"
                      isDisabled={submitting}
                      className="modal-button"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
