/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import {
  useState,
  useEffect,
  useMemo,
} from 'react';
import CitiesController from '@root/controllers/citiesController';
import { CityRegion } from '@root/models/city';
import { useLocation, useParams } from 'react-router';
import L from 'leaflet';
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Polygon,
  Tooltip,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { LatLng } from '@root/models/address';
import { parseCenterCoordinate } from '@root/utils/functions';
import { AddCityRegion } from '@root/components/modals/AddNewCityRegionModal';
import osm from '../../utils/osm-providers';

const convert = (iwe) => iwe.map((e) => ({
  lat: e.latitude,
  lng: e.longitude,
}));

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Regions() {
  const ZOOM_LEVEL = 10;
  const [mapRef, setMapRef] = useState(null);
  const [regions, setRegions] = useState<CityRegion[]>([]);
  const [center, setCenter] = useState({ lat: 24.4539, lng: 54.3773 });

  const [regionTempCoords, setRegionTempCoords] = useState<LatLng[]>([]);
  const [showAddRegionModal, setShowAddRegionModal] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState<CityRegion>(null);
  const [refleshTime, setRefreshTime] = useState(new Date().getTime());

  const [editing, setEditing] = useState(false);

  const closeModal = () => {
    setShowAddRegionModal(false);
  };
  // const history = useHistory();
  const params = useParams() as { id: string };

  const query = useQuery();
  const cityName = query.get('name') ?? '';
  const cityId = params.id;

  const getRegions = async () => {
    const data = await CitiesController.getRegionsByCity(cityId);
    let allPoints: LatLng[] = [];
    data.map((region) => {
      allPoints = allPoints.concat(region?.coordinates ? region?.coordinates : []);
      return null;
    });
    const midPoint = parseCenterCoordinate(allPoints);
    if (mapRef && data.length > 0) {
      setCenter({ lat: midPoint.latitude, lng: midPoint.longitude });
      await mapRef?.setView({ lat: midPoint.latitude, lng: midPoint.longitude }, 10);
    }
    setRegions(data);
  };

  useEffect(() => {
    getRegions();
  }, [mapRef, refleshTime]);

  const created = (e) => {
    const coords = e.layer._latlngs[0].map(((coord) => ({
      latitude: coord.lat,
      longitude: coord.lng,
    })));
    setRegionTempCoords(coords);
    setShowAddRegionModal(true);
  };

  const edit = (e) => {
    const data = e.target._targets;
    // setEditing(true);
    Promise.all(
      Object.keys(e.target._targets).map(async (itm: any) => {
        if (data[itm].options.children) {
          const id = data[itm].options.children[0];
          const coordinates = data[itm]._latlngs[0].map((coord) => ({
            latitude: coord.lat,
            longitude: coord.lng,
          }));
          await CitiesController.editRegion(id, coordinates);
        }
        return null;
      }),
    );
    setRefreshTime(new Date().getTime());
    // window.location.reload(false);
    setEditing(false);
  };

  const saveRegion = async ({ region, areas }: { region: string, areas: string[] }) => {
    setEditing(true);
    const regionData: CityRegion = {
      city: cityName,
      cityId,
      region,
      areas,
      coordinates: regionTempCoords,
    };
    await CitiesController.saveRegion(regionData);
    setRefreshTime(new Date().getTime());
    setEditing(false);
    closeModal();
  };

  return (
    <div style={{ height: '90vh', width: '100%' }}>
      <div style={{ fontSize: '17px' }}>
        {`City: ${cityName}`}
      </div>
      <MapContainer
        ref={setMapRef}
        center={center}
        zoom={ZOOM_LEVEL}
        style={{ height: '100%', width: '100%' }}
      >
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={created}
            onEditStop={edit}
            // onDeleteStop={}
            draw={
              {
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
                polygon: {
                  icon: new L.DivIcon({
                    iconSize: new L.Point(8, 8),
                    className: 'leaflet-div-icon leaflet-editing-icon',
                  }),
                  shapeOptions: {
                    guidelineDistance: 10,
                    color: 'navy',
                    weight: 3,
                  },
                },
              }
            }
          />
          {regions.map((e) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <Polygon
              key={e.id}
              pathOptions={{
                color: e.id === selectedRegion?.id ? 'red' : 'blue',
              }}
              // eventHandlers={eventHandlers}
              eventHandlers={{
                click: () => {
                  setSelectedRegion(e);
                },
              }}
              positions={convert(e.coordinates)}
            >
              {e.id}
              <Tooltip permanent>
                {e.region}
              </Tooltip>
            </Polygon>
          ))}

        </FeatureGroup>
        <TileLayer
          url={osm.maptiler.url}
          attribution={osm.maptiler.attribution}
        />
      </MapContainer>
      <AddCityRegion
        saving={editing}
        isOpen={showAddRegionModal}
        closeModal={closeModal}
        saveRegion={saveRegion}
      />
    </div>
  );
}
