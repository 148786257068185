/* eslint-disable */
import { useTrainerChanges } from "@root/controllers/trainerFirestoreController";
import StyledLabelTitle from "@root/utils/styles/StyledLabelTitle";
import { TrainerChangesHead } from "../../components/table/content/Head";
import { TrainerChangesRows } from "../../components/table/content/rows";
import Table from "../../components/table/table";

export default function TraineChanges() {
  const {items, loading} = useTrainerChanges()

  return (
    <>
      <div>
        <StyledLabelTitle title="Trainer changes" />
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table isLoading={loading} headType={TrainerChangesHead} row={TrainerChangesRows(items)} />
      </div>
    </>
  );
}
