import { TrainerPackageHead } from '@root/components/table/content/Head';
import { TrainerPackagesRows } from '@root/components/table/content/rows';
import Table from '@root/components/table/table';
import TrainerPackageFirestore from '@root/controllers/trainerPackageFirestoreController';
import StyledLabelTitle from '@root/utils/styles/StyledLabelTitle';
import { useEffect } from 'react';

export default function TrainerPackages({ trainerId }: {trainerId: string}) {
  const { items, filterTrainerPackage } = TrainerPackageFirestore();

  useEffect(() => {
    const fetchPackages = async () => {
      await filterTrainerPackage(trainerId);
    };
    fetchPackages();
  }, [trainerId]);

  return (
    <div style={{ marginTop: 40 }}>
      <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <StyledLabelTitle title="Trainer Packages" />
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table row={TrainerPackagesRows(items)} headType={TrainerPackageHead} />
      </div>
    </div>
  );
}
