/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';
import { CuponCodes } from '../models/cuponCode';

export const CuponCodeFirestore = () => {
  const [items, setItems] = useState<CuponCodes[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.COUPONCODES)
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setItems(fetched as CuponCodes[]);
      });
    return unsubscribe;
  }, []);
  const updateCoupon = (coupon: CuponCodes) => {
    try {
      return db
        .collection(collections.COUPONCODES)
        .doc(coupon.id)
        .update(coupon);
    } catch (error) {
      // console.log('===Error updating trainer====', error);
    }
  };
  const deleteCoupon = (id: string) => {
    try {
      return db.collection(collections.COUPONCODES).doc(id).delete();
    } catch (error) {
      // console.log('===Error deleting coupon====', error);
    }
  };

  const addCuponCodes = async (cuponCode: CuponCodes) => {
    const response = await db
      .collection(collections.COUPONCODES)
      .add(cuponCode);
    response.update({ id: response.id });
  };

  return {
    items, updateCoupon, addCuponCodes, deleteCoupon,
  };
};

export default class CouponCodeController {
  static async getCouponCodeById(id: string): Promise<CuponCodes> {
    const response = await db.collection(collections.COUPONCODES).doc(id).get();
    return { ...response.data(), id: response.id };
  }
}
