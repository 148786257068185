import disciplinesFirestore from '@root/controllers/disciplinesFirebaseController';
import AddDiscipline from '@root/components/modals/AddNewDisciplineModal';
import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';
import Table from '../../components/table/table';
import { Disciplinesheader } from '../../components/table/content/Head';
import { DisciplinesRows } from '../../components/table/content/rows';

export default function Disciplines() {
  const { disciplines } = disciplinesFirestore();

  return (
    <div>
      <div className="Top">
        <StyledLabelTitle title="Disciplines" />
        <AddDiscipline />
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table
          headType={Disciplinesheader}
          row={DisciplinesRows(disciplines)}
          defaultSortKey="Order"
        />
      </div>
    </div>
  );
}
