import AddGym from '@root/components/modals/AddNewGymModal';
import { GymsFirestore } from '@root/controllers/gymsFirestoreController';
import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';
import Table from '../../components/table/table';
import { GymsHead } from '../../components/table/content/Head';
import { GymsRows } from '../../components/table/content/rows';

export default function Gyms() {
  const { items } = GymsFirestore();
  return (
    <div>
      <div className="Top">
        <StyledLabelTitle title="Gyms" />
        <AddGym />
        {/* change to add offer */}
      </div>

      <div style={{ marginTop: 20, marginLeft: 20 }}>
        <Table
          headType={GymsHead}
          row={GymsRows(items)}
          defaultSortKey="order"

        />
      </div>
    </div>
  );
}
