import { useState, useEffect } from 'react';
import _ from 'lodash';
import { DisciplineV2 } from '@root/models/disciplines';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';
import StorageController from './storageController';

const disciplinesFirestore = () => {
  const [disciplines, setDisciplines] = useState<DisciplineV2[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.DISCIPLINES)
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setDisciplines(_.orderBy(fetched, ['order'], ['asc']));
      });
    return unsubscribe;
  }, []);
  // eslint-disable-next-line consistent-return
  const updateDiscipline = (discipline: DisciplineV2) => {
    try {
      return db
        .collection(collections.DISCIPLINES)
        .doc(discipline.id)
        .update(discipline);
    } catch (error) {
      // console.log('===Error updating trainer====', error);
    }
  };
  const addDiscipline = async (discipline: DisciplineV2, image: string) => {
    const picture = await StorageController.uploadFile(image, 'disciplines');
    const newDiscipline = discipline;
    newDiscipline.picture = picture;
    newDiscipline.picture_1 = picture;
    const response = await db
      .collection(collections.DISCIPLINES)
      .add(newDiscipline);
    response.update({ id: response.id });
  };

  return {
    disciplines, addDiscipline, updateDiscipline,
  };
};
export default disciplinesFirestore;
