import { useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Textfield from '@atlaskit/textfield';
import AuthController from '@controllers/authController';
import 'firebase/auth';
import { useLocation } from 'react-router';

type LocationStateType = {
verificationId: string
}

export default function LoginOtp() {
  const location = useLocation();
  const state = location.state as LocationStateType;
  const { verificationId } = state;

  const [isPending, setIsPending] = useState(false);

  const [verificationCode, setVerificationCode] = useState<string | undefined>(
    undefined,
  );
  const confirmVerificationCode = async () => {
    if (!verificationCode) return;
    try {
      setIsPending(true);
      await AuthController.confirmOTP(
        verificationId,
        verificationCode,
      );
      // console.log('authResult', authResult);
    } catch (error) {
      // console.log('confirmVerificationCode error ', error);
    } finally {
      setIsPending(false);
    }
  };
  const handleVerificationCode = ({ target }) => {
    setVerificationCode(target.value);
  };
  return (
    <div
      style={{
        background: '#3FC7E0',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ fontWeight: 300, marginTop: 240, marginLeft: -100 }}>
          <h1
            style={{
              fontWeight: 300,
            }}
          >
            Please Enter Otp you received
          </h1>
          <Textfield
            name="basic"
            aria-label="default text field"
            placeholder="12345"
            value={verificationCode}
            onChange={handleVerificationCode}
          />
          <div style={{ marginTop: 20, borderRadius: 20 }}>
            {/* <Link to={"/home"} className="Link"> */}
            <Button
              onClick={confirmVerificationCode}
              appearance="primary"
              color="#3FC7E0"
              isDisabled={isPending}
              style={{
                borderRadius: 10,
                width: 500,
                height: 50,
                fontSize: 18,
                fontWeight: 300,
              }}
            >
              {isPending ? 'Loading' : 'Send Confirmation Code'}
            </Button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
