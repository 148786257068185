/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { db } from './firebase';
import collections from '../utils/enums/collections.enums';
import { Offer } from '../models/offer';
import StorageController from './storageController';

export const OffersFirestore = () => {
  const [items, setItems] = useState<Offer[]>([]);
  useEffect(() => {
    const unsubscribe = db
      .collection(collections.OFFERS)
      .onSnapshot((snap) => {
        const fetched = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setItems(fetched as Offer[]);
      });
    return unsubscribe;
  }, []);
  const updateOffer = (offer: Offer) => {
    try {
      return db
        .collection(collections.OFFERS)
        .doc(offer.id)
        .update(offer);
    } catch (error) {
      // console.log('===Error updating trainer====', error);
    }
  };
  const deleteOffer = (id: string) => {
    try {
      return db.collection(collections.OFFERS).doc(id).delete();
    } catch (error) {
      // console.log('===Error deleting Offer====', error);
    }
  };

  const addOffer = async (offer: Offer, image: string) => {
    const picture = await StorageController.uploadFile(image, 'offers');
    const newOffer = offer;
    newOffer.picture = picture;
    const response = await db
      .collection(collections.OFFERS)
      .add(newOffer);
    response.update({ id: response.id });
  };

  return {
    items, updateOffer, addOffer, deleteOffer,
  };
};

export default class OffersController {
  static async getCouponCodeById(id: string): Promise<Offer> {
    const response = await db.collection(collections.OFFERS).doc(id).get();
    return { ...response.data(), id: response.id } as Offer;
  }
}
