import { Button } from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import moment from 'moment';
import colors from '../colors';

export default function StyledLabelTitle({
  title,
  style,
  refresh,
  lastRefresh,
}: {
  title?: string;
  style?: any;
  refresh?: () => void;
  lastRefresh?: Date;
}) {
  return (
    <div>
      {title && (

        <div
          style={{
            display: 'inline-flex',
            height: 28,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.darkGray,
            borderRadius: 3,
            padding: '5px 10px',
            ...style,
          }}
        >
          <p
            style={{
              fontSize: 14,
              color: 'white',
            }}
          >
            {title}
          </p>
        </div>
      )}
      {refresh && (
        <Button onClick={refresh}>
          <p>
            {moment(lastRefresh).format('DD MMM YYYY HH:mm')}
          </p>
          <RefreshOutlined color="action" />
          {/* <RefreshOutlined style={{ color: 'white' }} /> */}
        </Button>
      )}
    </div>
  );
}
