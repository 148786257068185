/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { UseUsersFirestore } from '@controllers/userFirestoreController';
import moment from 'moment';
import {
  BookingConfirmation,
  BookingState,
} from '@root/utils/enums/booking.enums';
import { RowType } from '@atlaskit/dynamic-table/dist/types/types';
import Select from '@atlaskit/select';
import {
  BookingLateCancellationOptions,
} from '@root/utils/common/data';
import _ from 'lodash';
import {
  CancelHead,
  CompleteHead,
  ConfirmHead,
  unconfirmedHead,
  RejectedHead,
} from '../../components/table/content/Head';
import {
  CancelledSessionsRows,
  CompletedSessionsRows,
  ConfirmedSessionsRows,
  RejectedSessionsRows,
  UnconfirmedSessionsRows,
} from '../../components/table/content/rows';
import Table from '../../components/table/table';
import { fetchSessionsController } from '../../controllers/sessionsFirestoreController';
import StyledLabelTitleLarge from '../../utils/styles/StyledLabelTitleLarge';
import Top, { cities } from './top';
import { TrainerSession } from '../../models/booking';
import { OptionType } from '../../types/types';

const dateTo = moment().add(7, 'd').format('YYYY-MM-DD');
const dateFrom = moment().subtract(3, 'd').format('YYYY-MM-DD');

export default function Content() {
  const { userSelect } = UseUsersFirestore();
  const [fromDate, setFromDate] = useState<string>(dateFrom);
  const [toDate, setToDate] = useState<string>(dateTo);
  const [loading, toggleLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<OptionType[]>([]);
  const [trainers, setTrainers] = useState<OptionType[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [selectedTrainer, setSelectedTrainer] = useState<string>('');
  const [clientFilter, setClientFilter] = useState(cities[1]);
  const [unConfirmedSessions, setUnConfirmedSessions] = useState<TrainerSession[]>([]);
  const [confirmedSessions, setConfirmedSessions] = useState<TrainerSession[]>(
    [],
  );
  const [cancelledSessions, setCancelledSessions] = useState<TrainerSession[]>(
    [],
  );
  const [completedSessions, setCompletedSessions] = useState<TrainerSession[]>(
    [],
  );
  const [rejectedSessions, setRejectedSessions] = useState<TrainerSession[]>(
    [],
  );
  const [
    bookingLateCancellationFilter, setbookingLateCancellationFilter,
  ] = useState<null | boolean>(null);

  const queryObj = useMemo(
    () => ({
      from: moment(fromDate).toDate(),
      to: moment(toDate).toDate(),
      client: selectedClient,
      trainer: selectedTrainer,
    }),
    [fromDate, selectedClient, selectedTrainer, toDate],
  );

  const filterUsers = useCallback(
    (role: string): OptionType[] => userSelect.filter((elm) => elm?.roles?.includes(role)),
    [userSelect],
  );

  useEffect(() => {
    setTrainers(filterUsers('trainer'));
    setClients(filterUsers('user'));
  }, [filterUsers]);

  useEffect(() => {
    const fetchSessions = async () => {
      toggleLoading(true);
      const unconfirmed = await fetchSessionsController({
        confirmation: BookingConfirmation.NO_ANSWER,
        status: BookingState.SCHEDULED,
        ...queryObj,
      });
      const confirmed = await fetchSessionsController({
        confirmation: BookingConfirmation.CONFIRMED,
        status: BookingState.SCHEDULED,
        ...queryObj,
      });
      const completed = await fetchSessionsController({
        status: BookingState.COMPLETED,
        ...queryObj,
      });
      const rejected = await fetchSessionsController({
        confirmation: BookingConfirmation.REJECTED,
        ...queryObj,
      });
      const rejectedOld = await fetchSessionsController({
        // this is to support old session logs on production with confirmation = on_hold
        confirmation: BookingConfirmation.ON_HOLD,
        ...queryObj,
      });
      // console.log({ unconfirmed1: unconfirmed });
      setUnConfirmedSessions(unconfirmed);
      setConfirmedSessions(confirmed);
      setCompletedSessions(completed);
      setRejectedSessions([...rejected, ...rejectedOld]);
      toggleLoading(false);
    };
    fetchSessions();
  }, [fromDate, queryObj, selectedClient, selectedTrainer, toDate]);

  const fetchCancelledSessions = useCallback(async () => {
    const cancelled = await fetchSessionsController({
      status: BookingState.CANCELLED,
      ...queryObj,
    });
    setCancelledSessions(cancelled);
  }, [queryObj]);

  useEffect(() => {
    fetchCancelledSessions();
  }, [fetchCancelledSessions, queryObj]);

  const onDateChanged = (date: string, type: string) => {
    if (type === 'from') {
      setFromDate(date);
    } else {
      setToDate(date);
    }
  };

  const selectUser = (user: OptionType, isClear?: boolean) => {
    if (user.roles.includes('user')) {
      if (isClear) {
        setSelectedClient('');
      } else {
        setSelectedClient(user.value as string);
      }
    } else if (isClear) {
      setSelectedTrainer('');
    } else {
      setSelectedTrainer(user.value as string);
    }
  };

  const filteredData = useMemo(() => {
    let filteredUnConfirmedSessions: TrainerSession[] = [];
    let filteredConfirmedSessions: TrainerSession[] = [];
    let filteredCancelledSessions: TrainerSession[] = [];
    let filteredCompletedSessions: TrainerSession[] = [];
    let filteredRejectedSessions: TrainerSession[] = [];

    if (clientFilter === 'All') {
      filteredUnConfirmedSessions = unConfirmedSessions;
      filteredConfirmedSessions = confirmedSessions;
      filteredCancelledSessions = cancelledSessions;
      filteredCompletedSessions = completedSessions;
      filteredRejectedSessions = rejectedSessions;
    }

    if (clientFilter === 'CLIENTS') {
      filteredUnConfirmedSessions = unConfirmedSessions.filter(
        (e) => e.isPersonalTrainerClient !== true,
      );
      filteredConfirmedSessions = confirmedSessions.filter(
        (e) => e.isPersonalTrainerClient !== true,
      );
      filteredCancelledSessions = cancelledSessions.filter((
        e,
      ) => e.isPersonalTrainerClient !== true);
      filteredCompletedSessions = completedSessions.filter(
        (e) => e.isPersonalTrainerClient !== true,
      );
      filteredRejectedSessions = rejectedSessions.filter((e) => e.isPersonalTrainerClient !== true);
    }

    if (clientFilter === 'PT CLIENTS') {
      filteredUnConfirmedSessions = unConfirmedSessions.filter(
        (e) => e.isPersonalTrainerClient === true,
      );
      filteredConfirmedSessions = confirmedSessions.filter(
        (e) => e.isPersonalTrainerClient === true,
      );
      filteredCancelledSessions = cancelledSessions.filter((
        e,
      ) => e.isPersonalTrainerClient === true);
      filteredCompletedSessions = completedSessions.filter(
        (e) => e.isPersonalTrainerClient === true,
      );
      filteredRejectedSessions = rejectedSessions.filter((e) => e.isPersonalTrainerClient === true);
    }
    if (bookingLateCancellationFilter != null) {
      filteredCompletedSessions = filteredCompletedSessions.filter(
        (e) => e.lateCancellation === bookingLateCancellationFilter,
      );
    }
    return {
      filteredUnConfirmedSessions,
      filteredConfirmedSessions,
      filteredCancelledSessions,
      filteredCompletedSessions,
      filteredRejectedSessions,
    };
  }, [
    unConfirmedSessions,
    confirmedSessions,
    cancelledSessions,
    completedSessions,
    rejectedSessions,
    bookingLateCancellationFilter,
    clientFilter,
  ]);

  const {
    filteredUnConfirmedSessions,
    filteredConfirmedSessions,
    filteredCancelledSessions,
    filteredCompletedSessions,
    filteredRejectedSessions,
  } = filteredData;

  const usersCompletedSession = useMemo(() => {
    const uniqueUsers = _.uniqBy(filteredCompletedSessions, (e: TrainerSession) => e.userId);
    return uniqueUsers.length ?? 0;
  }, [filteredCompletedSessions]);

  return (
    <div style={{ margin: 20 }}>
      <Top
        fromDate={fromDate}
        toDate={toDate}
        onDateChanged={onDateChanged}
        clients={clients}
        trainers={trainers}
        selectUser={selectUser}
        setClientFilter={setClientFilter}
        clientFilter={clientFilter}
      />
      <div style={{ marginTop: 20 }}>
        <StyledLabelTitleLarge title="Unconfirmed sessions" />

        <div style={{ marginTop: 20 }}>
          <Table
            isLoading={loading}
            row={UnconfirmedSessionsRows(filteredUnConfirmedSessions)}
            headType={unconfirmedHead}
          />
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <StyledLabelTitleLarge title="Rejected sessions" />

        <div style={{ marginTop: 20 }}>
          <Table
            isLoading={loading}
            row={RejectedSessionsRows(filteredRejectedSessions)}
            headType={RejectedHead}
          />
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <StyledLabelTitleLarge title="Confirmed sessions" />
        <div style={{ marginTop: 20 }}>
          <Table
            isLoading={loading}
            row={ConfirmedSessionsRows(filteredConfirmedSessions)}
            headType={ConfirmHead}
          />
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <StyledLabelTitleLarge title="Cancellations" />

        <div style={{ marginTop: 20 }}>
          <Table
            isLoading={loading}
            row={CancelledSessionsRows(filteredCancelledSessions, fetchCancelledSessions)}
            headType={CancelHead}
          />
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <StyledLabelTitleLarge title="Completed Sessions" />
          <div style={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
            <div style={{ width: 150, marginLeft: 30 }}>
              <label style={{ color: '#121212' }}>Late Cancellation</label>
              <div style={{ marginTop: 5 }}>
                <Select
                  inputId="multi-select-example"
                  className="multi-select"
                  classNamePrefix="react-select"
                  options={BookingLateCancellationOptions}
                  isSearchable={false}
                  placeholder="All"
                  onChange={(option) => {
                    setbookingLateCancellationFilter(option?.value as any);
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            {`Users: ${usersCompletedSession}`}
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <Table
            isLoading={loading}
            row={CompletedSessionsRows(filteredCompletedSessions)}
            headType={CompleteHead}
          />
        </div>
      </div>
    </div>
  );
}
