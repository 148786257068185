/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  Content,
  LeftSidebarWithoutResize,
  Main,
  PageLayout,
  TopNavigation,
} from '@atlaskit/page-layout';
import { useAuthState } from '@contexts/auth.context';
import { BrowserRouter as Router } from 'react-router-dom';
import Sidebar from '../sidebar/Siderbar';
import Topbar from '../topbar/topbar';
import Switchs from './switch';

export default function Navigation() {
  const { isAuthenticated, isAdmin } = useAuthState();
  return (
    <Router>
      <PageLayout>
        {isAuthenticated && isAdmin && (
          <TopNavigation isFixed={false} height={80}>
            <Topbar />
          </TopNavigation>
        )}
        <Content>
          {isAuthenticated && isAdmin && (
            <LeftSidebarWithoutResize isFixed={false} width={250}>
              <Sidebar />
            </LeftSidebarWithoutResize>
          )}
          <Main>
            <div style={{ margin: '20px 30px' }}>
              <Switchs />
            </div>
          </Main>
        </Content>
      </PageLayout>
    </Router>
  );
}
