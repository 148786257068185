import colors from '../colors';

export default function StyledLabel({ label, width = '80%' } : { label: string, width?: string}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width,
        backgroundColor: colors.lightGray,
        borderRadius: 3,
        fontSize: 14,
        padding: '0px 10px',
        marginRight: 10,
        height: 40,
      }}
    >
      <p>{label}</p>
    </div>
  );
}
