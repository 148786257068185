import UserStatusEnum from '../enums/userStatus.enums';

const defaultValues = {
  uriAvatarImage:
    'https://firebasestorage.googleapis.com/v0/b/fitlov-fz-llc.appspot.com/o/placeholder.jpg?alt=media&token=e9aef673-9d41-4565-909d-40a8d50a0e3b',
  confirmBookingMSG:
    'Hi there and thanks for booking with me, looking forward to our first session!',
  bannerShowTo: [
    { label: 'New User', value: UserStatusEnum.NEW_USER },
    { label: 'Active Client', value: UserStatusEnum.ACTIVE_CLIENT },
    { label: 'Siesta Client', value: UserStatusEnum.SIESTA_CLIENT },
    { label: 'Pending Client', value: UserStatusEnum.PENDING_CLIENT },
    { label: 'Lost Client', value: UserStatusEnum.LOST_CLIENT },
    { label: 'PT Client', value: UserStatusEnum.PT_CLIENT },
    { label: 'Override', value: UserStatusEnum.OVERRIDE },
    { label: 'Archived Client', value: UserStatusEnum.ARCHIVED_CLIENT },
  ],
};

export default defaultValues;
