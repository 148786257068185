import Select, {
  ActionMeta,
  OptionsType,
  OptionType,
  SelectProps,
} from '@atlaskit/select';
import { useGlobalsContext } from '@contexts/global.context';
import FetchCities from '@root/controllers/FetchCitiesController';
import sortingAlpha from '@root/utils/sortingAlpha';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Packages, Status, UserTypes,
} from '../../utils/common/data';

type Props = {
  name?: string;
  width?: number,
  isSearchable?: boolean;
  onChange?:
  // eslint-disable-next-line no-unused-vars
  | ((value: OptionsType<OptionType>, action: ActionMeta<OptionType>) => void)
  | undefined;
} & SelectProps<
  {
    label: string;
    value: any;
  },
  true
>;

export function StyledSelect({
  name,
  isSearchable = false,
  labelWidth,
  ...rest
}: Props) {
  return (
    <div className="inputWrapper">
      {name && <div className="label" style={{ width: labelWidth }}>{name}</div>}
      <div className="input">
        <Select
          isSearchable={isSearchable}
          className="single-select fitlov-select"
          classNamePrefix="react-select"
          menuPortalTarget={document.body}
          menuPosition="fixed"
          {...rest}
        />
      </div>
    </div>
  );
}

export function StyledUsertypeSelect() {
  return (
    <div
      style={{
        width: 100,
        marginLeft: 20,
        position: 'absolute',
        marginTop: -10,
      }}
    >
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={UserTypes}
        isMulti
        isSearchable={false}
        placeholder="User"
      />
    </div>
  );
}

export function StyledUsertypeSelectModal() {
  return (
    <div
      style={{
        width: 300,
        position: 'absolute',
        marginTop: -20,
        marginRight: 300,
      }}
    >
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={UserTypes}
        isMulti
        isSearchable={false}
        placeholder="User"
      />
    </div>
  );
}

export function StyledCitySelectModal() {
  const [cities, setCities] = useState<{ value: string; label: string }[]>([]);
  const fetchCities = async () => {
    const response = await FetchCities.getAll();
    const sortedCities = sortingAlpha(response);
    setCities(sortedCities);
  };

  useEffect(() => {
    fetchCities();
  }, []);

  return (
    <div
      style={{
        width: 600,
        position: 'absolute',
        marginTop: -20,
        marginLeft: -20,
      }}
    >
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={cities}
        isMulti
        isSearchable={false}
        placeholder="city"
      />
    </div>
  );
}

export function StyledCitySelectTrainer({ ...props }: Props) {
  const [cities, setCities] = useState<{ value: string; label: string }[]>([]);
  const fetchCities = async () => {
    const response = await FetchCities.getAll();
    const sortedCities = sortingAlpha(response);
    setCities(sortedCities);
  };

  useEffect(() => {
    fetchCities();
  }, []);
  const options = _.reverse(_.concat(cities, { label: 'All', value: 'All' }));

  return (
    <div style={{ width: props.width, marginLeft: 20 }}>
      <Select
        innerRef={props.innerRef}
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={options}
        isSearchable={false}
        placeholder={props.name}
        {...props}
      />
    </div>
  );
}

export function StyledPackagesSelectModal() {
  return (
    <div style={{ position: 'absolute' }}>
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={Packages}
        isMulti
        isSearchable={false}
        placeholder="package"
        spacing="compact"
        styles={{
          control: (base) => ({ ...base, marginTop: -10, width: 500 }),
        }}
      />
    </div>
  );
}

export function StyledCitySelect() {
  const [cities, setCities] = useState<{ value: string; label: string }[]>([]);
  const fetchCities = async () => {
    const response = await FetchCities.getAll();
    const sortedCities = sortingAlpha(response);
    setCities(sortedCities);
  };

  useEffect(() => {
    fetchCities();
  }, []);
  return (
    <div
      style={{
        width: 100,
        marginLeft: 20,
        marginTop: -10,
        position: 'absolute',
      }}
    >
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={cities}
        isMulti
        isSearchable={false}
        placeholder="city"
      />
    </div>
  );
}

export function StyledRegionsSelect({
  name,

  ...props
}: Props) {
  return (
    <div style={{ clear: 'left', width: 350, marginLeft: 20 }}>
      <label htmlFor="multi-select-example">{name}</label>
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        isMulti
        isSearchable={false}
        placeholder="Regions"
        {...props}
      />
    </div>
  );
}
export function StyledUserProfileSelect({ name, ...props }: Props) {
  return (
    <div style={{ clear: 'left', width: 350, marginLeft: 20 }}>
      <label htmlFor="multi-select-example">{name}</label>
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        isMulti
        isSearchable={false}
        {...props}
      />
    </div>
  );
}

export function StyledTrainerProfileSelect({ name, ...props }: Props) {
  const { disciplines } = useGlobalsContext();
  const options = _.concat(
    _.map(disciplines, (discipline) => ({
      label: discipline.trainerType,
      value: discipline.trainerType,
    })),
    {
      label: 'All',
      value: 'All',
    },
  ) as OptionType[];

  return (
    <div style={{ width: 150, marginLeft: 20 }}>
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={_.reverse(options)}
        isSearchable={false}
        placeholder="Discipline"
        {...props}
      />
    </div>
  );
}

export function StyledTrainerStatus() {
  return (
    <div style={{ width: 150, marginLeft: 20 }}>
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={Status}
        isSearchable={false}
        placeholder="Status"
      />
    </div>
  );
}

export function StyledTrainerProfileSelectLanguage({
  name,
  ...props
}: Props) {
  const { languages } = useGlobalsContext();
  return (
    <div style={{ clear: 'left', width: 350, marginLeft: 20 }}>
      <label htmlFor="multi-select-example">{name}</label>
      <Select
        inputId="multi-select-example"
        className="multi-select"
        classNamePrefix="react-select"
        options={languages}
        isMulti
        isSearchable={false}
        placeholder="prefered descpline"
        {...props}
      />
    </div>
  );
}
