import { useModal } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { size } from '@atlaskit/icon';

export default function CustomModalHeader({ title }:{title: string}) {
  const { onClose, titleId } = useModal();
  return (
    <div className="modal-header">
      <Button
        appearance="link"
        onClick={onClose}
        autoFocus={false}
        className="modalHeaderCloseButton"
      >
        <CrossIcon label="Close Modal" primaryColor="black" size={size.large} />
      </Button>
      <h1 className="modal-heading" id={titleId}>
        {title}
      </h1>
    </div>
  );
}
