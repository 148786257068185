/* eslint-disable camelcase */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable radix */
/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import Button from '@atlaskit/button';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import InlineEdit from '@atlaskit/inline-edit';
import Select, { OptionsType } from '@atlaskit/select';
import TextArea from '@atlaskit/textarea';
import Textfield from '@atlaskit/textfield';
import AddSession from '@components/modals/BookNewSessionModal';
import {
  deleteReview,
  updateReview,
} from '@controllers/reviewsFirestoreController';
import { DisciplineDeliveryTypeOptions, DisciplineServicesOptions, DisciplineV2 } from '@root/models/disciplines';
import { deleteTrainerRequest, updateTrainer } from '@controllers/trainerFirestoreController';
import { updateUser } from '@controllers/userFirestoreController';
import { css } from '@emotion/react';
import { Booking, Session, TrainerSession } from '@models/booking';
import { Renewal } from '@models/renewal';
import { ReviewRecord, TrainerRatingRecord } from '@models/review';
import { Trainer } from '@models/trainer';
import { Transaction } from '@models/transactions';
import { TrainerLevel, User } from '@models/user';
import WarningModal from '@root/components/modals/WarningModal';
import { DateInput, TextInput } from '@root/components/textInput';
import ProgressController from '@root/controllers/progressFirestoreController';
import { OptionType } from '@root/types/types';
import { useFilePicker } from 'use-file-picker';
import Spinner from '@atlaskit/spinner';
import '../table.css';

import { BookingConfirmation, BookingState, RejectedActionStatus } from '@utils/enums/booking.enums';
import {
  capitalizeFirstLetter, convertBookingDateToDateTime,
  convertFirebaseTimestampToDateString,
  convertTo12hTime,
  // getFullName,
  getUnixTimestamp, indexToTableNum,
} from '@utils/functions';
import disciplinesFirestore from '@root/controllers/disciplinesFirebaseController';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Tooltip from '@atlaskit/tooltip';
import AvatarPicture from '@root/components/avatar/Avatar';
import { GymsFirestore } from '@root/controllers/gymsFirestoreController';
import { BannerFirestore } from '@root/controllers/bannerFirebaseController';
import { OffersFirestore } from '@root/controllers/offersFirestoreController';
import { deleteCancelledBooking, updateBooking as updateBooking2 } from '@root/controllers/trainerBookingController';
import { deleteClientPackage, deleteTransaction } from '@root/controllers/userTransactionsFirestoreController';
import { BillingHistory } from '@root/models/billingDetails';
import defaultValues from '@root/utils/constants/defaultValues';
import FirestoreService, { getCollection } from '@root/utils/firestoreService';
import firebase from 'firebase';
import _, { findLast, map } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StorageController from '@root/controllers/storageController';
import { Offer } from '@root/models/offer';
import { Banner } from '@root/models/banner';
import UserController from '@root/controllers/userController';
import { CityTypes, CityRegion } from '@root/models/city';
import Revenue, { RevenueType } from '@root/models/revenue';
import MonthlyBalanceController from '@root/controllers/monthlyBalanceController';
import TrainerPackageFirestore from '@root/controllers/trainerPackageFirestoreController';
import { ClientPackage } from '@root/models/clientPackage';
import collections from '@root/utils/enums/collections.enums';
import { isRenewalTypes } from '@root/components/modals/AddNewPackageModal';
import { AttributeUpdated, RequestFields, RequestStatus } from '@root/models/attributeUpdated';
import UserStatusEnum from '@root/utils/enums/userStatus.enums';
import CitiesController from '@root/controllers/citiesController';
import { ExpiredPackage } from '@root/models/expiredPackages';
import PreBooking from '@root/models/preBooking';
import { Chip } from '@material-ui/core';
import colors from '@root/utils/colors';
import axios from 'axios';
import endpoint_functions from '@root/utils/endpoints';
import { toast } from 'react-toastify';
import { useGlobalsContext } from '../../../contexts/global.context';
import { CuponCodeFirestore } from '../../../controllers/cuponCodesFirestoreController';
import PackageFirestore from '../../../controllers/packagesFireStoreController';
import {
  AllUserStatusOptions,
  CouponCodeType,
  CouponCodeTypeEnum,
  formatCurrency,
  FreeSessionEligible,
  OrientationOptions,
  PromoCodeTypes,
  RequestStatusOptions,
  Status,
  TrainerLevels,
  TrainerTypes,
  UserStatusOptions,
  UserTypes,
} from '../../../utils/common/data';
import routes from '../../../utils/enums/routes.enums';
import {
  StyledSelect,
} from '../../select/StyledDataSelect';

export const BookingStatusOptions = [
  { label: 'Completed', value: BookingState.COMPLETED },
  { label: 'InCompleted', value: BookingState.SCHEDULED },
];

export const createKey = (input: string) => (input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input);

export const createPhoneNumberKey = (input: string) => (input ? createKey(input.replace(/[`~!@#$%^&*()_|+\-=?;:'" ,/]/gi, '')) : input);
export const bigFontStyles = css({

  padding: 5,
  width: 200,
  height: 100,
  '& > [data-ds--text-field--input]': {
    fontSize: 20,
  },
});

const NameWrapper = styled.span`
  display: flex;
  align-items: center;
`;
let disciplinesArr: string[] | undefined;
let certificationsArr: string[] | undefined;

export const TrainerRows = (items) => {
  const { cityRegions } = useGlobalsContext();
  const RegionDataType = _.map(
    cityRegions,
    (cityRegion) => ({ value: cityRegion.id, label: cityRegion.region }),
  );
  return items.map((item) => {
    const itemRegionValues = _.map(
      item.trainingRegions || [],
      (trainingRegion) => {
        const exist = _.findLast(
          RegionDataType,
          (cityRegion) => trainingRegion === cityRegion.value,
        );
        return exist;
      },
    );

    const status = findLast(Status, (itm) => Boolean(item.isActive) === itm.value);
    const trainerLevel = findLast(TrainerLevels, (itm) => item.trainerLevel === itm.value);

    const isOfferFreeSessions = findLast(
      FreeSessionEligible,
      (itm) => Boolean(item.isOfferFreeSessions) === itm.value,
    );
    return {
      key: item.id,
      cells: [
        {
          key: createKey(item.fullname),
          content: (
            <Link to={`${routes.TRAINERPROFILE}/${item.id}`} className="Link">
              <NameWrapper>{item.displayName}</NameWrapper>
            </Link>
          ),
        },
        // {
        //   key: createPhoneNumberKey(`${item.mobile?.areaCode}`),

        //   content: (
        //     <Link to={`${routes.TRAINERPROFILE}/${item.id}`} className="Link">
        //       <NameWrapper>{item.mobile?.number}</NameWrapper>
        //     </Link>
        //   ),
        // },
        {
          content: (TrainerTypes.find((type) => type.value === item.trainerType)?.label),
        },
        { key: createKey(item?.gender), content: item.gender },
        {
          key: item?.appearanceScore ? parseInt(item?.appearanceScore, 10) : 100,
          content: (
            <InlineEdit
              defaultValue={item.appearanceScore}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                  value={item.appearanceScore}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    updateTrainer({
                      id: item.id,
                      appearanceScore: Number(e.currentTarget.value),
                    });
                  }}
                />
              )}
              readView={() => (
                <div>{item.appearanceScore || 'Click to enter a value'}</div>
              )}
              onConfirm={() => {
              }}
            />
          ),
        },
        {
          key: item.trainerLevel,
          content: (
            <Select
              options={TrainerLevels}
              value={trainerLevel}
              onChange={(value) => updateTrainer({
                id: item.id,
                trainerLevel: value?.value as TrainerLevel,
              })}
            />
          ),
        },
        {
          key: createKey(item?.id),
          content: (
            <Select
              inputId="multi-select-region"
              className="multi-select"
              classNamePrefix="react-select"
              options={RegionDataType}
              value={itemRegionValues || []}
              onChange={(value) => {
                const updatedRegions: string[] = _.map(
                  value,
                  (val) => val?.value,
                ) as string[];
                updateTrainer({
                  id: item.id,
                  trainingRegions: updatedRegions,
                });
              }}
              isMulti
              isSearchable={false}
              styles={{
                control: (base) => ({
                  ...base,
                }),
              }}
            />
          ),
        },
        {
          key: item.isOfferFreeSessions,
          content: (
            <Select
              options={FreeSessionEligible}
              value={isOfferFreeSessions}
              onChange={(value) => updateTrainer({
                id: item.id,
                isOfferFreeSessions: value?.value as boolean,
              })}
            />
          ),
        },
        {
          key: item.isActive,
          content: (
            <Select
              options={Status}
              value={status}
              onChange={(value) => updateTrainer({
                id: item.id,
                isActive: value?.value as boolean,
              })}
            />
          ),
        },
      ],
    };
  });
};

export const TrainerPackagesRows = (items) => {
  const { updateTrainerPackage, deleteTrainerPackage } = TrainerPackageFirestore();
  return items.map((item) => {
    const shownTo = item?.shownTo || [];
    const showToOptionValue = _.map(shownTo, (val) => {
      const exist = _.findLast(UserTypes, (type) => (
        (type.value as string).toLowerCase()
        === (val as string)?.toLowerCase()
      ));
      return exist;
    });
    const status = findLast(Status, (itm) => Boolean(item.isActive) === itm.value);
    const starterPack = findLast(FreeSessionEligible, (itm) => Boolean(item.starterPack) === itm.value);
    return {
      key: `row-${item.id}`,
      cells: [
        {
          content: (
            <Link to={`/trainerProfile/${item.trainerId}`} className="Link">
              <NameWrapper>{item.trainerName}</NameWrapper>
            </Link>
          ),
        },
        {
          content: (
            <InlineEdit
              defaultValue={item.packageName}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield {...fieldProps} autoFocus />
              )}
              readView={() => (
                <div>{item.packageName || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updateTrainerPackage({
                  ...item,
                  id: item.id,
                  packageName: value,
                });
              }}
            />
          ),
        },
        {
          key: Number(item.sessions),
          content: (
            <InlineEdit
              defaultValue={item.sessions}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield {...fieldProps} autoFocus />
              )}
              readView={() => (
                <div>{item.sessions || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updateTrainerPackage({
                  ...item,
                  id: item.id,
                  sessions: parseInt(value, 10),
                });
              }}
            />
          ),
        },

        {
          key: Number(item.price),
          content: (
            <InlineEdit
              defaultValue={item.price}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield {...fieldProps} autoFocus />
              )}
              readView={() => (
                <div>{String(item.price) || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                const price = parseFloat(value);
                updateTrainerPackage({
                  ...item,
                  id: item.id,
                  price: Number.isInteger(price) ? parseInt(`${price}`, 10) : Number(price.toFixed(2)),
                });
              }}
            />
          ),
        },
        {
          content: (
            <Select
              options={FreeSessionEligible}
              value={starterPack}
              onChange={(value) => updateTrainerPackage({
                ...item,
                id: item.id,
                starterPack: value?.value as boolean,
              })}
            />
          ),
        },
        {
          content: (
            <Select
              options={Status}
              value={status}
              onChange={(value) => updateTrainerPackage({
                ...item,
                id: item.id,
                isActive: value?.value as boolean,
              })}
            />
          ),
        },
        {
          content: (
            <StyledSelect
              options={UserTypes}
              value={showToOptionValue as unknown as OptionType}
              onChange={(value) => {
                const updatedShownTo: string[] = _.map(
                  value,
                  (val) => val?.value,
                ) as string[];
                if (updatedShownTo.length > 0) {
                  updateTrainerPackage({
                    ...item,
                    id: item.id,
                    shownTo: updatedShownTo,
                  });
                }
              }}
              isMulti
            />
          ),
        },
        {
          content: (
            <WarningModal
              title="Are you sure you want to delete this trainer's package?"
              onPrimaryAction={() => deleteTrainerPackage(item.id)}
              primaryButtonLabel="Delete"
              triggerButton={(
                <Button appearance="link">
                  <TrashIcon label="Delete Trainer Package" primaryColor="#DE350B" />
                </Button>
              )}
            />
          ),
        },
      ],
    };
  });
};

export const PackagesRows = (items) => {
  const { updatePackageFirestore, deletePackage } = PackageFirestore();

  return items.map((item) => {
    const shownTo = item?.shownTo || [];
    const showToOptionValue = _.map(shownTo, (val) => {
      const exist = _.findLast(UserTypes, (type) => (
        (type.value as string).toLowerCase()
        === (val as string)?.toLowerCase()
      ));
      return exist;
    });
    const mostPopular = findLast(FreeSessionEligible, (itm) => Boolean(item.mostPopular) === itm.value);
    const starterPack = findLast(FreeSessionEligible, (itm) => Boolean(item.starterPack) === itm.value);
    const status = findLast(Status, (itm) => Boolean(item.isActive) === itm.value);

    return {
      key: `row-${item.id}`,
      cells: [
        {
          content: (
            <InlineEdit
              defaultValue={item.packageName}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield {...fieldProps} autoFocus />
              )}
              readView={() => (
                <div>{item.packageName || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updatePackageFirestore({
                  id: item.id,
                  packageName: value,
                });
              }}
            />
          ),
        },
        {
          content: (
            <InlineEdit
              defaultValue={item.sessions}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield {...fieldProps} autoFocus />
              )}
              readView={() => (
                <div>{item.sessions || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updatePackageFirestore({
                  id: item.id,
                  sessions: parseInt(value, 10),
                });
              }}
            />
          ),
        },
        {
          content: (
            <InlineEdit
              defaultValue={item.nutritionSessions}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield {...fieldProps} autoFocus />
              )}
              readView={() => (
                <div>
                  {String(item.nutritionSessions) || 'Click to enter a value'}
                </div>
              )}
              onConfirm={(value) => {
                updatePackageFirestore({
                  id: item.id,
                  nutritionSessions: parseInt(value, 10),
                });
              }}
            />
          ),
        },

        {
          content: (
            <InlineEdit
              defaultValue={item.price}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield {...fieldProps} autoFocus />
              )}
              readView={() => (
                <div>{String(item.price) || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                const price = parseFloat(value);

                updatePackageFirestore({
                  id: item.id,
                  price: (Number.isInteger(price) ? parseInt(`${price}`, 10) : Number(price.toFixed(2)))as number,
                });
              }}
            />
          ),
        },

        {
          content: (
            <Select
              options={
                [
                  { label: 'Nutrition', value: 'nutrition' },
                  { label: 'Trainer', value: 'trainer' },
                ] as OptionType[]
              }
              value={
                item.category === 'nutrition'
                  ? { label: 'Nutrition', value: 'nutrition' }
                  : { label: 'Trainer', value: 'trainer' }
              }
              onChange={(value) => {
                updatePackageFirestore({
                  id: item.id,
                  category: value?.value as string,
                });
              }}
            />
          ),
        },
        {
          content: (
            <Select
              options={FreeSessionEligible}
              value={starterPack}
              onChange={(value) => updatePackageFirestore({
                id: item.id,
                starterPack: value?.value as boolean,
              })}
            />
          ),
        },
        {
          content: (
            <Select
              options={FreeSessionEligible}
              value={mostPopular}
              onChange={(value) => updatePackageFirestore({
                id: item.id,
                mostPopular: value?.value as boolean,
              })}
            />
          ),
        },
        {
          content: (
            <Select
              options={Status}
              value={status}
              onChange={(value) => updatePackageFirestore({
                id: item.id,
                isActive: value?.value as boolean,
              })}
            />
          ),
        },
        {
          content: (
            <StyledSelect
              options={UserTypes}
              value={showToOptionValue as unknown as OptionType}
              onChange={(value) => {
                const updatedShownTo: string[] = _.map(
                  value,
                  (val) => val?.value,
                ) as string[];
                if (updatedShownTo.length > 0) {
                  updatePackageFirestore({
                    id: item.id,
                    shownTo: updatedShownTo,
                  });
                }
              }}
              isMulti
            />
          ),
        },
        {
          content: (
            <WarningModal
              title="Are you sure you want to delete this package?"
              onPrimaryAction={() => deletePackage(item.id)}
              primaryButtonLabel="Delete"
              triggerButton={(
                <Button appearance="link">
                  <TrashIcon label="Delete Package" primaryColor="#DE350B" />
                </Button>
              )}
            />
          ),
        },
      ],
    };
  });
};

export const UserRows = (items) => items.map((item) => {
  // eslint-disable-next-line no-underscore-dangle
  const registrationDate = item?.createdAt._seconds
    // eslint-disable-next-line no-underscore-dangle
    ? moment(item.createdAt._seconds * 1000).format('DD MMM YYYY')
    : 'N/A';
  // eslint-disable-next-line no-underscore-dangle
  const registrationDateWithTime = item?.createdAt._seconds
    // eslint-disable-next-line no-underscore-dangle
    ? moment(item.createdAt._seconds * 1000).format('DD MMM YYYY  hh:mm A')
    : 'N/A'; // added this line to sort by date and time
  // this table only sorts by string or number, so converting the date into unix timestamp
  const registrationDateKey = getUnixTimestamp(registrationDateWithTime);
  // eslint-disable-next-line no-return-assign
  return {
    key: `row-${item.id}`,
    cells: [
      {
        // zzz, so that the users with no name will be sorted at the end
        key: createKey(item?.fullname ? item.fullname : 'zzz'),
        content: (
          <Link to={`/UserProfile/${item.id}`} className="Link">
            <NameWrapper>{item.fullname}</NameWrapper>
          </Link>
        ),
      },
      {
        key: createPhoneNumberKey(`${item.mobile?.areaCode}`),
        content: (
          <Link to={`/UserProfile/${item.id}`} className="Link">
            <NameWrapper>{`${item.mobile?.areaCode} ${item.mobile?.number}`}</NameWrapper>
          </Link>
        ),
      },
      {
        key: item.email,
        content: item.email ?? '',
      },
      {
        key: item.status,
        content: item.assignedTrainerId ? UserStatusEnum.PT_CLIENT : item.status,
      },
      {
        key: registrationDateKey,
        content: registrationDate,
      },
      { content: item?.city },
      { content: item?.region },
    ],
  };
});

export const ApplicationRows = (items) => {
  const { cityRegions } = useGlobalsContext();
  const RegionDataType = _.map(
    cityRegions,
    (cityRegion) => ({ value: cityRegion.id, label: cityRegion.region }),
  );
  return items.map((item) => {
    const itemRegionValues = _.map(
      item.trainingRegions || [],
      (trainingRegion) => {
        const exist = _.findLast(
          RegionDataType,
          (cityRegion) => trainingRegion === cityRegion.value,
        );
        return exist;
      },
    );
    const registrationDate = item?.joinedDate
      ? moment(item.joinedDate.toDate()).format('DD MMM YYYY')
      : 'N/A';
    const registrationDateKey = getUnixTimestamp(registrationDate);
    return {
      key: item.id,
      cells: [
        {
          key: item.fullname,
          content: (
            <Link to={`${routes.TRAINERPROFILE}/${item.id}`} className="Link">
              <NameWrapper>{item.fullname}</NameWrapper>
            </Link>
          ),
        },
        {
          key: createPhoneNumberKey(`${item.mobile?.areaCode}`),
          content: (
            <Link to={`${routes.TRAINERPROFILE}/${item.id}`} className="Link">
              <NameWrapper>{`${item.mobile?.areaCode}${item.mobile?.number}`}</NameWrapper>
            </Link>
          ),
        },
        {
          key: item.gender,
          content: item.gender,
        },
        {
          key: registrationDateKey,
          content: registrationDate,
        },
        {
          key: `row-${item.id}-country`,
          content: item.country,
        },
        {
          key: `row-${item.id}-city`,
          content: item.city,
        },
        {
          key: 'region',
          content: (
            <Select
              inputId="multi-select-region"
              className="multi-select"
              classNamePrefix="react-select"
              options={RegionDataType}
              value={itemRegionValues || []}
              onChange={(value) => {
                const updatedRegions: string[] = _.map(
                  value,
                  (val) => val?.value,
                ) as string[];
                updateTrainer({
                  id: item.id,
                  trainingRegions: updatedRegions,
                });
              }}
              isMulti
              isSearchable={false}
              styles={{
                control: (base) => ({
                  ...base,
                }),
              }}
            />
          ),
        },

        {
          key: 'action',
          content: (
            <WarningModal
              title="Are you sure you want to verify this trainer's profile?"
              onPrimaryAction={() => updateTrainer({ id: item.id, isVerified: true })}
              primaryButtonLabel="Verify"
              secondaryButtonLabel="No"
              triggerButton={(
                <Button
                  appearance="primary"
                  style={{
                    flex: 1,
                    backgroundColor: '#3FC7E0',
                  }}
                >
                  Verify
                </Button>
              )}
            />
          ),
        },
      ],
    };
  });
};

export function BannersRows(items) {
  const { deleteBanner, updateBanner } = BannerFirestore();
  const [selectedBanner, setSelectedBanner] = useState<Banner | null>(null);
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  useEffect(() => {
    const updateImage = async () => {
      if (filesContent[0]?.content && selectedBanner?.id) {
        try {
          await StorageController.deleteFile(selectedBanner?.imageUrl as string);
          const imageUrl = await StorageController.uploadFile(filesContent[0].content, 'banners');
          await updateBanner({ ...selectedBanner, id: selectedBanner?.id, imageUrl });
          setSelectedBanner({ ...selectedBanner, isLoading: false });
          clear();
        } catch (err) {
          setSelectedBanner({ ...selectedBanner, isLoading: false });
          console.log('Error updating banner image', err);
        }
      } else {
        alert('No Image uploaded');
      }
    };
    if (filesContent[0]?.content && selectedBanner?.id) {
      setSelectedBanner({ ...selectedBanner, isLoading: true });
      updateImage();
    }
  }, [filesContent[0]?.content, selectedBanner?.id]);

  const showToOptions = defaultValues.bannerShowTo.map((showTo) => ({
    ...showTo,
    label: showTo?.label,
    value: showTo?.value,
  }));
  return items.map((item) => {
    const shownTos = (map(item?.showTo || [], (itm: OptionType) => {
      const exist = findLast(showToOptions, (show) => (
        (itm as unknown as string).toLowerCase()
        === (show.value as string).toLowerCase()
      ));
      return exist;
    }) as OptionType[]) || [];
    return {
      key: `row-${item.id}`,
      cells: [
        {
          key: Number(item.order),
          content: (
            <InlineEdit
              defaultValue={item.order}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                />
              )}
              readView={() => (
                <div>{item.order || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updateBanner({ ...item, id: item.id, order: Number(value) });
              }}
            />
          ),
        },
        {
          key: item.imageUrl,
          content: (
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              {selectedBanner?.id === item.id && selectedBanner?.isLoading ? <Spinner size="large" />
                : <img src={item.imageUrl} alt="Banner" style={{ height: 100 }} />}
              <Button
                appearance="link"
                onClick={() => {
                  setSelectedBanner(item);
                  openFileSelector();
                }}
              >
                Edit Banner Image

              </Button>
            </div>
          ),
        },
        {
          content: (
            <StyledSelect
              isMulti
              options={showToOptions}
              value={shownTos}
              onChange={(value, actionMeta) => {
                if (actionMeta.action === 'select-option') {
                  const shownTo = value.map((v) => v.value);
                  updateBanner({
                    ...item,
                    id: item.id,
                    showTo: firebase.firestore.FieldValue.arrayUnion(
                      ...shownTo,
                    ),
                  });
                }
                if (actionMeta.action === 'clear') {
                  const shownTo = actionMeta.removedValues.map((v) => v.value);
                  updateBanner({
                    ...item,
                    id: item.id,
                    showTo: firebase.firestore.FieldValue.arrayRemove(
                      ...shownTo,
                    ),
                  });
                }
                if (actionMeta.action === 'remove-value') {
                  updateBanner({
                    ...item,
                    id: item.id,
                    showTo: firebase.firestore.FieldValue.arrayRemove(
                      actionMeta.removedValue.value,
                    ),
                  });
                }
              }}
            />
          ),
        },
        {
          key: `${item.id}-${item.isActive}`,
          content: (
            <Select
              inputId="single-select-example"
              options={
                [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ] as OptionType[]
              }
              value={
                item.isActive
                  ? { label: 'Yes', value: true }
                  : { label: 'No', value: false }
              }
              onChange={(data) => {
                updateBanner({
                  ...item,
                  id: item.id,
                  isActive: data?.value as boolean,
                });
              }}
            />
          ),
        },
        {
          key: item?.link || '',
          content: (
            <InlineEdit
              defaultValue={item.link}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                />
              )}
              readView={() => (
                <div>{item.link || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updateBanner({ ...item, id: item.id, link: value });
              }}
            />
          ),
        },
        {
          content: (
            <WarningModal
              title="Are you sure you want to delete this banner?"
              onPrimaryAction={() => deleteBanner(item.id)}
              primaryButtonLabel="Delete"
              triggerButton={(
                <Button appearance="link">
                  <TrashIcon label="Delete Package" primaryColor="#DE350B" />
                </Button>
              )}
            />
          ),
        },
      ],
    };
  });
}

export function OffersRows(items) {
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });
  const { updateOffer, deleteOffer } = OffersFirestore();
  const [citiesDb, setCitiesDb] = useState<CityTypes[]>([]);
  const getCities = async () => {
    const data = await CitiesController.getAllCities();
    setCitiesDb(data);
  };
  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    const updateImage = async () => {
      if (filesContent[0]?.content && selectedOffer?.id) {
        try {
          await StorageController.deleteFile(selectedOffer?.picture as string);
          const picture = await StorageController.uploadFile(filesContent[0].content, 'offers');
          await updateOffer({ ...selectedOffer, id: selectedOffer?.id, picture });
          setSelectedOffer({ ...selectedOffer, isLoading: false });
          clear();
        } catch (err) {
          setSelectedOffer({ ...selectedOffer, isLoading: false });
          console.log('Error updating offer image', err);
        }
      } else {
        alert('No Image uploaded');
      }
    };
    if (filesContent[0]?.content && selectedOffer?.id) {
      setSelectedOffer({ ...selectedOffer, isLoading: true });
      updateImage();
    }
  }, [filesContent[0]?.content, selectedOffer?.id]);

  return items.map((item: any) => {
    const shownTo = item?.shownTo || [];
    const showToOptionValue = _.map(shownTo, (val) => {
      const exist = _.findLast(AllUserStatusOptions, (type) => (
        (type.value as string).toLowerCase()
        === (val as string)?.toLowerCase()
      ));
      return exist;
    });

    const orientation = item.orientation || 'square';
    const orientationOptions = OrientationOptions.find((orientationOption) => orientationOption.value === orientation);

    return {
      key: `row-${item.id}`,
      cells: [
        {
          key: item.order,
          content: (
            <InlineEdit
              defaultValue={item.order}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                  defaultValue={item.order}
                />
              )}
              readView={() => (
                <div>{item.order || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updateOffer({ ...item, id: item.id, order: Number(value) });
              }}
            />
          ),
        },
        {
          key: createKey(item.code),

          content: (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
            }}
            >
              {(selectedOffer?.id === item.id && selectedOffer?.isLoading) ? <Spinner size="large" />
                : <AvatarPicture pictureUrl={item.picture} />}
              <Button
                appearance="link"
                onClick={() => {
                  setSelectedOffer(item);
                  openFileSelector();
                }}
              >
                Edit Offer Image

              </Button>
            </div>
          ),
        },
        {
          key: createKey(item.code),

          content: (
            <InlineEdit
              defaultValue={item.code}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                />
              )}
              readView={() => (
                <div>{item.code || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updateOffer({ ...item, id: item.id, code: value });
              }}
            />
          ),
        },
        {
          content: (
            <InlineEdit
              defaultValue={item.title}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                />
              )}
              readView={() => (
                <div>{item.title || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updateOffer({
                  ...item,
                  id: item.id,
                  title: value,
                });
              }}
            />
          ),
        },
        {
          content: (
            <InlineEdit
              defaultValue={item.description}
              editView={({ errorMessage, ...fieldProps }) => (
                <TextArea
                  {...fieldProps}
                  autoFocus
                />
              )}
              readView={() => (
                <div>{item.description || 'Click to enter a value'}</div>
              )}
              onConfirm={(value) => {
                updateOffer({
                  ...item,
                  id: item.id,
                  description: value,
                });
              }}
            />
          ),
        },
        {
          content: (
            <div style={{ width: '104px' }}>
              <InlineEdit
                defaultValue={item.expiryDate}
                editView={({ errorMessage, ...fieldProps }) => (
                  <Textfield
                    {...fieldProps}
                    autoFocus
                  />
                )}
                readView={() => (
                  <div>{item.expiryDate || 'Click to enter a value'}</div>
                )}
                onConfirm={(value) => {
                  updateOffer({
                    ...item,
                    id: item.id,
                    expiryDate: value,
                  });
                }}
              />
            </div>
          ),
        },
        {
          content: (
            <div style={{ width: '240px' }}>
              <Select
                inputId="multi-select-region"
                className="multi-select"
                classNamePrefix="react-select"
                options={(citiesDb ?? []).map((e) => ({ value: e.name, label: e.name }))}
                value={(item.city ?? []).map((e) => ({ value: e, label: e }))}
                onChange={(value) => {
                  const updatedCities: string[] = _.map(
                    value,
                    (val) => val?.value,
                  ) as string[];
                  updateOffer({
                    id: item.id,
                    city: updatedCities,
                  });
                }}
                isMulti
                isSearchable={false}
                styles={{
                  control: (base) => ({
                    ...base,
                  }),
                }}
              />
            </div>
          ),
        },
        {
          content: (
            <div style={{ width: '240px' }}>
              <StyledSelect
                options={AllUserStatusOptions}
                value={showToOptionValue as unknown as OptionType}
                onChange={(value) => {
                  const updatedShownTo: string[] = _.map(
                    value,
                    (val) => val?.value,
                  ) as string[];
                  // if (updatedShownTo.length > 0) {
                  updateOffer({
                    ...item,
                    id: item.id,
                    shownTo: updatedShownTo,
                  });
                  // }
                }}
                isMulti
              />
            </div>
          ),
        },
        {
          content: (

            <div style={{ width: '104px' }}>
              <StyledSelect
                options={OrientationOptions}
                value={orientationOptions as unknown as OptionType}
                onChange={(value) => {
                  updateOffer({
                    ...item,
                    id: item.id,
                    orientation: value.value,
                  });
                }}
                // isMulti
              />
            </div>
          ),
        },
        {
          key: `${item.id}-${item.isActive}`,
          content: (
            <Select
              inputId="single-select-example"
              options={
                [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ] as OptionType[]
              }
              value={
                item.isActive
                  ? { label: 'Yes', value: true }
                  : { label: 'No', value: false }
              }
              onChange={(data) => {
                updateOffer({
                  ...item,
                  id: item.id,
                  isActive: data?.value as boolean,
                });
              }}
            />
          ),
        },
        {
          content: (
            <WarningModal
              title="Are you sure you want to delete this offer?"
              onPrimaryAction={() => deleteOffer(item.id)}
              primaryButtonLabel="Delete"
              triggerButton={(
                <Button appearance="link">
                  <TrashIcon label="Delete Package" primaryColor="#DE350B" />
                </Button>
              )}
            />
          ),
        },
      ],
    };
  });
}

export const GymsRows = (items) => {
  const { updateGym, deleteGym } = GymsFirestore();
  const [citiesDb, setCitiesDb] = useState<CityTypes[]>([]);
  const getCities = async () => {
    const data = await CitiesController.getAllCities();
    setCitiesDb(data);
  };
  useEffect(() => {
    getCities();
  }, []);
  return items.map((item) => ({
    key: `row-${item.id}`,
    cells: [
      {
        content: (
          <InlineEdit
            defaultValue={item.order}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield
                {...fieldProps}
                autoFocus
                defaultValue={item.order}
              />
            )}
            readView={() => (
              <div>{item.order || 'Click to enter a value'}</div>
            )}
            onConfirm={(value) => {
              updateGym({
                id: item.id,
                order: Number(value || 0),
              });
            }}
          />
        ),
      },
      {
        key: createKey(item.code),

        content: (
          <AvatarPicture pictureUrl={item.picture} />
        ),
      },
      {
        content: (
          <InlineEdit
            defaultValue={item.name}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield
                {...fieldProps}
                autoFocus
                defaultValue={item.name}
              />
            )}
            readView={() => (
              <div>{item.name || 'Click to enter a value'}</div>
            )}
            onConfirm={(value) => {
              updateGym({
                id: item.id,
                name: value.toString(),
              });
            }}
          />
        ),
      },
      {
        key: createKey(item.city),
        content: (
          <Select
            inputId="multi-select-region"
            className="multi-select"
            classNamePrefix="react-select"
            options={(citiesDb ?? []).map((e) => ({ value: e.name, label: e.name }))}
            value={(item.city ?? []).map((e) => ({ value: e, label: e }))}
            onChange={(value) => {
              const updatedCities: string[] = _.map(
                value,
                (val) => val?.value,
              ) as string[];
              updateGym({ id: item.id, city: updatedCities });
            }}
            isMulti
            isSearchable={false}
            styles={{
              control: (base) => ({
                ...base,
              }),
            }}
          />
        ),
      },
      {
        key: createKey(item.description),
        content: (
          <InlineEdit
            defaultValue={item.description}
            editView={({ errorMessage, ...fieldProps }) => (
              <TextArea
                {...fieldProps}
                defaultValue={item.description}
              />
            )}
            readView={() => (
              <div>{item.description.substring(0, 400) || 'Click to enter a value'}</div>
            )}
            onConfirm={(value) => {
              updateGym({
                id: item.id,
                description: value.toString(),
              });
            }}
          />
        ),
      },
      {
        key: createKey(item.membershipOffers),
        content: (
          <InlineEdit
            defaultValue={item.membershipOffers ?? ''}
            editView={({ errorMessage, ...fieldProps }) => (
              <TextArea
                {...fieldProps}
                defaultValue={item.membershipOffers}
              />
            )}
            readView={() => (
              <div>{(item.membershipOffers ?? '').substring(0, 400) || 'Click to enter a value'}</div>
            )}
            onConfirm={(value) => {
              updateGym({
                id: item.id,
                membershipOffers: value.toString(),
              });
            }}
          />
        ),
      },
      {
        key: createKey(item.howToAccess),
        content: (
          <InlineEdit
            defaultValue={item.howToAccess}
            editView={({ errorMessage, ...fieldProps }) => (
              <TextArea
                {...fieldProps}
                autoFocus
                value={item.howToAccess}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  updateGym({ id: item.id, howToAccess: e.currentTarget.value });
                }}
              />
            )}
            readView={() => (
              <div>{item.howToAccess.substring(0, 400) || 'Click to enter a value'}</div>
            )}
            onConfirm={() => {
            }}
          />
        ),
      },
      {
        key: 'images',
        content: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {item.images.map((img) => <img src={img} alt="Banner" style={{ width: 80, height: 45 }} />)}
          </div>
        ),
      },
      {
        key: `${item.id}-${item.isActive}`,
        content: (
          <Select
            inputId="single-select-example"
            options={
              [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ] as OptionType[]
            }
            value={
              item.isActive
                ? { label: 'Yes', value: true }
                : { label: 'No', value: false }
            }
            onChange={(data) => {
              updateGym({
                ...item,
                id: item.id,
                isActive: data?.value as boolean,
              });
            }}
          />
        ),
      },
      {
        content: (
          <WarningModal
            title="Are you sure you want to delete this offer?"
            onPrimaryAction={() => deleteGym(item.id)}
            primaryButtonLabel="Delete"
            triggerButton={(
              <Button appearance="link">
                <TrashIcon label="Delete Package" primaryColor="#DE350B" />
              </Button>
            )}
          />
        ),
      },
    ],
  }));
};

export const DisciplinesRows = (items: DisciplineV2[]) => {
  const { updateDiscipline } = disciplinesFirestore();
  const [selectedDiscipline, setSelectedDiscipline] = useState<DisciplineV2 | null>(null);

  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  useEffect(() => {
    const updateImage = async () => {
      if (filesContent[0]?.content && selectedDiscipline?.id) {
        try {
          await StorageController.deleteFile(selectedDiscipline?.picture_1 as string);
          const picture = await StorageController.uploadFile(filesContent[0].content, 'homeDisciplines/1:1');
          await updateDiscipline({ ...selectedDiscipline, id: selectedDiscipline?.id, picture_1: picture });
          // eslint-disable-next-line
          setSelectedDiscipline({ ...selectedDiscipline, isLoading: false });
          clear();
        } catch (err) {
          setSelectedDiscipline({ ...selectedDiscipline, isLoading: false });
          console.log('Error updating disciplilne image', err);
        }
      } else {
        alert('No Image uploaded');
      }
    };
    if (filesContent[0]?.content && selectedDiscipline?.id) {
      setSelectedDiscipline({ ...selectedDiscipline, isLoading: true });
      updateImage();
    }
  }, [filesContent[0]?.content, selectedDiscipline?.id]);

  return items.map((item) => {
    const { id } = item;

    return {
      key: id,
      cells: [
        {
          key: item.order,
          content: (
            <InlineEdit
              defaultValue={item.order}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                  type="number"
                  defaultValue={item.order}
                />
              )}
              readView={() => (
                <div>{item.order || 'Click to enter a value'}</div>
              )}
              // eslint-disable-next-line max-len
              onConfirm={(value) => updateDiscipline({ id: item.id, order: Number(value) })}
            />
          ),
        },
        {
          key: item.trainerType,
          content: (
            <InlineEdit
              defaultValue={item.trainerType}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                  defaultValue={item.trainerType}
                />
              )}
              readView={() => (
                <div>{item.trainerType || 'Click to enter a value'}</div>
              )}
              // eslint-disable-next-line max-len
              onConfirm={(value) => updateDiscipline({ id: item.id, trainerType: value })}
            />
          ),
        },
        {
          key: '3',
          content: (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
            }}
            >
              {(selectedDiscipline?.id === item.id && selectedDiscipline?.isLoading) ? <Spinner size="large" />
                : <AvatarPicture pictureUrl={item.picture_1} />}
              <Button
                appearance="link"
                onClick={() => {
                  setSelectedDiscipline(item);
                  openFileSelector();
                }}
              >
                Edit Image
              </Button>
            </div>),
        },
        {
          key: '4',
          content: (
            <InlineEdit
              defaultValue={item.duration}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                  type="number"
                  defaultValue={item.duration}
                />
              )}
              readView={() => (
                <div>{item.duration || 'Click to enter a value'}</div>
              )}
              // eslint-disable-next-line max-len
              onConfirm={(value) => updateDiscipline({ id: item.id, duration: Number(value) })}
            />
          ),
        },
        {
          key: '5',
          content: (
            <StyledSelect
              options={DisciplineDeliveryTypeOptions}
              value={{
                label: item.deliveryType ? item.deliveryType.toUpperCase() : 'N/A',
                value: item.type,
              } as unknown as OptionType}
              onChange={(option) => {
                updateDiscipline({ id, deliveryType: option.value });
              }}
            />
          ),
        },
        {
          key: '6',
          content: (
            <StyledSelect
              options={DisciplineServicesOptions}
              value={{ label: item.type ? item.type.toUpperCase() : 'N/A', value: item.type } as unknown as OptionType}
              onChange={(option) => {
                updateDiscipline({ id, type: option.value });
              }}
            />
          ),
        }, {
          key: '5',
          content: (
            <StyledSelect
              options={
                [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ] as OptionType[]
              }
              value={{ label: item.active ? 'Yes' : 'No', value: item.active } as unknown as OptionType}
              onChange={(option) => {
                updateDiscipline({ id, active: option.value });
              }}
            />
          ),
        },
      ],
    };
  });
};

export const CuponCodesRows = (items) => {
  const { updateCoupon, deleteCoupon } = CuponCodeFirestore();
  const { plans } = useGlobalsContext();
  const planOptions = plans.map((plan) => ({
    ...plan,
    planId: plan?.planId,
    label: plan?.packageName,
    value: plan?.id,
  }));

  return items.map((item) => {
    const promoType = findLast(CouponCodeType, (itm) => (
      item.isPercent
      === (itm.value as string)
        .toLowerCase()
        .includes(CouponCodeTypeEnum.percentage)
    ));
    const pkgs = (map(item?.packages || [], (itm: OptionType) => {
      const exist = findLast(planOptions, (plan) => (
        (itm as unknown as string).toLowerCase()
        === (plan.planId as string).toLowerCase()
      ));
      return exist;
    }) as OptionType[]) || [];
    return {
      key: `row-${item.id}`,
      cells: [
        {
          key: createKey(item.code),

          content: (
            <InlineEdit
              defaultValue={item.code}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                  value={item.code}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    updateCoupon({ id: item.id, code: e.currentTarget.value });
                  }}
                />
              )}
              readView={() => (
                <div>{item.code || 'Click to enter a value'}</div>
              )}
              onConfirm={() => {
              }}
            />
          ),
        },
        {
          content: (
            <Select
              inputId="single-select-example"
              options={PromoCodeTypes}
              value={promoType}
              onChange={(value) => {
                const isPercent = value?.value === CouponCodeTypeEnum.percentage;
                if (item.discount > 100 && isPercent) {
                  alert('percent value can not large than 100%');
                  return;
                }
                updateCoupon({
                  id: item.id,
                  isPercent,
                });
              }}
            />
          ),
        },
        {
          content: (
            <InlineEdit
              defaultValue={item.discount}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                  value={item.discount}
                  type="number"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    const value = parseFloat(e.currentTarget.value);

                    updateCoupon({
                      id: item.id,
                      discount: value,
                    });
                  }}
                />
              )}
              readView={() => (
                <div>{item.discount || 'Click to enter a value'}</div>
              )}
              onConfirm={() => {
              }}
            />
          ),
        },
        {
          content: (
            <InlineEdit
              defaultValue={item.numTimes}
              editView={({ errorMessage, ...fieldProps }) => (
                <Textfield
                  {...fieldProps}
                  autoFocus
                  value={item.numTimes}
                  type="number"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    updateCoupon({
                      id: item.id,
                      numTimes: parseInt(e.currentTarget.value, 10),
                    });
                  }}
                />
              )}
              readView={() => (
                <div>{item.numTimes || 'Click to enter a value'}</div>
              )}
              onConfirm={() => {
              }}
            />
          ),
        },
        {
          content: (
            <Select
              inputId="single-select-example"
              options={
                [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ] as OptionType[]
              }
              value={
                item.useOnce
                  ? { label: 'Yes', value: true }
                  : { label: 'No', value: false }
              }
              onChange={(data) => {
                updateCoupon({
                  id: item.id,
                  useOnce: data?.value as boolean,
                });
              }}
            />
          ),
        },
        {
          content: (
            <Select
              inputId="single-select-example"
              options={
                [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ] as OptionType[]
              }
              value={
                item.isUniversal
                  ? { label: 'Yes', value: true }
                  : { label: 'No', value: false }
              }
              onChange={(data) => {
                updateCoupon({
                  id: item.id,
                  isUniversal: data?.value as boolean,
                });
              }}
            />
          ),
        },
        {
          content: (
            <StyledSelect
              isMulti
              isDisabled={item.isUniversal}
              options={planOptions}
              value={pkgs}
              onChange={(value, actionMeta) => {
                if (actionMeta.action === 'select-option') {
                  const planIds = value.map((v) => v.planId);
                  updateCoupon({
                    id: item.id,
                    packages: firebase.firestore.FieldValue.arrayUnion(
                      ...planIds,
                    ),
                  });
                }
                if (actionMeta.action === 'clear') {
                  const planIds = actionMeta.removedValues.map((v) => v.planId);
                  updateCoupon({
                    id: item.id,
                    packages: firebase.firestore.FieldValue.arrayRemove(
                      ...planIds,
                    ),
                  });
                }
                if (actionMeta.action === 'remove-value') {
                  updateCoupon({
                    id: item.id,
                    packages: firebase.firestore.FieldValue.arrayRemove(
                      actionMeta.removedValue.planId,
                    ),
                  });
                }
              }}
            />
          ),
        },
        {
          content: (
            <WarningModal
              title="Are you sure you want to delete this coupon code?"
              onPrimaryAction={() => deleteCoupon(item.id)}
              primaryButtonLabel="Delete"
              triggerButton={(
                <Button appearance="link">
                  <TrashIcon label="Delete Package" primaryColor="#DE350B" />
                </Button>
              )}
            />
          ),
        },
      ],
    };
  });
};

const valueWithUnit = (value?: string | number, unit?: string) => (
  <div className="flex-row">
    <div className="inline-edit-input">{value || 'N/A'}</div>
    {unit && <p className="progress-unit">{unit}</p>}
  </div>
);

const updateProgress = (oldVal, val, userId) => {
  ProgressController.addOrUpdateProgress(userId, val, oldVal);
};

export const UserProfileNutritionProgressRows = (progress, userId) => progress.map((item) => {
  const startDate = moment(item.x.toDate()).format('DD MMM YYYY');
  const registrationDateKey = getUnixTimestamp(startDate);
  return {
    key: `row-${item.id}`,
    cells: [
      {
        key: registrationDateKey,
        content: (
          <InlineEdit
            defaultValue={startDate}
            editView={({ errorMessage, ...fieldProps }) => (
              <TextInput type="date" {...fieldProps} autoFocus />
            )}
            readView={() => (
              <div className="inline-edit-input">
                {startDate || 'Click to enter a value'}
              </div>
            )}
            onConfirm={(value) => updateProgress(
              item,
              {
                ...item,
                x: firebase.firestore.Timestamp.fromDate(new Date(value)),
              },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-weight`,
        content: (
          <InlineEdit
            defaultValue={String(item?.y)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.y), 'KG')}
            onConfirm={(value) => updateProgress(item, { ...item, y: parseFloat(value) }, userId)}
          />
        ),
      },

      {
        key: `row-${item.id}-weightTarget`,
        content: (
          <InlineEdit
            defaultValue={String(item?.weightTarget)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.weightTarget), 'KG')}
            onConfirm={(value) => updateProgress(
              item,
              { ...item, weightTarget: parseFloat(value) },
              userId,
            )}
          />
        ),
      },

      {
        key: `row-${item.id}-bmi`,
        content: (
          <InlineEdit
            defaultValue={String(item?.bmi)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.bmi))}
            onConfirm={(value) => updateProgress(
              item,
              { ...item, bmi: parseFloat(value) },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-bmiTarget`,
        content: (
          <InlineEdit
            defaultValue={String(item?.bmiTarget)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.bmiTarget))}
            onConfirm={(value) => updateProgress(
              item,
              { ...item, bmiTarget: parseFloat(value) },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-bodyFat`,
        content: (
          <InlineEdit
            defaultValue={String(item?.bodyFat)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.bodyFat), '%')}
            onConfirm={(value) => updateProgress(
              item,
              { ...item, bodyFat: parseFloat(value) },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-bodyFatTarget`,
        content: (
          <InlineEdit
            defaultValue={String(item?.bodyFatTarget)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.bodyFatTarget), '%')}
            onConfirm={(value) => updateProgress(
              item,
              { ...item, bodyFatTarget: parseFloat(value) },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-muscleMass`,
        content: (
          <InlineEdit
            defaultValue={String(item?.other?.muscleMass)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.other?.muscleMass), 'KG')}
            onConfirm={(value) => updateProgress(
              item,
              {
                ...item,
                other: { ...item.other, muscleMass: parseFloat(value) },
              },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-waist`,
        content: (
          <InlineEdit
            defaultValue={String(item?.other?.waist)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.other?.waist), 'cm')}
            onConfirm={(value) => updateProgress(
              item,
              {
                ...item,
                other: { ...item.other, waist: parseFloat(value) },
              },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-hip`,
        content: (
          <InlineEdit
            defaultValue={String(item?.other?.hip)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.other?.hip), 'cm')}
            onConfirm={(value) => updateProgress(
              item,
              { ...item, other: { ...item.other, hip: parseFloat(value) } },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-arm`,
        content: (
          <InlineEdit
            defaultValue={String(item?.other?.biceps)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.other?.biceps), 'cm')}
            onConfirm={(value) => updateProgress(
              item,
              {
                ...item,
                other: { ...item.other, biceps: parseFloat(value) },
              },
              userId,
            )}
          />
        ),
      },
      {
        key: `row-${item.id}-thigh`,
        content: (
          <InlineEdit
            defaultValue={String(item?.other?.thigh)}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} autoFocus />
            )}
            readView={() => valueWithUnit(String(item?.other?.thigh), 'cm')}
            onConfirm={(value) => updateProgress(
              item,
              {
                ...item,
                other: { ...item.other, thigh: parseFloat(value) },
              },
              userId,
            )}
          />
        ),
      },
    ],
  };
});

export const UserProfileNutritionRows = (items) => items.map((item) => ({
  key: `row-${item.id}`,
  cells: [
    {
      key: createKey(item.Date),
      content: (
        <InlineEdit
          defaultValue={item.Date}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => <div>{item.Date || 'Click to enter a value'}</div>}
          onConfirm={() => {
          }}
        />
      ),
    },
    {
      key: createKey(item.Weight),
      content: (
        <InlineEdit
          defaultValue={item.Weight}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{item.Weight || 'Click to enter a value'}</div>
          )}
          onConfirm={() => {
          }}
        />
      ),
    },

    {
      key: createKey(item.WeightGoal),
      content: (
        <InlineEdit
          defaultValue={item.WeightGoal}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{item.WeightGoal || 'Click to enter a value'}</div>
          )}
          onConfirm={() => {
          }}
        />
      ),
    },

    {
      key: createKey(item.BMI),
      content: (
        <InlineEdit
          defaultValue={item.BMI}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => <div>{item.BMI || 'Click to enter a value'}</div>}
          onConfirm={() => {
          }}
        />
      ),
    },
    {
      key: createKey(item.BMIGoal),
      content: (
        <InlineEdit
          defaultValue={item.BMIGoal}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{item.BMIGoal || 'Click to enter a value'}</div>
          )}
          onConfirm={() => {
          }}
        />
      ),
    },
    {
      key: createKey(item.BFP),
      content: (
        <InlineEdit
          defaultValue={item.BFP}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => <div>{item.BFP || 'Click to enter a value'}</div>}
          onConfirm={() => {
          }}
        />
      ),
    },
    {
      key: createKey(item.BFPGoal),
      content: (
        <InlineEdit
          defaultValue={item.BFPGoal}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{item.BFPGoal || 'Click to enter a value'}</div>
          )}
          onConfirm={() => {
          }}
        />
      ),
    },

    {
      key: createKey(item.otherInfo),
      content: (
        <div
          style={{
            width: 400,
            position: 'absolute',
            marginRight: 150,
            marginTop: -20,
          }}
        >
          <InlineEdit
            defaultValue={item.otherInfo}
            editView={() => (
              <TextArea
                maxHeight="300"
                name="area"
                defaultValue=" Muscle Mass: 25,8kg
              Waist: 78,5cm
              Hip: 100cm
              Arm: 24,5cm
              Thigh: 48,5 cm"
              />
            )}
            readView={() => (
              <div>{item.otherInfo || 'Click to enter a value'}</div>
            )}
            onConfirm={() => {
            }}
          />
        </div>
      ),
    },

    {
      key: createKey(item.InBodyReport),
      content: (
        <div style={{ marginLeft: 20 }}>
          <Button
            appearance="primary"
            style={{
              backgroundColor: '#3FC7E0',
            }}
          >
            View
          </Button>

          <Button
            appearance="primary"
            style={{
              backgroundColor: '#3FC7E0',
            }}
          >
            Upload
          </Button>
        </div>
      ),
    },
  ],
}));
export const UserSessionRows = (
  items: Session[],
  user: User & Trainer,
  // eslint-disable-next-line no-unused-vars
  cancelBooking: (bookingId: string, service: string, isFree: boolean) => void,
  // eslint-disable-next-line no-unused-vars
  updateBooking: ({ bookingId, item }: { bookingId: string; item: Partial<Booking> }) => void,
  // eslint-disable-next-line max-len, no-unused-vars
  deleteFieldFromBooking: ({ bookingId, fieldName }: { bookingId: string; fieldName: string; }) => void,
  // eslint-disable-next-line no-unused-vars
) => items.map((item) => {
  const statusOptionVal = _.findLast(
    BookingStatusOptions,
    (option) => option.value === item.status,
  ) || null;
  return {
    key: `row-${item.id}`,
    cells: [
      {
        content: (item?.confirmation === BookingConfirmation.REJECTED
          || (item?.cancellationReason
            && item?.cancellationReason.includes('REJECTION')
            && item?.status !== BookingState.COMPLETED)) && (
            <Tooltip content={item.cancellationReason}>
              <EditorWarningIcon
                label="Rejected Session"
                primaryColor="#ffc107"
              />
            </Tooltip>
        ),
      },
      {
        content: `${item.madeBy ? `(${item.madeBy?.toUpperCase()[0]})` : ''}`,
      },
      { content: item.date },
      { content: item.time },
      { content: item.service },
      { content: item.type.label },
      { content: item.location },
      { content: item.trainer?.name },
      {
        content: statusOptionVal ? (
          <StyledSelect
            options={BookingStatusOptions}
            value={statusOptionVal as unknown as OptionType}
            onChange={(option) => {
              const updatedItem: Partial<Booking> = {
                id: item?.id,
                status: option.value,
              };
              if (option.value === BookingState.COMPLETED) {
                // eslint-disable-next-line max-len
                updatedItem.completedAt = FirestoreService.getTimestamp() as firebase.firestore.Timestamp;
              }
              if (option.value === BookingState.SCHEDULED) {
                deleteFieldFromBooking({
                  bookingId: item?.id as string,
                  fieldName: 'completedAt',
                });
                // if admin changes the completed status to scheduled, delete completedAt property
              }
              updateBooking({ bookingId: item.id!, item: updatedItem });
            }}
          />
        ) : (
          capitalizeFirstLetter(item.status)
        ),
      },
      {},
      {
        content: (<InlineEdit
          defaultValue={item.price}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus defaultValue={item.price?.split(' ')[1]} />
          )}
          readView={() => (
            <div>{item.price || 'Click to enter a value'}</div>
          )}
          onConfirm={(value) => {
            let price = 0;
            if (value.includes('AED')) {
              price = Number(value.split(' ')[1]);
            } else {
              price = Number(value);
            }
            if (!Number.isNaN(price)) {
              updateBooking2({
                bookingId: item.id as string,
                item: {
                  price,
                },
              });
            }
          }}
        />),
      },

      {
        key: createKey(item.status),
        content:
          item.status === BookingState.COMPLETED ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Button
                isDisabled
                appearance="primary"
                className="fitlov-button"
                style={{
                  flex: 1,
                  backgroundColor: '#3FC7E0',
                }}
              >
                {capitalizeFirstLetter(item.status)}
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {item.status === BookingState.CANCELLED ? null : (
                <div style={{ display: 'flex', flex: 1, marginRight: 10 }}>
                  <AddSession
                    user={user}
                    sessionId={item.id}
                    trainerInfo={{
                      id: item.trainer!.id!,
                      name: item.trainer!.name!,
                    }}
                  />
                </div>
              )}
              <WarningModal
                title={`Are you sure you want to ${item.status === BookingState.CANCELLED ? 'delete' : 'cancel'
                } this session?`}
                onPrimaryAction={() => (item.status === BookingState.CANCELLED
                  ? deleteCancelledBooking(item?.id as string)
                  : cancelBooking(
                    item?.id!,
                    item.service,
                    Boolean(item.isFree),
                  ))}
                primaryButtonLabel="Yes"
                secondaryButtonLabel="No"
                triggerButton={(
                  <Button
                    appearance={
                      item.status === BookingState.CANCELLED
                        ? 'danger'
                        : 'primary'
                    }
                    className="fitlov-button"
                    style={{
                      flex: 1,
                      backgroundColor:
                        item.status === BookingState.CANCELLED
                          ? '#DE350B'
                          : '#3FC7E0',
                    }}
                  >
                    {item.status === BookingState.CANCELLED
                      ? 'Delete'
                      : 'Cancel'}
                  </Button>
                )}
              />
            </div>
          ),
      },
    ],
  };
});
export const UserProfileRows = (items) => items.map((item: Session) => ({
  key: `row-${item.id}`,
  cells: [
    {
      key: createKey(item.date),
      content: (
        <InlineEdit
          defaultValue={item.date}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{item.date || 'Click to enter a value'}</div>
          )}
          onConfirm={() => {
          }}
        />
      ),
    },
    {
      key: createKey(item.time),
      content: (
        <InlineEdit
          defaultValue={item.time}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{item.time || 'Click to enter a value'}</div>
          )}
          onConfirm={() => {
          }}
        />
      ),
    },

    {
      key: createKey(item.service),
      content: (
        <DropdownMenu trigger={item.service} triggerType="button">
          <DropdownItemGroup>
            <DropdownItem>{item.service}</DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },

    {
      key: createKey(item.type.label),
      content: (
        <DropdownMenu trigger={item.type.label} triggerType="button">
          <DropdownItemGroup>
            <DropdownItem>{item.type.label}</DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
    {
      key: createKey(item.location),
      content: (
        <InlineEdit
          defaultValue={item.location}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{item.location || 'Click to enter a value'}</div>
          )}
          onConfirm={() => {
          }}
        />
      ),
    },

    {
      key: createKey(item?.trainer?.id || ''),
      content: (
        <DropdownMenu trigger={item.trainer?.name} triggerType="button">
          <DropdownItemGroup>
            <DropdownItem>{item.trainer?.name}</DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },

    {
      key: createKey(item.status),
      content: (
        <DropdownMenu trigger={item.status} triggerType="button">
          <DropdownItemGroup>
            <DropdownItem>{item.status}</DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },

    {
      key: createKey(item.status),
      content: (
        <Button
          appearance="primary"
          style={{
            backgroundColor: '#3FC7E0',
          }}
        >
          Cancel
        </Button>
      ),
    },
  ],
}));

export const UserTransactionsRows = (items, trainerOptions: OptionsType[]) => items.map((item: Transaction) => ({
  key: `row-${item.id}`,
  cells: [
    {
      key: item.createdAt,
      content: <InlineEdit
        defaultValue={moment(item.createdAt.toDate() || item.createdAt).format('YYYY-M-D')}
        editView={({ errorMessage, ...fieldProps }) => (
          <Textfield type="date" {...fieldProps} autoFocus />
        )}
        readView={() => (
          <div>{String(item.date) || 'N/A'}</div>
        )}
        onConfirm={(value) => {
          getCollection(collections.BILLING_HISTORY).doc(item.id).update({
            createdAt: moment(value).toDate(),
            updatedAt: FirestoreService.getTimestamp(),
            updatedBy: 'admin',
          });
        }}
      />,
    },
    {
      content: (
        <Select
          options={trainerOptions}
          value={{ label: item.trainerName, value: item.trainerId } as any}
          onChange={(trainer) => {
            getCollection(collections.BILLING_HISTORY).doc(item.id).update({
              trainerId: trainer.value,
              trainerName: trainer.label,
              updatedAt: FirestoreService.getTimestamp(),
              updatedBy: 'admin',
            });
          }}
        />
      ),
    },
    { content: item?.sessions },
    { content: `${item.amountPaid} AED` },
    { content: item.fitlovPoints },
    { content: item.couponCode },
    { content: item.typeofPayment },
    { content: item.madeBy || item.paidBy },
    {
      content: (
        <WarningModal
          title="Are you sure you want to delete this transaction?"
          onPrimaryAction={async () => {
            await deleteTransaction(item.id as string);
          }}
          primaryButtonLabel="Delete"
          triggerButton={(
            <Button appearance="link">
              <TrashIcon label="Delete Package" primaryColor="#DE350B" />
            </Button>
          )}
        />
      ),
    },
  ],
}));

export const TrainerSessionsRows = (items) => items.map((item: Session, index: number) => {
  const dateKey = item.date ? getUnixTimestamp(item.date) : 0;
  return {
    key: `row-${item.id}`,
    cells: [
      {
        content: indexToTableNum({ index }),
      },
      {
        content: `${item.madeBy ? `(${item.madeBy?.toUpperCase()[0]})` : ''}`,
      },
      { key: dateKey, content: item.date },
      { content: item.time },
      { content: item.service },
      { content: item.type.label },
      { content: item.location },
      {
        content: (
          <Link to={`/UserProfile/${item?.client?.id}`} className="Link">
            <NameWrapper>{item?.client?.name || ''}</NameWrapper>
          </Link>
        ),
      },
      { content: item.status },
      {
        content: (<InlineEdit
          defaultValue={item.price}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus defaultValue={item.price?.split(' ')[1]} />
          )}
          readView={() => (
            <div>{item.price || 'Click to enter a value'}</div>
          )}
          onConfirm={(value) => {
            let price = 0;
            if (value.includes('AED')) {
              price = parseInt(value.split(' ')[1]);
            } else {
              price = parseInt(value);
            }
            if (!Number.isNaN(price)) {
              updateBooking2({
                bookingId: item.id as string,
                item: {
                  price,
                },
              });
            }
          }}
        />),
      },
      {
        content: item.status === BookingState.CANCELLED && (
          <WarningModal
            title="Are you sure you want to delete this session?"
            onPrimaryAction={async () => {
              await deleteCancelledBooking(item.id);
            }}
            primaryButtonLabel="Delete"
            triggerButton={(
              <Button appearance="link">
                <TrashIcon label="Delete Package" primaryColor="#DE350B" />
              </Button>
            )}
          />
        ),
      },
    ],
  };
});

export const MyClientsRows = (items, trainerId, fetchMyClients) => items.map((item) => ({
  key: `row-${item.id}`,
  cells: [
    { content: item.fullName },
    { content: item.sessionsAvailable },
    {
      content: (
        <WarningModal
          title="Are you sure you want to remove this client?"
          onPrimaryAction={async () => {
            await UserController.removeClient(item.id, trainerId, item.type).then(() => fetchMyClients());
          }}
          primaryButtonLabel="Remove"
          triggerButton={(
            <Button appearance="link">
              <TrashIcon label="Remove Client" primaryColor="#DE350B" />
            </Button>
          )}
        />
      ),
    },
  ],
}));

export const TrainerChangesRows = (items) => items.map((item: AttributeUpdated) => ({
  key: `row-${item.id}`,
  cells: [
    { content: moment(item.createdAt.toDate()).format('DD MMM YYYY') },
    {
      content: (
        <Link to={`/TrainerProfile/${item.trainerId}`} className="Link">
          <NameWrapper>{item.trainerName}</NameWrapper>
        </Link>
      ),
    },
    { content: item.field },
    {
      content: (
        // eslint-disable-next-line no-nested-ternary
        item.field === RequestFields.CERTIFICATIONS || item.field === RequestFields.DISCIPLINES
          ? (
            <div style={{ flexDirection: 'row', width: 110 }}>
              {(item.oldValue ?? []).map((value) => (
                <div style={{ marginBottom: 10 }}>
                  <span style={{ backgroundColor: '#EAEDED', width: 110, display: 'inline-block' }}>{value}</span>
                </div>
              ))}
            </div>
          )
          : item.field === RequestFields.PICTURE
            ? (<img alt="" src={String(item.oldValue)} width={50} height={50} />)
            : (<div>{item.oldValue}</div>)
      ),
    },
    {
      content: (
        // eslint-disable-next-line no-nested-ternary
        item.field === RequestFields.CERTIFICATIONS || item.field === RequestFields.DISCIPLINES
          ? (
            <div style={{ flexDirection: 'row', width: 160 }}>
              {(item.newValue ?? []).map((value) => (
                <div>
                  <span
                    id={value}
                    style={{ backgroundColor: '#EAEDED', width: 110, display: 'inline-block' }}
                  >
                    {value}
                  </span>
                  <Button
                    appearance="link"
                    id={`${value}x`}
                    onClick={() => {
                      const element1 = document.getElementById(value);
                      const element2 = document.getElementById(`${value}x`);
                      if (element1) {
                        element1.style.display = 'none';
                      }
                      if (element2) {
                        element2.style.display = 'none';
                      }
                      // eslint-disable-next-line no-unused-expressions
                      item.field === RequestFields.CERTIFICATIONS
                        ? certificationsArr = [...item.newValue].filter((e) => e !== value)
                        : console.log('error');
                      // eslint-disable-next-line no-unused-expressions
                      item.field === RequestFields.DISCIPLINES
                        ? disciplinesArr = [...item.newValue].filter((e) => e !== value)
                        : console.log('error');
                    }}
                  >
                    <span style={{ marginLeft: '5px' }}>x</span>
                  </Button>
                </div>
              ))}
            </div>
          )
          : item.field === RequestFields.PICTURE
            ? (<img alt="" src={String(item.newValue)} width={50} height={50} />)
            : (<div>{item.newValue}</div>)
      ),
    },
    {
      key: item.status,
      content: (
        <Select
          options={RequestStatusOptions}
          value={{ label: item.status, value: item.status } as any}
          onChange={(status) => {
            getCollection(collections.TRAINER_REQUEST).doc(item.id)
              .update({
                status: status.value,
                updatedAt: FirestoreService.getTimestamp(),
              });
            if (status.value === RequestStatus.APPROVED) {
              if (item.field === RequestFields.DISPLAY_NAME) {
                updateTrainer({
                  id: item.trainerId,
                  displayName: String(item.newValue),
                });
              }
              if (item.field === RequestFields.BIO) {
                updateTrainer({
                  id: item.trainerId,
                  bio: String(item.newValue),
                });
              }
              if (item.field === RequestFields.DISCIPLINES) {
                updateTrainer({
                  id: item.trainerId,
                  disciplines: disciplinesArr ?? item.newValue,
                });
                disciplinesArr = undefined;
              }
              if (item.field === RequestFields.CERTIFICATIONS) {
                updateTrainer({
                  id: item.trainerId,
                  certifications: certificationsArr ?? item.newValue,
                });
                certificationsArr = undefined;
              }
              deleteTrainerRequest(item.id);
            }
          }}
        />
      ),
    },
  ],
}));

export const MonthlyBalancerows = (items: Revenue[], update: () => void) => items.map((item: Revenue, index: number) => ({
  key: `row-${item.id}`,
  cells: [
    {
      content: <Tooltip content={item.id}>
        {index + 1}
        {item.isDuplicated && (
          <WarningIcon
            label="Warning"
            primaryColor="#ffc107"
          />
        )}
      </Tooltip>,
    },
    {
      content: <Tooltip content={item.id}>
        {item.sessionDate}
      </Tooltip>,
    },
    {
      content: (
        <Link to={`/userProfile/${item.clientId}`} className="Link">
          <NameWrapper>{item.clientName || 'Not name assigned'}</NameWrapper>
        </Link>
      ),
    },
    {
      content: (
        <Link to={`/trainerProfile/${item.trainerId}`} className="Link">
          <NameWrapper>{item.trainerName || 'Not name assigned'}</NameWrapper>
        </Link>
      ),
    },
    { content: item.type === RevenueType.firstPackage ? 'First' : 'Renewals' },
    { content: item.pricePerSession },
    {
      content: (
        <InlineEdit
          defaultValue={item.trainerCompensation}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{String(item.trainerCompensation) || 'Click to enter a value'}</div>
          )}
          onConfirm={(value) => {
            if (Number(value) === item.trainerCompensation) {
              return;
            }
            MonthlyBalanceController.update(item.id, {
              trainerCompensation: Number(value),
              fitlovCommission: Number((item.pricePerSession - Number(value)).toFixed(2)),
              note: `${item.note}. Trainer compensation updated by admin.`,
            }).then(update);
          }}
        />
      ),
    },
    { content: item.fitlovCommission },
    {
      content: (
        <InlineEdit
          defaultValue={item.note}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield {...fieldProps} autoFocus />
          )}
          readView={() => (
            <div>{String(item.note) || 'N/A'}</div>
          )}
          onConfirm={(value) => {
            MonthlyBalanceController.update(item.id, {
              note: value,
            }).then(update);
          }}
        />
      ),
    },
    {
      content: (
        <Button appearance="link" onClick={() => MonthlyBalanceController.delete(item.id).then(update)}>
          <TrashIcon primaryColor="grey" label="Delete Package" />
        </Button>
      ),
    },
  ],
}));

export const ClientPackagesRows = (
  items: ClientPackage[],
  trainerOptions: OptionsType[],
) => items.map((item: ClientPackage) => ({
  key: `row-${item.id}`,
  cells: [
    { content: moment(item.createdAt.toDate()).format('ddd MMM DD, YYYY') },
    {
      content: (
        <Select
          options={trainerOptions}
          value={{ label: item.trainerName, value: item.trainerId } as any}
          onChange={(trainer) => {
            getCollection(collections.CLIENT_PACKAGES).doc(item.id)
              .update({
                trainerId: trainer.value,
                trainerName: trainer.label,
                updatedAt: FirestoreService.getTimestamp(),
                updatedBy: 'admin',
              });
          }}
        />
      ),
    },
    {
      content: (
        <Select
          options={isRenewalTypes}
          value={{ label: item.renewal ? 'Yes' : 'No', value: item.renewal } as any}
          onChange={(isRenewal) => {
            getCollection(collections.CLIENT_PACKAGES).doc(item.id)
              .update({
                renewal: isRenewal.value,
                updatedAt: FirestoreService.getTimestamp(),
                updatedBy: 'admin',
              });
          }}
        />
      ),
    },
    {
      content: (
        <InlineEdit
          defaultValue={(item.price || 0)}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield
              {...fieldProps}
              autoFocus
            />
          )}
          readView={() => (
            <div>{item.price || '0'}</div>
          )}
          onConfirm={(value) => {
            getCollection(collections.CLIENT_PACKAGES).doc(item.id)
              .update({
                price: Number(value),
                pricePerSession: Number((Number(value) / item.package.sessions).toFixed(2)),
                updatedAt: FirestoreService.getTimestamp(),
                updatedBy: 'admin',
              });
          }}
        />
      ),
    },
    {
      content: item.pricePerSession,
    },
    {
      content: (
        <InlineEdit
          defaultValue={item.package?.sessions}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield
              {...fieldProps}
              autoFocus
            />
          )}
          readView={() => (
            <div>{item.package?.sessions || '0'}</div>
          )}
          onConfirm={(value) => {
            getCollection(collections.CLIENT_PACKAGES).doc(item.id)
              .update({
                package: {
                  ...item.package,
                  sessions: Number(value),
                },
                pricePerSession: Number((item.price / Number(value)).toFixed(2)),
                updatedAt: FirestoreService.getTimestamp(),
                updatedBy: 'admin',
              });
          }}
        />
      ),
    },
    {
      content: (
        <InlineEdit
          defaultValue={item.sessions}
          editView={({ errorMessage, ...fieldProps }) => (
            <Textfield
              {...fieldProps}
              autoFocus
            />
          )}
          readView={() => (
            <div>{item.sessions || '0'}</div>
          )}
          onConfirm={(value) => {
            getCollection(collections.CLIENT_PACKAGES).doc(item.id)
              .update({
                sessions: Number(value),
                updatedAt: FirestoreService.getTimestamp(),
                updatedBy: 'admin',
              });
          }}
        />
      ),
    },
    {
      content: item.scheduledBookings,
    },
    {
      content: item.completedBookings,
    },
    {
      content: (
        <Select
          options={isRenewalTypes}
          value={{ label: item.archived ? 'Yes' : 'No', value: item.archived } as any}
          onChange={(archived) => {
            getCollection(collections.CLIENT_PACKAGES).doc(item.id)
              .update({
                archived: archived.value,
                updatedAt: FirestoreService.getTimestamp(),
                updatedBy: 'admin',
              });
          }}
        />
      ),
    },
    {
      content: (
        <DateInput
          divStyle={{ width: '200%' }}
          hideIcon
          value={moment(item.expirationDate?.toDate()).toISOString()}
          dateFormat="YYYY-MM-DD"
          onChange={(date: string) => {
            if (date.length > 0) {
              getCollection(collections.CLIENT_PACKAGES).doc(item.id)
                .update({
                  expirationDate: new Date(date),
                  updatedAt: FirestoreService.getTimestamp(),
                  updatedBy: 'admin',
                });
            }
          }}
        />
      ),
    },
    {
      content: (
        <WarningModal
          title="Only unused packages can be deleted"
          onPrimaryAction={async () => {
            deleteClientPackage(item.id, item.billingHistoryId);
          }}
          primaryButtonLabel="Delete"
          triggerButton={(
            <Button appearance="link" isDisabled={!item.canRemove}>
              <TrashIcon label="Delete Package" primaryColor={item.canRemove ? '#DE350B' : 'grey'} />
            </Button>
          )}
        />
      ),
    },
  ],
}));

export const MonthlyBalanceSummaryrows = (items: any[]) => items.map((item: any) => ({
  key: `row-${item.id}`,
  cells: [
    { content: item.clientName },
    { content: item.totalSessions },
    { content: `${item.totalPaid} AED` },
    { content: `${item.totalTrainerCompensation} AED` },
    { content: `${item.totalFitlovCommission} AED` },
  ],
}));

export const TrainerPerformanceRows = (items: any[]) => items.map((item: any) => ({
  key: `row-${item.trainerId}`,
  cells: [
    // { content: item.trainerName },
    {
      content: (
        <Link to={`/TrainerProfile/${item.trainerId}`} className="Link">
          <NameWrapper>{item.trainerName}</NameWrapper>
        </Link>
      ),
    },
    { key: item.clients, content: item.clients },
    { key: item.sessionsDelivered, content: item.sessionsDelivered },
    { key: item.averageSessionsPerClient, content: item.averageSessionsPerClient },
    { key: item.lostClients, content: item.lostClients },
    { key: item.converted, content: item.converted },
    { key: item.nonConverted, content: item.nonConverted },
    { key: item.convertionRate, content: `${item.convertionRate}%` },
    { key: item.nonRetention, content: item.nonRetention },
    { key: item.retention, content: item.retention },
    { key: item.retentionRate, content: `${item.retentionRate}%` },
    { key: item.clientsThatComplete10Sessions, content: item.clientsThatComplete10Sessions },
    { key: item.clientsThatComplete20Sessions, content: item.clientsThatComplete20Sessions },
    { key: item.clientsThatComplete30Sessions, content: item.clientsThatComplete30Sessions },
  ],
}));

export const ClientFinsihingRows = (items, updateRenewalArr) => items.map((item: Renewal) => {
  const lastCompleteDateKey = item.lastCompleteDate
    ? getUnixTimestamp(item.lastCompleteDate)
    : 0;
  return {
    key: `row-${item.userName}`,
    cells: [
      {
        key: item.userName,
        content: (
          <Link to={`/UserProfile/${item.id}`} className="Link">
            <NameWrapper>{item.userName}</NameWrapper>
          </Link>
        ),
      },
      {
        key: createPhoneNumberKey(`${item.phoneNumber}`),
        content: item.phoneNumber,
      },
      {
        key: item.balance,
        content: item.balance,
      },
      {
        key: lastCompleteDateKey,
        content: item.lastCompleteDate,
      },
      {
        key: item.lastCompletedTrainerName,
        content: item.lastCompletedTrainerName,
      },
      {
        key: 'row-comment',
        content: (
          <div
            style={{
              maxWidth: 280,
              position: 'relative',
              marginRight: 16,
              marginTop: 8,
            }}
          >
            <InlineEdit
              defaultValue={item.comment}
              editView={(fieldProps) => (
                // @ts-ignore - textarea does not pass through ref as a prop
                <TextArea
                  defaultValue={item.comment}
                  resize="auto"
                  maxHeight="100"
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div>
                  {_.truncate(item.comment, {
                    length: 40,
                    separator: '..',
                    omission: '..',
                  }) || 'Write Comment'}
                </div>
              )}
              onConfirm={(value) => {
                updateUser({
                  id: item.id!,
                  comment: value,
                });
                updateRenewalArr(item.id, 'comment', value, 'TRAINING');
              }}
            />
          </div>
        ),
      },

    ],
  };
});
export const ClientPendingRenewalRows = (items, updateRenewalArr) => items.map((item: Renewal) => {
  const lastCompleteDateKey = item.lastCompleteDate
    ? getUnixTimestamp(item.lastCompleteDate)
    : 0;
  return {
    key: `row-${item.userName}`,
    cells: [
      {
        key: item.userName,
        content: (
          <Link to={`/UserProfile/${item.id}`} className="Link">
            <NameWrapper>{item.userName}</NameWrapper>
          </Link>
        ),
      },
      {
        key: createPhoneNumberKey(`${item.phoneNumber}`),
        content: item.phoneNumber,
      },
      {
        key: item.completedSessions,
        content: item.completedSessions,
      },
      {
        key: lastCompleteDateKey,
        content: item.lastCompleteDate,
      },
      {
        key: item.lastCompletedTrainerName,
        content: item.lastCompletedTrainerName,
      },
      {
        key: 'row-comment',
        content: (
          <div
            style={{
              maxWidth: 280,
              position: 'relative',
              marginRight: 16,
              marginTop: 8,
            }}
          >
            <InlineEdit
              defaultValue={item.comment}
              editView={(fieldProps) => (
                // @ts-ignore - textarea does not pass through ref as a prop
                <TextArea
                  defaultValue={item.comment}
                  resize="auto"
                  maxHeight="100"
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div>
                  {_.truncate(item.comment, {
                    length: 40,
                    separator: '..',
                    omission: '..',
                  }) || 'Write Comment'}
                </div>
              )}
              onConfirm={(value) => {
                updateUser({
                  id: item.id!,
                  comment: value,
                });
                updateRenewalArr(item.id, 'comment', value, 'PENDING');
              }}
            />
          </div>
        ),
      },
      {
        key: item.status,
        content: (
          <Select
            options={UserStatusOptions}
            defaultValue={{ label: item.status, value: item.status }}
            onChange={(option) => {
              updateUser({
                id: item.id,
                status: option?.value.toString(),
              });
              updateRenewalArr(item.id, 'status', option?.value.toString());
            }}
          />
        ),
      },
    ],
  };
});

export const UnconfirmedSessionsRows = (items) => items.map((item: TrainerSession) => {
  const dateTime = convertBookingDateToDateTime({
    date: item.date,
    startTime: item?.slot?.startHour as number,
    endTime: item?.slot?.endHour as number,
  });

  return {
    key: item.id,
    cells: [
      {
        content: item.madeBy ? `(${item.madeBy?.toUpperCase()[0]})` : '',
      },
      {
        content: dateTime,
      },
      {
        content: convertFirebaseTimestampToDateString(item.createdAt),
      },
      {
        content: (
          <Link to={`/UserProfile/${item.userId}`} className="Link">
            <NameWrapper>{item.clientName}</NameWrapper>
          </Link>
        ),
      },
      {
        content: item.trainerName,
      },
      {
        content: item?.discipline,
      },
      {
        content: `${item?.people} on 1`,
      },
    ],
  };
});

export const CancelledSessionsRows = (items, fetchCancelledSessions) => items.map((item: TrainerSession) => {
  const dateTime = convertBookingDateToDateTime({
    date: item.date,
    startTime: item?.slot?.startHour as number,
    endTime: item?.slot?.endHour as number,
  });
  const createdAtTime = item?.createdAt?.seconds
    ? Number(item?.createdAt?.seconds) * 1000
    : 'N/A';
  const cancelledAtTime = item?.cancelledAt?.seconds
    ? Number(item?.cancelledAt?.seconds) * 1000
    : 'N/A';
  const duration = Math.round(
    moment
      .duration(moment(cancelledAtTime).diff(moment(createdAtTime)))
      .asHours(),
  );

  const cancelledByName = () => {
    if (item.cancelledBy === item.userId) {
      return item.clientName;
    }
    if (item.cancelledBy === item.trainerId) {
      return item.trainerName;
    }
    return 'ADMIN';
  };
  return {
    key: item.id,
    cells: [
      {
        content: `${item.madeBy ? `(${item.madeBy?.toUpperCase()[0]})` : ''
        }`,
      },
      {
        content: dateTime,
      },
      {
        content: convertFirebaseTimestampToDateString(
          item?.createdAt,
        ),
      },
      {
        content: convertFirebaseTimestampToDateString(
          item?.cancelledAt,
        ),
      },
      {
        content: duration >= 6 ? 'Yes' : 'No',
      },
      {
        content: cancelledByName(),
      },
      {
        content: (
          <Link to={`/UserProfile/${item.userId}`} className="Link">
            <NameWrapper>{item.clientName}</NameWrapper>
          </Link>
        ),
      },
      {
        content: item.trainerName,
      },
      {
        content: (
          <WarningModal
            title="Are you sure you want to delete this session?"
            onPrimaryAction={async () => {
              await deleteCancelledBooking(item.id).then(() => fetchCancelledSessions());
            }}
            primaryButtonLabel="Delete"
            triggerButton={(
              <Button appearance="link">
                <TrashIcon label="Delete Package" primaryColor="#DE350B" />
              </Button>
            )}
          />
        ),
      },
    ],
  };
});

export const ConfirmedSessionsRows = (items) => items.map((item: TrainerSession) => {
  const dateTime = convertBookingDateToDateTime({
    date: item.date,
    startTime: item?.slot?.startHour as number,
    endTime: item?.slot?.endHour as number,
  });
  return {
    key: item.id,
    cells: [
      {
        content: `${item.madeBy ? `(${item.madeBy?.toUpperCase()[0]})` : ''
        }`,
      },
      {
        content: dateTime,
      },
      {
        content: convertFirebaseTimestampToDateString(
          item?.createdAt,
        ),
      },
      {
        content: item?.confirmedAt
          ? convertFirebaseTimestampToDateString(item?.confirmedAt)
          : 'Error 1',
      },
      {
        content: (
          <Link to={`/UserProfile/${item.userId}`} className="Link">
            <NameWrapper>{item.clientName}</NameWrapper>
          </Link>
        ),
      },
      {
        content: item.trainerName,
      },
    ],
  };
});

export const CompletedSessionsRows = (items) => items.map((item: TrainerSession) => {
  const dateTime = convertBookingDateToDateTime({
    date: item.date,
    startTime: item?.slot?.startHour as number,
    endTime: item?.slot?.endHour as number,
  });
  return {
    key: item.id,
    cells: [
      {
        content: `${item.madeBy ? `(${item.madeBy?.toUpperCase()[0]})` : ''
        }`,
      },
      {
        content: dateTime,
      },
      {
        content: convertFirebaseTimestampToDateString(
          item?.createdAt,
        ),
      },
      {
        content: convertFirebaseTimestampToDateString(
          item?.completedAt,
        ),
      },
      {
        content: (
          <Link to={`/UserProfile/${item.userId}`} className="Link">
            <NameWrapper>{item.clientName}</NameWrapper>
          </Link>
        ),
      },
      {
        content: item.trainerName,
      },
    ],
  };
});

export const RejectedSessionsRows = (items) => items.map((item: TrainerSession) => {
  const dateTime = convertBookingDateToDateTime({
    date: item.date,
    startTime: item?.slot?.startHour as number,
    endTime: item?.slot?.endHour as number,
  });

  const cancelledByName = () => {
    if (item.cancelledBy === item.userId) {
      return item.clientName;
    }
    if (item.cancelledBy === item.trainerId) {
      return item.trainerName;
    }
    return 'ADMIN';
  };

  return {
    key: item.id,
    cells: [
      {
        content: `${item.madeBy ? `(${item.madeBy?.toUpperCase()[0]})` : ''
        }`,
      },
      {
        content: dateTime,
      },
      {
        content: convertFirebaseTimestampToDateString(
          item?.createdAt,
        ),
      },
      {
        content: item?.cancelledAt
          ? convertFirebaseTimestampToDateString(item?.cancelledAt)
          : 'Error 1',
      },
      {
        content: item?.cancellationReason,
      },
      {
        content: (
          <Link to={`/UserProfile/${item.userId}`} className="Link">
            <NameWrapper>{item.clientName}</NameWrapper>
          </Link>
        ),
      },
      {
        content: item.trainerName,
      },
      {
        content: cancelledByName(),
      },
      {
        content: (
          <Tooltip content={item?.rejectedActionStatus ?? ''}>
            {item?.rejectedActionStatus === RejectedActionStatus.SOLVED && (
              <SuccessIcon
                primaryColor="green" // {token('color.icon.success', 'red')}
                label="Success"
              />
            )}
            {item?.rejectedActionStatus === RejectedActionStatus.PENDING && (

              <WarningIcon
                label="Warning"
                primaryColor="#ffc107"
              />
            )}
          </Tooltip>
        ),
      },
    ],
  };
});

export const ClientPendingRows = (items, updateRenewalArr) => items.map((item: Renewal) => {
  const lastCompleteDateKey = getUnixTimestamp(item.lastCompleteDate);
  return {
    key: `row-${item.userName}`,
    cells: [
      {
        key: item.userName,
        content: (
          <Link to={`/UserProfile/${item.id}`} className="Link">
            <NameWrapper>{item.userName}</NameWrapper>
          </Link>
        ),
      },
      {
        key: createPhoneNumberKey(`${item.phoneNumber}`),
        content: item.phoneNumber,
      },
      {
        key: item.balance,
        content: item.balance,
      },

      {
        key: lastCompleteDateKey,
        content: item.lastCompleteDate,
      },
      {
        key: item.lastCompletedTrainerName,
        content: item.lastCompletedTrainerName,
      },
      {
        key: item.lastBillingDate !== 'N/A' ? moment(item.lastBillingDate, 'DD MMM YYYY').unix() : 'N/A',
        content: item.lastBillingDate,
      },
      {
        key: 'row-comment',
        content: (
          <div
            style={{
              maxWidth: 280,
              position: 'relative',
              marginRight: 16,
              marginTop: 8,
            }}
          >
            <InlineEdit
              defaultValue={item.comment}
              editView={(fieldProps) => (
                // @ts-ignore - textarea does not pass through ref as a prop
                <TextArea
                  defaultValue={item.comment}
                  resize="auto"
                  maxHeight="100"
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div>
                  {_.truncate(item.comment, {
                    length: 40,
                    separator: '..',
                    omission: '..',
                  }) || 'Write Comment'}
                </div>
              )}
              onConfirm={(value) => {
                updateUser({
                  id: item.id!,
                  comment: value,
                });
                updateRenewalArr(item.id, 'comment', value, 'NOT_TRAINING');
              }}
            />
          </div>
        ),
      },
    ],
  };
});
export const ExpirationRows = (items) => items.map((item: ExpiredPackage, index) => ({
  key: `row-${index}-${item.clientName}`,
  cells: [
    {
      key: item.clientName,
      content: (
        <Link to={`/UserProfile/${item.userId}`} className="Link">
          <NameWrapper>{item.clientName}</NameWrapper>
        </Link>
      ),
    },
    {
      key: item.trainerName,
      content: (
        <Link to={`/TrainerProfile/${item.trainerId}`} className="Link">
          <NameWrapper>{item.trainerName}</NameWrapper>
        </Link>
      ),
    },
    {
      key: item.sessionsLeft,
      content: item.sessionsLeft,
    },
    {
      key: item.pricePerSession,
      content: `AED ${(item.pricePerSession ?? '').toLocaleString()}`,
    },
    {
      key: item.totalExpired,
      content: `AED ${Number(item.totalExpired?.toFixed(2)).toLocaleString()}`,
    },
    {
      key: new Date(item.expirationDate),
      // key: moment(item.expirationDate).seconds,
      content: moment(item.expirationDate).format('DD/MM/YYYY'),
    },
    {
      key: item.archived,
      content: item.archived ? 'Yes' : 'No',
    },

  ],
}));

export const RatingsRows = (items: ReviewRecord[]) => items.map((item: ReviewRecord) => {
  const ratingDate = item?.date
    ? moment(item.date, 'DD MMM YYYY').format('DD MMM YYYY') : 'N/A';
  const ratingKey = getUnixTimestamp(ratingDate);
  return ({
    key: item.id,
    cells: [
      {
        key: ratingKey,
        content: ratingDate,
      },

      {
        key: item.reviewerName?.length > 0 ? item.reviewerName : item.user.name,
        content: item.reviewerName?.length > 0 ? item.reviewerName : item.user.name,
      },

      {
        key: item.trainer.name,
        content: item.trainer.name,
      },

      {
        key: Number(item.avgRating).toFixed(1),
        content: item.avgRating.toFixed(1),
      },
      {
        // key: `row-comment`,
        content: (
          <div
            style={{
              width: 56,
              position: 'relative',
            }}
          >
            <InlineEdit
              defaultValue={item.qualityRate}
              editView={(fieldProps) => (
                // @ts-ignore - textarea does not pass through ref as a prop
                <TextInput
                  type="number"
                  defaultValue={item.qualityRate.toString()}
                  {...fieldProps}
                />
              )}
              readView={() => <div>{item.qualityRate || 'No Review'}</div>}
              onConfirm={(value) => {
                updateReview(item.id!, 'qualityRate', value);
              }}
            />
          </div>
        ),
      },
      {
        // key: `row-comment`,
        content: (
          <div
            style={{
              width: 80,
              position: 'relative',
            }}
          >
            <InlineEdit
              defaultValue={item.punctualityRate}
              editView={(fieldProps) => (
                // @ts-ignore - textarea does not pass through ref as a prop
                <TextInput
                  type="number"
                  defaultValue={item.punctualityRate.toString()}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div>{item.punctualityRate || 'View Review'}</div>
              )}
              onConfirm={(value) => {
                updateReview(item.id!, 'punctualityRate', value);
              }}
            />
          </div>
        ),
      },
      {
        // key: `row-comment`,
        content: (
          <div
            style={{
              width: 88,
              position: 'relative',
            }}
          >
            <InlineEdit
              defaultValue={item.friendlinessRate}
              editView={(fieldProps) => (
                // @ts-ignore - textarea does not pass through ref as a prop
                <TextInput
                  type="number"
                  defaultValue={item.friendlinessRate.toString()}
                  {...fieldProps}
                />
              )}
              readView={() => (
                <div>{item.friendlinessRate || 'View Review'}</div>
              )}
              onConfirm={(value) => {
                updateReview(item.id!, 'friendlinessRate', value);
              }}
            />
          </div>
        ),
      },

      {
        // key: `row-comment`,
        content: (
          <div
            style={{
              width: 280,
              position: 'relative',
              marginRight: 150,
              marginTop: 20,
            }}
          >
            <InlineEdit
              defaultValue={item.comment}
              editView={(fieldProps) => (
                // @ts-ignore - textarea does not pass through ref as a prop
                <TextArea
                  defaultValue={item.comment}
                  resize="auto"
                  maxHeight="100"
                  {...fieldProps}
                />
              )}
              readView={() => <div>{item.comment || 'No comment'}</div>}
              onConfirm={(value) => {
                updateReview(item.id!, 'comment', value);
              }}
            />
          </div>
        ),
      },

      {
        content: (
          <Select
            inputId="single-select-example"
            options={
              [
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ] as OptionType[]
            }
            value={
              item.isApproved
                ? { label: 'Yes', value: 'true' }
                : { label: 'No', value: 'false' }
            }
            onChange={(data) => {
              updateReview(item.id!, 'isApproved', data!.value === 'true');
            }}
          />
        ),
      },
      {
        content: (
          <WarningModal
            title="Are you sure you want to delete this review?"
            onPrimaryAction={() => deleteReview(item.id)}
            primaryButtonLabel="Delete"
            triggerButton={(
              <Button appearance="link">
                <TrashIcon label="Delete Package" primaryColor="#DE350B" />
              </Button>
            )}
          />
        ),
      },
    ],
  });
});

export const TrainerRatingsRows = (
  items: TrainerRatingRecord[],
) => items.map((item: TrainerRatingRecord) => ({
  key: item.id,
  cells: [
    {
      key: item.fullName,
      content: item.fullName,
    },
    {
      key: Number(item.avgRating).toFixed(1),
      content: Number(item.avgRating).toFixed(1),
    },
    {
      key: Number(item.avgQualityRate).toFixed(1),
      content: item.avgQualityRate.toFixed(1),
    },
    {
      key: Number(item.avgPunctualityRate).toFixed(1),
      content: item.avgPunctualityRate.toFixed(1),
    },
    {
      key: Number(item.avgFriendlinessRate).toFixed(1),
      content: item.avgFriendlinessRate.toFixed(1),
    },
    {
      key: Number(item.appearanceScore).toFixed(0),
      content: Number(item.appearanceScore).toFixed(0),
    },
  ],
}));
export const TransactionsRows = (items, trainerOptions: OptionType[]) => items.map((item: BillingHistory) => {
  const createdAt = item?.createdAt
    ? moment(item.createdAt, 'DD MMM YYYY').format('DD MMM YYYY') : 'N/A';
  return ({
    key: item.id,
    cells: [
      { key: item?.createdAtKey, content: createdAt },
      { key: item?.userName ?? '', content: item?.userName },
      {
        content: (
          <Select
            options={trainerOptions}
            value={{ label: item.trainerName, value: item.trainerId } as any}
            onChange={(trainer) => {
              getCollection(collections.BILLING_HISTORY).doc(item.id).update({
                trainerId: trainer.value,
                trainerName: trainer.label,
                updatedAt: FirestoreService.getTimestamp(),
                updatedBy: 'admin',
              });
            }}
          />
        ),
      },
      { key: item.sessions, content: item.sessions },
      { key: item.fitlovPoints || '', content: item.fitlovPoints || '' },
      { key: item.couponCode || '', content: item.couponCode || '' },
      { key: item.price, content: formatCurrency(item.price ?? 0) },
      {
        key: item.madeBy || 'Client',
        content: item.madeBy ? item.madeBy?.toUpperCase() : item?.paidBy?.toUpperCase() || 'Client',
      },
      { key: item.typeOfPayment, content: item.typeOfPayment },
    ],
  });
});

export const CitiesRows = (items, updateCity: any) => items.map((item: CityTypes) => ({
  key: item.id,
  cells: [
    {
      key: item?.name ?? '',
      content: (
        <Link to={`/cities/${item.id}?name=${item.name}`} className="Link">
          <NameWrapper>{item.name}</NameWrapper>
        </Link>
      ),
    },
    { key: item.country, content: item.country },
    { key: item.regions || '', content: item.regions || '0' },
    {
      content: (
        <Select
          inputId="single-select-example"
          options={
            [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ] as OptionType[]
          }
          value={
            item.isActive
              ? { label: 'Yes', value: true }
              : { label: 'No', value: false }
          }
          onChange={(selection) => {
            updateCity(item.id, { ...item, isActive: selection?.value });
          }}
        />),
    },
  ],
}));
export const RegionRows = (cityId, items) => items.map((item: CityRegion) => ({
  key: item.id,
  cells: [
    {
      key: item?.region ?? '',
      content: (
        <Link to={`/cities/${cityId}/${item.id}`} className="Link">
          <NameWrapper>{item.region}</NameWrapper>
        </Link>
      ),
    },
    { key: item.region, content: item.region },
    { key: item.region || '', content: item.region || '0' },
  ],
}));

export const PreBookingsRows = (items, trainerList) => items.map((item: PreBooking) => {
  const selected = item.selected?.trainerName || 'N/A';
  const openProfile = (trainerId) => {
    window.open(`/trainerProfile/${trainerId}`);
  };

  // const formattedDate = moment(item.date).format('dddd MMMM Do');
  const trainers = [...item.pendingBy, ...item.confirmedBy, ...item.rejectedBy];
  const trainerOptions = trainers.map((trainer) => ({ label: trainer.trainerName, value: trainer.trainerId }));
  const approvedStyle = { margin: 1, backgroundColor: colors.primary, color: 'white' };
  const cancelledStyle = { margin: 1, backgroundColor: colors.red, color: 'white' };
  const formattedDate = moment(item.date).format('dddd MMMM Do');

  const cancelTrainer = (itemId: string, field: string, trainer: any) => {
    const elementIndex = item[field].findIndex((element) => element.trainerId === trainer.trainerId);
    const updatedItem = { ...item };

    updatedItem[field][elementIndex].cancelled = true;
    getCollection(collections.PRE_BOOKINGS).doc(itemId).update({
      [field]: [
        ...item[field],

      ],
    });

    axios.post(endpoint_functions.send_notification, {
      userIds: [trainer.trainerId],
      title: 'Booking cancelled',
      message: `Your session on ${formattedDate} at ${convertTo12hTime(
        item.slot.startHour,
      )} has been cancelled.`,
    });
    toast.warning('Booking cancellation notification sent');
  };

  return {
    key: item.id,
    cells: [
      { key: item?.createdAt, content: moment((item.createdAt as any).toDate()).format('ddd MMM DD, YYYY') },
      // { key: item.clientName, content: item.clientName },
      {
        key: item.clientName,
        content: (
          <Link to={`/UserProfile/${item.userId}`} className="Link">
            {item.clientName}
          </Link>
        ),
      },
      {
        key: item.date,
        content: convertBookingDateToDateTime({
          date: item.date,
          startTime: item.slot.startHour,
          endTime: item.slot.endHour,
        }),
      },
      { key: item.address, content: item.address },
      {
        key: item.pendingBy,
        content:
          item.pendingBy.map((pendingBy) => (
            <Chip
              style={{ margin: 1 }}
              label={pendingBy.trainerName}
              onClick={() => openProfile(pendingBy.trainerId)}
              // onDelete={() => cancelTrainer(item.id, 'pendingBy', pendingBy)}
            />
          )),
      },
      {
        key: item.rejectedBy,
        content: item.rejectedBy.map((rejectedBy) => (
          <Chip
            style={{ margin: 1 }}
            label={rejectedBy.trainerName}
            onClick={() => openProfile(rejectedBy.trainerId)}
            // onDelete={() => removeTrainer(item.id, 'rejectedBy', rejectedBy)}

          />
        )),
      },
      {
        key: item.confirmedBy,
        content: item.confirmedBy.map((confirmedBy) => (
          <Chip
            style={confirmedBy.cancelled ? cancelledStyle : approvedStyle}
            label={confirmedBy.trainerName}
            onClick={() => openProfile(confirmedBy.trainerId)}
            onDelete={() => cancelTrainer(item.id, 'confirmedBy', confirmedBy)}
          />
        )),
      },
      {
        key: selected,
        content: (
          <Select
            options={trainerOptions}
            value={{ label: item.selected?.trainerName, value: item.selected?.trainerId } as any}
            onChange={(trainer) => {
              getCollection(collections.PRE_BOOKINGS).doc(item.id).update({
                selected: {
                  trainerId: trainer.value,
                  trainerName: trainer.label,
                  date: new Date(),
                },
                updatedAt: FirestoreService.getTimestamp(),
              });
            }}
          />
        ),
      },
      {
        content: (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <WarningModal
              title="Are you sure you want to delete this row?"
              onPrimaryAction={() => {
                getCollection(collections.PRE_BOOKINGS).doc(item.id).delete();
              }}
              primaryButtonLabel="Delete"
              triggerButton={(
                <Button appearance="link">
                  <TrashIcon label="Delete" primaryColor="#DE350B" />
                </Button>
              )}
            />

            <StyledSelect
              placeholder="Add trainer"
              classNamePrefix="react-select"
              options={trainerList}
              value={null}
              isSearchable
              onChange={(e) => {
                getCollection(collections.PRE_BOOKINGS).doc(item.id).update({
                  pendingBy:
                    firebase.firestore.FieldValue.arrayUnion({
                      trainerId: e.value,
                      trainerName: e.displayName || e.fullname,
                    }),
                });
                toast.success('New booking notification sent');
                axios.post(endpoint_functions.send_notification, {
                  userIds: [e.value],
                  title: 'New booking',
                  message: 'A user has booked a session with you. Check your Fitlov app for further details and confirm the session.',
                });
              }}
            />
          </div>
        ),
      },

    ],
  };
});
